import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import { TranslateService } from '@ngx-translate/core';
import { PageService, PageInterface } from '@app/services/page.service';
import { User, UserSearchParams } from '@app/models/user';
import { SessionService } from '@app/services/session.service';
import {
	UserWalker,
	UserWalkerService,
} from '@app/models/user/user-walker.service';

@Component({
	selector: 'app-molecule-respondant-list',
	templateUrl: './molecule-respondant-list.component.html',
	styleUrls: ['./molecule-respondant-list.component.less'],
})
export class MoleculeRespondantListComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'list';
	currentUser: User;

	respondants: User[] = [];
	searchParams: UserSearchParams = new UserSearchParams({
		_page: 0,
		_limit: 100,
		_sort: 'created_at',
	});
	sorts = ['created_at', 'last_name'];
	mode: 'inline' | 'card' = 'card';
	walker: UserWalker;
	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		private sessionService: SessionService,
		public userWalkerService: UserWalkerService
	) {
		super(pageService, translate);

		this.walker = this.userWalkerService.getWalker();
	}

	async ngOnInit() {
		super.ngOnInit();
		this.currentUser = await this.sessionService.getCachedUser();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	trackById(_: number, respondant: User): string {
		return respondant.getId();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-respondant_list'),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-respondant_list'),
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
