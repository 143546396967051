import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Color } from '@app/models/color';
import { BaseCalendarComponent } from '../base/base-calendar.component';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

registerLocaleData(en);

@Component({
	selector: 'app-atom-thematique-calendar',
	templateUrl: './atom-thematique-calendar.component.html',
	styleUrls: ['./atom-thematique-calendar.component.less'],
})
export class AtomThematiqueCalendarComponent extends BaseCalendarComponent
	implements AfterViewInit {
	@ViewChild('calendar', { read: ElementRef }) calendar: ElementRef;
	@ViewChild('selectFormation', { read: ElementRef })
	selectFormation: ElementRef;

	ngAfterViewInit(): void {
		this.calendar.nativeElement.childNodes[0].prepend(
			this.selectFormation.nativeElement
		);
	}

	//Event call after each filter change of calendar
	changeFiltre(date: Date): void {
		if (this.mode === 'month') {
			this.date_min = new Date(
				date.getFullYear(),
				date.getMonth(),
				1,
				0,
				0,
				0
			);
			this.date_max = new Date(
				date.getFullYear(),
				date.getMonth(),
				new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(),
				23,
				59,
				59
			);
		}
		if (this.mode === 'year') {
			this.date_min = new Date(date.getFullYear(), 0, 1, 0, 0, 0);
			this.date_max = new Date(date.getFullYear(), 11, 31, 23, 59, 59);
		}
		this.refresh();
	}

	calendarFormat(items, mode): void {
		const size =
			mode === 'month'
				? new Date(
						this.date_max.getFullYear(),
						this.date_max.getMonth() + 1,
						0
				  ).getDate()
				: 12;
		for (let i = 0; i < size; i++) {
			this.listDateMap = [...new Array(size + 1)].map((_) => []);
		}

		for (let item of items) {
			const day = <Date>item.props.start_date;
			const index = Number(
				mode === 'month' ? day.getUTCDate() : day.getMonth()
			);

			this.listDateMap[index].push({
				id: item.props._id,
				title: item.props.name,
				color: <Color>item.props.color,
				month: day.getMonth(),
			});
		}
	}

	panelChange(change: { date: Date; mode: string }): void {
		this.changeFiltre(this.date);
	}
}
