import {
	Component,
	OnInit,
	OnChanges,
	Input,
	Output,
	EventEmitter,
	HostListener,
	SimpleChanges,
} from '@angular/core';
import { Question } from '@app/models/question';
import { Answer } from '@app/models/answer';
import { User } from '@app/models/user';

@Component({
	selector: 'app-atom-question-slider',
	templateUrl: './atom-question-slider.component.html',
	styleUrls: ['./atom-question-slider.component.less'],
})
export class AtomQuestionSliderComponent implements OnInit, OnChanges {
	@Input() question: Question;
	@Input() user: User;
	@Input() selectedAnswer: string;
	@Output() answerUpdate = new EventEmitter<string>();
	sliderIndex: number = 0;

	sliderMarks: string[];
	labelMin: string;
	labelMax: string;
	verticalDisplay = false;

	constructor() {}

	async ngOnInit() {
		this.updateSliderIndex();
		this.updateQuestion();
		if (window.innerWidth > 767) {
			this.verticalDisplay = false;
		} else {
			this.verticalDisplay = true;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.selectedAnswer) this.updateSliderIndex();
		if (changes.question) this.updateQuestion();
	}

	updateQuestion() {
		this.labelMin = this.question.getLabelMinText(this.user);
		this.labelMax = this.question.getLabelMaxText(this.user);
		this.sliderMarks = this.question.props.answers.map(() => '');
	}

	updateSliderIndex(): void {
		this.sliderIndex = this.selectedAnswer
			? (this.question.props.answers as Answer[]).findIndex(
					(answer) => answer.getId() === this.selectedAnswer
			  )
			: 0;
	}

	getSliderMarks() {
		const marks = this.question.props.answers.map(() => '');
		marks[0] = this.question.getLabelMinText(this.user) ?? '';
		marks[marks.length - 1] =
			this.question.getLabelMaxText(this.user) ?? '';
		return marks;
	}

	onAnswerUpdate() {
		const selectedAnswerId = (this.question.props.answers as Answer[])[
			this.sliderIndex
		].getId();
		setTimeout(() => this.answerUpdate.emit(selectedAnswerId), 500);
	}
}
