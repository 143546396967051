import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'i18nImage',
	pure: false,
})
export class I18nImagePipe implements PipeTransform {
	constructor(private translate: TranslateService) {}

	transform(filename: string, densities: string[] = null): string {
		const filenameExploded = filename.split('.');
		const extension =
			filenameExploded.length > 1 ? `.${filenameExploded.pop()}` : '';
		filename = filenameExploded.join('.');

		if (!densities) {
			// For Src
			return this.getTransformedFilename(
				filename,
				this.translate.currentLang,
				extension
			);
		} else {
			// For SrcSet
			return densities
				.map((density) =>
					this.getTransformedFilename(
						filename,
						this.translate.currentLang,
						extension,
						density
					)
				)
				.join(', ');
		}
	}

	getTransformedFilename(filename, lang, extension, density = null): string {
		return `${filename}_${lang.toUpperCase()}${
			density ? `@${density}` : ''
		}${extension}${density ? ` ${density}` : ''}`;
	}
}
