import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-atom-skeleton-list',
	templateUrl: './atom-skeleton-list.component.html',
	styleUrls: ['./atom-skeleton-list.component.less'],
})
export class AtomSkeletonListComponent implements OnInit {
	items: number[] = new Array(10);
	constructor() {}

	ngOnInit(): void {}
}
