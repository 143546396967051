<!-- New Display Report Cards -->
<ng-container *ngIf="!inline && reportCard">
	<div
		[routerLink]="['/session', reportCard.id, 'details']"
		class="clicable p-relative"
	>
		<div class="cards-container formation-cards-container">
			<!-- Cards -->
			<div class="circle-cards">
				<div
					class="content-circle"
					[style.border-color]="reportCard.color"
					[style.border-width.px]="
						reportCard.avatarSponsor !== null ? 2 : 1
					"
				>
					<span class="day-card" [style.color]="reportCard.color">{{
						reportCard.date
							| date: 'dd':'':translateService.currentLang
					}}</span>
					<span class="month-card">{{
						reportCard.date
							| date: 'MMMM':'':translateService.currentLang
					}}</span>
					<span class="year-card">{{
						reportCard.date
							| date: 'yyyy':'':translateService.currentLang
					}}</span>
				</div>
				<div class="content-avatar">
					<nz-avatar
						class="respondant-avatar"
						[ngClass]="
							reportCard.avatarSponsor
								? 'avatarPhoto'
								: 'avatarIcone'
						"
						[nzSize]="60"
						nzIcon="user"
						[nzSrc]="reportCard.avatarSponsor"
					></nz-avatar>
				</div>
			</div>
			<!-- /Cards -->
		</div>
		<div class="name-wrapper">
			<span class="name-card">
				{{ reportCard.nameFormation }}
			</span>
		</div>
	</div>
</ng-container>
<!-- /New Display Report Cards -->

<!-- Display Report Inline -->
<ng-container *ngIf="inline && reportCard">
	<a
		class="row d-flex flex-nowrap justify-content-start align-items-center px-0 py-3 m-0 mx-md-4 inline-wrapper"
		[routerLink]="['/session', reportCard.id, 'details']"
	>
		<span class="col-5 col-xl-5 sponsor d-md-flex align-items-center">
			<nz-avatar
				class="respondant-avatar"
				[ngClass]="
					reportCard.avatarSponsor ? 'avatarPhoto' : 'avatarIcone'
				"
				[nzSize]="60"
				nzIcon="user"
				[nzSrc]="reportCard.avatarSponsor"
			></nz-avatar>
			<span *ngIf="reportCard.nameSponsor; else orphelinReport">{{
				reportCard.nameSponsor
			}}</span>
			<ng-template #orphelinReport>
				{{ 'report-moderated-without-sponsor' | translate }}
			</ng-template>
		</span>
		<span
			class="col-2 col-sm-3 col-xl-5 module d-md-flex align-items-center"
		>
			{{ reportCard.nameFormation }}
		</span>
		<span class="col-3 col-sm-2 date">
			{{ reportCard.date | date: 'dd/MM/yy' }}
		</span>
	</a>
</ng-container>
<!-- /Display Report Inline -->
