<div
	*ngIf="!loading; else loadingTpl"
	class="container-fluid px-0 px-xl-5 py-4 py-xl-5"
>
	<app-atom-formation-details
		*ngIf="formation"
		[formation]="formation"
	></app-atom-formation-details>
</div>

<ng-template #loadingTpl>
	<div class="text-center my-5 py-5">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
