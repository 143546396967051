import { Injectable } from '@angular/core';
import {
	FormateurProfile,
	FormateurProfileInterface,
	FormateurProfilePayload,
} from './formateur-profile';
import { FormateurProfileSearchParamsInterface } from './formateur-profile-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class FormateurProfileService extends BaseModelService<
	FormateurProfile,
	FormateurProfileInterface,
	FormateurProfilePayload,
	FormateurProfileSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'formateur-profile';
	}
	/** @inheritDoc */
	protected new(object: FormateurProfileInterface): FormateurProfile {
		return new FormateurProfile(object);
	}
}
