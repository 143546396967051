import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
import {
	SurveyLabel,
	SurveyLabelInterface,
} from '../survey-label/survey-label';
import { AxeWeight, AxeWeightInterface } from '../axe-weight/axe-weight';
import { User } from '../user';
export interface AnswerInterface extends BaseModelInterface {
	created_at: number | Date;
	labels: (string | SurveyLabel | SurveyLabelInterface)[];
	weights?: (string | AxeWeight | AxeWeightInterface)[];
}
export interface AnswerPayload {
	labels: (string | SurveyLabel | SurveyLabelInterface)[];
	weights?: (string | AxeWeight | AxeWeightInterface)[];
}
type AnswerPayloadKey = keyof AnswerPayload;
export class Answer extends BaseModel<AnswerInterface, AnswerPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of labels has been populated */
	labelsExists(): boolean {
		return (
			!!this.props &&
			this.props.labels instanceof Array &&
			(<SurveyLabel[]>this.props.labels).every((item) => {
				return item instanceof SurveyLabel && item.exists();
			})
		);
	}
	/** Denotes if the instance of weights has been populated */
	weightsExists(): boolean {
		return (
			!!this.props &&
			this.props.weights instanceof Array &&
			(<AxeWeight[]>this.props.weights).every((item) => {
				return item instanceof AxeWeight && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: AnswerInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.labels instanceof Array) {
			this.props.labels = (<SurveyLabelInterface[]>this.props.labels).map(
				(item) => {
					return typeof item === 'object'
						? new SurveyLabel(item)
						: item;
				}
			);
		}
		if (this.props.weights instanceof Array) {
			this.props.weights = (<AxeWeightInterface[]>this.props.weights).map(
				(item) => {
					return typeof item === 'object'
						? new AxeWeight(item)
						: item;
				}
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): AnswerInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.weights === 'undefined') {
			props.weights = [];
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.labels instanceof Array) {
			props.labels = (<SurveyLabel[]>props.labels).map((item) => {
				return item instanceof SurveyLabel ? item.toObject() : item;
			});
		}
		if (this.props.weights instanceof Array) {
			props.weights = (<AxeWeight[]>props.weights).map((item) => {
				return item instanceof AxeWeight ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): AnswerPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as AnswerInterface);
		payload.labels = payload.labels
			? payload.labels.map((i) => this.extractId(i))
			: null;
		payload.weights = payload.weights
			? payload.weights.map((i) => this.extractId(i))
			: null;
		return payload as AnswerPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): AnswerPayloadKey[] {
		return ['labels', 'weights'];
	}

	/**
	 * Return text label that best fits user age and sport
	 */
	getText(user: User): null | string {
		// guards against unpopulated labels
		if (!this.props.labels.length) return null;
		if (typeof this.props.labels[0] === 'string')
			return 'unpopulated labels';

		const matches: {
			partial: string;
			default: string;
		} = {
			partial: null,
			default: null,
		};

		const labels = this.props.labels as SurveyLabel[];

		return labels[0].getLabel();
	}
}
