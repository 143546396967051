import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import {
	FormationTheme,
	FormationThemeInterface,
} from '../formation-theme/formation-theme';
export interface ThematiqueFormateurInterface extends BaseModelInterface {
	created_at: number | Date;
	label: string;
	formateur: string | User | UserInterface;
	theme: string | FormationTheme | FormationThemeInterface;
}
export interface ThematiqueFormateurPayload {
	label: string;
	formateur: string | User | UserInterface;
	theme: string | FormationTheme | FormationThemeInterface;
}
type ThematiqueFormateurPayloadKey = keyof ThematiqueFormateurPayload;
export class ThematiqueFormateur extends BaseModel<
	ThematiqueFormateurInterface,
	ThematiqueFormateurPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.label}`;
	}
	/** Denotes if the instance of formateur has been populated */
	formateurExists(): boolean {
		return (
			!!this.props &&
			this.props.formateur instanceof User &&
			this.props.formateur.exists()
		);
	}
	/** Denotes if the instance of theme has been populated */
	themeExists(): boolean {
		return (
			!!this.props &&
			this.props.theme instanceof FormationTheme &&
			this.props.theme.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: ThematiqueFormateurInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.formateur === 'object') {
			this.props.formateur = new User(
				<UserInterface>this.props.formateur
			);
		}
		if (typeof this.props.theme === 'object') {
			this.props.theme = new FormationTheme(
				<FormationThemeInterface>this.props.theme
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ThematiqueFormateurInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.formateur instanceof User) {
			props.formateur = props.formateur.toObject();
		}
		if (props.theme instanceof FormationTheme) {
			props.theme = props.theme.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ThematiqueFormateurPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ThematiqueFormateurInterface);
		payload.formateur = payload.formateur
			? this.extractId(payload.formateur)
			: null;
		payload.theme = payload.theme ? this.extractId(payload.theme) : null;
		return payload as ThematiqueFormateurPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): ThematiqueFormateurPayloadKey[] {
		return ['label', 'formateur', 'theme'];
	}
}
