import { Injectable } from '@angular/core';
import { Color, ColorInterface, ColorPayload } from './color';
import { ColorSearchParamsInterface } from './color-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class ColorService extends BaseModelService<
	Color,
	ColorInterface,
	ColorPayload,
	ColorSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'color';
	}
	/** @inheritDoc */
	protected new(object: ColorInterface): Color {
		return new Color(object);
	}
}
