import { Component, OnInit, Input } from '@angular/core';
import { User } from '@app/models/user';

@Component({
	selector: 'app-atom-respondant-card',
	templateUrl: './atom-respondant-card.component.html',
	styleUrls: ['./atom-respondant-card.component.less'],
})
export class AtomRespondantCardComponent implements OnInit {
	@Input() respondant: User;
	@Input() inline = false;

	constructor() {}

	ngOnInit() {}
}
