import { Component, OnInit, Input, HostListener } from '@angular/core';
import { BaseReportModerated } from '@app/abstracts/base-report-moderated/base-report-moderated.component';
import { Color, ColorService } from '@app/models/color';
import { Report } from '@app/models/report';
import { User } from '@app/models/user';
import { AppModalService } from '@app/services/app-modal.service';
import { NavigationService } from '@app/services/navigation.service';
import { SessionService } from '@app/services/session.service';

@Component({
	selector: 'app-atom-report-moderated-simplified',
	templateUrl: './atom-report-moderated-simplified.component.html',
	styleUrls: ['./atom-report-moderated-simplified.component.less'],
})
export class AtomReportModeratedSimplifiedComponent extends BaseReportModerated
	implements OnInit {
	@Input() allowDetailled = false;
	@Input() typeReport: string;
	@Input() colorThematique: Color;
	@Input() owner: string[];
	@Input() surveyOpenResults?: [];

	sendPrint: boolean = false;
	printLoading: boolean = false;

	showIndividualReport = false;
	showGroupedReport = false;

	userLogged: User;

	constructor(
		protected colorService: ColorService,
		public appModalService: AppModalService,
		public navigationService: NavigationService,
		private sessionService: SessionService
	) {
		super(colorService);
	}

	async ngOnInit(): Promise<void> {
		await super.ngOnInit();

		this.userLogged = await this.sessionService.getCachedUser();

		const displayMode = (<Report>(
			this.reportModerated.props.report
		)).getDisplayMode(this.user);

		this.showIndividualReport =
			displayMode === 'individual' || displayMode === 'both';
		this.showGroupedReport =
			displayMode === 'grouped' || displayMode === 'both';

		if (
			this.user &&
			!this.showIndividualReport &&
			!this.showGroupedReport
		) {
			this.navigationService.go('dashboard');
		}
	}

	@HostListener('window:afterprint', [])
	onWindowAfterPrint() {
		this.sendPrint = false;
	}

	handleClickToPrint(): void {
		this.printLoading = true;
		this.sendPrint = true;
		setTimeout(() => {
			window.print();
			this.printLoading = false;
		}, 1000);
	}
}
