import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

interface S3FormData {
	key: string;
	bucket: string;
	policy: string;
	'x-amz-date': string;
	'x-amz-algorithm': string;
	'x-amz-credential': string;
	'x-amz-signature': string;
	file?: any;
}

interface S3Token {
	post_url: string;
	form_data: S3FormData;
}

@Injectable()
export class S3Service {
	/** Constructor */
	constructor(private http: HttpClient) {}

	/** Convert token to form data */
	private static getFormData(token: S3Token): FormData {
		const formData = new FormData();
		for (const key in token.form_data) {
			if (token.form_data.hasOwnProperty(key)) {
				formData.append(key, token.form_data[key]);
			}
		}

		return formData;
	}

	/** Upload file to S3 from an input and returns its URI */
	async uploadFromInput(input: HTMLInputElement): Promise<string> {
		// Check if a file is present
		if (input.files.length === 0) {
			throw new Error('No input file');
		}

		return <string>await this.uploadFile(input.files.item(0));
	}

	/** Upload file to S3 */
	async uploadFile(file: File): Promise<string> {
		// Get token from S3
		const s3Token = await this.getToken(file.type);

		const formData = S3Service.getFormData(s3Token);

		// Append file
		formData.append('file', file);

		// Send request
		return <string>await this.http
			.post(s3Token.post_url, formData, { responseType: 'arraybuffer' })
			.toPromise()
			.then(() => {
				return s3Token.form_data.key;
			});
	}

	/** Get an upload token */
	private async getToken(mime: string): Promise<S3Token> {
		return <S3Token>(
			await this.http
				.get(`${environment.api.uri}/s3/token`, { params: { mime } })
				.toPromise()
		);
	}

	/**
	 * Upload file to S3 and returns its URI
	 * @param {File} file
	 * @return {Promise<string>}
	 */
	async uploadFromFileAndReportProgress(
		file: File
	): Promise<{ path: string; request: Observable<Object> }> {
		// Check if a file is present
		if (!file) {
			throw new Error('No file');
		}

		// Get token from S3
		const s3Token = await this.getToken(file.type);
		const formData = S3Service.getFormData(s3Token);

		// Append file
		formData.append('file', file);

		// Send request
		const req = new HttpRequest<Object>(
			'POST',
			s3Token.post_url,
			formData,
			{
				reportProgress: true,
				responseType: 'arraybuffer',
			}
		);

		return {
			path: s3Token.form_data.key,
			request: this.http.request(req),
		};
	}
}
