import { Component, Input } from '@angular/core';
import { EntitySelectComponent } from '@app/abstracts';
import { ErrorService } from '@app/services/error.service';
import { UserSearchParams } from '../user-search-params';
import { UserService } from '../user.service';
import { User, UserRole } from '../user';
@Component({
	selector: 'hpf-user-select',
	templateUrl:
		'../../../abstracts/entity-select/entity-select.component.html',
})
export class UserSelectComponent extends EntitySelectComponent<User> {
	@Input() roleFilter: UserRole;
	@Input() isAdmin = false;
	/** Constructor */
	constructor(
		private userService: UserService,
		protected errorService: ErrorService
	) {
		super(errorService);
	}
	/** @inheritDoc */
	protected async getList(): Promise<User[]> {
		const params = new UserSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		if (this.filterEnabled && this.filterValue) {
			params.props._text = this.filterValue;
		}
		if (this.roleFilter) params.props.role = this.roleFilter;
		return this.isAdmin
			? (await this.userService.listAsAdmin(params.toObject())).items
			: (await this.userService.list(params.toObject())).items;
	}

	/** @inheritDoc */
	protected async getOne(id: string): Promise<User> {
		return await this.userService.get(id);
	}
}
