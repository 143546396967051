import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NzCalendarMode } from 'ng-zorro-antd/calendar';
import {
	Formation,
	FormationSearchParams,
	FormationService,
} from '@app/models/formation';
import {
	FormationThematiqueSearchParams,
	FormationThematique,
	FormationThematiqueService,
} from '@app/models/formation-thematique';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorService } from '@app/services/error.service';
import { Color } from '@app/models/color';
import { User } from '@app/models/user';

registerLocaleData(en);

@Component({
	selector: 'app-atom-base-calendar',
	template: '',
})
export abstract class BaseCalendarComponent implements OnInit, OnDestroy {
	date: Date;
	date_min: Date;
	date_max: Date;
	mode: NzCalendarMode = 'month';

	searchParams: FormationSearchParams = new FormationSearchParams({
		_page: 0,
		_limit: 100,
		_order: 'asc',
		_sort: null,
	});
	sorts = ['created_at', 'name'];

	listDateMap: {
		[key: number]: {
			id: string;
			title: string;
			color: Color;
			month: number;
		}[];
	};

	@Input() user: User;

	/** @type {Subject<void>} Observables unsubscriber */
	protected unsubscribe: Subject<void> = new Subject<void>();

	/** Search params of model */
	@Input() formationThematiqueSearchParams: FormationThematiqueSearchParams;

	items: FormationThematique[] = [];
	loading = true;

	constructor(
		private formationThematiqueService: FormationThematiqueService,
		private formationService: FormationService,
		private errorService: ErrorService
	) {}

	async ngOnInit(): Promise<void> {
		this.date = new Date();
		this.changeFiltre(this.date);
		this.formationThematiqueSearchParams
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(() => {
				this.refresh();
			});
	}

	ngOnDestroy() {
		this.unsubscribe.next();
	}

	async getFormations(): Promise<Formation[]> {
		return this.formationService
			.list(this.searchParams.toObject())
			.then((result) => result.items)
			.catch((err) => {
				this.errorService.handle(err);
				return [];
			});
	}

	//Event call after each filter change of calendar
	abstract changeFiltre(date: Date): void;

	async refresh() {
		this.loading = true;
		if (this.formationThematiqueSearchParams) {
			const formations = await this.getFormations();

			const params = new FormationThematiqueSearchParams({
				...this.formationThematiqueSearchParams.toObject(),
				start_date__min: this.date_min,
				start_date__max: this.date_max,
				formation: formations.map((f) => f.props._id),
			}).toObject();

			this.items = formations.length
				? await this.formationThematiqueService
						.list(params)
						.then((result) =>
							this.calendarFormat(result.items, this.mode)
						)
						.catch((err) => {
							this.errorService.handle(err);
							return null;
						})
				: [];
		}

		this.loading = false;
	}

	abstract calendarFormat(items, mode): void;
}
