import { Injectable } from '@angular/core';
import {
	FormationSession,
	FormationSessionInterface,
	FormationSessionPayload,
} from './formation-session';
import { FormationSessionSearchParamsInterface } from './formation-session-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class FormationSessionService extends BaseModelService<
	FormationSession,
	FormationSessionInterface,
	FormationSessionPayload,
	FormationSessionSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'formation-session';
	}
	/** @inheritDoc */
	protected new(object: FormationSessionInterface): FormationSession {
		return new FormationSession(object);
	}
}
