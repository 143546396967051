import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { User } from '@app/models/user';
import { Image } from '@app/models/image';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormationThematiqueSearchParams } from '@app/models/formation-thematique';
import {
	Formation,
	FormationSearchParams,
	FormationService,
} from '@app/models/formation';
import { ErrorService } from '@app/services/error.service';

interface DashboardSponsorInterface {
	name: string;
	avatar: string;
	formations: string[];
}

@Component({
	selector: 'app-atom-dashboard-respondant',
	templateUrl: './atom-dashboard-respondant.component.html',
	styleUrls: ['./atom-dashboard-respondant.component.less'],
})
export class AtomDashboardRespondantComponent implements OnInit, OnDestroy {
	@Input() user: User;

	loading = false;
	subscription: Subscription;

	listIdFormation: string[] = [];
	sponsors: DashboardSponsorInterface[];

	formationSearchParams: FormationSearchParams;
	formationThematiqueSearchParams: FormationThematiqueSearchParams;

	constructor(
		private router: Router,
		private formationService: FormationService,
		private errorService: ErrorService
	) {
		// Trick to force the molecule to refresh at navigation
		this.router.routeReuseStrategy.shouldReuseRoute = function () {
			return false;
		};
		// Refregh page at navigation
		this.subscription = this.router.events
			.pipe(
				filter((event: RouterEvent) => event instanceof NavigationEnd)
			)
			.subscribe(() => {
				this.router.navigated = false;
			});
	}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		await this.getFormationId();
		this.loading = false;

		this.formationThematiqueSearchParams = new FormationThematiqueSearchParams(
			{
				_page: 0,
				_limit: 100,
				_sort: 'start_date',
				_populate: ['color'],
				formation: this.listIdFormation,
			}
		);
	}

	async ngOnDestroy(): Promise<void> {
		this.subscription.unsubscribe();
	}

	async getFormationId() {
		const formationSearchParams = new FormationSearchParams({
			_page: 0,
			_limit: 100,
			_populate: ['sponsor.avatar'],
		});
		await this.formationService
			.list(formationSearchParams.toObject())
			.then((result) => {
				this.groupBySponsor(result.items);
				result.items.map((f) => this.listIdFormation.push(f.getId()));
			})
			.catch((err) => {
				this.errorService.handle(err);
				return null;
			});
	}

	async groupBySponsor(formations) {
		const listSponsor = [];
		//Determine number of sponsor
		for (let i = 0; i < formations.length; i++) {
			listSponsor[(<User>formations[i].props.sponsor).getId()] = {
				name: (<User>formations[i].props.sponsor).getLabel(),
				avatar: (<User>formations[i].props.sponsor).avatarExists()
					? (<Image>(
							(<User>formations[i].props.sponsor).props.avatar
					  )).getUrl()
					: null,
				formations: [],
			};
		}

		//Get formation for each sponsor
		formations.forEach((formation) => {
			listSponsor[
				(<User>formation.props.sponsor).getId()
			].formations.push(formation.props.name);
		});

		//Change Format of listSponsor
		this.sponsors = Object.values(listSponsor);
	}
}
