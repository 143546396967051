import { Component, Input, OnInit } from '@angular/core';
import { Color } from '@app/models/color';
import { Formation } from '@app/models/formation';
import { TranslateService } from '@ngx-translate/core';

interface FormationsSponsorsInterface {
	name: string;
	totalRespondant: number;
	thematiques: ThematiqueSponsorsInterface[];
}

interface ThematiqueSponsorsInterface {
	id_thematique: string;
	title: string;
	date: Date;
	color: Color;
	status: string;
}
@Component({
	selector: 'app-atom-sponsor-formation-details',
	templateUrl: './atom-sponsor-formation-details.component.html',
	styleUrls: ['./atom-sponsor-formation-details.component.less'],
})
export class AtomSponsorFormationDetailsComponent implements OnInit {
	@Input() formation: FormationsSponsorsInterface;

	constructor(public translateService: TranslateService) {}

	ngOnInit(): void {}
}
