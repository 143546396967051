import { Injectable } from '@angular/core';
import {
	FormationAccess,
	FormationAccessInterface,
	FormationAccessPayload,
} from './formation-access';
import { FormationAccessSearchParamsInterface } from './formation-access-search-params';
import { BaseModelService } from '@app/abstracts';
import { Helpers } from '@app/shared/helpers';
@Injectable()
export class FormationAccessService extends BaseModelService<
	FormationAccess,
	FormationAccessInterface,
	FormationAccessPayload,
	FormationAccessSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'formation-access';
	}
	/** @inheritDoc */
	protected new(object: FormationAccessInterface): FormationAccess {
		return new FormationAccess(object);
	}

	/** @inheritDoc */
	protected transformSearchParams(
		searchParams: FormationAccessSearchParamsInterface
	): FormationAccessSearchParamsInterface {
		// Search by edge for Date
		if (typeof searchParams.date__min !== 'undefined') {
			searchParams.date__min = Helpers.toMidnightUTC(
				searchParams.date__min
			);
		}
		if (typeof searchParams.date__max !== 'undefined') {
			searchParams.date__max = Helpers.toLastSecondUTC(
				searchParams.date__max
			);
		}
		if (typeof searchParams.date !== 'undefined') {
			searchParams.date__min = Helpers.toMidnightUTC(searchParams.date);
			searchParams.date__max = Helpers.toLastSecondUTC(searchParams.date);
			delete searchParams.date;
		}
		return searchParams;
	}
}
