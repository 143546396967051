<div
	*ngIf="!loading; else loadingTpl"
	class="container-fluid px-0 px-xl-5 py-4 py-xl-5"
>
	<div class="container-xl px-xl-0 mb-5 ml-0 details-sponsor-container">
		<app-atom-sponsor-details
			[sponsor]="sponsor"
			[totalRespondant]="totalRespondant"
		>
		</app-atom-sponsor-details>
	</div>
	<div
		class="container-xl px-xl-0 mb-5 ml-0 details-formation-sponsor-container"
	>
		<ng-container *ngFor="let formation of formations">
			<app-atom-sponsor-formation-details [formation]="formation">
			</app-atom-sponsor-formation-details>
		</ng-container>
	</div>
</div>

<ng-template #loadingTpl>
	<div class="text-center my-5 py-5">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
