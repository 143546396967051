import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Interface for Page
 */
export interface PageBreadcrumbsInterface {
	label: string;
	link?: string[] | string;
}

export interface PageInterface {
	hideTopBar?: boolean;
	hideMenu?: boolean;
	breadcrumbs?: PageBreadcrumbsInterface[];
	title?: string;
	skeleton?: 'list' | 'dashboard' | null;
}

@Injectable()
export class PageService {
	/**
	 * Subject for the event
	 *
	 * @type {Subject<PageInterface>}
	 * @private
	 */
	private page = new Subject<PageInterface>();

	private defaultPage: PageInterface = {
		hideTopBar: false,
		hideMenu: false,
		breadcrumbs: [],
		title: null,
		skeleton: null,
	};

	private currentPage;

	/**
	 * Set the current page header
	 *
	 * @param page
	 */
	setPage(page: PageInterface, clear = false): void {
		this.currentPage = Object.assign(
			{},
			this.defaultPage,
			clear ? {} : this.currentPage,
			page
		);

		this.page.next(this.currentPage);
	}

	/**
	 * Clear the page header
	 */
	clearPage(): void {
		this.setPage({}, true);
	}

	/**
	 * Retrieve the current page as Observable
	 *
	 * @return {Observable<PageInterface>}
	 */
	getPage(): Observable<PageInterface> {
		return this.page.asObservable();
	}

	getCurrentPage(): PageInterface {
		return this.currentPage ? this.currentPage : this.defaultPage;
	}
}
