import { Helpers } from '@app/shared/helpers';
import {
	BaseModelSearchParams,
	BaseModelSearchParamsInterface,
} from '@app/abstracts';
/** Used to export and import search params */
export interface ReportSearchParamsInterface
	extends BaseModelSearchParamsInterface {
	_sort?: '_id' | 'created_at' | 'name' | 'shortcode' | 'grouped';
	_id?: string[];
	_text?: string;
	name?: string;
	shortcode?: string;
	surveys?: string[];
	moderation_type?: string;
	display_mode?: string;
	grouped?: 'true' | 'false' | boolean;
}
/** Manage report search params */
export class ReportSearchParams extends BaseModelSearchParams<
	ReportSearchParamsInterface
> {
	/** @inheritDoc */
	fromObject(input: Partial<ReportSearchParamsInterface>): void {
		// Clone object with allowed keys only
		const props = {} as ReportSearchParamsInterface;
		this.allowedKeys().map((key: string) => {
			if (typeof input[key] !== 'undefined') {
				props[key] = input[key];
			}
		});
		// Keep default values
		if (typeof props._page === 'undefined') {
			props._page = this.defaultPage;
		}
		if (typeof props._limit === 'undefined') {
			props._limit = this.defaultLimit;
		}
		if (typeof props._sort === 'undefined') {
			props._sort = this.defaultSort;
		}
		if (typeof props._order === 'undefined') {
			props._order = this.defaultOrder;
		}
		// Convert potentially multiple primary keys
		if (typeof props._id === 'string') {
			props._id = (<string>props._id).split(',').filter((s) => s.length);
		}
		// Convert potentially multiple ids for surveys
		if (typeof props.surveys === 'string') {
			props.surveys = (<string>props.surveys)
				.split(',')
				.filter((s) => s.length);
		}
		// Convert boolean grouped
		if (typeof props.grouped !== 'undefined') {
			props.grouped = Helpers.convertToBoolean(props.grouped);
		}
		// Assign values
		this.props = props;
		this.next();
	}
	/** @inheritDoc */
	toObject(): ReportSearchParamsInterface {
		// Filter not allowed, undefined and null values
		const props = {} as ReportSearchParamsInterface;
		this.allowedKeys().map(Helpers.prepareObjectForApi(this.props, props));
		// Convert boolean grouped
		if (typeof props.grouped !== 'undefined') {
			props.grouped = props.grouped ? 'true' : 'false';
		}
		return props;
	}
	/** @inheritDoc */
	protected allowedKeys(): string[] {
		return super
			.allowedKeys()
			.concat([
				'_id',
				'_text',
				'name',
				'shortcode',
				'surveys',
				'moderation_type',
				'grouped',
				'display_mode',
			]);
	}
}
