<!-- Report Moderated List -->
<ng-container *ngFor="let report of reportsModerated; let i = index">
	<ng-container *ngIf="report.hasAccess; else elseBlock">
		<app-atom-report-moderated-simplified
			[title]="report.title"
			[owner]="report.owner"
			[typeReport]="report.typeReport"
			[surveyOpenResults]="surveyOpenResultsByReportModerateds[i]"
			[reportModerated]="report.reportModerated"
			[colorThematique]="colorThematique"
			[user]="user"
		></app-atom-report-moderated-simplified>
	</ng-container>
	<ng-template #elseBlock>
		<h2>
			{{ 'no_right_to_see_report' | translate }}
		</h2>
	</ng-template>
</ng-container>
<!-- Report Moderated List -->

<!-- Coach Individual Survey Result -->
<div *ngIf="coachIndividualSurveyResults.length">
	<app-atom-report-moderated-user-list
		[surveyResults]="coachIndividualSurveyResults"
		[customLink]="'/session/' + formationThematique.getId() + '/user/'"
	></app-atom-report-moderated-user-list>
</div>
<!-- Coach Individual Survey Result -->
