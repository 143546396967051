import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PageService, PageInterface } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@app/services/session.service';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import {
	FormationThematique,
	FormationThematiqueSearchParams,
} from '@app/models/formation-thematique';
import {
	FormationThematiqueWalker,
	FormationThematiqueWalkerService,
} from '@app/models/formation-thematique/formation-thematique-walker.service';
import { User } from '@app/models/user';

@Component({
	selector: 'app-molecule-formation-thematique-list',
	templateUrl: './molecule-formation-thematique-list.component.html',
	styleUrls: ['./molecule-formation-thematique-list.component.less'],
})
export class MoleculeFormationThematiqueListComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'list';

	user: User;
	thematiques: FormationThematique[] = [];
	searchParams: FormationThematiqueSearchParams;
	sorts = ['start_date', 'name'];
	mode: 'inline' | 'card' = 'card';
	walker: FormationThematiqueWalker;
	constructor(
		private sessionService: SessionService,
		protected pageService: PageService,
		protected translate: TranslateService,
		public formationThematiqueWalkerService: FormationThematiqueWalkerService
	) {
		super(pageService, translate);

		this.walker = this.formationThematiqueWalkerService.getWalker();
	}

	async ngOnInit() {
		super.ngOnInit();
		this.user = await this.sessionService.getCachedUser();
		this.searchParams = new FormationThematiqueSearchParams({
			_page: 0,
			_limit: 100,
			_order: 'asc',
			_sort: 'start_date',
			_populate: ['color', 'formation.sponsor.avatar'],
			formateur: this.user.getId(),
		});
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	trackById(_: number, thematique: FormationThematique): string {
		return thematique.getId();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant(
				'page_title-formation_thematique_list'
			),
			breadcrumbs: [
				{
					label: this.translate.instant(
						'top_bar-formation_thematique_list'
					),
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
