import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService, UserSearchParams } from '@app/models/user';
import { ErrorService } from '@app/services/error.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-atom-respondant-counter',
	templateUrl: './atom-respondant-counter.component.html',
	styleUrls: ['./atom-respondant-counter.component.less'],
})
export class AtomRespondantCounterComponent implements OnInit, OnDestroy {
	/** @type {Subject<void>} Observables unsubscriber */
	protected unsubscribe: Subject<void> = new Subject<void>();

	@Input() searchParams: UserSearchParams;

	loading = true;
	count: number;

	constructor(
		private userService: UserService,
		private errorService: ErrorService
	) {}

	ngOnInit() {
		this.refresh();

		this.searchParams.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
			this.refresh();
		});
	}

	ngOnDestroy() {
		this.unsubscribe.next();
	}

	async refresh() {
		this.loading = true;
		if (this.searchParams) {
			const params = this.searchParams.toObjectSanitizedForCount();
			this.count = await this.userService.count(params).catch((err) => {
				this.errorService.handle(err);
				return null;
			});
		}
		this.loading = false;
	}
}
