<div
	class="graph-container"
	[style.height]="height"
	[ngClass]="'theme--' + theme + ' graph--' + typeGraph"
>
	<div
		class="bandeau"
		*ngIf="colorThematique"
		[ngStyle]="
			colorThematique.props.gradient_min &&
			colorThematique.props.gradient_max
				? {
						background:
							'linear-gradient(180deg, ' +
							colorThematique.props.gradient_min +
							', ' +
							colorThematique.props.gradient_max +
							')'
				  }
				: {
						background: colorThematique.props.main
				  }
		"
	></div>
	<div
		class="bandeau"
		*ngIf="!colorThematique"
		[ngStyle]="{
			background: 'linear-gradient(180deg, #79c5f1, #07a1e2)'
		}"
	></div>
	<h2 class="pt-4 mb-3" *ngIf="titleGraph">
		{{ titleGraph }}
	</h2>

	<div class="averages pb-4">
		<span class="d-block" *ngFor="let average of averages"
			><strong
				>{{
					average.name === 'all'
						? ('graph-average-all_surveys' | translate)
						: ('graph-average-survey' | translate)
				}}:</strong
			>
			{{ average.average }}/{{ average.maxAxis }}</span
		>
	</div>

	<div #graphDiv class="graph text-center"></div>
</div>
