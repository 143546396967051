import { Injectable } from '@angular/core';
import { AxeWeight, AxeWeightInterface, AxeWeightPayload } from './axe-weight';
import { AxeWeightSearchParamsInterface } from './axe-weight-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class AxeWeightService extends BaseModelService<
	AxeWeight,
	AxeWeightInterface,
	AxeWeightPayload,
	AxeWeightSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'axe-weight';
	}
	/** @inheritDoc */
	protected new(object: AxeWeightInterface): AxeWeight {
		return new AxeWeight(object);
	}
}
