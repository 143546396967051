<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
	<div class="container-xl px-xl-0 mb-5 ml-0 details-formation-container">
		<div class="row mx-0">
			<div
				class="col-12 px-4 px-md-5 py-3 d-flex flex-row align-items-center"
			>
				<!-- Avatar Sponsor -->
				<div class="mr-3 mr-md-5 container-avatar">
					<app-atom-skeleton
						class="avatarIcone d-md-none avatarFormation"
						[height]="120"
						[circle]="true"
						[animated]="true"
					></app-atom-skeleton>
					<app-atom-skeleton
						avatarIcone
						d-none
						d-md-inline-block
						avatarFormation
						[height]="150"
						[circle]="true"
						[animated]="true"
					>
					</app-atom-skeleton>
				</div>
				<!-- Avatar Sponsor -->
				<!-- Infos Formation -->
				<div class="infos-formation-container w-100">
					<h2 class="formation-title mb-1">
						<app-atom-skeleton
							[height]="37"
							[width]="50"
							[animated]="true"
						></app-atom-skeleton>
					</h2>
					<p class="formation-description mb-1">
						<app-atom-skeleton
							[height]="20"
							[width]="50"
							[animated]="true"
						></app-atom-skeleton>
					</p>
				</div>
				<!-- Infos Formation -->
			</div>
		</div>
	</div>

	<div class="container-xl px-xl-0 mb-5 ml-0">
		<div class="row">
			<div class="col-12">
				<table>
					<thead>
						<tr>
							<th>{{ 'table_head-thematique' | translate }}</th>
							<th>{{ 'table_head-state' | translate }}</th>
							<th>{{ 'table_head-date' | translate }}</th>
							<th>
								{{ 'table_head-count-respondants' | translate }}
							</th>
							<th>
								{{ 'table_head-coach' | translate }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of items">
							<td class="title">
								<app-atom-skeleton
									[height]="20"
									[animated]="true"
								></app-atom-skeleton>
							</td>
							<td class="state">
								<app-atom-skeleton
									[height]="20"
									[animated]="true"
								></app-atom-skeleton>
							</td>
							<td class="date">
								<app-atom-skeleton
									[height]="20"
									[animated]="true"
								></app-atom-skeleton>
							</td>
							<td class="respondants">
								<app-atom-skeleton
									[height]="20"
									[animated]="true"
								></app-atom-skeleton>
							</td>
							<td class="coach">
								<app-atom-skeleton
									[height]="20"
									[animated]="true"
								></app-atom-skeleton>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="container-xl px-xl-0 mb-5 ml-0">
		<app-atom-skeleton [height]="400" [animated]="true"></app-atom-skeleton>
	</div>
</div>
