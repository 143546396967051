import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-atom-skeleton-dashboard',
	templateUrl: './atom-skeleton-dashboard.component.html',
	styleUrls: ['./atom-skeleton-dashboard.component.less'],
})
export class AtomSkeletonDashboardComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
