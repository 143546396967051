import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { ExplainErrorsService } from '@app/services/explain-errors.service';
import { PasswordService } from '@app/services/password.service';
import { NavigationService } from '@app/services/navigation.service';

@Component({
	selector: 'app-atom-change-password',
	templateUrl: './atom-change-password.component.html',
	styleUrls: ['./atom-change-password.component.less'],
})
export class AtomChangePasswordComponent implements OnInit {
	form: FormGroup;
	success = false;
	processing: boolean;
	passwordVisible = false;
	passwordMinLength = 6;

	constructor(
		private formBuilder: FormBuilder,
		private passwordService: PasswordService,
		private message: NzMessageService,
		private navigation: NavigationService,
		public explainErrorService: ExplainErrorsService
	) {}

	ngOnInit() {
		this._initForm();
	}

	private _initForm(): void {
		this.form = this.formBuilder.group({
			old_password: ['', [Validators.required]],
			new_password: [
				'',
				[
					Validators.minLength(this.passwordMinLength),
					Validators.required,
				],
			],
		});
	}

	onSubmit(): void {
		this.processing = true;
		this.passwordService
			.change(
				this.form.get('old_password').value,
				this.form.get('new_password').value
			)
			.then(() => {
				this.success = true;
				this.navigation.delayed(['my-account']);
			})
			.catch((err) => {
				if (err.error.statusCode === 400) {
					this.form.get('new_password').setErrors({ pattern: true });
				} else if (err.error.statusCode === 403) {
					this.form
						.get('old_password')
						.setErrors({ passwordWrong: true });
				} else {
					this.message.create('error', err.message);
				}
			})
			.then(() => (this.processing = false));
	}
}
