<div class="description-container">
	<h2>
		<span class="title-survey">
			{{ survey.getLabel() }}
		</span>
		<span class="meta-survey">
			<span class="name-report">{{ thematique.props.name }}</span> |
			{{ 'survey' | translate }} {{ surveyNumber + 1 }}
		</span>
	</h2>
	<div [innerHTML]="survey.props.description"></div>
</div>
<div class="animation-container">
	<app-atom-wheel></app-atom-wheel>
</div>
