<app-atom-model-counter
	[label]="'counter-label-sponsor' | translate"
	[customClass]="'counter-sponsor'"
	[loading]="loading"
	[count]="count"
	[iconTpl]="iconTpl"
	[linkFullList]="['/sponsor/list']"
></app-atom-model-counter>

<ng-template #iconTpl>
	<i nz-icon nzType="user" nzTheme="outline"></i>
</ng-template>
