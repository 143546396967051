<div
	*ngIf="!loading; else loadingTpl"
	class="container-fluid px-0 px-xl-5 py-4 py-xl-5"
>
	<!-- Respondant details -->
	<ng-container *ngIf="details; else loadingTpl">
		<div class="container-xl px-xl-0 mb-5 ml-0">
			<div class="row mx-0">
				<div
					class="col-12 px-3 pb-3 d-flex flex-row respondant-container"
				>
					<!-- Avatar -->
					<div class="card-wrapper">
						<div class="avatars-container">
							<nz-avatar
								[nzSize]="150"
								nzIcon="user"
								[nzSrc]="details.avatar"
								class="respondant-avatar"
							></nz-avatar>
						</div>
					</div>
					<!-- Avatar -->
					<!-- Infos respondant -->
					<div class="infos-respondant-container">
						<p class="respondant-name mb-1">
							{{ details.name }}
						</p>
					</div>
					<!-- Infos respondant -->
					<!-- Certifications respondant -->
					<div class="certifications-container">
						<div
							*ngFor="let report of details.reports"
							class="logo-certification"
						>
							<img [src]="report" alt="logo certifications" />
						</div>
					</div>
					<!-- Certifications respondant -->
					<!-- Link Edit -->
					<a
						[routerLink]="['/respondant', details._id, 'edit']"
						class="align-self-end link-edit"
						>{{ 'team_details-edit-team' | translate
						}}<i
							class="ml-2"
							nz-icon
							nzType="edit"
							nzTheme="outline"
						></i
					></a>
					<!-- Link Edit -->
				</div>
			</div>
		</div>
	</ng-container>
	<!-- /Respondant details -->

	<!-- Report Moderated List -->
	<div class="container-xl px-xl-0 mb-5 ml-0">
		<app-report-moderated-list
			*ngIf="respondant"
			[owner]="respondant"
		></app-report-moderated-list>
	</div>
	<!-- Report Moderated List -->
</div>

<ng-template #loadingTpl>
	<div class="text-center my-5 py-5">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
