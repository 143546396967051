<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5 bg-grey-light">
	<div class="container-xl ml-0">
		<!-- Counter -->
		<div class="row">
			<div class="col-12 col-md-4">
				<app-atom-model-counter
					*ngIf="
						numberFormationInterventionTodo ||
						numberFormationInterventionTodo === 0
					"
					[label]="'intervention-to-close' | translate"
					[customClass]="'admin-counter-intervention'"
					[loading]="loading"
					[count]="numberFormationInterventionTodo"
					[iconTpl]="iconInterventionTpl"
				></app-atom-model-counter>

				<ng-template #iconInterventionTpl>
					<i nz-icon>
						<svg
							width="48"
							height="48"
							viewBox="0 0 48 48"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M45.0008 23.995H42.0008C41.7651 23.995 41.5723 24.1878 41.5723 24.4236V41.5664H6.42941V6.42355H23.5723C23.808 6.42355 24.0008 6.23069 24.0008 5.99498V2.99498C24.0008 2.75926 23.808 2.56641 23.5723 2.56641H4.28655C3.33834 2.56641 2.57227 3.33248 2.57227 4.28069V43.7093C2.57227 44.6575 3.33834 45.4236 4.28655 45.4236H43.7151C44.6633 45.4236 45.4294 44.6575 45.4294 43.7093V24.4236C45.4294 24.1878 45.2366 23.995 45.0008 23.995Z"
								fill="#EA5823"
							/>
							<path
								d="M15.638 25.2218L15.5362 31.5914C15.5308 32.0682 15.9166 32.4593 16.3933 32.4593H16.4148L22.7362 32.3039C22.8433 32.2985 22.9505 32.2557 23.0255 32.1807L45.3058 9.94855C45.4719 9.78248 45.4719 9.50926 45.3058 9.34319L38.6469 2.68962C38.5612 2.60391 38.4541 2.56641 38.3415 2.56641C38.2291 2.56641 38.1219 2.60926 38.0362 2.68962L15.7612 24.9218C15.6838 25.0027 15.6398 25.1098 15.638 25.2218ZM19.0398 26.486L38.3415 7.22712L40.763 9.64319L21.4505 28.9128L19.0023 28.9718L19.0398 26.486Z"
								fill="#EA5823"
							/>
						</svg>
					</i>
				</ng-template>
			</div>

			<div class="col-12 col-md-4">
				<app-atom-model-counter
					*ngIf="numberCoach"
					[label]="'main_menu-coaches' | translate"
					[customClass]="'admin-counter-coach'"
					[loading]="loading"
					[count]="numberCoach"
					[iconTpl]="iconCoachTpl"
					[linkFullList]="['/coach/list']"
				></app-atom-model-counter>

				<ng-template #iconCoachTpl>
					<i nz-icon nzType="user" nzTheme="outline"></i>
				</ng-template>
			</div>

			<div class="col-12 col-md-4">
				<app-atom-model-counter
					*ngIf="numberFormation"
					[label]="'main_menu-formations' | translate"
					[customClass]="'admin-counter-formation'"
					[loading]="loading"
					[count]="numberFormation"
					[iconTpl]="iconFormationTpl"
					[linkFullList]="['/formations/list']"
				></app-atom-model-counter>

				<ng-template #iconFormationTpl>
					<i nz-icon nzType="audit" nzTheme="outline"></i>
				</ng-template>
			</div>
		</div>
		<!-- /Counter -->
	</div>

	<div class="container-xl ml-0">
		<div class="row">
			<div class="col-12">
				<div
					class="d-flex flex-wrap align-items-center dashboard__header"
				>
					<h2 class="title">
						{{ 'admin-dashboard-todo' | translate }}
					</h2>
					<div class="d-flex align-items-center sort-container">
						<hpf-user-select
							[(id)]="searchParams.props.admin"
							[isAdmin]="true"
							[roleFilter]="'admin'"
							[nullable]="true"
							[filterEnabled]="false"
							[placeholder]="'filter-placeholder' | translate"
							[multiple]="false"
							(change)="searchParams.next()"
						></hpf-user-select>
					</div>
				</div>
			</div>
			<div class="col-12">
				<ng-container *ngIf="formationInterventions">
					<app-atom-formation-intervention-table
						[formationInterventions]="formationInterventions"
					></app-atom-formation-intervention-table>
				</ng-container>
			</div>
		</div>
	</div>
</div>
