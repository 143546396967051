<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
	<div
		class="container-xl px-0 px-xl-0 ml-0 d-flex flex-wrap justify-content-between"
	>
		<div
			class="order-1 px-3 px-xl-0 d-flex justify-content-between align-items-center mb-0 mb-md-4 mt-4 mt-md-0"
		>
			<h2 class="mb-0">
				{{ 'page_title-respondant_update' | translate }}
			</h2>
		</div>
		<div
			class="order-3 order-md-2 px-3 px-xl-0 d-flex justify-content-md-center justify-content-md-start align-items-center btns-container mb-4 mt-5 mt-md-0"
		>
			<button
				nz-button
				nzType="primary"
				[disabled]="form?.invalid"
				[nzLoading]="saving"
				(click)="save()"
				type="submit"
			>
				{{ 'common_save' | translate }}
			</button>
			<button
				*ngIf="respondant"
				nz-button
				nzType="default"
				[nzLoading]="refreshing"
				[routerLink]="['/respondant', respondant.getId(), 'details']"
				class="cancel-btn ml-2"
			>
				{{ 'common_cancel' | translate }}
			</button>
		</div>

		<form
			nz-form
			class="user-form w-100 order-2 order-md-3"
			[formGroup]="form"
			[nzAutoTips]="explainErrorsService.autoTips"
			*ngIf="!loading; else loadingTemplate"
		>
			<div class="col-12 p-4 mt-4 form-container">
				<h3 class="mb-4">
					{{
						'respondant_update-personel-informations-form'
							| translate
					}}
				</h3>
				<div
					class="d-flex flex-column flex-md-row align-items-start align-items-md-center"
				>
					<div
						class="d-flex flex-column flex-md-row justify-content-md-between inputs-container inputs-container-half w-100"
					>
						<!-- First Name -->
						<nz-form-item>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="
										'user_first-name' | translate
									"
									formControlName="first_name"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /First Name -->
						<!-- Last Name -->
						<nz-form-item>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="'user_last-name' | translate"
									formControlName="last_name"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Last Name -->
						<!-- Birthdate -->
						<nz-form-item>
							<nz-form-control>
								<nz-date-picker
									class="w-100"
									[nzPlaceHolder]="
										'user_birthdate' | translate
									"
									formControlName="birthdate"
								></nz-date-picker>
							</nz-form-control>
						</nz-form-item>
						<!-- /Birthdate -->
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

<ng-template #loadingTemplate>
	<app-atom-concept-loader></app-atom-concept-loader>
</ng-template>
