<div class="table__container">
	<div class="table__wrapper">
		<table>
			<thead>
				<tr>
					<th>
						{{
							'table_head-formation-intervention-name' | translate
						}}
					</th>
					<th>
						{{
							'table_head-formation-intervention-coach'
								| translate
						}}
					</th>
					<th>
						{{ 'table_head-state' | translate }}
					</th>
					<th>
						{{ 'table_head-formation-date' | translate }}
					</th>
				</tr>
			</thead>
			<tbody>
				<ng-container
					*ngIf="
						formationInterventionsTransformed.length > 0;
						else noFormationTpl
					"
				>
					<tr
						*ngFor="
							let formationIntervention of formationInterventionsTransformed;
							let i = index
						"
						[routerLink]="[
							'/formations',
							formationIntervention.formationId,
							'details',
							formationIntervention.interventionId
						]"
						class="clicable"
					>
						<td class="label">
							{{ formationIntervention.name }}
							<div
								*ngIf="
									formationIntervention.color &&
										formationIntervention.colorExists();
									else emptyBandeau
								"
								class="bandeau"
								[ngStyle]="
									formationIntervention.color.props
										.gradient_min &&
									formationIntervention.color.props
										.gradient_max
										? {
												background:
													'linear-gradient(180deg, ' +
													formationIntervention.color
														.props.gradient_min +
													', ' +
													formationIntervention.color
														.props.gradient_max +
													')'
										  }
										: {
												background:
													formationIntervention.color
														.props.main
										  }
								"
							></div>
							<ng-template #emptyBandeau>
								<div class="bandeau"></div>
							</ng-template>
						</td>
						<td class="small">
							{{ formationIntervention.coach | uppercase }}
						</td>
						<td
							class="state"
							[ngSwitch]="formationIntervention.status"
						>
							<ng-container *ngSwitchCase="'created'">
								<span
									class="d-flex align-items-center justify-content-center"
								>
									<i
										nz-icon
										nzType="exclamation-circle"
										nzTheme="fill"
										class="mr-2"
										[style.color]="'#EA5823'"
									></i>
									<span>{{
										'formation-state-admin-created'
											| translate
									}}</span>
								</span>
							</ng-container>
							<ng-container *ngSwitchCase="'todo'">
								<span>{{
									'formation-state-admin-todo' | translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'finished'">
								<span
									class="d-flex align-items-center justify-content-center"
								>
									<i
										nz-icon
										nzType="check-circle"
										class="mr-2"
										[style.color]="'black'"
									></i>
									<span>{{
										'formation-state-admin-finished'
											| translate
									}}</span>
								</span>
							</ng-container>
						</td>
						<td class="date">
							<span>
								{{
									formationIntervention.start_date
										? (formationIntervention.start_date
										  | date
												: 'dd MMMM yyyy'
												: ''
												: translateService.currentLang)
										: ' - '
								}}
							</span>
							<span>
								{{
									formationIntervention.end_date
										? (formationIntervention.end_date
										  | date
												: 'dd MMMM yyyy'
												: ''
												: translateService.currentLang)
										: ' - '
								}}
							</span>
						</td>
					</tr>
				</ng-container>

				<ng-template #noFormationTpl>
					<tr>
						<td colspan="4">
							{{ 'empty-formation-for-current-user' | translate }}
						</td>
					</tr>
				</ng-template>
			</tbody>
		</table>
	</div>
</div>
