import { Component, OnInit, Input } from '@angular/core';
import { AppModalService } from '@app/services/app-modal.service';
import { NavigationService } from '@app/services/navigation.service';

@Component({
	selector: 'app-atom-survey-end',
	templateUrl: './atom-survey-end.component.html',
	styleUrls: ['./atom-survey-end.component.less'],
})
export class AtomSurveyEndComponent implements OnInit {
	@Input() mode: 'fail' | 'success' = 'fail';
	@Input() thematiqueId: string;
	constructor(
		private appModalService: AppModalService,
		private navigationService: NavigationService
	) {}

	ngOnInit(): void {}

	navigateToDashboard(): void {
		this.appModalService.clear();
		this.navigationService.go('dashboard');
	}

	navigateToSession(): void {
		this.appModalService.clear();
		this.navigationService.go(`session/${this.thematiqueId}/details`);
		location.reload();
	}
}
