<div class="container px-0 px-xl-5 py-4 py-xl-5">
	<ng-container *ngIf="!loading">
		<div class="row mx-0">
			<div class="col-12 px-0 py-0 content-survey">
				<div
					class="bandeau"
					[ngStyle]="
						colorThematique.props.gradient_min &&
						colorThematique.props.gradient_max
							? {
									background:
										'linear-gradient(180deg, ' +
										colorThematique.props.gradient_min +
										', ' +
										colorThematique.props.gradient_max +
										')'
							  }
							: {
									background: colorThematique.props.main
							  }
					"
				></div>
				<div
					class="description-survey-container"
					[ngClass]="{
						open: currentStep === 'start',
						close: currentStep !== 'start',
						hidden: currentStep === 'end'
					}"
				>
					<app-atom-survey-details
						[survey]="survey"
						[thematique]="thematique"
						[surveyNumber]="surveyNumber"
					></app-atom-survey-details>
				</div>
				<div
					class="survey-container"
					[ngClass]="{
						open: currentStep !== 'start',
						close: currentStep === 'start',
						full: currentStep === 'end'
					}"
				>
					<app-atom-survey-start
						*ngIf="currentStep === 'start'"
						[survey]="survey"
						(surveyStart)="currentStep = 'fill'"
					>
					</app-atom-survey-start>
					<app-atom-survey-fill
						*ngIf="currentStep === 'fill'"
						[survey]="survey"
						[user]="user"
						(surveySuccess)="onSurveySuccess()"
						(surveyFail)="onSurveyFail()"
					>
					</app-atom-survey-fill>
					<app-atom-survey-end
						*ngIf="currentStep === 'end'"
						[mode]="endMode"
						[thematiqueId]="thematiqueId"
					>
					</app-atom-survey-end>
					<ng-container *ngIf="currentStep === 'end'">
						<app-atom-survey-animation></app-atom-survey-animation>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</div>
