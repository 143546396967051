<nz-affix [nzOffsetTop]="0">
	<div
		class="bg-white border-bottom d-flex d-xl-none justify-content-between align-items-center py-3 px-5"
	>
		<div
			class="d-flex justify-content-center align-items-center logo-container"
		>
			<a [routerLink]="'/'">
				<img src="./assets/svg/logo/logo_NC.svg" alt="Nove Perform" />
			</a>
		</div>
		<div class="toggle-menu-container">
			<i
				*ngIf="!visible"
				class="icon-menu"
				(click)="openMenu()"
				nz-icon
				nzType="menu"
				nzTheme="outline"
			></i>
		</div>
	</div>

	<div
		class="bg-white border-bottom d-none d-xl-flex justify-content-between align-items-center py-3 px-5"
	>
		<nz-breadcrumb class="flex-grow-1 mr-3">
			<nz-breadcrumb-item *ngFor="let item of breadcrumbs; let i = index">
				<a *ngIf="item.link" [routerLink]="item.link">{{
					item.label
				}}</a>
				<ng-container *ngIf="!item.link">
					<h1 class="m-0" *ngIf="i === breadcrumbs.length - 1">
						{{ item.label }}
					</h1>
					<span *ngIf="i !== breadcrumbs.length - 1">{{
						item.label
					}}</span>
				</ng-container>
			</nz-breadcrumb-item>
		</nz-breadcrumb>
		<nz-input-group
			*appRestrictedToRoles="['coach']"
			[nzSuffix]="suffixIconSearch"
		>
			<input
				type="text"
				nz-input
				[nzAutocomplete]="autoComplete"
				[(ngModel)]="searchInput"
				(input)="inputSubject.next()"
				(keyup.enter)="navigateToPlayerPage()"
				placeholder="{{ 'top_bar-search-placeholder' | translate }}"
			/>
			<nz-autocomplete #autoComplete nzOverlayClassName="results-search">
				<nz-auto-option
					*ngFor="let player of searchedPlayers"
					[nzValue]="
						player.props.first_name + ' ' + player.props.last_name
					"
				>
					<a
						[routerLink]="[
							'/respondant',
							player.getId(),
							'details'
						]"
						nz-button
						nzType="link"
						class="search-result"
					>
						<nz-avatar
							ngClass="respondant-avatar mr-3"
							nzIcon="user"
							[nzSrc]="
								player.avatarExists()
									? player.props?.avatar.getUrl()
									: undefined
							"
						></nz-avatar>
						{{ player.props.first_name }}
						{{ player.props.last_name }}
					</a>
				</nz-auto-option>
			</nz-autocomplete>
		</nz-input-group>
	</div>
</nz-affix>

<!-- Icon input search template -->
<ng-template #suffixIconSearch>
	<i nz-icon nzType="search"></i>
</ng-template>
<!--/ Icon input search template -->

<!-- Drawer of menu to mobile and tablet-->
<nz-drawer
	class="custom-closable"
	[nzClosable]="true"
	[nzCloseOnNavigation]="true"
	[nzVisible]="visible"
	[nzPlacement]="'left'"
	[nzWidth]="'100%'"
	[nzTitle]="titleModalSurvey"
	[nzWrapClassName]="'drawerMenu'"
	(nzOnClose)="closeMenu()"
>
	<app-atom-main-menu
		device="responsive-menu"
		(closeDrawerEvent)="closeMenu()"
	></app-atom-main-menu>
</nz-drawer>
<!-- /Drawer of menu to mobile and tablet-->

<ng-template #titleModalSurvey>
	<img src="./assets/svg/logo/logo_NC.svg" alt="Nove Perform" />
</ng-template>
