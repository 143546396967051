<div
	*ngIf="!loading; else loadingTpl"
	class="formation-details__container container-fluid px-0 px-xl-5 py-4 py-xl-5"
>
	<app-atom-formation-thematique-details
		*ngIf="thematique && !loading"
		[formationThematique]="thematique"
		[userLogged]="userLogged"
		[user]="user"
	></app-atom-formation-thematique-details>
</div>

<ng-template #loadingTpl>
	<div class="text-center my-5 py-5">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
