import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Formation } from '@app/models/formation';
import { User, UserSearchParams, UserService } from '@app/models/user';
import { ErrorService } from '@app/services/error.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@app/services/navigation.service';
import { PageInterface, PageService } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import {
	FormationThematiqueSearchParams,
	FormationThematiqueService,
} from '@app/models/formation-thematique';
import { SessionService } from '@app/services/session.service';
import { Color } from '@app/models/color';

interface FormationsSponsorsInterface {
	name: string;
	totalRespondant: number;
	thematiques: ThematiqueSponsorsInterface[];
}

interface ThematiqueSponsorsInterface {
	id_thematique: string;
	title: string;
	date: Date;
	color: Color;
	status: string;
}

@Component({
	selector: 'app-molecule-sponsor-details',
	templateUrl: './molecule-sponsor-details.component.html',
	styleUrls: ['./molecule-sponsor-details.component.less'],
})
export class MoleculeSponsorDetailsComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	private sponsorId: string;
	sponsor: User;
	user: User;
	formations: FormationsSponsorsInterface[];
	totalRespondant: number;

	loading = false;

	constructor(
		private sessionService: SessionService,
		protected pageService: PageService,
		protected translate: TranslateService,
		private userService: UserService,
		private formationThematiqueService: FormationThematiqueService,
		private errorService: ErrorService,
		private route: ActivatedRoute,
		private navigate: NavigationService
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		this.sponsorId = this.route.snapshot.params.id;
		await this.refresh();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	async refresh() {
		this.loading = true;
		this.user = await this.sessionService.getCachedUser();
		this.sponsor = await this.getSponsor();
		await this.getFormations();

		if (this.formations && this.formations.length) {
			this.formations.forEach((formation) => {
				this.totalRespondant = +formation.totalRespondant;
			});
		}
		this.loading = false;
	}

	getSponsor(): Promise<User> {
		return this.userService
			.listSponsor(
				new UserSearchParams({
					_limit: 1,
					_id: [this.sponsorId],
					_populate: ['avatar'],
				}).toObject()
			)
			.then((result) => result.items[0])
			.catch((err) => {
				this.errorService.handle(err);
				this.navigate.go(['/sponsor']);
				return null;
			});
	}

	async getFormations() {
		//First get all thematique formation of coach
		await this.formationThematiqueService
			.list(
				new FormationThematiqueSearchParams({
					_limit: 100,
					formateur: this.user.getId(),
					_populate: ['formation', 'color'],
				}).toObject()
			)
			.then((result) => {
				//Filter thematique from formation of sponsor's page
				const thematiqueFormations = result.items.filter(
					(m) =>
						(<Formation>m.props.formation).props.sponsor ===
						this.sponsorId
				);
				//Regroup thematiques by formation
				this.groupByFormation(thematiqueFormations);
			})
			.catch((err) => {
				this.errorService.handle(err);
				this.navigate.go(['/sponsor']);
				return null;
			});
	}

	groupByFormation(mod) {
		const listFormation = new Array();
		//Determine number of formations of sponsor
		for (let i = 0; i < mod.length; i++) {
			listFormation[(<Formation>mod[i].props.formation).getId()] = {
				name: (<Formation>mod[i].props.formation).getLabel(),
				totalRespondant: (<Formation>(
					mod[i].props.formation
				)).countRespondant(),
				thematiques: [],
			};
		}

		//Get thematique for each formations
		mod.forEach((thematique) => {
			const mod = {
				id_thematique: thematique.props._id,
				title: thematique.props.name,
				date: thematique.props.start_date,
				color: thematique.props.color,
				status:
					thematique.props.start_date > new Date() ? 'soon' : 'done',
			};
			listFormation[
				(<Formation>thematique.props.formation).getId()
			].thematiques.push(mod);
		});

		//Change Format of listFormation
		this.formations = Object.values(listFormation);
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-sponsor_details', {
				sponsor: this.sponsor ? this.sponsor.getLabel() : '',
			}),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-sponsor_list'),
					link: ['/sponsor/list'],
				},
				{ label: this.sponsor ? this.sponsor.getLabel() : null },
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
