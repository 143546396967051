import {
	Component,
	OnInit,
	Input,
	OnDestroy,
	ViewChild,
	ElementRef,
	HostListener,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	ReportModeratedSearchParams,
	ReportModeratedService,
	ReportModerated,
} from '@app/models/report-moderated';
import { Subject } from 'rxjs';
import { ErrorService } from '@app/services/error.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-atom-report-moderated-inline-list',
	templateUrl: './atom-report-moderated-inline-list.component.html',
	styleUrls: ['./atom-report-moderated-inline-list.component.less'],
})
export class AtomReportModeratedInlineListComponent
	implements OnInit, OnDestroy {
	/** @type {Subject<void>} Observables unsubscriber */
	protected unsubscribe: Subject<void> = new Subject<void>();

	@Input() searchParams: ReportModeratedSearchParams;
	@Output() reportsNumber = new EventEmitter<number>();

	loading = true;
	items: ReportModerated[];

	/** Variable used for caroussel */
	@ViewChild('cardsReportContainer') cardsContainer: ElementRef;
	position: number;
	posScroll: number;
	btnScrollNext: boolean;
	widthContainer: number;
	nbColumn: number;
	maxPag: number;
	currentPag: number;

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.refreshSize();
	}

	constructor(
		private reportModeratedService: ReportModeratedService,
		private errorService: ErrorService
	) {
		this.position = 0;
		this.posScroll = 0;
		this.btnScrollNext = false;
		this.nbColumn = 0;
		this.maxPag = 0;
		this.currentPag = 0;
	}

	ngOnInit() {
		this.refresh();

		this.searchParams.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
			this.refresh();
		});
	}

	ngOnDestroy() {
		this.unsubscribe.next();
	}

	async refresh() {
		this.loading = true;
		if (this.searchParams) {
			this.items = await this.reportModeratedService
				.list(this.searchParams.toObject())
				.then((result) => result.items)
				.catch((err) => {
					this.errorService.handle(err);
					return [];
				});
		}
		this.loading = false;
		this.emitNumberReports(this.items.length);
		this.refreshSize();
	}

	refreshSize(): void {
		if (!this.cardsContainer) return;
		this.widthContainer = this.items.length * 170;
		this.nbColumn =
			Math.trunc(this.cardsContainer.nativeElement.offsetWidth / 170) - 2;
		const pag = (this.widthContainer - 2 * 170) / (this.nbColumn * 170);
		if (Number.isInteger(pag)) {
			this.maxPag =
				(this.widthContainer - 2 * 170) / (this.nbColumn * 170) - 1;
		} else {
			this.maxPag = Math.trunc(
				(this.widthContainer - 2 * 170) / (this.nbColumn * 170)
			);
		}
		this.btnScrollNext = this.items.length > this.nbColumn ? true : false;
		this.position = 0;
		this.currentPag = 0;
		this.cardsContainer.nativeElement.scrollLeft = 0;
	}

	next(): void {
		this.position = this.position - this.nbColumn * 170;
		this.currentPag = this.currentPag + 1;
	}

	prev(): void {
		this.position = this.position + this.nbColumn * 170;
		this.currentPag = this.currentPag - 1;
	}

	scroll(event: Event): void {
		let element = event.target as HTMLDivElement;
		this.posScroll = element.scrollLeft;
		if (element.scrollWidth - element.offsetWidth == this.posScroll) {
			this.btnScrollNext = false;
		} else {
			this.btnScrollNext = true;
		}
	}

	emitNumberReports(value: number) {
		this.reportsNumber.emit(value);
	}
}
