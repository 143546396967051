import { Helpers } from '@app/shared/helpers';
import {
	BaseModelSearchParams,
	BaseModelSearchParamsInterface,
} from '@app/abstracts';
/** Used to export and import search params */
export interface FormationThemeSearchParamsInterface
	extends BaseModelSearchParamsInterface {
	_sort?: '_id' | 'created_at';
	_id?: string[];
}
/** Manage formation theme search params */
export class FormationThemeSearchParams extends BaseModelSearchParams<
	FormationThemeSearchParamsInterface
> {
	/** @inheritDoc */
	fromObject(input: Partial<FormationThemeSearchParamsInterface>): void {
		// Clone object with allowed keys only
		const props = {} as FormationThemeSearchParamsInterface;
		this.allowedKeys().map((key: string) => {
			if (typeof input[key] !== 'undefined') {
				props[key] = input[key];
			}
		});
		// Keep default values
		if (typeof props._page === 'undefined') {
			props._page = this.defaultPage;
		}
		if (typeof props._limit === 'undefined') {
			props._limit = this.defaultLimit;
		}
		if (typeof props._sort === 'undefined') {
			props._sort = this.defaultSort;
		}
		if (typeof props._order === 'undefined') {
			props._order = this.defaultOrder;
		}
		// Convert potentially multiple primary keys
		if (typeof props._id === 'string') {
			props._id = (<string>props._id).split(',').filter((s) => s.length);
		}
		// Assign values
		this.props = props;
		this.next();
	}
	/** @inheritDoc */
	toObject(): FormationThemeSearchParamsInterface {
		// Filter not allowed, undefined and null values
		const props = {} as FormationThemeSearchParamsInterface;
		this.allowedKeys().map(Helpers.prepareObjectForApi(this.props, props));
		return props;
	}
	/** @inheritDoc */
	protected allowedKeys(): string[] {
		return super.allowedKeys().concat(['_id']);
	}
}
