<div
	*ngIf="!loading; else loadingTpl"
	class="container-fluid px-0 px-xl-5 py-4 py-xl-5"
>
	<div
		class="container-xl px-0 px-xl-0 ml-0 d-flex flex-wrap justify-content-between"
	>
		<div
			class="order-1 px-3 px-xl-0 d-flex justify-content-between align-items-center mb-0 mb-md-4 mt-4 mt-md-0"
		>
			<h2 class="mb-0">{{ 'my_profile-title' | translate }}</h2>
		</div>
		<div
			class="order-3 order-md-2 px-3 px-xl-0 d-flex justify-content-md-center justify-content-md-start align-items-center btns-container mb-4 mt-5 mt-md-0"
		>
			<button
				nz-button
				nzType="primary"
				[nzLoading]="saving"
				(click)="save()"
				type="submit"
			>
				{{ 'common_save' | translate }}
			</button>
			<button
				nz-button
				nzType="default"
				(click)="resetForm()"
				[routerLink]="['/coach/list']"
				class="cancel-btn ml-2"
			>
				{{ 'common_cancel' | translate }}
			</button>
		</div>
		<form
			nz-form
			class="user-form w-100 order-2 order-md-3"
			[formGroup]="form"
		>
			<div class="col-12 mt-4 form-container">
				<h3 class="form__subtitle">
					{{ 'my_profile-personel-informations_form' | translate }}
				</h3>
				<div
					class="d-flex flex-column flex-md-row align-items-start align-items-md-center"
				>
					<div class="d-flex flex-column inputs-container w-100">
						<!-- gender -->
						<nz-form-item>
							<nz-form-control>
								<nz-select
									name="coach-select--order"
									formControlName="gender"
									[nzPlaceHolder]="'user_gender' | translate"
								>
									<nz-option
										*ngFor="let gender of genders"
										[nzValue]="gender"
										[nzLabel]="
											'user_gender-' + gender | translate
										"
									>
									</nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
						<!-- /gender -->
						<!-- Last Name -->
						<nz-form-item>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="'user_last-name' | translate"
									formControlName="last_name"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Last Name -->
						<!-- first Name -->
						<nz-form-item>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="
										'user_first-name' | translate
									"
									formControlName="first_name"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /first Name -->
						<div class="form__group--address">
							<!-- address -->
							<nz-form-item>
								<nz-form-control>
									<input
										nz-input
										class="w-100"
										type="text"
										[placeholder]="
											'user_address' | translate
										"
										formControlName="address"
									/>
								</nz-form-control>
							</nz-form-item>
							<!-- /address -->
							<div class="form__group__row">
								<!-- postalCode -->
								<nz-form-item>
									<nz-form-control>
										<input
											nz-input
											class="w-100"
											type="text"
											[placeholder]="
												'user_postalCode' | translate
											"
											formControlName="postal_code"
										/>
									</nz-form-control>
								</nz-form-item>
								<!-- /postalCode -->
								<!-- city -->
								<nz-form-item>
									<nz-form-control>
										<input
											nz-input
											class="w-100"
											type="text"
											[placeholder]="
												'user_city' | translate
											"
											formControlName="city"
										/>
									</nz-form-control>
								</nz-form-item>
								<!-- /city -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

<ng-template #loadingTpl>
	<div class="text-center">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>

<ng-template #successTemplate>
	{{ 'common_save' | translate }}
</ng-template>
