import { Component, OnInit, Input } from '@angular/core';
import {
	ReportModeratedSearchParams,
	ReportModerated,
	ReportModeratedService,
} from '@app/models/report-moderated';
import { Report, ReportDisplayMode } from '@app/models/report';
import { User } from '@app/models/user';
import { Subject } from 'rxjs';
import { Color } from '@app/models/color';
import { FormationThematique } from '@app/models/formation-thematique';
import { SurveyResult } from '@app/models/survey-result';
import { SurveyOpenResultService } from '@app/models/survey-open-result';
import { Survey } from '@app/models/survey';
import { Question } from '@app/models/question';
import { SurveyLabel } from '@app/models/survey-label';

interface ReportModeratedFormatedInterface {
	title: string;
	typeReport?: string;
	owner?: string[];
	reportModerated?: ReportModerated;
	userDisplayMode?: ReportDisplayMode;
	coachDisplayMode?: ReportDisplayMode;
	sponsorDisplayMode?: ReportDisplayMode;
	hasAccess?: boolean;
}

@Component({
	selector: 'app-report-moderated-list',
	templateUrl: './report-moderated-list.component.html',
	styleUrls: ['./report-moderated-list.component.less'],
})
export class ReportModeratedListComponent implements OnInit {
	/** @type {Subject<void>} Observables unsubscriber */
	protected unsubscribe: Subject<void> = new Subject<void>();

	@Input() owner: User;
	@Input() colorThematique: Color;
	@Input() formationThematique: FormationThematique;
	@Input() user: User | null;

	coachIndividualSurveyResults: SurveyResult[] = [];

	reportsModerated: ReportModeratedFormatedInterface[] = [];

	surveyOpenResultsByReportModerateds = [];

	_populate = [
		'report',
		'owners',
		'graphs.graph.axes.axe',
		'graphs.graph.axes.survey',
		'graphs.graph.axes.color',
		'graphs.axes.axe',
		'results.answers.weights',
		'results.owner',
	];

	constructor(
		private reportModeratedService: ReportModeratedService,
		private surveyOpenResultService: SurveyOpenResultService
	) {}

	async ngOnInit(): Promise<void> {
		this.refreshReportsModerated();

		this.refreshCoachIndividualSurveys();
	}

	async refreshReportsModerated(): Promise<void> {
		this.reportsModerated = [];

		// Stop if owner is respondant and module doesn't have any report for him
		if (
			this.owner.isRespondant() &&
			!this.formationThematique.getRespondantReports().length
		)
			return;

		const searchParams = (this.user || this.owner).isRespondant()
			? new ReportModeratedSearchParams({
					_limit: 100,
					owners: [(this.user || this.owner).getId()],
					grouped: false,
					report: this.formationThematique
						.getRespondantReports()
						.map((r) => r.getId()),
					_populate: this._populate,
			  })
			: new ReportModeratedSearchParams({
					_limit: 100,
					owners: [(this.user || this.owner).getId()],
					grouped: true,
					formation_thematique: this.formationThematique.getId(),
					_populate: this._populate,
			  });

		this.reportsModerated = await this.getReportsModeratedFormatted(
			searchParams
		);
	}

	async refreshCoachIndividualSurveys(): Promise<void> {
		this.coachIndividualSurveyResults = [];

		// Stop if owner is not coach
		if (!this.owner.isCoach()) return;

		const respondantReports = this.formationThematique
			.getRespondantReports()
			.map((r) => r.getId());

		if (!respondantReports.length) return;

		const searchParams = new ReportModeratedSearchParams({
			_limit: 100,
			report: respondantReports,
			_populate: this._populate,
		});

		this.coachIndividualSurveyResults = (
			await this.getReportsModeratedFormatted(searchParams)
		).reduce(
			(results, reportModerated) => [
				...results,
				...reportModerated.reportModerated.props.results.map(
					(r) => r as SurveyResult
				),
			],
			[] as SurveyResult[]
		);
	}

	private getReportsModeratedFormatted(
		searchParams: ReportModeratedSearchParams
	): Promise<ReportModeratedFormatedInterface[]> {
		return this.reportModeratedService
			.list(searchParams.toObject())
			.then((res) => res.items)
			.then((reportsModerated) =>
				reportsModerated.map((reportModerated) =>
					this.format(reportModerated)
				)
			)
			.catch(() => []);
	}

	private format(
		reportModerated: ReportModerated
	): ReportModeratedFormatedInterface {
		const surveyIds = [
			...new Set(
				reportModerated.props.results.map(
					(result: SurveyResult) => result.props.survey
				)
			),
		];
		const surveyOpenResults = [];
		surveyIds.map(async (id: string, indexSurvey: number) => {
			const result = await this.surveyOpenResultService.list({
				_page: 0,
				_limit: 100,
				survey: id,
				_populate: ['survey', 'question.labels'],
			});
			if (result.items?.length > 0) {
				surveyOpenResults.push({
					surveyLabel: (<Survey>result.items[0].props.survey).props
						.label,
					questions: [],
				});
				const questions = [];
				result.items.map((item) => {
					const currentQuestion = <Question>item.props.question;
					const indexQuestion = questions.findIndex(
						(q) => q._id === currentQuestion.props._id
					);
					if (indexQuestion > -1) {
						questions[indexQuestion].answers.push({
							answer: item.props.answer,
						});
					} else {
						const questionLabel = currentQuestion.props.labels
							.length
							? (<SurveyLabel>currentQuestion.props.labels[0])
									.props.label
							: '';
						questions.push({
							_id: currentQuestion.props._id,
							questionLabel,
							answers: [],
						});
						const indexQuestion = questions.findIndex(
							(q) => q._id === currentQuestion.props._id
						);
						if (item.props.answer)
							questions[indexQuestion].answers.push({
								answer: item.props.answer,
							});
					}
				});
				surveyOpenResults[indexSurvey].questions = questions;
			}
		});
		this.surveyOpenResultsByReportModerateds.push(surveyOpenResults);

		const displayMode = (<Report>(
			reportModerated.props.report
		)).getDisplayMode(this.owner);

		return {
			title: (<Report>reportModerated.props.report).getLabel(),
			typeReport: (<Report>reportModerated.props.report).props.shortcode,
			owner: (<User[]>reportModerated.props.owners).map((u) =>
				u.getLabel()
			),
			reportModerated,
			hasAccess: reportModerated.props.grouped
				? displayMode === 'grouped' || displayMode === 'both'
				: displayMode === 'individual' || displayMode === 'both',
		};
	}
}
