import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import { User, UserService } from '@app/models/user';
import { PageService, PageInterface } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Image } from '@app/models/image';
import { Report } from '@app/models/report';
import { takeUntil, filter } from 'rxjs/operators';

interface RespondantDetailsInterface {
	_id: string;
	name: string;
	avatar: string;
	reports: string[];
}

@Component({
	selector: 'app-molecule-respondant-details',
	templateUrl: './molecule-respondant-details.component.html',
	styleUrls: ['./molecule-respondant-details.component.less'],
})
export class MoleculeRespondantDetailsComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	loading = true;
	respondantId: string;
	respondant: User;
	details: RespondantDetailsInterface;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();

		await this.initPage();

		this.router.events
			.pipe(
				takeUntil(this.unsubscribe),
				filter((e): e is NavigationEnd => e instanceof NavigationEnd)
			)
			.subscribe(async () => {
				this.loading = true;
				await this.initPage();
				this.loading = false;
			});
	}

	async initPage() {
		this.respondantId = this.route.snapshot.params.id;
		await this.refresh();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	async refresh() {
		this.loading = true;

		this.respondant = await this.getRespondant();

		this.refreshPageData();

		this.details = await this.getDetails();

		this.loading = false;
	}

	getRespondant(): Promise<User> {
		return this.userService.get(this.respondantId, {
			_populate: [, 'reports.surveys', 'avatar'],
		});
	}

	async getDetails(): Promise<RespondantDetailsInterface> {
		return {
			_id: this.respondant.getId(),
			name: this.respondant.getLabel(),
			reports: [
				...new Set(
					(<Report[]>this.respondant.props.reports).map((r) => null)
				),
			],
			avatar: this.respondant.avatarExists()
				? (<Image>this.respondant.props.avatar).getUrl()
				: null,
		};
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-respondant_details', {
				respondant: this.respondant ? this.respondant.getLabel() : '',
			}),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-respondant_list'),
					link: ['/respondant/list'],
				},
				{ label: this.respondant ? this.respondant.getLabel() : null },
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
