import { Component, OnInit, Input } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
	FormArray,
} from '@angular/forms';

import { ErrorService } from '@app/services/error.service';
import { ExplainErrorsService } from '@app/services/explain-errors.service';
import { UserService, User } from '@app/models/user';

@Component({
	selector: 'app-atom-respondant-edit',
	templateUrl: './atom-respondant-edit.component.html',
	styleUrls: ['./atom-respondant-edit.component.less'],
})
export class AtomRespondantEditComponent implements OnInit {
	@Input() respondant: User;

	form: FormGroup;

	// Spinners
	loading = false;
	saving = false;
	refreshing = false;
	userPayloadKeys = [
		'first_name',
		'last_name',
		'email',
		'phone_number',
		'role',
	];

	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private userService: UserService
	) {}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		this.initForm();
		this.loading = false;
	}

	initForm(): void {
		this.form = this.formBuilder.group({
			first_name: new FormControl(this.respondant.props.first_name, [
				Validators.required,
			]),
			last_name: new FormControl(this.respondant.props.last_name, [
				Validators.required,
			]),
			email: new FormControl(this.respondant.props.email, [
				Validators.email,
				Validators.required,
			]),
			phone_number: new FormControl(
				this.respondant.props.phone_number,
				[]
			),
			birthdate: new FormControl(this.respondant.props.birthdate, []),
		});
	}

	async save(): Promise<void> {
		this.saving = true;
		this.updateModel();
		try {
			await this.userService.update(
				this.respondant.getId(),
				this.respondant.toPayload()
			);
		} catch (e) {
			this.errorService.handle(e);
		}
		this.saving = false;
	}

	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.respondant.props[key] = this.form.get(key).value;
		}
	}
}
