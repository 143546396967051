<div class="table__container">
	<div class="table__header">
		<h2 class="table__title">
			{{ title }}
		</h2>
		<div class="d-flex btns-container" #btnsContainer>
			<button
				*ngIf="!showButtonSendGrouped"
				class="btn--generate"
				nz-button
				nzType="primary"
				nz-dropdown
				nzTrigger="click"
				[nzDropdownMenu]="menu"
				[disabled]="generateLoading || disableGenerate"
				(nzVisibleChange)="handleDropdownVisible($event)"
			>
				{{ 'common_generate' | translate }}

				<ng-container *ngIf="generateLoading; else arrowDownTpl">
					<i class="ml-2" nz-icon [nzType]="'loading'"></i>
				</ng-container>
				<ng-template #arrowDownTpl>
					<i nz-icon>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
						>
							<path
								d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
								fill="white"
							/>
						</svg>
					</i>
				</ng-template>
			</button>
			<nz-dropdown-menu #menu="nzDropdownMenu">
				<ul nz-menu>
					<li
						*ngFor="let doc of docsToGenerate"
						nz-menu-item
						(click)="emitGenerate.emit(doc)"
					>
						{{ doc | translate }}
					</li>
				</ul>
			</nz-dropdown-menu>

			<input
				style="display: none;"
				type="file"
				(change)="onFileSelect($event)"
				#hiddenfileinput
			/>
			<button
				nz-button
				nzType="primary"
				[disabled]="disableUpload"
				(click)="hiddenfileinput.click()"
			>
				{{ 'common_upload' | translate }}
			</button>
			<button
				*ngIf="showButtonSendGrouped"
				nz-button
				nzType="default"
				class="icon-btn"
				[title]="'common_grouped_send' | translate"
				(click)="emitSendGrouped.emit()"
			>
				<i nz-icon nzType="mail" nzTheme="outline"></i>
			</button>
		</div>
	</div>
	<div class="table__wrapper">
		<table>
			<thead>
				<tr>
					<th>{{ 'table_head-document-name' | translate }}</th>
					<th>{{ 'table_head-document-state' | translate }}</th>
					<th>{{ 'table_head-document-date' | translate }}</th>
					<th>{{ 'table_head-document-actions' | translate }}</th>
				</tr>
			</thead>
			<tbody>
				<ng-container
					*ngIf="
						documents.length > 0 && documents[0] !== null;
						else noDocTpl
					"
				>
					<tr *ngFor="let document of documents; let i = index">
						<td class="label small">
							<a
								(click)="
									emitReadDocument.emit(document.props._id)
								"
							>
								{{ document.props.label }}
							</a>
						</td>
						<td class="state" [ngSwitch]="document.props.status">
							<ng-container *ngSwitchCase="'created'">
								<span>{{
									'formation-intervention-state-admin-created'
										| translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'generated'">
								<span>{{
									'formation-intervention-state-admin-generated'
										| translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'sended'">
								<span>{{
									'formation-intervention-state-admin-sended'
										| translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'signed'">
								<span
									class="d-flex align-items-center justify-content-center"
								>
									<i
										nz-icon
										nzType="check-circle"
										class="mr-2"
										[style.color]="'black'"
									></i>
									<span>{{
										'formation-intervention-state-admin-signed'
											| translate
									}}</span>
								</span>
							</ng-container>
						</td>
						<td class="date">
							<span>
								{{
									document.props.created_at
										| date
											: 'dd MMMM yyyy'
											: ''
											: translateService.currentLang
								}}
							</span>
						</td>
						<td>
							<div class="actions">
								<button
									nz-button
									nzType="default"
									class="icon-btn"
									[title]="'common_download' | translate"
									(click)="
										emitDownloadDocument.emit({
											idDoc: document.getId(),
											filename: document.getLabel()
										})
									"
								>
									<i
										nz-icon
										nzType="download"
										nzTheme="outline"
									></i>
								</button>
								<button
									nz-button
									nzType="default"
									class="icon-btn"
									[title]="'common_send' | translate"
									[disabled]="disableSend"
									(click)="
										emitSendDocument.emit(
											document.props._id
										)
									"
								>
									<i
										nz-icon
										nzType="mail"
										nzTheme="outline"
									></i>
								</button>
								<button
									nz-button
									nzType="default"
									class="icon-btn delete"
									[title]="'common_delete' | translate"
									[disabled]="disableDelete"
									(click)="
										emitDeleteDocument.emit(
											document.props._id
										)
									"
								>
									<i
										nz-icon
										nzType="delete"
										nzTheme="outline"
									></i>
								</button>
							</div>
						</td>
					</tr>
				</ng-container>
				<ng-template #noDocTpl>
					<tr>
						<td colspan="4">
							{{ 'empty-docs' | translate }}
						</td>
					</tr>
				</ng-template>
			</tbody>
		</table>
	</div>
</div>
