import { Injectable } from '@angular/core';
import { Graph, GraphInterface, GraphPayload } from './graph';
import { GraphSearchParamsInterface } from './graph-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class GraphService extends BaseModelService<
	Graph,
	GraphInterface,
	GraphPayload,
	GraphSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'graph';
	}
	/** @inheritDoc */
	protected new(object: GraphInterface): Graph {
		return new Graph(object);
	}
}
