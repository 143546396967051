<!-- New Display Report Cards -->
<ng-container *ngIf="!inline">
	<div class="p-relative">
		<div class="cards-container respondant-cards-container">
			<!-- Cards -->
			<div class="circle-cards">
				<div
					class="content-circle"
					[style.border-color]="'#a3c66c'"
					[style.border-width.px]="2"
				>
					<span class="name-respondant">{{
						respondant.getLabel()
					}}</span>
				</div>
			</div>
			<!-- /Cards -->
		</div>
		<div class="info-respondant-wrapper">
			<span class="position-respondant">
				{{ respondant.props.position }}
			</span>
			<span class="department-respondant">
				{{ respondant.props.department }}
			</span>
		</div>
	</div>
</ng-container>
<!-- /New Display Report Cards -->
<!-- Display Report Inline -->
<ng-container *ngIf="inline">
	<div
		class="row d-flex flex-nowrap justify-content-start align-items-center px-0 py-3 m-0 mx-md-4 inline-wrapper"
	>
		<span class="col-5 col-xl-5 respondant d-md-flex align-items-center">
			{{ respondant.getLabel() }}
		</span>
		<span
			class="col-2 col-sm-3 col-xl-5 positiond-md-flex align-items-center"
		>
			{{ respondant.props.position }}
		</span>
		<span class="col-3 col-sm-2 department">
			{{ respondant.props.department }}
		</span>
	</div>
</ng-container>
<!-- /Display Report Inline -->
