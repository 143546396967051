import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
} from '@angular/router';
import { SessionService } from '@app/services/session.service';

@Injectable()
export class IsRoleGuard implements CanActivate {
	constructor(
		private _sessionService: SessionService,
		private _router: Router
	) {}

	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean> {
		const user = await this._sessionService.getCachedUser();

		const acceptedRoles = (next.data.roles as string[]) || [];
		if (acceptedRoles.includes(user.props.role)) {
			return true;
		} else {
			await this._router.navigate(['/']);
			return false;
		}
	}
}
