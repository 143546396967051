import { Component, OnInit, Input } from '@angular/core';

export type SpinnerSize = 'large' | 'small' | 'default';
@Component({
	selector: 'app-atom-spinner',
	templateUrl: './atom-spinner.component.html',
	styleUrls: ['./atom-spinner.component.less'],
})
export class AtomSpinnerComponent implements OnInit {
	@Input() size: SpinnerSize = 'default';
	constructor() {}

	ngOnInit() {}
}
