import { Injectable } from '@angular/core';
import {
	ThematiqueFormateur,
	ThematiqueFormateurInterface,
	ThematiqueFormateurPayload,
} from './thematique-formateur';
import { ThematiqueFormateurSearchParamsInterface } from './thematique-formateur-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class ThematiqueFormateurService extends BaseModelService<
	ThematiqueFormateur,
	ThematiqueFormateurInterface,
	ThematiqueFormateurPayload,
	ThematiqueFormateurSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'thematique-formateur';
	}
	/** @inheritDoc */
	protected new(object: ThematiqueFormateurInterface): ThematiqueFormateur {
		return new ThematiqueFormateur(object);
	}
}
