import { Injectable } from '@angular/core';
import { Question, QuestionInterface, QuestionPayload } from './question';
import { QuestionSearchParamsInterface } from './question-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class QuestionService extends BaseModelService<
	Question,
	QuestionInterface,
	QuestionPayload,
	QuestionSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'question';
	}
	/** @inheritDoc */
	protected new(object: QuestionInterface): Question {
		return new Question(object);
	}
}
