import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import {
	DocumentAdministrative,
	DocumentAdministrativeService,
} from '@app/models/document-administrative';
import {
	DocumentPedagogique,
	DocumentPedagogiqueService,
} from '@app/models/document-pedagogique';
import { Formation } from '@app/models/formation';
import {
	FormationIntervention,
	FormationInterventionService,
} from '@app/models/formation-intervention';
import { ErrorService } from '@app/services/error.service';

import { PageInterface, PageService } from '@app/services/page.service';
import { S3Service } from '@app/services/s3.service';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-molecule-formation-intervention-details-admin',
	templateUrl:
		'./molecule-formation-intervention-details-admin.component.html',
	styleUrls: [
		'./molecule-formation-intervention-details-admin.component.less',
	],
})
export class MoleculeFormationInterventionDetailsAdminComponent
	extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'detail';
	private unsubscribe$ = new Subject<void>();

	formationIntervention: FormationIntervention;
	formationId: string;
	formationLabel: string;

	contractReadonly = false;
	orderMissionReadonly = false;

	documents: DocumentPedagogique[] = [];
	documentContract: DocumentAdministrative[] = [];
	documentOrderMission: DocumentAdministrative[] = [];

	docsToGenerateContract: string[] = [
		'CDDC-RA-Distanciel',
		'CDDC-Distanciel',
		'CDDC-RA-Presentiel',
		'CDDC-Presentiel',
		'CDD-RA-Distanciel',
		'CDD-Distanciel',
		'CDD-RA-Presentiel',
		'CDD-Presentiel',
		'CDDC-AO2-RA-Distanciel',
		'CDDC-AO2-Distanciel',
		'CDDC-AO2-RA-Presentiel',
		'CDDC-AO2-Presentiel',
		'CDD-AO2-RA-Distanciel',
		'CDD-AO2-Distanciel',
		'CDD-AO2-RA-Presentiel',
		'CDD-AO2-Presentiel',
	];
	docsToGenerateOrderMission: string[] = [
		'ordre-de-mission-presentiel',
		'ordre-de-mission-distanciel',
		'Charte de Déontologie NOVE Concept - MAJ 2024',
	];

	loading = false;
	contratTravailLoading = false;
	ordreDeMissionLoading = false;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		protected formationInterventionService: FormationInterventionService,
		protected documentPedagogiqueService: DocumentPedagogiqueService,
		protected documentAdministrativeService: DocumentAdministrativeService,
		private route: ActivatedRoute,
		private s3Service: S3Service,
		private errorService: ErrorService,
		private messageConfirmation: NzMessageService,
		private http: HttpClient,
		private modal: NzModalService
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.getFormationInterventionInfos();
		this.loading = false;
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async getFormationInterventionInfos() {
		this.formationIntervention = await this.formationInterventionService.getAsAdmin(
			this.route.snapshot.params.interventionId,
			{
				_populate: [
					'formateur',
					'thematiques',
					'formation.sponsor.avatar',
					'documents',
					'ordre_de_mission',
					'contrat_travail',
				],
			}
		);
		const formation = this.formationIntervention.props
			.formation as Formation;

		this.documents = this.formationIntervention.props
			.documents as DocumentPedagogique[];

		this.documentContract = [
			this.formationIntervention.props
				.contrat_travail as DocumentAdministrative,
		];

		this.documentOrderMission = [
			this.formationIntervention.props
				.ordre_de_mission as DocumentAdministrative,
		];

		this.formationId = formation.getId();
		this.formationLabel = formation.getLabel();
		this.contractReadonly = this.isDocSigned(this.documentContract);
		this.orderMissionReadonly = this.isDocSigned(this.documentOrderMission);

		this.refreshPageData();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant(
				'page_title-formation_detail_intervention',
				{
					formationIntervention: this.formationIntervention
						? this.formationIntervention.getLabel()
						: '',
				}
			),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-formation_list'),
					link: ['/formations/list'],
				},
				{
					label: this.translate.instant(
						'page_title-formation_list_intervention',
						{
							formation: this.formationIntervention
								? this.formationLabel
								: '',
						}
					),
					link: ['/formations', this.formationId, 'details'],
				},
				{
					label: this.formationIntervention
						? this.formationIntervention.getLabel()
						: null,
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}

	isGenerateDisable(
		docType: 'ordre_de_mission' | 'contrat_travail'
	): boolean {
		return !!this.formationIntervention.props[docType];
	}

	setGenerateLoading(
		docType: 'ordre_de_mission' | 'contrat_travail',
		isLoading: boolean
	): void {
		if (docType === 'contrat_travail')
			this.contratTravailLoading = isLoading;
		if (docType === 'ordre_de_mission')
			this.ordreDeMissionLoading = isLoading;
	}

	isUploadDisable(docType: 'ordre_de_mission' | 'contrat_travail'): boolean {
		return !!this.formationIntervention.props[docType];
	}

	isSendDisable(documents: DocumentAdministrative[]): boolean {
		return this.isDocSigned(documents);
	}

	downloadPdf(url: string, filename: string) {
		this.http
			.get(url, { responseType: 'blob' })
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((response) => {
				const file = new Blob([response], { type: response.type });

				const fileURL = URL.createObjectURL(file);
				const fakeDownload = document.createElement('a');
				fakeDownload.href = fileURL;
				fakeDownload.target = '_blank';
				fakeDownload.download = filename;
				document.body.appendChild(fakeDownload);
				fakeDownload.click();
				URL.revokeObjectURL(fileURL);
				fakeDownload.remove();
			});
	}

	async handleDownloadDocPedagogique(event: {
		idDoc: string;
		filename: string;
	}) {
		const {
			url,
		} = await this.formationInterventionService.getPresignedDocPedagogiqueAsAdmin(
			this.formationIntervention.getId(),
			event.idDoc
		);

		this.downloadPdf(url, event.filename);
	}

	async handleDownloadDocumentAdministrative(
		event: { idDoc: string; filename: string },
		docType: string
	) {
		const {
			url,
		} = await this.formationInterventionService.getPresignedDocAdmininistrativeAsAdmin(
			this.formationIntervention.props._id,
			docType
		);
		this.downloadPdf(url, event.filename);
	}

	async handleSendGrouped() {
		try {
			await this.formationInterventionService.sendEmailPedagogique(
				this.formationIntervention.props._id
			);
			this.createMessageConfirmationSendGrouped();
		} catch (error) {
			this.createMessageErrorSend();
			this.errorService.handle(error);
		}
	}

	async handleGenerate(
		generationTemplate: string,
		type: 'ordre_de_mission' | 'contrat_travail'
	): Promise<void> {
		try {
			// TODO: change the hard_code contrat-travail-basic
			this.setGenerateLoading(type, true);
			await this.formationInterventionService.generate(
				generationTemplate, //'contrat-travail-basic', // ordre-de-mission-basic
				type,
				this.formationIntervention.props._id
			);
			await this.getFormationInterventionInfos();
			this.setGenerateLoading(type, false);
		} catch (error) {
			this.errorService.handle(error);
		}
	}

	async handleDocAdminUpload(
		file: File,
		previousDoc: DocumentAdministrative[],
		type: 'ordre_de_mission' | 'contrat_travail'
	): Promise<void> {
		const hasPreviousDoc = this.docExists(previousDoc);

		try {
			const res = await this.s3Service.uploadFile(file);
			if (res) {
				// create administrative document
				const docAdded = await this.documentAdministrativeService.createAsAdmin(
					{
						label: file.name,
						status: hasPreviousDoc ? 'signed' : 'created',
						uri: res,
					}
				);

				// update formation intervention with the document adminstrative id
				await this.formationInterventionService.updateAsAdmin(
					this.formationIntervention.getId(),
					{
						[type]: docAdded.getId(),
					}
				);

				// upload administrative document to the secure bucket
				await this.formationInterventionService.uploadDocumentAdministrative(
					this.formationIntervention.props._id,
					type,
					res
				);

				// update info
				await this.getFormationInterventionInfos();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
	}

	async handleDocPedagogiqueUpload(file: File) {
		try {
			const res = await this.s3Service.uploadFile(file);
			if (res) {
				// create pedagogique document
				const docAdded = await this.documentPedagogiqueService.createAsAdmin(
					{
						label: file.name,
						status: 'created',
						uri: res,
					}
				);
				const allDocs = [
					...this.documents.map((doc) => doc.getId()),
					docAdded.getId(),
				];

				// update formation intervention with all the documents id
				await this.formationInterventionService.updateAsAdmin(
					this.formationIntervention.getId(),
					{
						documents: allDocs,
					}
				);

				// upload administrative document to the secure bucket
				await this.formationInterventionService.uploadDocumentPedagogique(
					this.formationIntervention.props._id,
					docAdded.getId(),
					res,
					file.name
				);

				// update info
				await this.getFormationInterventionInfos();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
	}

	async handleReadDocumentAdministrative(docType: string) {
		const {
			url,
		} = await this.formationInterventionService.getPresignedDocAdmininistrativeAsAdmin(
			this.formationIntervention.props._id,
			docType
		);
		window.open(url, '_blank');
	}

	async handleReadDocumentPedagogique(id: string) {
		const {
			url,
		} = await this.formationInterventionService.getPresignedDocPedagogiqueAsAdmin(
			this.formationIntervention.props._id,
			id
		);
		window.open(url, '_blank');
	}

	async handleSendPedagogique(id: string) {
		try {
			if (!id) return '';
			await this.formationInterventionService.sendEmailPedagogique(
				this.formationIntervention.props._id,
				id
			);
			this.createMessageConfirmationSend();
		} catch (error) {
			this.createMessageErrorSend();
			this.errorService.handle(error);
		}
	}

	async handleSendAdministrative(
		id: string,
		docType: 'ordre_de_mission' | 'contrat_travail'
	) {
		try {
			if (!id) return '';
			await this.formationInterventionService.sendEmailAdministrative(
				this.formationIntervention.props._id,
				docType
			);
			this.createMessageConfirmationSend();
		} catch (error) {
			this.createMessageErrorSend();
			this.errorService.handle(error);
		}
	}

	async handleDeleteDocumentPedagogique(idDocument: string) {
		const documentsWithoutDeletedId = this.documents
			.filter((doc) => doc.getId() !== idDocument)
			.map((doc) => doc.getId());

		await this.formationInterventionService.updateAsAdmin(
			this.formationIntervention.getId(),
			{
				documents: documentsWithoutDeletedId,
			}
		);
		await this.getFormationInterventionInfos();
	}

	async handleDeleteDocumentAdministrative(
		docType: 'ordre_de_mission' | 'contrat_travail'
	) {
		const payload = { [docType]: null };
		await this.formationInterventionService.updateAsAdmin(
			this.formationIntervention.getId(),
			payload
		);
		await this.getFormationInterventionInfos();
	}

	private isDocSigned(doc: DocumentAdministrative[]) {
		if (this.docExists(doc)) {
			return doc[0].props.status === 'signed';
		}
		return false;
	}

	private docExists(doc: DocumentAdministrative[]) {
		return doc.length > 0 && doc[0] !== null;
	}

	createMessageConfirmationSend(): void {
		this.messageConfirmation.success(
			this.translate.instant('common_document_send')
		);
	}

	createMessageConfirmationSendGrouped(): void {
		this.messageConfirmation.success(
			this.translate.instant('common_document_send_grouped')
		);
	}

	createMessageErrorSend(): void {
		this.messageConfirmation.success(
			this.translate.instant('common_document_send_error')
		);
	}

	showDeleteConfirmOnDocAdmin(
		docType: 'ordre_de_mission' | 'contrat_travail'
	): void {
		this.modal.confirm({
			nzTitle: this.translate.instant('document_confirm_delete'),
			nzOkText: this.translate.instant('common_yes'),
			nzOnOk: async () => {
				await this.handleDeleteDocumentAdministrative(docType);
			},
			nzCancelText: this.translate.instant('common_no'),
		});
	}

	showDeleteConfirmOnDocPedagogique(idDocument: string): void {
		this.modal.confirm({
			nzTitle: this.translate.instant('document_confirm_delete'),
			nzOkText: this.translate.instant('common_yes'),
			nzOnOk: async () => {
				await this.handleDeleteDocumentPedagogique(idDocument);
			},
			nzCancelText: this.translate.instant('common_no'),
		});
	}
}
