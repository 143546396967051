import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
export interface AxeInterface extends BaseModelInterface {
	created_at: number | Date;
	name: string;
	description: string;
}
export interface AxePayload {
	name: string;
	description: string;
}
type AxePayloadKey = keyof AxePayload;
export class Axe extends BaseModel<AxeInterface, AxePayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}

	/** Format description to be used in innerHTML */
	formatDescription(): string {
		return this.props.description
			.replace(/<fieldset>/g, '<div class="fieldset">')
			.replace(/<\/fieldset>/g, '</div>')
			.replace(/<legend>/g, '<div class="fieldset-legend"><span>')
			.replace(/<\/legend>/g, '</span></div>');
	}

	/** Populate the current instance from an object */
	fromObject(object: AxeInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): AxeInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): AxePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as AxeInterface);
		return payload as AxePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): AxePayloadKey[] {
		return ['name', 'description'];
	}
}
