import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {
	BaseGraphComponent,
	DataAverageInterface,
} from '@app/abstracts/base-graph/base-graph.component';
import { environment } from '@env/environment';
import { Radar } from '@antv/g2plot';
import { Breakpoint, ResizeService } from '@app/services/resize.service';
import { Color } from '@app/models/color';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-atom-graph-radar',
	templateUrl: '../../../abstracts/base-graph/base-graph.component.html',
	styleUrls: ['../../../abstracts/base-graph/base-graph.component.less'],
})
export class AtomGraphRadarComponent extends BaseGraphComponent
	implements OnInit {
	@ViewChild('graphDiv', { static: true }) graphDiv: ElementRef;

	@Input() data: DataAverageInterface[] = [];
	@Input() color: Color;
	@Input() maxAxis: number;

	typeGraph: string = 'radar';

	protected defaultOptions = environment.graphs.radar.options;

	constructor(
		protected resizeService: ResizeService,
		private translate: TranslateService
	) {
		super(resizeService);
	}

	initializeGraph() {
		this.data.map((d) => {
			d.x = d.x.charAt(0).toUpperCase() + d.x.slice(1).toLowerCase();
		});

		const options: any = Object.assign(this.options, {
			data: this.data,
			line: {
				size: 8,
				style: {
					opacity: 0.6,
					stroke: this.color.toAnt(true),
				},
			},
		});

		if (this.maxAxis) {
			options.radiusAxis.max = this.maxAxis;
		}

		this.graph = new Radar(
			this.graphDiv.nativeElement,
			Object.assign(this.options, options)
		);
	}

	onResize(breakpoint: Breakpoint) {}

	async loadAverages(): Promise<void> {
		this.averages = [
			{
				name: 'all',
				average: this.calculateAverage(this.data),
				maxAxis: this.maxAxis ?? 5,
			},
		];
	}
}
