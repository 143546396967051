import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Image, ImageInterface } from '../image/image';
import { Report, ReportInterface } from '../report/report';
import { Survey, SurveyInterface } from '../survey/survey';

export type UserRole = 'respondant' | 'coach' | 'sponsor' | 'admin';

export interface UserInterface extends BaseModelInterface {
	created_at?: number | Date;
	first_name: string;
	last_name: string;
	email?: string;
	password?: string;
	role?: UserRole;
	last_connected_at?: number | Date;
	email_confirmed?: boolean;
	coachs?: (string | User | UserInterface)[];
	phone_number?: string;
	birthdate?: number | Date;
	avatar?: string | Image | ImageInterface;
	reports?: (string | Report | ReportInterface)[];
	surveys?: (string | Survey | SurveyInterface)[];
	position?: string;
	department?: string;
	address?: string;
}
export interface UserPayload {
	first_name: string;
	last_name: string;
	email?: string;
	password?: string;
	role?: UserRole;
	coachs?: (string | User | UserInterface)[];
	phone_number?: string;
	birthdate?: number | Date;
	avatar?: string | Image | ImageInterface;
	reports?: (string | Report | ReportInterface)[];
	surveys?: (string | Survey | SurveyInterface)[];
	position?: string;
	department?: string;
	address?: string;
}
type UserPayloadKey = keyof UserPayload;
export class User extends BaseModel<UserInterface, UserPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${
			this.props.role !== 'sponsor' ? `${this.props.first_name} ` : ''
		}${this.props.last_name}`;
	}
	/** Denotes if the instance of coachs has been populated */
	coachsExists(): boolean {
		return (
			!!this.props &&
			this.props.coachs instanceof Array &&
			(<User[]>this.props.coachs).every((item) => {
				return item instanceof User && item.exists();
			})
		);
	}
	/** Denotes if the instance of avatar has been populated */
	avatarExists(): boolean {
		return (
			!!this.props &&
			this.props.avatar instanceof Image &&
			this.props.avatar.exists()
		);
	}
	/** Denotes if the instance of reports has been populated */
	reportsExists(): boolean {
		return (
			!!this.props &&
			this.props.reports instanceof Array &&
			(<Report[]>this.props.reports).every((item) => {
				return item instanceof Report && item.exists();
			})
		);
	}
	/** Denotes if the instance of surveys has been populated */
	surveysExists(): boolean {
		return (
			!!this.props &&
			this.props.surveys instanceof Array &&
			(<Survey[]>this.props.surveys).every((item) => {
				return item instanceof Survey && item.exists();
			})
		);
	}

	/** @return { boolean} Ensure user have access to report */
	haveAccess(report: Report | string): boolean {
		const reportId = report instanceof Report ? report.getId() : report;

		return !!this.props.reports.find((r) =>
			r instanceof Report ? reportId === r.getId() : reportId === r
		);
	}

	profilIsCompleted(): boolean {
		return this.props.role !== 'respondant' || !!this.props.position;
	}

	isCoach() {
		return this.props.role === 'coach';
	}

	isRespondant() {
		return this.props.role === 'respondant';
	}

	isSponsor() {
		return this.props.role === 'sponsor';
	}

	getAge(): number {
		if (!this.props.birthdate || !(this.props.birthdate instanceof Date))
			return 0;

		var ageDifMs = Date.now() - (<Date>this.props.birthdate).getTime();
		var ageDate = new Date(ageDifMs); // miliseconds from epoch
		return Math.abs(ageDate.getUTCFullYear() - 1970);
	}

	/** Populate the current instance from an object */
	fromObject(object: UserInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.last_connected_at !== null) {
			this.props.last_connected_at = Helpers.convertToDate(
				this.props.last_connected_at
			);
		}
		if (this.props.coachs instanceof Array) {
			this.props.coachs = (<UserInterface[]>this.props.coachs).map(
				(item) => {
					return typeof item === 'object' ? new User(item) : item;
				}
			);
		}
		if (
			this.props.avatar !== null &&
			typeof this.props.avatar === 'object'
		) {
			this.props.avatar = new Image(<ImageInterface>this.props.avatar);
		}
		if (this.props.reports instanceof Array) {
			this.props.reports = (<ReportInterface[]>this.props.reports).map(
				(item) => {
					return typeof item === 'object' ? new Report(item) : item;
				}
			);
		}
		if (this.props.surveys instanceof Array) {
			this.props.surveys = (<SurveyInterface[]>this.props.surveys).map(
				(item) => {
					return typeof item === 'object' ? new Survey(item) : item;
				}
			);
		}

		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): UserInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.last_connected_at === 'undefined') {
			props.last_connected_at = null;
		}
		if (typeof props.coachs === 'undefined') {
			props.coachs = [];
		}
		if (
			typeof props.phone_number !== 'string' ||
			props.phone_number.length === 0
		) {
			props.phone_number = null;
		}
		if (typeof props.avatar === 'undefined') {
			props.avatar = null;
		}
		if (typeof props.reports === 'undefined') {
			props.reports = [];
		}
		if (typeof props.surveys === 'undefined') {
			props.surveys = [];
		}
		if (typeof props.position !== 'string' || props.position.length === 0) {
			props.position = null;
		}
		if (
			typeof props.department !== 'string' ||
			props.department.length === 0
		) {
			props.department = null;
		}
		if (typeof props.address !== 'string' || props.address.length === 0) {
			props.address = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.last_connected_at !== null) {
			props.last_connected_at = Helpers.convertToTimestamp(
				props.last_connected_at
			);
		}
		if (this.props.coachs instanceof Array) {
			props.coachs = (<User[]>props.coachs).map((item) => {
				return item instanceof User ? item.toObject() : item;
			});
		}
		if (props.avatar !== null && props.avatar instanceof Image) {
			props.avatar = props.avatar.toObject();
		}
		if (this.props.reports instanceof Array) {
			props.reports = (<Report[]>props.reports).map((item) => {
				return item instanceof Report ? item.toObject() : item;
			});
		}
		if (this.props.surveys instanceof Array) {
			props.surveys = (<Survey[]>props.surveys).map((item) => {
				return item instanceof Survey ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): UserPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys(raw.role as UserRole);
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as UserInterface);
		if (payload.password === null) {
			delete payload.password;
		}
		payload.coachs = payload.coachs
			? payload.coachs.map((i) => this.extractId(i))
			: undefined;
		payload.avatar = payload.avatar
			? this.extractId(payload.avatar)
			: undefined;
		payload.reports = payload.reports
			? payload.reports.map((i) => this.extractId(i))
			: undefined;
		payload.phone_number = payload.phone_number
			? payload.phone_number
			: undefined;
		payload.surveys = payload.surveys
			? payload.surveys.map((i) => this.extractId(i))
			: undefined;
		return payload as UserPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(userRole: UserRole): UserPayloadKey[] {
		const baseFields = ['last_name', 'email', 'password', 'avatar'];

		let roleFields = [];
		switch (userRole) {
			case 'respondant':
				roleFields = ['first_name', 'position', 'department'];
				break;
			case 'coach':
				roleFields = ['first_name'];
				break;
			case 'sponsor':
				roleFields = ['address', 'department', 'phone_number'];
				break;
			case 'admin':
				roleFields = ['first_name'];
				break;
		}

		return [...baseFields, ...roleFields] as UserPayloadKey[];
	}
}
