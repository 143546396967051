import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Survey, SurveyInterface } from '../survey/survey';
import { User, UserInterface } from '../user/user';
import { Question, QuestionInterface } from '../question/question';
export interface SurveyOpenResultInterface extends BaseModelInterface {
	created_at?: number | Date;
	survey: string | Survey | SurveyInterface;
	owner?: string | User | UserInterface;
	question: string | Question | QuestionInterface;
	answer?: string;
}
export interface SurveyOpenResultPayload {
	survey: string | Survey | SurveyInterface;
	question: string | Question | QuestionInterface;
	answer?: string;
}
type SurveyOpenResultPayloadKey = keyof SurveyOpenResultPayload;
export class SurveyOpenResult extends BaseModel<
	SurveyOpenResultInterface,
	SurveyOpenResultPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of survey has been populated */
	surveyExists(): boolean {
		return (
			!!this.props &&
			this.props.survey instanceof Survey &&
			this.props.survey.exists()
		);
	}
	/** Denotes if the instance of owner has been populated */
	ownerExists(): boolean {
		return (
			!!this.props &&
			this.props.owner instanceof User &&
			this.props.owner.exists()
		);
	}
	/** Denotes if the instance of question has been populated */
	questionExists(): boolean {
		return (
			!!this.props &&
			this.props.question instanceof Question &&
			this.props.question.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: SurveyOpenResultInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.survey === 'object') {
			this.props.survey = new Survey(<SurveyInterface>this.props.survey);
		}
		if (typeof this.props.owner === 'object') {
			this.props.owner = new User(<UserInterface>this.props.owner);
		}
		if (typeof this.props.question === 'object') {
			this.props.question = new Question(
				<QuestionInterface>this.props.question
			);
		}

		this.props.answer = this.props.answer?.replace('\n', '<br/>');
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): SurveyOpenResultInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.answer !== 'string' || props.answer.length === 0) {
			props.answer = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.survey instanceof Survey) {
			props.survey = props.survey.toObject();
		}
		if (props.owner instanceof User) {
			props.owner = props.owner.toObject();
		}
		if (props.question instanceof Question) {
			props.question = props.question.toObject();
		}
		this.props.answer = this.props.answer?.replace('<br/>', '\n');
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): SurveyOpenResultPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as SurveyOpenResultInterface);
		payload.survey = payload.survey ? this.extractId(payload.survey) : null;
		payload.question = payload.question
			? this.extractId(payload.question)
			: null;
		payload.answer = payload.answer.trim() === '' ? null : payload.answer;
		return payload as SurveyOpenResultPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): SurveyOpenResultPayloadKey[] {
		return ['survey', 'question', 'answer'];
	}
}
