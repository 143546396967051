import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';

import { ErrorService } from '@app/services/error.service';
import { ExplainErrorsService } from '@app/services/explain-errors.service';
import { User, UserInterface } from '../user';
import { UserService } from '../user.service';
@Component({
	selector: 'hpf-user-form',
	templateUrl: './user-form.component.html',
})
export class UserFormComponent implements OnInit {
	/** The user to inject in the form */
	@Input() user: User;
	/** Called the save button is clicked for a new instance */
	@Output() create = new EventEmitter<User>();
	/** Called the save button is clicked for an existing instance */
	@Output() update = new EventEmitter<User>();
	/** The form group to use */
	form: FormGroup;
	/** Denotes if the form is pending */
	saving = false;
	/** Constructor */
	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private userService: UserService
	) {}
	/** Init */
	ngOnInit() {
		// If no instance, create a new one
		if (!this.user) {
			this.user = new User();
		}
		// Form validator
		const hiddenRequired = this.user.isNew() ? [Validators.required] : [];
		this.form = this.formBuilder.group({
			first_name: new FormControl(this.user.props.first_name, [
				Validators.required,
			]),
			last_name: new FormControl(this.user.props.last_name, [
				Validators.required,
			]),
			email: new FormControl(this.user.props.email, [
				Validators.email,
				Validators.required,
			]),
			password: new FormControl(
				this.user.props.password,
				[Validators.minLength(6)].concat(hiddenRequired)
			),
			role: new FormControl(this.user.props.role, [Validators.required]),
			coachs: new FormControl(this.user.props.coachs, []),
			phone_number: new FormControl(this.user.props.phone_number, [
				Validators.required,
			]),
			birthdate: new FormControl(this.user.props.birthdate, []),
			reports: new FormControl(this.user.props.reports, []),
			avatar: new FormControl(this.user.props.avatar, []),
		});
	}
	/** Called on form submit */
	async onSubmit(): Promise<void> {
		// Saving flag
		this.saving = true;
		try {
			// Update model
			this.updateModel();
			// Creation or update ?
			if (this.user.isNew()) {
				// Creation
				const user: User = await this.userService.create(
					this.user.toPayload()
				);
				this.create.next(user);
			} else {
				// Update
				await this.userService.update(
					this.user.getId(),
					this.user.toPayload()
				);
				this.update.next(this.user);
			}
		} catch (error) {
			this.errorService.handle(error);
		}
		// Saving flag
		this.saving = false;
	}
	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.user.props[key] = this.form.get(key).value;
		}
	}
}
