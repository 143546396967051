import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import {
	ThematiqueFormateur,
	ThematiqueFormateurInterface,
} from '../thematique-formateur/thematique-formateur';
export interface FormateurProfileInterface extends BaseModelInterface {
	created_at: number | Date;
	formateur: string | User | UserInterface;
	gender?: any;
	thematiques: (
		| string
		| ThematiqueFormateur
		| ThematiqueFormateurInterface
	)[];
	address?: string;
	postal_code?: string;
	city?: string;
}
export interface FormateurProfilePayload {
	formateur: string | User | UserInterface;
	gender?: any;
	thematiques: (
		| string
		| ThematiqueFormateur
		| ThematiqueFormateurInterface
	)[];
	address?: string;
	postal_code?: string;
	city?: string;
}
type FormateurProfilePayloadKey = keyof FormateurProfilePayload;
export class FormateurProfile extends BaseModel<
	FormateurProfileInterface,
	FormateurProfilePayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of formateur has been populated */
	formateurExists(): boolean {
		return (
			!!this.props &&
			this.props.formateur instanceof User &&
			this.props.formateur.exists()
		);
	}
	/** Denotes if the instance of thematiques has been populated */
	thematiquesExists(): boolean {
		return (
			!!this.props &&
			this.props.thematiques instanceof Array &&
			(<ThematiqueFormateur[]>this.props.thematiques).every((item) => {
				return item instanceof ThematiqueFormateur && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: FormateurProfileInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.formateur === 'object') {
			this.props.formateur = new User(
				<UserInterface>this.props.formateur
			);
		}
		if (this.props.thematiques instanceof Array) {
			this.props.thematiques = (<ThematiqueFormateurInterface[]>(
				this.props.thematiques
			)).map((item) => {
				return typeof item === 'object'
					? new ThematiqueFormateur(item)
					: item;
			});
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): FormateurProfileInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.gender === 'undefined') {
			props.gender = null;
		}
		if (typeof props.address !== 'string' || props.address.length === 0) {
			props.address = null;
		}
		if (
			typeof props.postal_code !== 'string' ||
			props.postal_code.length === 0
		) {
			props.postal_code = null;
		}
		if (typeof props.city !== 'string' || props.city.length === 0) {
			props.city = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.formateur instanceof User) {
			props.formateur = props.formateur.toObject();
		}
		if (this.props.thematiques instanceof Array) {
			props.thematiques = (<ThematiqueFormateur[]>props.thematiques).map(
				(item) => {
					return item instanceof ThematiqueFormateur
						? item.toObject()
						: item;
				}
			);
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): FormateurProfilePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as FormateurProfileInterface);
		payload.formateur = payload.formateur
			? this.extractId(payload.formateur)
			: null;
		payload.thematiques = payload.thematiques
			? payload.thematiques.map((i) => this.extractId(i))
			: [];
		return payload as FormateurProfilePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): FormateurProfilePayloadKey[] {
		return [
			'formateur',
			'gender',
			'thematiques',
			'address',
			'postal_code',
			'city',
		];
	}
}
