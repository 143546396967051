import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
export interface ColorInterface extends BaseModelInterface {
	created_at?: number | Date;
	name: string;
	main: string;
	gradient_min?: string;
	gradient_max?: string;
	disabled?: boolean;
}
export interface ColorPayload {
	name: string;
	main: string;
	gradient_min?: string;
	gradient_max?: string;
}
type ColorPayloadKey = keyof ColorPayload;
export class Color extends BaseModel<ColorInterface, ColorPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}

	/** Format color to be used with antv */
	toAnt(allowDisabled?: boolean): string {
		const disabled = allowDisabled ? false : this.isDisabled();

		if (!this.isGradient())
			return disabled
				? this.disableColor(this.props.main)
				: this.props.main;

		const gradientMin = disabled
			? this.disableColor(this.props.gradient_min)
			: this.props.gradient_min;
		const gradientMax = disabled
			? this.disableColor(this.props.gradient_max)
			: this.props.gradient_max;

		return `l(270) 0:${gradientMin} 1:${gradientMax}`;
	}

	isDisabled(): boolean {
		return this.props.disabled;
	}

	isGradient(): boolean {
		return !!this.props.gradient_min && !!this.props.gradient_max;
	}

	/** Populate the current instance from an object */
	fromObject(object: ColorInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ColorInterface {
		const props = Object.assign({}, this.props);
		if (
			typeof props.gradient_min !== 'string' ||
			props.gradient_min.length === 0
		) {
			props.gradient_min = null;
		}
		if (
			typeof props.gradient_max !== 'string' ||
			props.gradient_max.length === 0
		) {
			props.gradient_max = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ColorPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ColorInterface);
		return payload as ColorPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): ColorPayloadKey[] {
		return ['name', 'main', 'gradient_min', 'gradient_max'];
	}

	private disableColor(color: string): string {
		if (!color || color[0] !== '#') return color;

		if (color.length > 7) {
			return `${color.substring(0, 7)}55`;
		} else {
			return `${color}55`;
		}
	}
}
