import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModuleLoad } from '@app/translate-import';
import { AnswerService } from './answer';
import { AxeService } from './axe';
import { AxeSurveyService } from './axe-survey';
import { AxeWeightService } from './axe-weight';
import { ColorService } from './color';
import { GraphService } from './graph';
import { GraphModeratedService } from './graph-moderated';
import { ImageService, ImageSelectComponent } from './image';
import { QuestionService } from './question';
import { ReportService, ReportSelectComponent } from './report';
import { ReportModeratedService } from './report-moderated';
import { SurveyService } from './survey';
import { SurveyLabelService } from './survey-label';
import { SurveyResultService } from './survey-result';
import { UserService, UserFormComponent, UserSelectComponent } from './user';
import { FormationService, FormationSelectComponent } from './formation';
import {
	FormationThemeSelectComponent,
	FormationThemeService,
} from './formation-theme';
import { SurveyOpenResultService } from './survey-open-result';
import { FormateurProfileService } from './formateur-profile';
import { ThematiqueFormateurService } from './thematique-formateur';
import { DocumentAdministrativeService } from './document-administrative';
import { DocumentPedagogiqueService } from './document-pedagogique';
import { FormationAccessService } from './formation-access';
import { FormationSessionService } from './formation-session';
import { FormationThematiqueService } from './formation-thematique';
import { FormationInterventionService } from './formation-intervention';
@NgModule({
	imports: [CommonModule, SharedModule, TranslateModuleLoad()],
	declarations: [
		ImageSelectComponent,
		ReportSelectComponent,
		FormationSelectComponent,
		UserFormComponent,
		UserSelectComponent,
		FormationThemeSelectComponent,
	],
	providers: [
		AnswerService,
		AxeService,
		AxeSurveyService,
		AxeWeightService,
		ColorService,
		FormationService,
		FormationThematiqueService,
		FormationAccessService,
		FormationThemeService,
		GraphService,
		GraphModeratedService,
		ImageService,
		QuestionService,
		ReportService,
		ReportModeratedService,
		SurveyService,
		SurveyLabelService,
		SurveyOpenResultService,
		SurveyResultService,
		UserService,
		FormateurProfileService,
		ThematiqueFormateurService,
		DocumentAdministrativeService,
		DocumentPedagogiqueService,
		FormationAccessService,
		FormationSessionService,
		FormationThematiqueService,
		FormationInterventionService,
	],
	exports: [
		ImageSelectComponent,
		ReportSelectComponent,
		FormationSelectComponent,
		UserFormComponent,
		UserSelectComponent,
		FormationThemeSelectComponent,
	],
})
export class ModelsModule {}
