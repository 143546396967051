import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Question } from '@app/models/question';
import { User } from '@app/models/user';

@Component({
	selector: 'app-atom-question',
	templateUrl: './atom-question.component.html',
	styleUrls: ['./atom-question.component.less'],
})
export class AtomQuestionComponent implements OnInit {
	@Input() question: Question;
	@Input() user: User;
	@Output() answerUpdate = new EventEmitter<string>();

	@Input() selectedAnswer: string;

	constructor() {}

	ngOnInit() {}
}
