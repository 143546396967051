import { Injectable } from '@angular/core';
import { Report, ReportInterface, ReportPayload } from './report';
import { ReportSearchParamsInterface } from './report-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class ReportService extends BaseModelService<
	Report,
	ReportInterface,
	ReportPayload,
	ReportSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'report';
	}
	/** @inheritDoc */
	protected new(object: ReportInterface): Report {
		return new Report(object);
	}
}
