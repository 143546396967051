import { Component, Input, OnInit } from '@angular/core';
import { Formation } from '@app/models/formation';
import { Image } from '@app/models/image';
import { User } from '@app/models/user';

@Component({
	selector: 'app-atom-sponsor-details',
	templateUrl: './atom-sponsor-details.component.html',
	styleUrls: ['./atom-sponsor-details.component.less'],
})
export class AtomSponsorDetailsComponent implements OnInit {
	@Input() sponsor: User;
	@Input() totalRespondant: number;

	name: string;
	avatar: string;

	constructor() {}

	ngOnInit(): void {
		this.name = this.sponsor.getLabel();
		this.avatar = this.sponsor.avatarExists()
			? (<Image>this.sponsor.props.avatar).getUrl()
			: null;
	}
}
