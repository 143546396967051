import { Injectable } from '@angular/core';
import { Survey, SurveyInterface, SurveyPayload } from './survey';
import { SurveySearchParamsInterface } from './survey-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class SurveyService extends BaseModelService<
	Survey,
	SurveyInterface,
	SurveyPayload,
	SurveySearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'survey';
	}
	/** @inheritDoc */
	protected new(object: SurveyInterface): Survey {
		return new Survey(object);
	}
}
