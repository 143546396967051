<div
	class="graph-form d-flex flex-column gap-6"
	*ngIf="graphFormDataList?.length"
>
	<div class="graph-container" [ngClass]="'theme--' + theme">
		<div
			class="bandeau"
			*ngIf="colorThematique"
			[ngStyle]="
				colorThematique.props.gradient_min &&
				colorThematique.props.gradient_max
					? {
							background:
								'linear-gradient(180deg, ' +
								colorThematique.props.gradient_min +
								', ' +
								colorThematique.props.gradient_max +
								')'
					  }
					: {
							background: colorThematique.props.main
					  }
			"
		></div>
		<div
			class="bandeau"
			*ngIf="!colorThematique"
			[ngStyle]="{
				background: 'linear-gradient(180deg, #79c5f1, #07a1e2)'
			}"
		></div>
		<!-- Side bar -->
		<div class="graph-form__wrapper">
			<aside class="graph-form__aside">
				<ng-container *ngIf="currentGraphFormData">
					<header class="graph-form__aside__header">
						<h3 class="graph-form__aside__title">
							{{
								currentGraphFormData.surveyResultFormatted.label
							}}
						</h3>
						<p class="graph-form__aside__text">
							{{ 'graph-form-total-points' | translate }}
							<strong>
								{{
									currentGraphFormData.surveyResultFormatted
										.correctAnswers
								}}/{{
									currentGraphFormData.surveyResultFormatted
										.totalAnswers
								}}
							</strong>
						</p>
					</header>
					<footer class="graph-form__aside__footer">
						<div *ngIf="graphFormDataList.length > 1">
							<a
								class="pr-2 text-underline"
								*ngIf="currentIndex > 0"
								(click)="selectIndex(currentIndex - 1)"
								>previous</a
							>
							<span>
								{{ currentIndex + 1 }}/{{
									graphFormDataList.length
								}}
							</span>
							<a
								class="pl-2"
								*ngIf="
									currentIndex < graphFormDataList.length - 1
								"
								(click)="selectIndex(currentIndex + 1)"
								>next</a
							>
						</div>
					</footer>
				</ng-container>
			</aside>
			<!-- /Side bar -->

			<!-- Content -->
			<div class="graph-form__inner">
				<ng-container *ngIf="currentGraphFormData">
					<ng-container
						*ngFor="
							let question of currentGraphFormData
								.surveyResultFormatted.questions;
							let questionIndex = index
						"
					>
						<div class="question__item">
							<h3 class="question__item__title">
								{{ questionIndex + 1 }})
								{{ question.label.label }}
							</h3>

							<div class="question__item__responses">
								<ng-container [ngSwitch]="question.type">
									<!-- Content Open Question -->
									<ng-container *ngSwitchCase="'open'">
										<div
											class="question__item__response--open"
										>
											<span>{{
												question.open_answer
											}}</span>
										</div>
									</ng-container>
									<!-- /Content Open Question -->

									<!-- Content Default Question -->
									<ng-container *ngSwitchDefault>
										<ng-container
											*ngFor="
												let answer of question.answers
											"
										>
											<div
												class="question__item__response"
												[class.correct-select]="
													answer.selected &&
													answer.isCorrectAnswer
												"
												[class.incorrect-select]="
													answer.selected &&
													!answer.isCorrectAnswer
												"
												[class.correct-unselect]="
													!answer.selected &&
													answer.isCorrectAnswer
												"
											>
												{{ answer.label.label }}
											</div>
										</ng-container>
									</ng-container>
									<!-- /Content Default Question -->
								</ng-container>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<!-- /Content -->
		</div>
	</div>
</div>
