import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import {
	BaseModel,
	BaseModelInterface,
	BaseModelSearchParamsInterface,
} from '@app/abstracts';
import { BaseWalker } from './base-walker';

/** Interface for listings result */
export interface BaseModelSearchResultInterface<T> {
	page: number;
	limit: number;
	count: number;
	total: number;
	items: T[];
}

/** Interface for count results */
export interface BaseModelCountResultInterface {
	total: number;
}

export interface BaseReadQueryInterface {
	_populate?: string[];
}

@Injectable()
export abstract class BaseModelService<
	T extends BaseModel<BaseModelInterface, P>,
	I extends BaseModelInterface,
	P extends {},
	S extends BaseModelSearchParamsInterface
> {
	/** Removes credentials on read action to allow shared caching */
	protected publicRead = false;
	/** Removes credentials on list action to allow shared caching */
	protected publicList = false;
	/** Removes credentials on count action to allow shared caching */
	protected publicCount = false;
	/** Constructor */
	constructor(protected http: HttpClient) {}

	/** Create a new model */
	create(
		payload: P,
		query: BaseReadQueryInterface = {},
		asAdmin = false
	): Promise<T> {
		// Start request
		const options = {
			withCredentials: true,
			params: query as {},
		};
		return this.http
			.post<I>(`${this.uri(asAdmin)}`, payload, options)
			.toPromise()
			.then((result) => this.new(result));
	}

	createAsAdmin(payload: P, query: BaseReadQueryInterface = {}): Promise<T> {
		return this.create(payload, query, true);
	}

	/** Update an model selected from it's id */
	update(id: string, payload: Partial<P>, asAdmin = false): Promise<void> {
		// Start request
		const options = { withCredentials: true };
		return this.http
			.patch<void>(`${this.uri(asAdmin)}/${id}`, payload, options)
			.toPromise();
	}

	updateAsAdmin(id: string, payload: Partial<P>): Promise<void> {
		return this.update(id, payload, true);
	}

	/** Get an model from it's id */
	get(
		id: string,
		query: BaseReadQueryInterface = {},
		asAdmin = false
	): Promise<T> {
		// Start request
		const options = {
			withCredentials: !this.publicRead,
			params: query as {},
		};
		return this.http
			.get<I>(`${this.uri(asAdmin)}/${id}`, options)
			.toPromise()
			.then((result) => this.new(result));
	}

	getAsAdmin(id: string, query: BaseReadQueryInterface = {}): Promise<T> {
		return this.get(id, query, true);
	}

	/** Delete an model selected from it's id */
	remove(id: string, asAdmin = false): Promise<void> {
		// Start request
		const options = { withCredentials: true };
		return this.http
			.delete<void>(`${this.uri(asAdmin)}/${id}`, options)
			.toPromise();
	}

	removeAsAdmin(id: string): Promise<void> {
		return this.remove(id, true);
	}

	/** Get list for model search */
	list(
		searchParams: S,
		bypassTransform = false,
		asAdmin = false
	): Promise<BaseModelSearchResultInterface<T>> {
		// Start request
		const options = {
			withCredentials: !this.publicList,
			params: bypassTransform
				? searchParams
				: (this.transformSearchParams(searchParams) as {}),
		};
		return this.http
			.get<BaseModelSearchResultInterface<I>>(
				`${this.uri(asAdmin)}`,
				options
			)
			.toPromise()
			.then((result) => {
				// Create list from results
				return {
					page: result.page,
					limit: result.limit,
					count: result.count,
					total: result.total,
					items: result.items.map((item): T => this.new(item)),
				};
			});
	}

	listAsAdmin(
		searchParams: S,
		bypassTransform = false
	): Promise<BaseModelSearchResultInterface<T>> {
		return this.list(searchParams, bypassTransform, true);
	}

	/** Count for model */
	count(
		searchParams: S,
		bypassTransform = false,
		asAdmin = false
	): Promise<number> {
		// Remove unwanted properties
		const params = Object.assign(
			{},
			bypassTransform
				? searchParams
				: this.transformSearchParams(searchParams)
		);
		delete params._page;
		delete params._limit;
		delete params._order;
		delete params._sort;
		// Start request
		const options = {
			withCredentials: !this.publicCount,
			params: params as {},
		};
		return this.http
			.get<BaseModelCountResultInterface>(
				`${this.uri(asAdmin)}/count`,
				options
			)
			.toPromise()
			.then((result) => result.total);
	}

	countAsAdmin(searchParams: S, bypassTransform = false): Promise<number> {
		return this.count(searchParams, bypassTransform, true);
	}

	getWalker(): BaseWalker<T, I, P, S> {
		return new BaseWalker<T, I, P, S>(this);
	}

	/** Returns the base URI for this model */
	protected uri(isAdmin = false): string {
		return `${environment.api.uri}/${
			isAdmin ? 'admin/' : ''
		}${this.path()}`;
	}

	/** Transform search params before search & count */
	protected transformSearchParams(searchParams: S): S {
		return searchParams;
	}

	/** Returns the base URI for this model */
	protected abstract path(): string;

	/** Create a new instance for this payload */
	protected abstract new(object: I): T;
}
