import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { Survey, SurveyInterface } from '../survey/survey';
import { Report, ReportInterface } from '../report/report';
export interface FormationAccessInterface extends BaseModelInterface {
	created_at: number | Date;
	survey: string | Survey | SurveyInterface;
	reports?: (string | Report | ReportInterface)[];
	date: number | Date;
	allocated: boolean;
	email_date?: number | Date;
	email_sended: boolean;
	reminder_date?: number | Date;
	reminder_email_sended: boolean;
}
export interface FormationAccessPayload {
	survey: string | Survey | SurveyInterface;
	reports?: (string | Report | ReportInterface)[];
	date: number | Date;
	email_date?: number | Date;
	reminder_date?: number | Date;
}
type FormationAccessPayloadKey = keyof FormationAccessPayload;
export class FormationAccess extends BaseModel<
	FormationAccessInterface,
	FormationAccessPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of survey has been populated */
	surveyExists(): boolean {
		return (
			!!this.props &&
			this.props.survey instanceof Survey &&
			this.props.survey.exists()
		);
	}
	/** Denotes if the instance of reports has been populated */
	reportsExists(): boolean {
		return (
			!!this.props &&
			this.props.reports instanceof Array &&
			(<Report[]>this.props.reports).every((item) => {
				return item instanceof Report && item.exists();
			})
		);
	}

	/** Get list of report visible by respondant in this access */
	getRespondantReports(): Report[] {
		return this.reportsExists()
			? (<Report[]>this.props.reports).filter((r) => !r.props.grouped)
			: [];
	}

	/** Populate the current instance from an object */
	fromObject(object: FormationAccessInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.props.date = Helpers.convertToDate(this.props.date);
		if (this.props.email_date !== null) {
			this.props.email_date = Helpers.convertToDate(
				this.props.email_date
			);
		}
		if (this.props.reminder_date !== null) {
			this.props.reminder_date = Helpers.convertToDate(
				this.props.reminder_date
			);
		}
		if (typeof this.props.survey === 'object') {
			this.props.survey = new Survey(<SurveyInterface>this.props.survey);
		}
		if (this.props.reports instanceof Array) {
			this.props.reports = (<ReportInterface[]>this.props.reports).map(
				(item) => {
					return typeof item === 'object' ? new Report(item) : item;
				}
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): FormationAccessInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.reports === 'undefined') {
			props.reports = [];
		}
		if (typeof props.email_date === 'undefined') {
			props.email_date = null;
		}
		if (typeof props.reminder_date === 'undefined') {
			props.reminder_date = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		props.date = Helpers.convertToTimestamp(props.date);
		if (props.email_date !== null) {
			props.email_date = Helpers.convertToTimestamp(props.email_date);
		}
		if (props.reminder_date !== null) {
			props.reminder_date = Helpers.convertToTimestamp(
				props.reminder_date
			);
		}
		if (props.survey instanceof Survey) {
			props.survey = props.survey.toObject();
		}
		if (this.props.reports instanceof Array) {
			props.reports = (<Report[]>props.reports).map((item) => {
				return item instanceof Report ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): FormationAccessPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as FormationAccessInterface);
		payload.survey = payload.survey ? this.extractId(payload.survey) : null;
		payload.reports = payload.reports
			? payload.reports.map((i) => this.extractId(i))
			: null;
		payload.date = <number>payload.date;
		payload.email_date = <number>payload.email_date;
		payload.reminder_date = <number>payload.reminder_date;
		return payload as FormationAccessPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): FormationAccessPayloadKey[] {
		return ['survey', 'reports', 'date', 'email_date', 'reminder_date'];
	}
}
