<div *ngIf="user && showIndividualReport">
	<div
		class="container-xl px-xl-0 mb-5 ml-0 details-formation-thematique-container"
	>
		<div class="row mx-0">
			<div
				class="col-12 px-4 px-md-5 py-3 d-flex flex-row align-items-center"
			>
				<!-- Avatar Sponsor -->
				<div class="mr-3 mr-md-5 container-avatar">
					<nz-avatar
						id="avatar"
						class="respondant-avatar d-md-none"
						[ngClass]="
							user.props.avatar ? 'avatarPhoto' : 'avatarIcone'
						"
						[nzSize]="120"
						nzIcon="user"
						[nzSrc]="user.props.avatar"
					></nz-avatar>
					<nz-avatar
						id="avatar"
						class="respondant-avatar d-none d-md-inline-block"
						[ngClass]="
							user.props.avatar ? 'avatarPhoto' : 'avatarIcone'
						"
						[nzSize]="150"
						nzIcon="user"
						[nzSrc]="user.props.avatar"
					></nz-avatar>
				</div>
				<!-- Avatar Sponsor -->
				<!-- Infos Thematique Formation -->
				<div class="infos-thematique-container">
					<h2 class="thematique-title mb-1">
						{{ user.props.first_name + ' ' + user.props.last_name }}
					</h2>
				</div>
				<div
					class="bandeau"
					[ngStyle]="
						colorThematique.props.gradient_min &&
						colorThematique.props.gradient_max
							? {
									background:
										'linear-gradient(180deg, ' +
										colorThematique.props.gradient_min +
										', ' +
										colorThematique.props.gradient_max +
										')'
							  }
							: {
									background: colorThematique.props.main
							  }
					"
				></div>
			</div>
		</div>
	</div>
</div>
<div class="d-flex justify-content-between align-items-end">
	<h2 class="mb-0">
		{{ title }}
	</h2>

	<a
		*ngIf="(showIndividualReport && user) || showGroupedReport"
		class="link--download"
		(click)="handleClickToPrint()"
	>
		{{ 'report_moderated-download' | translate }}
		<i
			*ngIf="!printLoading; else iconLoading"
			class="ml-2"
			nz-icon
			nzType="file-pdf"
			nzTheme="outline"
		></i>
		<ng-template #iconLoading>
			<i class="ml-2" nz-icon [nzType]="'loading'"></i>
		</ng-template>
	</a>

	<ng-container *ngIf="allowDetailled">
		<a
			class="link-report-full"
			(click)="
				appModalService.openReportModeratedDetailled(
					respondantId,
					reportModerated.getId()
				)
			"
			>{{ 'report_moderated-see-analyze' | translate }}
			<i class="ml-2" nz-icon nzType="right" nzTheme="outline"></i
		></a>
	</ng-container>
</div>
<div *ngIf="(showIndividualReport && user) || showGroupedReport">
	<ng-container
		*ngIf="
			reportModerated &&
				(reportModerated.props.moderated || allowNotModerated);
			else reportModeratedEmptyTpl
		"
	>
		<div class="graphs-container">
			<ng-container
				*ngFor="let graph of graphsModerated"
				[ngSwitch]="graph.type"
			>
				<div
					[ngClass]="
						'container-graph-' +
						graph.type +
						' graph-' +
						graph.nbAxe
					"
					[class.graph-large]="graph.type === 'form'"
					class="container-graph"
				>
					<app-atom-graph-bar
						*ngSwitchCase="'bar'"
						[data]="graph.data"
						[showAverage]="graph.showAverage"
						[colors]="graph.colors"
						[theme]="graph.theme"
						[titleGraph]="graph.title"
						[maxAxis]="graph.max"
						[colorThematique]="colorThematique"
						[isPrinting]="sendPrint"
					></app-atom-graph-bar>

					<app-atom-graph-grouped-bar
						*ngSwitchCase="'grouped_bar'"
						[data]="graph.dataGrouped"
						[showAverage]="graph.showAverage"
						[colors]="graph.colors"
						[theme]="graph.theme"
						[titleGraph]="graph.title"
						[maxAxis]="graph.max"
						[colorThematique]="colorThematique"
						[isPrinting]="sendPrint"
					></app-atom-graph-grouped-bar>

					<app-atom-graph-radar
						*ngSwitchCase="'radar'"
						[data]="graph.data"
						[showAverage]="graph.showAverage"
						[color]="graph.colors[0]"
						[theme]="graph.theme"
						[titleGraph]="graph.title"
						[maxAxis]="graph.max"
						[colorThematique]="colorThematique"
					></app-atom-graph-radar>

					<app-atom-graph-gauge-svg
						*ngSwitchCase="'gauge'"
						[valueAxe1]="graph.data[0].y"
						[valueAxe2]="graph.data[1].y"
						[colors]="graph.colors"
						[theme]="graph.theme"
						[titleGraph]="graph.title"
						[labelMin]="graph.labelMin"
						[labelMax]="graph.labelMax"
						[colorThematique]="colorThematique"
					></app-atom-graph-gauge-svg>

					<app-atom-graph-average
						*ngSwitchCase="'average'"
						[data]="graph.data"
						[showAverage]="graph.showAverage"
						[theme]="graph.theme"
						[titleGraph]="graph.title"
						[maxAxis]="graph.max"
						[colorThematique]="colorThematique"
					></app-atom-graph-average>

					<app-atom-graph-form
						*ngSwitchCase="'form'"
						[theme]="graph.theme"
						[graphModerated]="graph.source"
						[reportModerated]="reportModerated"
						[colorThematique]="colorThematique"
					></app-atom-graph-form>
				</div>
			</ng-container>
		</div>

		<!-- Report All open question result -->
		<ng-container *ngIf="surveyOpenResults">
			<div class="container--print container-xl px-xl-0 mb-5 mt-5 ml-0">
				<div class="row">
					<div
						class="col-12"
						*ngFor="let surveyOpen of surveyOpenResults"
					>
						<h2>
							{{ 'survey-answers-question-title' | translate }} :
							{{ surveyOpen.surveyLabel }}
						</h2>
						<div
							class="content-break"
							*ngFor="let question of surveyOpen.questions"
						>
							<h3>
								{{ 'survey-answers-question-name' | translate }}
								:
								{{ question.questionLabel }}
							</h3>
							<p
								*ngIf="
									question.answers.length === 0;
									else tplAnswer
								"
							>
								{{ 'survey-answers-no-result' | translate }}
							</p>
							<ng-template #tplAnswer>
								<div class="table-container">
									<table>
										<tbody>
											<ng-container
												*ngFor="
													let answer of question.answers
												"
											>
												<tr *ngIf="answer.answer">
													<td>{{ answer.answer }}</td>
												</tr>
											</ng-container>
										</tbody>
									</table>
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-container>

	<!-- Report Moderated Empty -->
	<ng-template #reportModeratedEmptyTpl>
		<div class="emptyReport" [ngClass]="'emptyReport-' + typeReport">
			<div
				class="bandeau"
				[ngStyle]="
					colorThematique.props.gradient_min &&
					colorThematique.props.gradient_max
						? {
								background:
									'linear-gradient(180deg, ' +
									colorThematique.props.gradient_min +
									', ' +
									colorThematique.props.gradient_max +
									')'
						  }
						: {
								background: colorThematique.props.main
						  }
				"
			></div>
			<p>{{ 'report_moderated-list_empty_available' | translate }}</p>
		</div>
	</ng-template>
</div>
<app-atom-report-moderated-user-list
	*ngIf="
		!user &&
		userLogged &&
		userLogged.props.role !== 'respondant' &&
		showIndividualReport &&
		!allowNotModerated
	"
	[surveyResults]="reportModerated.props.results"
	[customLink]="'../user/'"
></app-atom-report-moderated-user-list>
<!-- Report Moderated Empty -->
