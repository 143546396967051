import { Injectable } from '@angular/core';
import { Formation, FormationInterface, FormationPayload } from './formation';
import { FormationSearchParamsInterface } from './formation-search-params';
import { BaseWalker } from '@app/abstracts/base-walker';
import { FormationService } from './formation.service';

export class FormationWalker extends BaseWalker<
	Formation,
	FormationInterface,
	FormationPayload,
	FormationSearchParamsInterface
> {}

export class FormationFromAdminWalker extends BaseWalker<
	Formation,
	FormationInterface,
	FormationPayload,
	FormationSearchParamsInterface
> {
	listFonction = 'listAsAdmin';
}

@Injectable()
export class FormationWalkerService {
	constructor(private formationService: FormationService) {}

	getWalker(): FormationWalker {
		return new FormationWalker(this.formationService);
	}

	getWalkerFromAdmin(): FormationFromAdminWalker {
		return new FormationFromAdminWalker(this.formationService);
	}
}
