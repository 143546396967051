import {
	NgSignaturePadOptions,
	SignaturePadComponent,
} from '@almothafar/angular-signature-pad';
import { HttpClient } from '@angular/common/http';
import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
	selector: 'app-atom-pdf-signature',
	templateUrl: './atom-pdf-signature.component.html',
	styleUrls: ['./atom-pdf-signature.component.less'],
})
export class AtomPdfSignatureComponent implements AfterViewInit {
	@Input() pdfSrc: string;
	@Output() signature = new EventEmitter<string>();

	@ViewChild('signature')
	public signaturePad: SignaturePadComponent;
	public options: NgSignaturePadOptions = {
		canvasWidth: 500,
		canvasHeight: 284,
	};
	public unsubscribe$ = new Subject<void>();
	readyToSave = false;
	processing = false;

	constructor(private breakpointObserver: BreakpointObserver) {}

	ngAfterViewInit(): void {
		this.breakpointObserver
			.observe(['(min-width: 768px)'])
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((state: BreakpointState) => {
				if (state.matches) {
					this.signaturePad.set('canvasWidth', 500);
					this.signaturePad.set('canvasHeight', 224);
				} else {
					this.signaturePad.set('canvasWidth', 280);
					this.signaturePad.set('canvasHeight', 180);
				}
				this.signaturePad.clear();
			});
	}

	drawComplete(event: MouseEvent | Touch) {
		// will be notified of szimek/signature_pad's onEnd event
		this.readyToSave = true;
	}

	save() {
		this.readyToSave = false;
		this.processing = true;
		this.signature.emit(this.signaturePad.toDataURL());
	}

	delete() {
		this.signaturePad.clear();
		return true;
	}
}
