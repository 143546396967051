<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
	<div class="container-xl px-xl-0 ml-0">
		<div
			class="d-flex flex-wrap justify-content-between align-items-center mb-4"
		>
			<h2 class="mb-4 mb-md-0">
				{{ 'page_title-coach_list' | translate }}
			</h2>
			<!-- Sort and Filter -->
			<div
				class="d-flex align-items-center sort-container"
				*ngIf="searchParams; else loadingTpl"
			>
				<nz-input-group nzSize="large" [nzSuffix]="suffixIcon">
					<input
						type="text"
						nz-input
						[(ngModel)]="searchInput"
						(input)="inputSubject.next()"
						placeholder="{{ 'search-placeholder' | translate }}"
					/>
				</nz-input-group>
				<ng-template #suffixIcon>
					<i
						nz-icon
						nzType="search"
						style="font-size: 14px; color: rgba(0, 0, 0, 0.25);"
					></i>
				</ng-template>
				<nz-autocomplete
					#autoComplete
					nzOverlayClassName="results-search coach-results-search"
				>
					<nz-auto-option
						*ngFor="let coach of searchedCoaches"
						[nzValue]="
							coach.props.first_name + ' ' + coach.props.last_name
						"
					>
						<a
							[routerLink]="['/coach', coach.getId(), 'details']"
							nz-button
							nzType="link"
							class="search-result"
						>
							<nz-avatar
								ngClass="respondant-avatar mr-3"
								nzIcon="user"
								[nzSrc]="
									coach.avatarExists()
										? coach.props?.avatar.getUrl()
										: undefined
								"
							></nz-avatar>
							{{ coach.props.first_name }}
							{{ coach.props.last_name }}
						</a>
					</nz-auto-option>
				</nz-autocomplete>
				<nz-select
					class="coach-select--order"
					[(ngModel)]="searchParams.props._order"
					[nzPlaceHolder]="
						'coach_list-sort_select-placeholder' | translate
					"
					(ngModelChange)="handleOrderChange($event)"
				>
					<nz-option
						*ngFor="let order of orders"
						[nzValue]="order"
						[nzLabel]="'coach_list-sort-' + order | translate"
					>
					</nz-option>
				</nz-select>
			</div>
			<!-- /Sort and Filter -->
		</div>
	</div>
	<div *ngIf="searchParams && !loading" class="container-xl px-xl-0 ml-0">
		<div class="row">
			<div class="col-12 py-4 px-0 list-inline-container coach-list">
				<div
					class="d-flex flex-nowrap justify-content-start align-items-center head-list-inline"
				>
					<span class="coach">{{
						'coach-list-inline-title-name' | translate
					}}</span>
				</div>

				<ng-container
					*ngIf="coaches && coaches.length > 0; else emptyTpl"
				>
					<div class="col-12 item-list-container">
						<ng-container
							*ngFor="let coach of coaches; trackBy: trackById"
						>
							<app-atom-coach-card
								[coach]="coach"
							></app-atom-coach-card>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
		<div class="row" *ngIf="numberTotalCoach > limit">
			<div class="col-12 justify-content-end">
				<nz-pagination
					[nzPageIndex]="currentPageIndex"
					[nzPageSize]="limit"
					[nzTotal]="numberTotalCoach"
					(nzPageIndexChange)="handleNextPage($event)"
				></nz-pagination>
			</div>
		</div>
	</div>

	<ng-template #emptyTpl>
		<div class="col-12 py-4">
			<p>{{ 'coach-list-empty' | translate }}</p>
		</div>
	</ng-template>

	<!-- Loading -->
	<ng-template #loadingTpl>
		<div class="text-center">
			<app-atom-concept-loader></app-atom-concept-loader>
		</div>
	</ng-template>
	<!-- /Loading -->
</div>
