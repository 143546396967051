<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
	<div class="container-xl px-xl-0 ml-0">
		<ng-container *ngIf="formation && !loading">
			<app-atom-formation-card-detail
				[formation]="formation"
				[formationEnd]="formationEnd"
				[numberIntervention]="numberIntervention"
			></app-atom-formation-card-detail>
		</ng-container>
		<div
			class="d-flex flex-wrap justify-content-between align-items-center header__formations"
		>
			<h2 class="mb-0">
				{{ 'formation-intervention-list-admin-title' | translate }}
			</h2>
			<!-- Sort and Filter -->
			<div
				class="d-flex align-items-center sort-container"
				*ngIf="searchParams; else loadingTpl"
			>
				<!-- TRIE par date de creation ou label -->
				<nz-select
					[(ngModel)]="searchParams.props._sort"
					(ngModelChange)="searchParams.next()"
				>
					<nz-option
						*ngFor="let sort of sorts"
						[nzValue]="sort"
						[nzLabel]="'formation_list-sort-' + sort | translate"
					>
					</nz-option>
				</nz-select>

				<!-- TRIE ASC ou DESC -->
				<nz-select
					class="formation-select--order"
					[(ngModel)]="searchParams.props._order"
					(ngModelChange)="searchParams.next()"
				>
					<nz-option
						*ngFor="let order of orders"
						[nzValue]="order"
						[nzLabel]="
							'formation_list-intervention-sort-' + order
								| translate
						"
					>
					</nz-option>
				</nz-select>

				<nz-select
					class="formation-select--status"
					[nzAllowClear]="true"
					[nzPlaceHolder]="'select-filter-state' | translate"
					[(ngModel)]="searchParams.props.administrative_status"
					(ngModelChange)="handleFilterState($event)"
				>
					<nz-option
						*ngFor="let state of statesAdmin"
						[nzValue]="state"
						[nzLabel]="'formation-state-admin-' + state | translate"
					>
					</nz-option>
				</nz-select>
			</div>
			<!-- /Sort and Filter -->
		</div>
	</div>

	<div *ngIf="searchParams && !loading" class="container-xl ml-0">
		<ng-container *ngIf="formationInterventions.length > 0; else emptyTpl">
			<div class="row">
				<div class="col-12">
					<app-atom-formation-intervention-table
						[formationInterventions]="formationInterventions"
					></app-atom-formation-intervention-table>
				</div>
			</div>
		</ng-container>
	</div>
</div>

<!-- Loading -->
<ng-template #loadingTpl>
	<div class="text-center">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
<!-- /Loading -->

<!-- Empty list -->
<ng-template #emptyTpl>
	<div class="col-12 py-4 card-container empty">
		<p>{{ 'report_moderated-list-empty' | translate }}</p>
	</div>
</ng-template>
<!-- /Empty list -->
