<div class="start-container">
	<div class="time-container">
		<i nz-icon nzType="clock-circle" nzTheme="outline"></i>
		<p>{{ 'survey_time' | translate }} :</p>
		<p class="time">
			{{ survey.props.duration }}
			<span *ngIf="survey.props.duration > 1; else minute">
				{{ 'common_minutes' | translate }}
			</span>
			<ng-template #minute>{{ 'common_minute' | translate }}</ng-template>
		</p>
	</div>
	<button nz-button nzType="primary" (click)="surveyStart.emit()">
		{{ 'common_start' | translate }}
	</button>
</div>
