import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
export interface SurveyLabelInterface extends BaseModelInterface {
	created_at: number | Date;
	label: string;
}
export interface SurveyLabelPayload {
	label: string;
}
type SurveyLabelPayloadKey = keyof SurveyLabelPayload;
export class SurveyLabel extends BaseModel<
	SurveyLabelInterface,
	SurveyLabelPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.label}`;
	}
	/** Populate the current instance from an object */
	fromObject(object: SurveyLabelInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);

		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): SurveyLabelInterface {
		const props = Object.assign({}, this.props);

		props.created_at = Helpers.convertToTimestamp(props.created_at);

		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): SurveyLabelPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as SurveyLabelInterface);

		return payload as SurveyLabelPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): SurveyLabelPayloadKey[] {
		return ['label'];
	}
}
