<div
	class="graph-container"
	[style.height]="height"
	[ngClass]="'theme--' + theme"
>
	<div
		class="bandeau"
		*ngIf="colorThematique"
		[ngStyle]="
			colorThematique.props.gradient_min &&
			colorThematique.props.gradient_max
				? {
						background:
							'linear-gradient(180deg, ' +
							colorThematique.props.gradient_min +
							', ' +
							colorThematique.props.gradient_max +
							')'
				  }
				: {
						background: colorThematique.props.main
				  }
		"
	></div>
	<div
		class="bandeau"
		*ngIf="!colorThematique"
		[ngStyle]="{
			background: 'linear-gradient(180deg, #79c5f1, #07a1e2)'
		}"
	></div>
	<h2 class="py-4 mb-3" *ngIf="titleGraph">
		{{ titleGraph }}
	</h2>

	<div class="text-center graph">
		<svg
			width="416px"
			height="200px"
			viewBox="0 0 416 250"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			class="h-100 w-90"
		>
			<defs>
				<!-- <linearGradient
					x1="50%"
					y1="0%"
					x2="50%"
					y2="100%"
					[id]="'linearGradient1_' + randomId"
				>
					<stop [attr.stop-color]="gradient1.min" offset="0%"></stop>
					<stop
						[attr.stop-color]="gradient1.max"
						offset="100%"
					></stop>
				</linearGradient>
				<linearGradient
					x1="50%"
					y1="0%"
					x2="50%"
					y2="100%"
					[id]="'linearGradient2_' + randomId"
				>
					<stop [attr.stop-color]="gradient2.min" offset="0%"></stop>
					<stop
						[attr.stop-color]="gradient2.max"
						offset="100%"
					></stop>
				</linearGradient> -->

				<linearGradient [id]="'linearGradient1_' + randomId">
					<stop
						offset="0%"
						[attr.style]="'stop-color:' + gradient1.min"
						[attr.]="'stop-color:' + gradient1.min"
					/>
					<stop
						offset="100%"
						[attr.style]="'stop-color:' + gradient1.max"
					/>
				</linearGradient>
				<linearGradient [id]="'linearGradient2_' + randomId">
					<stop
						offset="0%"
						[attr.style]="'stop-color:' + gradient2.min"
					/>
					<stop
						offset="100%"
						[attr.style]="'stop-color:' + gradient2.max"
					/>
				</linearGradient>
			</defs>
			<g
				id="Symbols"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
			>
				<title>{{ title }}</title>
				<g id="charts/speed_gauge" fill-rule="nonzero">
					<g id="speed_gauge">
						<path
							d="M208,0 C93.5375804,0 0,86.8563247 0,195 L52,195 C52,116.47143 121.430771,52 208,52 L208,0 Z"
							id="left"
							[attr.fill]="gradient1.min"
							[attr.opacity]="gradient1.opacity"
						></path>
						<path
							d="M416,195 C416,86.8563247 322.46242,0 208,0 L208,52 C294.569229,52 364,116.47143 364,195 L416,195 Z"
							id="right"
							[attr.fill]="gradient2.min"
							[attr.opacity]="gradient2.opacity"
						></path>
						<g transform="rotate(-67 208 195)">
							<path
								d="M140,35.5 C141.507715,34.9565134 142.841048,35.6231801 144,37.5 L216.151593,187.978239 C217.940128,192.018329 216.114881,196.743362 212.074791,198.531897 C208.113919,200.285361 203.4947,198.565373 201.629701,194.690181 L201.521134,194.455094 L138.5,39.5 C137.992285,37.3768199 138.492285,36.0434866 140,35.5 Z"
								id="gauge"
								fill="#858585"
							>
								<animateTransform
									attributeName="transform"
									attributeType="XML"
									type="rotate"
									from="0 208 195"
									[attr.to]="
										((value * 100) / valueMax) * 1.8 +
										' 208 195'
									"
									begin="0.25s"
									dur="0.5s"
									repeatCount="1"
									fill="freeze"
								/>
							</path>
						</g>
						<text class="legend" x="0" y="237" fill="black">
							{{ labelMin }}
						</text>
						<text
							class="legend"
							x="416"
							y="237"
							fill="black"
							text-anchor="end"
						>
							{{ labelMax }}
						</text>
					</g>
				</g>
			</g>
		</svg>
	</div>
</div>
