<ng-container *ngIf="user">
	<!-- Dashobard Coach -->
	<app-atom-dashboard-coach
		[user]="user"
		*appRestrictedToRoles="['coach']"
	></app-atom-dashboard-coach>
	<!-- /Dashobard Coach -->

	<!-- Dashobard Sponsor -->
	<app-atom-dashboard-sponsor
		[user]="user"
		*appRestrictedToRoles="['sponsor']"
	></app-atom-dashboard-sponsor>
	<!-- /Dashobard Sponsor -->

	<!-- Dashobard Respondant -->
	<app-atom-dashboard-respondant
		[user]="user"
		*appRestrictedToRoles="['respondant']"
	></app-atom-dashboard-respondant>
	<!-- /Dashobard Respondant -->

	<!-- Dashboard Admin -->
	<app-atom-dashboard-admin
		[user]="user"
		*appRestrictedToRoles="['admin']"
	></app-atom-dashboard-admin>
	<!-- /Dashboard Admin -->
</ng-container>
