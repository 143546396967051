<div class="container-xl px-xl-0 ml-0 formation-intervention-card">
	<div class="row mx-0">
		<div class="col-12">
			<div class="mr-0 mr-md-5 container-avatar">
				<nz-avatar
					id="avatar"
					class="coach-avatar d-inline-block"
					[ngClass]="
						formationInterventionTransformed.avatar !== ''
							? 'avatarPhoto'
							: 'avatarIcone'
					"
					[nzSize]="148"
					nzIcon="user"
					[nzSrc]="
						formationInterventionTransformed.avatar !== ''
							? formationInterventionTransformed.avatar
							: ''
					"
				></nz-avatar>
			</div>
			<div class="formation-intervention-card__content">
				<h1 class="formation-intervention-card__title">
					{{ formationInterventionTransformed.name }}
				</h1>
				<p class="formation-intervention-card__content__infos">
					<span class="card__formation">
						{{ 'common_formation' | translate }}:
						<strong>
							{{
								formationInterventionTransformed.formationTitle
							}}
						</strong>
					</span>
					<span class="card__coach">
						{{ 'common-role-coach' | translate }}:
						<strong>
							{{
								formationInterventionTransformed.coach.getLabel()
							}}
						</strong>
					</span>
					<span class="card__place">
						{{ 'common-place' | translate }}:
						<strong>
							{{ formationInterventionTransformed.place }}
						</strong>
					</span>
					<span class="card__status">
						{{ 'table_head-state' | translate }}:
						<strong>
							{{
								'formation-state-admin-' +
									formationInterventionTransformed.status
									| translate
							}}
						</strong>
					</span>
					<span class="card__date">
						{{ 'common_value-date-start' | translate }}:
						<strong>
							{{
								formationInterventionTransformed.start_date
									| date
										: 'dd MMMM yyyy'
										: ''
										: translateService.currentLang
							}}
						</strong>
					</span>
					<span class="card__date">
						{{ 'common_value-date-end' | translate }}:
						<strong>
							{{
								formationInterventionTransformed.end_date
									| date
										: 'dd MMMM yyyy'
										: ''
										: translateService.currentLang
							}}
						</strong>
					</span>
					<span class="card__number">
						{{ 'common_number-thematique' | translate }}:
						<strong>
							{{
								'formation-intervention-thematique-number'
									| translate
										: {
												numberThematique:
													formationIntervention.props
														.thematiques.length
										  }
							}}
						</strong>
					</span>
				</p>
			</div>
		</div>
	</div>
</div>
