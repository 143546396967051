<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
	<div class="container-xl px-xl-0 ml-0">
		<div
			class="d-flex flex-wrap justify-content-between align-items-center"
		>
			<h2 class="mb-4">
				{{ 'formation-list-title' | translate }}
			</h2>
			<!-- Mode display list-->
			<div class="d-none d-lg-block mb-4 mode-display-container">
				<nz-radio-group [(ngModel)]="mode">
					<label nz-radio-button nzValue="card"
						><i nz-icon nzType="appstore" nzTheme="outline"></i
					></label>
					<label nz-radio-button nzValue="inline"
						><i nz-icon nzType="bars" nzTheme="outline"></i
					></label>
				</nz-radio-group>
			</div>
			<!-- /Mode display list-->
			<!-- Sort and Filter -->
			<div
				class="d-flex justify-content-start align-items-center sort-container mb-4"
				*ngIf="searchParams; else loadingTpl"
			>
				<nz-select
					[(ngModel)]="searchParams.props._sort"
					[nzPlaceHolder]="'sort_select-placeholder' | translate"
					style="width: 180px; margin-right: 15px;"
					(ngModelChange)="searchParams.next()"
				>
					<nz-option
						*ngFor="let sort of sorts"
						[nzValue]="sort"
						[nzLabel]="'formation_list-sort-' + sort | translate"
					>
					</nz-option>
				</nz-select>
				<hpf-formation-theme-select
					[(id)]="searchParams.props.themes"
					[nullable]="false"
					[emptyLabel]="'common_filter-empty' | translate"
					[filterEnabled]="false"
					[placeholder]="
						'common_filter_search-placeholder' | translate
					"
					[multiple]="false"
					(change)="searchParams.next()"
					style="width: 180px;"
				></hpf-formation-theme-select>
			</div>
			<!-- /Sort and Filter -->
		</div>
	</div>

	<!-- Infinite List -->
	<div *ngIf="searchParams" class="container-xl ml-0">
		<app-atom-infinite-list
			[walker]="walker"
			[searchParams]="searchParams"
			[contentTpl]="content"
		></app-atom-infinite-list>
	</div>
	<!--/ Infinite List -->
</div>

<!-- List Content -->
<ng-template #content let-items="items">
	<ng-container *ngIf="items.length > 0; else emptyTpl">
		<ng-container *ngIf="mode === 'inline'">
			<ng-container
				*ngTemplateOutlet="contentInline; context: { items: items }"
			></ng-container
		></ng-container>

		<ng-container *ngIf="mode === 'card'">
			<ng-container
				*ngTemplateOutlet="contentCard; context: { items: items }"
			></ng-container
		></ng-container>
	</ng-container>
</ng-template>
<!-- /List Content -->

<!-- List Inline Content -->
<ng-template #contentInline let-items="items">
	<div class="col-12 py-3 px-0 list-inline-container">
		<div
			class="row d-flex flex-nowrap justify-content-start align-items-center px-0 pb-3 m-0 mx-md-4 head-list-inline"
		>
			<span class="col-5 col-xl-5 formation">{{
				'formation-list-inline-title-title' | translate
			}}</span>
			<span class="col-2 col-sm-3 col-xl-5 module">{{
				'formation-list-inline-title-date_start' | translate
			}}</span>
			<span class="col-2 date">{{
				'formation-list-inline-title-respondants' | translate
			}}</span>
		</div>

		<ng-container
			*ngFor="let formation of items; trackBy: trackById"
			class="item-list-container"
		>
			<app-atom-formation-card
				[formation]="formation"
				[inline]="true"
			></app-atom-formation-card>
		</ng-container>
	</div>
</ng-template>
<!-- /List Inline Content -->

<!-- List Card Content -->
<ng-template #contentCard let-items="items">
	<div class="col-12 py-4 list-card-container">
		<ng-container *ngIf="!walker.processing; else loadingTpl">
			<div
				*ngFor="let formation of items; trackBy: trackById"
				class="item-card-container"
			>
				<app-atom-formation-card
					[formation]="formation"
				></app-atom-formation-card>
			</div>
		</ng-container>
	</div>
</ng-template>
<!-- List Card Content -->

<!-- Loading -->
<ng-template #loadingTpl>
	<div class="text-center">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
<!-- /Loading -->

<!-- Empty list -->
<ng-template #emptyTpl>
	<div class="col-12 py-4 card-container empty">
		<p>{{ 'report_moderated-list-empty' | translate }}</p>
	</div>
</ng-template>
<!-- /Empty list -->
