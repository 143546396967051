import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
import { Question, QuestionInterface } from '../question/question';
export interface SurveyInterface extends BaseModelInterface {
	created_at: number | Date;
	shortcode: string;
	label: string;
	description: string;
	questions: (string | Question | QuestionInterface)[];
	randomized: boolean;
	duration: number;
}
export interface SurveyPayload {
	shortcode: string;
	label: string;
	description: string;
	questions: (string | Question | QuestionInterface)[];
	randomized: boolean;
	duration: number;
}
type SurveyPayloadKey = keyof SurveyPayload;
export class Survey extends BaseModel<SurveyInterface, SurveyPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.shortcode}`;
	}
	/** Denotes if the instance of questions has been populated */
	questionsExists(): boolean {
		return (
			!!this.props &&
			this.props.questions instanceof Array &&
			(<Question[]>this.props.questions).every((item) => {
				return item instanceof Question && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: SurveyInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.questions instanceof Array) {
			this.props.questions = (<QuestionInterface[]>(
				this.props.questions
			)).map((item) => {
				return typeof item === 'object' ? new Question(item) : item;
			});
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): SurveyInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.questions instanceof Array) {
			props.questions = (<Question[]>props.questions).map((item) => {
				return item instanceof Question ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): SurveyPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as SurveyInterface);
		payload.questions = payload.questions
			? payload.questions.map((i) => this.extractId(i))
			: null;
		return payload as SurveyPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): SurveyPayloadKey[] {
		return [
			'shortcode',
			'label',
			'description',
			'questions',
			'randomized',
			'duration',
		];
	}
}
