<div *ngIf="question && user">
	<div class="question-label">
		{{ question.getLabelText(user) }}
	</div>
	<div [ngClass]="'reponse-' + question.props.type">
		<!-- Mobile version -->
		<div class="d-flex d-md-none h-100 flex-column slider-mobile">
			<p *ngIf="labelMin" class="mb-3 text-center label-min">
				{{ labelMin }}
			</p>

			<ng-container
				*ngTemplateOutlet="sliderTpl; context: { vertical: true }"
			></ng-container>

			<p *ngIf="labelMax" class="mt-3 text-center label-max">
				{{ labelMax }}
			</p>
		</div>
		<!-- /Mobile version -->

		<!-- Desktop version -->
		<div class="d-none d-md-block h-100">
			<ng-container
				*ngTemplateOutlet="sliderTpl; context: { vertical: false }"
			></ng-container>

			<div class="d-flex labels-desktop">
				<p *ngIf="labelMin" class="mb-0 pr-5 label-min">
					{{ labelMin }}
				</p>
				<p *ngIf="labelMax" class="mb-0 pl-5 label-max">
					{{ labelMax }}
				</p>
			</div>
		</div>
		<!-- /Desktop version -->
	</div>

	<!-- Slider -->
	<ng-template #sliderTpl let-vertical="vertical">
		<nz-slider
			[(ngModel)]="sliderIndex"
			[nzMin]="1"
			[nzMax]="question.props.answers.length - 1"
			[nzDots]="true"
			[nzVertical]="vertical"
			[nzReverse]="vertical"
			[nzMarks]="sliderMarks"
			(nzOnAfterChange)="onAnswerUpdate()"
			[style.height.px]="(question.props.answers.length - 1) * 100"
		>
		</nz-slider>
	</ng-template>
	<!-- /Slider -->
</div>
