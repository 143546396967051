import { Injectable } from '@angular/core';
import {
	SurveyOpenResult,
	SurveyOpenResultInterface,
	SurveyOpenResultPayload,
} from './survey-open-result';
import { SurveyOpenResultSearchParamsInterface } from './survey-open-result-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class SurveyOpenResultService extends BaseModelService<
	SurveyOpenResult,
	SurveyOpenResultInterface,
	SurveyOpenResultPayload,
	SurveyOpenResultSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'survey-open-result';
	}
	/** @inheritDoc */
	protected new(object: SurveyOpenResultInterface): SurveyOpenResult {
		return new SurveyOpenResult(object);
	}
}
