import { Injectable } from '@angular/core';
import {
	DocumentAdministrative,
	DocumentAdministrativeInterface,
	DocumentAdministrativePayload,
} from './document-administrative';
import { DocumentAdministrativeSearchParamsInterface } from './document-administrative-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class DocumentAdministrativeService extends BaseModelService<
	DocumentAdministrative,
	DocumentAdministrativeInterface,
	DocumentAdministrativePayload,
	DocumentAdministrativeSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'document-administrative';
	}
	/** @inheritDoc */
	protected new(
		object: DocumentAdministrativeInterface
	): DocumentAdministrative {
		return new DocumentAdministrative(object);
	}
}
