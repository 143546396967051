<a
	[routerLink]="linkFullList"
	class="border-counter {{ customClass }} mb-2 mb-md-0"
	[class.no-link]="!linkFullList"
>
	<div class="counter px-4 py-4">
		<div class="row-counter">
			<div class="col-left">
				<div>
					<span class="number-count">{{ count }}</span>
					<span class="label-count">{{ label }}</span>
				</div>
				<a
					*ngIf="linkFullList"
					class="link-counter"
					[routerLink]="linkFullList"
				>
					{{
						'model-counter-access-all'
							| translate: { label: label | lowercase }
					}}
					<i
						class="ml-2"
						nz-icon
						nzType="right"
						nzTheme="outline"
					></i>
				</a>
			</div>
			<div class="col-right">
				<ng-container *ngTemplateOutlet="iconTpl"></ng-container>
			</div>
		</div>
	</div>
</a>
