import { Injectable } from '@angular/core';
import {
	DocumentPedagogique,
	DocumentPedagogiqueInterface,
	DocumentPedagogiquePayload,
} from './document-pedagogique';
import { DocumentPedagogiqueSearchParamsInterface } from './document-pedagogique-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class DocumentPedagogiqueService extends BaseModelService<
	DocumentPedagogique,
	DocumentPedagogiqueInterface,
	DocumentPedagogiquePayload,
	DocumentPedagogiqueSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'document-pedagogique';
	}
	/** @inheritDoc */
	protected new(object: DocumentPedagogiqueInterface): DocumentPedagogique {
		return new DocumentPedagogique(object);
	}
}
