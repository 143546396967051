<div class="row mx-0 report-detailled-container">
	<div class="col-12 px-0 px-xl-3">
		<h2 class="mb-0 px-3 px-xl-0">
			{{ getReportName() }}
			<small
				*ngIf="reportModerated"
				class="d-block d-lg-inline ml-0 ml-lg-3"
				>{{
					'report_moderated-date-analyze'
						| translate
							: {
									date:
										reportModerated.props.moderated_at
										| date: 'dd/MM/yy'
							  }
				}}</small
			>
		</h2>
		<div class="report-container" [ngClass]="'theme--' + theme">
			<div class="decorate"></div>
			<!-- Report Detailed Mobile -->
			<div class="row d-lg-none row-mobile">
				<!-- Graphs -->
				<div class="col-12 col-graphs">
					<ng-container *ngFor="let graph of graphsModerated">
						<ng-container [ngSwitch]="graph.type">
							<app-atom-graph-bar
								*ngSwitchCase="'bar'"
								[data]="graph.data"
								[showAverage]="graph.showAverage"
								[colors]="graph.colors"
								[theme]="graph.theme"
								[titleGraph]="graph.title"
								[maxAxis]="graph.max"
							></app-atom-graph-bar>

							<app-atom-graph-grouped-bar
								*ngSwitchCase="'grouped_bar'"
								[data]="graph.dataGrouped"
								[showAverage]="graph.showAverage"
								[colors]="graph.colors"
								[theme]="graph.theme"
								[titleGraph]="graph.title"
								[maxAxis]="graph.max"
							></app-atom-graph-grouped-bar>

							<app-atom-graph-radar
								*ngSwitchCase="'radar'"
								[data]="graph.data"
								[showAverage]="graph.showAverage"
								[color]="graph.colors[0]"
								[theme]="graph.theme"
								[titleGraph]="graph.title"
								[maxAxis]="graph.max"
							></app-atom-graph-radar>

							<app-atom-graph-gauge-svg
								*ngSwitchCase="'gauge'"
								[valueAxe1]="graph.data[0].y"
								[valueAxe2]="graph.data[1].y"
								[colors]="graph.colors"
								[theme]="graph.theme"
								[titleGraph]="graph.title"
								[labelMin]="graph.labelMin"
								[labelMax]="graph.labelMax"
							></app-atom-graph-gauge-svg>
						</ng-container>
					</ng-container>
				</div>
				<!-- /Graph -->

				<!-- Graph Detailled -->
				<div
					class="col-12 col-explications"
					[ngClass]="panelActive ? 'panelVisible' : 'panelHidden'"
				>
					<div class="btn-toggle" (click)="togglePanel()">
						<ng-container *ngIf="!panelActive">
							<i
								nz-icon
								nzType="info-circle"
								nzTheme="outline"
							></i>
						</ng-container>
						<ng-container *ngIf="panelActive">
							<i nz-icon nzType="right" nzTheme="outline"></i>
						</ng-container>
					</div>
					<div class="explications-content" (click)="togglePanel()">
						<div *ngFor="let axesDetails of graphDetails">
							<div *ngFor="let axeDetails of axesDetails">
								<h2 [style.color]="axeDetails.color">
									{{ axeDetails.title }}
								</h2>
								<div
									class="axe-details"
									[innerHTML]="axeDetails.description"
									[style.borderColor]="axeDetails.color"
									[style.color]="axeDetails.color"
								></div>
							</div>
						</div>
					</div>
				</div>
				<!-- /Graph Detailled -->
			</div>
			<!-- /Report Detailed Mobile -->

			<!-- Report Detailed Desktop -->
			<div
				*ngFor="let graph of graphsModerated; let i = index"
				class="row d-none d-lg-flex row-desktop"
			>
				<!-- Graphs -->
				<div class="col-12 col-lg-7 col-graphs">
					<ng-container [ngSwitch]="graph.type">
						<app-atom-graph-bar
							*ngSwitchCase="'bar'"
							[data]="graph.data"
							[colors]="graph.colors"
							[theme]="graph.theme"
							[titleGraph]="graph.title"
							[maxAxis]="graph.max"
						></app-atom-graph-bar>

						<app-atom-graph-radar
							*ngSwitchCase="'radar'"
							[data]="graph.data"
							[color]="graph.colors[0]"
							[theme]="graph.theme"
							[titleGraph]="graph.title"
							[maxAxis]="graph.max"
						></app-atom-graph-radar>

						<app-atom-graph-gauge-svg
							*ngSwitchCase="'gauge'"
							[valueAxe1]="graph.data[0].y"
							[valueAxe2]="graph.data[1].y"
							[colors]="graph.colors"
							[theme]="graph.theme"
							[titleGraph]="graph.title"
							[labelMin]="graph.labelMin"
							[labelMax]="graph.labelMax"
						></app-atom-graph-gauge-svg>
					</ng-container>
				</div>
				<!-- /Graph -->

				<!-- Graph Detailled -->
				<div class="col-12 col-lg-5 col-explications">
					<div>
						<div *ngFor="let axeDetails of graphDetails[i]">
							<h2 [style.color]="axeDetails.color">
								{{ axeDetails.title }}
							</h2>
							<div
								class="axe-details"
								[innerHTML]="axeDetails.description"
								[style.borderColor]="axeDetails.color"
								[style.color]="axeDetails.color"
							></div>
						</div>
					</div>
				</div>
				<!-- /Graph Detailled -->
			</div>
			<!-- /Report Detailed Desktop -->
		</div>
	</div>
</div>
