import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import {
	FormationThematique,
	FormationThematiqueInterface,
} from '../formation-thematique/formation-thematique';
import { Formation, FormationInterface } from '../formation/formation';
import {
	DocumentAdministrative,
	DocumentAdministrativeInterface,
} from '../document-administrative/document-administrative';
import {
	DocumentPedagogique,
	DocumentPedagogiqueInterface,
} from '../document-pedagogique/document-pedagogique';
export interface FormationInterventionInterface extends BaseModelInterface {
	created_at: number | Date;
	label: string;
	formateur: string | User | UserInterface;
	thematiques: (
		| string
		| FormationThematique
		| FormationThematiqueInterface
	)[];
	lieu: string;
	administrative_status: any;
	formation: string | Formation | FormationInterface;
	contrat_travail?:
		| string
		| DocumentAdministrative
		| DocumentAdministrativeInterface;
	ordre_de_mission?:
		| string
		| DocumentAdministrative
		| DocumentAdministrativeInterface;
	documents: (string | DocumentPedagogique | DocumentPedagogiqueInterface)[];
	admin?: string | User | UserInterface;
}
export interface FormationInterventionPayload {
	label: string;
	formateur: string | User | UserInterface;
	thematiques: (
		| string
		| FormationThematique
		| FormationThematiqueInterface
	)[];
	lieu: string;
	administrative_status: any;
	formation: string | Formation | FormationInterface;
	contrat_travail?:
		| string
		| DocumentAdministrative
		| DocumentAdministrativeInterface;
	ordre_de_mission?:
		| string
		| DocumentAdministrative
		| DocumentAdministrativeInterface;
	documents: (string | DocumentPedagogique | DocumentPedagogiqueInterface)[];
	admin?: string | User | UserInterface;
}
type FormationInterventionPayloadKey = keyof FormationInterventionPayload;
export class FormationIntervention extends BaseModel<
	FormationInterventionInterface,
	FormationInterventionPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.label}`;
	}

	getThematiques(): FormationThematique[] {
		return this.thematiquesExists()
			? (this.props.thematiques as FormationThematique[])
			: [];
	}

	getExpiredDate(): Date | undefined {
		const endDatetime = this.getEndDate();
		if (!endDatetime) return undefined;

		const expiredDate = new Date(endDatetime);
		expiredDate.setMonth(expiredDate.getMonth() + 6);

		return expiredDate;
	}

	/** Denotes if the instance of formateur has been populated */
	formateurExists(): boolean {
		return (
			!!this.props &&
			this.props.formateur instanceof User &&
			this.props.formateur.exists()
		);
	}
	/** Denotes if the instance of thematiques has been populated */
	thematiquesExists(): boolean {
		return (
			!!this.props &&
			this.props.thematiques instanceof Array &&
			(<FormationThematique[]>this.props.thematiques).every((item) => {
				return item instanceof FormationThematique && item.exists();
			})
		);
	}

	getThematiqueDates(): number[] {
		if (!this.thematiquesExists()) return [];
		const thematiques = this.getThematiques();
		return [
			...thematiques.map((thematique) =>
				(thematique.props.start_date as Date).getTime()
			),
			...thematiques.map((thematique) =>
				(thematique.props.end_date as Date).getTime()
			),
		];
	}

	getStartDate(): number {
		const dates = this.getThematiqueDates();
		return dates.length ? Math.min(...dates) : undefined;
	}

	getEndDate(): number {
		const dates = this.getThematiqueDates();
		return dates.length ? Math.max(...dates) : undefined;
	}

	/** Denotes if the instance of formation has been populated */
	formationExists(): boolean {
		return (
			!!this.props &&
			this.props.formation instanceof Formation &&
			this.props.formation.exists()
		);
	}
	/** Denotes if the instance of contrat travail has been populated */
	contratTravailExists(): boolean {
		return (
			!!this.props &&
			this.props.contrat_travail instanceof DocumentAdministrative &&
			this.props.contrat_travail.exists()
		);
	}
	/** Denotes if the instance of ordre de mission has been populated */
	ordreDeMissionExists(): boolean {
		return (
			!!this.props &&
			this.props.ordre_de_mission instanceof DocumentAdministrative &&
			this.props.ordre_de_mission.exists()
		);
	}
	/** Denotes if the instance of documents has been populated */
	documentsExists(): boolean {
		return (
			!!this.props &&
			this.props.documents instanceof Array &&
			(<DocumentPedagogique[]>this.props.documents).every((item) => {
				return item instanceof DocumentPedagogique && item.exists();
			})
		);
	}
	/** Denotes if the instance of admin has been populated */
	adminExists(): boolean {
		return (
			!!this.props &&
			this.props.admin instanceof User &&
			this.props.admin.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: FormationInterventionInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.formateur === 'object') {
			this.props.formateur = new User(
				<UserInterface>this.props.formateur
			);
		}
		if (this.props.thematiques instanceof Array) {
			this.props.thematiques = (<FormationThematiqueInterface[]>(
				this.props.thematiques
			)).map((item) => {
				return typeof item === 'object'
					? new FormationThematique(item)
					: item;
			});
		}
		if (typeof this.props.formation === 'object') {
			this.props.formation = new Formation(
				<FormationInterface>this.props.formation
			);
		}
		if (
			this.props.contrat_travail !== null &&
			typeof this.props.contrat_travail === 'object'
		) {
			this.props.contrat_travail = new DocumentAdministrative(
				<DocumentAdministrativeInterface>this.props.contrat_travail
			);
		}
		if (
			this.props.ordre_de_mission !== null &&
			typeof this.props.ordre_de_mission === 'object'
		) {
			this.props.ordre_de_mission = new DocumentAdministrative(
				<DocumentAdministrativeInterface>this.props.ordre_de_mission
			);
		}
		if (this.props.documents instanceof Array) {
			this.props.documents = (<DocumentPedagogiqueInterface[]>(
				this.props.documents
			)).map((item) => {
				return typeof item === 'object'
					? new DocumentPedagogique(item)
					: item;
			});
		}
		if (this.props.admin !== null && typeof this.props.admin === 'object') {
			this.props.admin = new User(<UserInterface>this.props.admin);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): FormationInterventionInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.contrat_travail === 'undefined') {
			props.contrat_travail = null;
		}
		if (typeof props.ordre_de_mission === 'undefined') {
			props.ordre_de_mission = null;
		}
		if (typeof props.admin === 'undefined') {
			props.admin = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.formateur instanceof User) {
			props.formateur = props.formateur.toObject();
		}
		if (this.props.thematiques instanceof Array) {
			props.thematiques = (<FormationThematique[]>props.thematiques).map(
				(item) => {
					return item instanceof FormationThematique
						? item.toObject()
						: item;
				}
			);
		}
		if (props.formation instanceof Formation) {
			props.formation = props.formation.toObject();
		}
		if (
			props.contrat_travail !== null &&
			props.contrat_travail instanceof DocumentAdministrative
		) {
			props.contrat_travail = props.contrat_travail.toObject();
		}
		if (
			props.ordre_de_mission !== null &&
			props.ordre_de_mission instanceof DocumentAdministrative
		) {
			props.ordre_de_mission = props.ordre_de_mission.toObject();
		}
		if (this.props.documents instanceof Array) {
			props.documents = (<DocumentPedagogique[]>props.documents).map(
				(item) => {
					return item instanceof DocumentPedagogique
						? item.toObject()
						: item;
				}
			);
		}
		if (props.admin !== null && props.admin instanceof User) {
			props.admin = props.admin.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): FormationInterventionPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as FormationInterventionInterface);
		payload.formateur = payload.formateur
			? this.extractId(payload.formateur)
			: null;
		payload.thematiques = payload.thematiques
			? payload.thematiques.map((i) => this.extractId(i))
			: [];
		payload.formation = payload.formation
			? this.extractId(payload.formation)
			: null;
		payload.contrat_travail = payload.contrat_travail
			? this.extractId(payload.contrat_travail)
			: null;
		payload.ordre_de_mission = payload.ordre_de_mission
			? this.extractId(payload.ordre_de_mission)
			: null;
		payload.documents = payload.documents
			? payload.documents.map((i) => this.extractId(i))
			: [];
		payload.admin = payload.admin ? this.extractId(payload.admin) : null;
		return payload as FormationInterventionPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): FormationInterventionPayloadKey[] {
		return [
			'label',
			'formateur',
			'thematiques',
			'lieu',
			'administrative_status',
			'formation',
			'contrat_travail',
			'ordre_de_mission',
			'documents',
			'admin',
		];
	}
}
