<div class="table__container">
	<div class="table__wrapper">
		<table>
			<thead>
				<tr>
					<th>{{ 'table_head-formation' | translate }}</th>
					<th>{{ 'table_head-sponsor' | translate }}</th>
					<th>{{ 'table_head-state-admin' | translate }}</th>
					<th>{{ 'table_head-state' | translate }}</th>
					<th>
						{{ 'table_head-formation-date' | translate }}
					</th>
				</tr>
			</thead>
			<tbody>
				<ng-container
					*ngIf="formations.length > 0; else noFormationTpl"
				>
					<tr
						*ngFor="let formation of formations; let i = index"
						[routerLink]="[
							'/formations',
							formation.getId(),
							'details'
						]"
						class="clicable"
					>
						<td class="label">
							{{ formation.props.name }}
							<div
								class="bandeau"
								*ngIf="
									formation.props.color &&
										formation.colorExists();
									else emptyBandeau
								"
								[ngStyle]="
									formation.props.color.props.gradient_min &&
									formation.props.color.props.gradient_max
										? {
												background:
													'linear-gradient(180deg, ' +
													formation.props.color.props
														.gradient_min +
													', ' +
													formation.props.color.props
														.gradient_max +
													')'
										  }
										: {
												background:
													formation.props.color.props
														.main
										  }
								"
							></div>
							<ng-template #emptyBandeau>
								<div class="bandeau"></div>
							</ng-template>
						</td>
						<td class="small">
							{{
								(formation.props?.sponsor?.getLabel()
									| uppercase) || '-'
							}}
						</td>
						<td
							class="state state-admin"
							[ngSwitch]="formation.props.administrative_status"
						>
							<ng-container *ngSwitchCase="'created'">
								<span>{{
									'formation-state-admin-created' | translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'todo'">
								<span>{{
									'formation-state-admin-todo' | translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'finished'">
								<span
									class="d-flex align-items-center justify-content-center"
								>
									<i
										nz-icon
										nzType="check-circle"
										class="mr-2"
										[style.color]="'black'"
									></i>
									<span>{{
										'formation-state-admin-finished'
											| translate
									}}</span>
								</span>
							</ng-container>
						</td>
						<td
							class="state"
							[ngSwitch]="formation.props.session_status"
						>
							<ng-container *ngSwitchCase="'soon'">
								<span>{{
									'formation-state-soon' | translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'inprogress'">
								<span>{{
									'formation-state-inprogress' | translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'done'">
								<span
									class="d-flex align-items-center justify-content-center"
								>
									<i
										nz-icon
										nzType="check-circle"
										class="mr-2"
										[style.color]="'black'"
									></i>
									<span>{{
										'formation-state-done' | translate
									}}</span>
								</span>
							</ng-container>
						</td>
						<td class="date">
							<span>
								{{
									formation.props.start_date
										| date
											: 'dd MMMM yyyy'
											: ''
											: translateService.currentLang
								}}
							</span>
							<span>
								{{
									formation.props.end_date
										| date
											: 'dd MMMM yyyy'
											: ''
											: translateService.currentLang
								}}
							</span>
						</td>
					</tr>
				</ng-container>

				<ng-template #noFormationTpl>
					<tr>
						<td colspan="5">
							{{ 'empty-formation' | translate }}
						</td>
					</tr>
				</ng-template>
			</tbody>
		</table>
	</div>
</div>
