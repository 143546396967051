import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
import { Survey, SurveyInterface } from '../survey/survey';
import { Graph, GraphInterface } from '../graph/graph';
import { User } from '../user';

export type ReportTheme = 'green' | 'orange' | 'violet' | 'blue' | 'yellow';
export type ReportModerationType = 'manual' | 'preset-average' | 'all';
export type ReportDisplayMode = 'individual' | 'grouped' | 'both' | 'none';

export interface ReportInterface extends BaseModelInterface {
	created_at: number | Date;
	name: string;
	shortcode: string;
	description: string;
	surveys: (string | Survey | SurveyInterface)[];
	graphs: (string | Graph | GraphInterface)[];
	moderation_type: ReportModerationType;
	grouped: boolean;
	delayed?: number;
	notify_sponsor?: boolean;
	notify_coachs?: boolean;
	duplicated: boolean;
	source?: string | Report | ReportInterface;
	user_display_mode: ReportDisplayMode;
	coach_display_mode: ReportDisplayMode;
	sponsor_display_mode: ReportDisplayMode;
}
export interface ReportPayload {
	name: string;
	shortcode: string;
	description: string;
	surveys: (string | Survey | SurveyInterface)[];
	graphs: (string | Graph | GraphInterface)[];
	moderation_type: ReportModerationType;
	grouped: boolean;
	delayed?: number;
	notify_sponsor?: boolean;
	notify_coachs?: boolean;
	source?: string | Report | ReportInterface;
	user_display_mode: string;
	coach_display_mode: string;
	sponsor_display_mode: string;
}
type ReportPayloadKey = keyof ReportPayload;
export class Report extends BaseModel<ReportInterface, ReportPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Denotes if the instance of surveys has been populated */
	surveysExists(): boolean {
		return (
			!!this.props &&
			this.props.surveys instanceof Array &&
			(<Survey[]>this.props.surveys).every((item) => {
				return item instanceof Survey && item.exists();
			})
		);
	}

	getDisplayMode(user: User | undefined): ReportDisplayMode {
		if (!user) {
			return 'both';
		}

		if (user.props.role === 'coach') {
			return this.props.coach_display_mode || 'both';
		} else if (user.props.role === 'sponsor') {
			return this.props.sponsor_display_mode || 'both';
		}

		return this.props.user_display_mode || 'individual';
	}

	/** Denotes if the instance of graphs has been populated */
	graphsExists(): boolean {
		return (
			!!this.props &&
			this.props.graphs instanceof Array &&
			(<Graph[]>this.props.graphs).every((item) => {
				return item instanceof Graph && item.exists();
			})
		);
	}

	/** Populate the current instance from an object */
	fromObject(object: ReportInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.surveys instanceof Array) {
			this.props.surveys = (<SurveyInterface[]>this.props.surveys).map(
				(item) => {
					return typeof item === 'object' ? new Survey(item) : item;
				}
			);
		}
		if (this.props.graphs instanceof Array) {
			this.props.graphs = (<GraphInterface[]>this.props.graphs).map(
				(item) => {
					return typeof item === 'object' ? new Graph(item) : item;
				}
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ReportInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.surveys instanceof Array) {
			props.surveys = (<Survey[]>props.surveys).map((item) => {
				return item instanceof Survey ? item.toObject() : item;
			});
		}
		if (this.props.graphs instanceof Array) {
			props.graphs = (<Graph[]>props.graphs).map((item) => {
				return item instanceof Graph ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ReportPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ReportInterface);
		payload.surveys = payload.surveys
			? payload.surveys.map((i) => this.extractId(i))
			: null;
		payload.graphs = payload.graphs
			? payload.graphs.map((i) => this.extractId(i))
			: null;
		return payload as ReportPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): ReportPayloadKey[] {
		return [
			'name',
			'shortcode',
			'description',
			'surveys',
			'graphs',
			'moderation_type',
			'grouped',
		];
	}
}
