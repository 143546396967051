import { Component, OnInit, Input } from '@angular/core';
import {
	BaseGraphComponent,
	DataAverageInterface,
} from '@app/abstracts/base-graph/base-graph.component';
import { Breakpoint } from '@app/services/resize.service';

@Component({
	selector: 'app-atom-graph-average',
	templateUrl: './atom-graph-average.component.html',
	styleUrls: ['./atom-graph-average.component.less'],
})
export class AtomGraphAverageComponent extends BaseGraphComponent
	implements OnInit {
	private mobileSize = 25;
	private desktopSize = 40;

	@Input() data: DataAverageInterface[] = [];
	@Input() xLabel: string;
	@Input() yLabel: string;
	@Input() maxAxis: number;

	protected defaultOptions = {};

	refreshAverages(): void {
		this.loadAverages();
	}

	initializeGraph() {
		this.graph = {
			render: () => null,
			updateConfig: () => null,
		};
	}

	onResize(breakpoint: Breakpoint) {
		this.graph.updateConfig({
			columnSize:
				this.breakpoint.label === 'xs'
					? this.mobileSize
					: this.desktopSize,
		});
		this.graph.render();
	}

	async loadAverages(): Promise<void> {
		this.averages = [
			{
				name: await this.titleGraph,
				average: Number.parseFloat(
					this.calculateAverage(this.data).toFixed(2)
				),
				maxAxis: this.maxAxis ?? 5,
			},
		];
	}
}
