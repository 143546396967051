import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-molecule-password-change',
	templateUrl: './molecule-password-change.component.html',
	styleUrls: ['./molecule-password-change.component.less'],
})
export class MoleculePasswordChangeComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
