import { Component, Input, OnInit } from '@angular/core';
import { Axe } from '@app/models/axe';
import { AxeSurvey } from '@app/models/axe-survey';
import { GraphModerated } from '@app/models/graph-moderated';
import { ReportModerated } from '@app/models/report-moderated';
import { ReportTheme } from '@app/models/report';
import { Survey } from '@app/models/survey';
import {
	SurveyResult,
	SurveyResultFormatted,
	SurveyResultService,
} from '@app/models/survey-result';
import { Color } from '@app/models/color';
import { User } from '@app/models/user';
import { Graph } from '@app/models/graph';

interface GraphFormData {
	ownerName: string;
	surveyResultFormatted: SurveyResultFormatted;
}

@Component({
	selector: 'app-atom-graph-form',
	templateUrl: './atom-graph-form.component.html',
	styleUrls: ['./atom-graph-form.component.less'],
})
export class AtomGraphFormComponent implements OnInit {
	@Input() reportModerated: ReportModerated;
	@Input() theme: ReportTheme = 'green';
	@Input() graphModerated: GraphModerated;
	@Input() colorThematique: Color;

	graphFormDataList?: GraphFormData[];

	currentIndex?: number;
	currentGraphFormData?: GraphFormData;

	constructor(private surveyResultService: SurveyResultService) {}

	async ngOnInit() {
		const mainAxe = this.getMainAxe();

		if (mainAxe) {
			const surveyResults = this.findSurveyResults(mainAxe);

			this.graphFormDataList = await Promise.all(
				surveyResults.map((surveyResult) =>
					this.getFormatted(mainAxe, surveyResult)
				)
			);

			this.selectIndex(0);
		}
	}

	selectIndex(index: number) {
		this.currentIndex = index;
		this.currentGraphFormData = this.graphFormDataList[index];
	}

	async getFormatted(
		mainAxe: AxeSurvey,
		surveyResult: SurveyResult
	): Promise<GraphFormData> {
		const owner = surveyResult.props.owner as User;

		return {
			ownerName: this.transformOwnerName(owner),
			surveyResultFormatted: await this.surveyResultService.getFormatted(
				surveyResult.getId(),
				this.getScoreAxe(mainAxe)
			),
		};
	}

	transformOwnerName(user: User): string {
		const firstname = user.props.first_name.slice(0, 1).toUpperCase();
		const lastname = user.props.last_name;
		return `${firstname}. ${lastname.toUpperCase()}`;
	}

	getMainAxe(): AxeSurvey | undefined {
		if (!this.graphModerated.graphExists()) return;
		const graph = this.graphModerated.props.graph as Graph;

		if (!graph.axesExists()) return;
		return <AxeSurvey>graph.props.axes[0];
	}

	getScoreAxe(mainAxe: AxeSurvey): string {
		return mainAxe.axeExists()
			? (<Axe>mainAxe.props.axe).getId()
			: <string>mainAxe.props.axe;
	}

	findSurveyResults(mainAxe: AxeSurvey): SurveyResult[] {
		const mainAxeSurvey = <Survey>mainAxe.props.survey;

		return (<SurveyResult[]>this.reportModerated.props.results).filter(
			(result) => {
				const surveyId = result.surveyExists()
					? (<Survey>result.props.survey).getId()
					: <string>result.props.survey;
				return surveyId === mainAxeSurvey.getId();
			}
		);
	}
}
