<div
	*ngIf="!loading; else loadingTpl"
	class="container-fluid px-0 px-xl-5 py-4 py-xl-5"
>
	<div class="container-xl px-xl-0 ml-0">
		<ng-container *ngIf="formationIntervention">
			<app-atom-formation-intervention-card-detail
				*ngIf="formationIntervention"
				[formationIntervention]="formationIntervention"
				[formationTitle]="formationLabel"
			></app-atom-formation-intervention-card-detail>
			<!-- DOCUMENTS CONTRAT TRAVAIL -->
			<app-atom-formation-table-documents
				[title]="'formation-intervention-contract-title' | translate"
				[docsToGenerate]="docsToGenerateContract"
				[documents]="documentContract"
				[disableSend]="isSendDisable(documentContract)"
				[disableDelete]="false"
				[disableUpload]="isUploadDisable('contrat_travail')"
				[disableGenerate]="isGenerateDisable('contrat_travail')"
				[generateLoading]="contratTravailLoading"
				(emitGenerate)="handleGenerate($event, 'contrat_travail')"
				(emitUpload)="
					handleDocAdminUpload(
						$event,
						documentContract,
						'contrat_travail'
					)
				"
				(emitReadDocument)="
					handleReadDocumentAdministrative('contrat_travail')
				"
				(emitDownloadDocument)="
					handleDownloadDocumentAdministrative(
						$event,
						'contrat_travail'
					)
				"
				(emitSendDocument)="
					handleSendAdministrative($event, 'contrat_travail')
				"
				(emitDeleteDocument)="
					showDeleteConfirmOnDocAdmin('contrat_travail')
				"
			></app-atom-formation-table-documents>

			<!-- DOCUMENTS ORDRE DE MISSION -->
			<app-atom-formation-table-documents
				[title]="
					'formation-intervention-mission-order-title' | translate
				"
				[docsToGenerate]="docsToGenerateOrderMission"
				[documents]="documentOrderMission"
				[disableSend]="isSendDisable(documentOrderMission)"
				[disableDelete]="false"
				[disableUpload]="isUploadDisable('ordre_de_mission')"
				[disableGenerate]="isGenerateDisable('ordre_de_mission')"
				[generateLoading]="ordreDeMissionLoading"
				(emitGenerate)="handleGenerate($event, 'ordre_de_mission')"
				(emitUpload)="
					handleDocAdminUpload(
						$event,
						documentOrderMission,
						'ordre_de_mission'
					)
				"
				(emitReadDocument)="
					handleReadDocumentAdministrative('ordre_de_mission')
				"
				(emitDownloadDocument)="
					handleDownloadDocumentAdministrative(
						$event,
						'ordre_de_mission'
					)
				"
				(emitSendDocument)="
					handleSendAdministrative($event, 'ordre_de_mission')
				"
				(emitDeleteDocument)="
					showDeleteConfirmOnDocAdmin('ordre_de_mission')
				"
			></app-atom-formation-table-documents>

			<!-- DOCUMENTS PEDAGOGIQUES -->
			<app-atom-formation-table-documents
				[title]="'formation-intervention-documents-title' | translate"
				[documents]="documents"
				[disableSend]="false"
				[disableDelete]="false"
				[disableUpload]="false"
				[disableGenerate]="true"
				[showButtonSendGrouped]="true"
				(emitUpload)="handleDocPedagogiqueUpload($event)"
				(emitSendGrouped)="handleSendGrouped()"
				(emitReadDocument)="handleReadDocumentPedagogique($event)"
				(emitDownloadDocument)="handleDownloadDocPedagogique($event)"
				(emitSendDocument)="handleSendPedagogique($event)"
				(emitDeleteDocument)="showDeleteConfirmOnDocPedagogique($event)"
			></app-atom-formation-table-documents>
		</ng-container>
	</div>
</div>

<ng-template #loadingTpl>
	<div class="text-center my-5 py-5">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
