import { Injectable } from '@angular/core';
import {
	FormationThematique,
	FormationThematiqueInterface,
	FormationThematiquePayload,
} from './formation-thematique';
import { FormationThematiqueSearchParamsInterface } from './formation-thematique-search-params';
import { BaseModelService } from '@app/abstracts';
import { Helpers } from '@app/shared/helpers';
@Injectable()
export class FormationThematiqueService extends BaseModelService<
	FormationThematique,
	FormationThematiqueInterface,
	FormationThematiquePayload,
	FormationThematiqueSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'formation-thematique';
	}
	/** @inheritDoc */
	protected new(object: FormationThematiqueInterface): FormationThematique {
		return new FormationThematique(object);
	}
	/** @inheritDoc */
	protected transformSearchParams(
		searchParams: FormationThematiqueSearchParamsInterface
	): FormationThematiqueSearchParamsInterface {
		// Search by edge for Start Date
		if (typeof searchParams.start_date__min !== 'undefined') {
			searchParams.start_date__min = Helpers.toMidnightUTC(
				searchParams.start_date__min
			);
		}
		if (typeof searchParams.start_date__max !== 'undefined') {
			searchParams.start_date__max = Helpers.toLastSecondUTC(
				searchParams.start_date__max
			);
		}
		if (typeof searchParams.start_date !== 'undefined') {
			searchParams.start_date__min = Helpers.toMidnightUTC(
				searchParams.start_date
			);
			searchParams.start_date__max = Helpers.toLastSecondUTC(
				searchParams.start_date
			);
			delete searchParams.start_date;
		}
		return searchParams;
	}
}
