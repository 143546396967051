import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import {
	FormationIntervention,
	FormationInterventionInterface,
	FormationInterventionPayload,
} from './formation-intervention';
import { FormationInterventionSearchParamsInterface } from './formation-intervention-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class FormationInterventionService extends BaseModelService<
	FormationIntervention,
	FormationInterventionInterface,
	FormationInterventionPayload,
	FormationInterventionSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'formation-intervention';
	}
	/** @inheritDoc */
	protected new(
		object: FormationInterventionInterface
	): FormationIntervention {
		return new FormationIntervention(object);
	}

	generate(template: string, docType: string, id: string) {
		// Start request
		const options = {
			withCredentials: true,
		};
		return this.http
			.patch<{
				pdf: string;
			}>(
				`${environment.api.uri}/admin/formation-intervention/${id}/generate/${docType}`,
				{
					template,
				},
				options
			)
			.toPromise();
	}

	signDocument(
		id: string,
		docType: string,
		signature: string
	): Promise<void> {
		// Start request
		const options = {
			withCredentials: true,
		};
		return this.http
			.patch<void>(
				`${environment.api.uri}/formation-intervention/${id}/signature/${docType}`,
				{
					signature,
				},
				options
			)
			.toPromise();
	}

	getPresignedDocAdmininistrative(
		id: string,
		docType: string,
		asAdmin: boolean = false
	): Promise<{
		url: string;
	}> {
		const options = {
			withCredentials: true,
		};
		return this.http
			.get<{
				url: string;
			}>(`${this.uri(asAdmin)}/${id}/${docType}`, options)
			.toPromise();
	}

	async getPresignedDocAdmininistrativeAsAdmin(
		id: string,
		docType: string
	): Promise<{
		url: string;
	}> {
		return this.getPresignedDocAdmininistrative(id, docType, true);
	}

	getPresignedDocPedagogique(
		id: string,
		idDoc: string,
		asAdmin: boolean = false
	): Promise<{
		url: string;
	}> {
		const options = {
			withCredentials: true,
		};
		return this.http
			.get<{
				url: string;
			}>(`${this.uri(asAdmin)}/${id}/document/${idDoc}`, options)
			.toPromise();
	}

	getPresignedDocPedagogiqueAsAdmin(
		id: string,
		idDoc: string
	): Promise<{
		url: string;
	}> {
		return this.getPresignedDocPedagogique(id, idDoc, true);
	}

	uploadDocumentAdministrative(
		id: string,
		docType: string,
		uri: string
	): Promise<void> {
		// Start request
		const options = {
			withCredentials: true,
		};
		return this.http
			.patch<void>(
				`${environment.api.uri}/admin/formation-intervention/${id}/administrative/upload/${docType}`,
				{
					uri,
				},
				options
			)
			.toPromise();
	}

	uploadDocumentPedagogique(
		id: string,
		documentId: string,
		uri: string,
		label: string
	): Promise<void> {
		// Start request
		const options = {
			withCredentials: true,
		};
		return this.http
			.patch<void>(
				`${environment.api.uri}/admin/formation-intervention/${id}/pedagogique/upload/${documentId}`,
				{
					uri,
					label,
				},
				options
			)
			.toPromise();
	}

	sendEmailPedagogique(id: string, documentId?: string): Promise<void> {
		// Start request
		const options = {
			withCredentials: true,
		};

		// build path
		const path = documentId ? `/${documentId}` : '';
		return this.http
			.post<void>(
				`${environment.api.uri}/admin/formation-intervention/${id}/pedagogique/send${path}`,
				{},
				options
			)
			.toPromise();
	}

	sendEmailAdministrative(
		id: string,
		docType: 'ordre_de_mission' | 'contrat_travail'
	): Promise<void> {
		// Start request
		const options = {
			withCredentials: true,
		};

		return this.http
			.post<void>(
				`${environment.api.uri}/admin/formation-intervention/${id}/administrative/send/${docType}`,
				{},
				options
			)
			.toPromise();
	}
}
