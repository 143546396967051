import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService, UserSearchParams } from '@app/models/user';
import { ErrorService } from '@app/services/error.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-atom-sponsor-counter',
	templateUrl: './atom-sponsor-counter.component.html',
	styleUrls: ['./atom-sponsor-counter.component.less'],
})
export class AtomSponsorCounterComponent implements OnInit, OnDestroy {
	/** @type {Subject<void>} Observables unsubscriber */
	protected unsubscribe: Subject<void> = new Subject<void>();

	@Input() searchParams: UserSearchParams;

	loading = true;
	count: number;

	constructor(
		private userService: UserService,
		private errorService: ErrorService
	) {}

	ngOnInit(): void {
		this.refresh();

		this.searchParams.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
			this.refresh();
		});
	}

	ngOnDestroy() {
		this.unsubscribe.next();
	}

	async refresh() {
		this.loading = true;
		if (this.searchParams) {
			const params = this.searchParams.toObjectSanitizedForCount();
			this.count = await this.userService
				.countSponsor(params)
				.catch((err) => {
					this.errorService.handle(err);
					return null;
				});
		}
		this.loading = false;
	}
}
