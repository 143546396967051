import { Injectable } from '@angular/core';
import {
	FormationIntervention,
	FormationInterventionInterface,
	FormationInterventionPayload,
} from './formation-intervention';
import { FormationInterventionSearchParamsInterface } from './formation-intervention-search-params';
import { BaseWalker } from '@app/abstracts/base-walker';
import { FormationInterventionService } from './formation-intervention.service';

export class FormationInterventionWalker extends BaseWalker<
	FormationIntervention,
	FormationInterventionInterface,
	FormationInterventionPayload,
	FormationInterventionSearchParamsInterface
> {}

export class FormationInterventionFromAdminWalker extends BaseWalker<
	FormationIntervention,
	FormationInterventionInterface,
	FormationInterventionPayload,
	FormationInterventionSearchParamsInterface
> {
	listFonction = 'listAsAdmin';
}

@Injectable()
export class FormationInterventionWalkerService {
	constructor(
		private formationInterventionService: FormationInterventionService
	) {}

	getWalker(): FormationInterventionWalker {
		return new FormationInterventionWalker(
			this.formationInterventionService
		);
	}

	getWalkerFromAdmin(): FormationInterventionFromAdminWalker {
		return new FormationInterventionFromAdminWalker(
			this.formationInterventionService
		);
	}
}
