import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { SessionService } from '@app/services/session.service';
import { NavigationService } from '@app/services/navigation.service';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import { PageInterface, PageService } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-molecule-logout',
	templateUrl: './molecule-logout.component.html',
	styleUrls: ['./molecule-logout.component.less'],
})
export class MoleculeLogoutComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	constructor(
		private sessionService: SessionService,
		private navigation: NavigationService,
		protected pageService: PageService,
		protected translate: TranslateService
	) {
		super(pageService, translate);
	}

	ngOnInit() {
		super.ngOnInit();
		this.refreshPageData();

		this.sessionService.logout().then(() => {
			this.navigation.delayed(['/']);
		});
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-logout'),
			hideMenu: true,
			hideTopBar: true,
		};
	}
}
