import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
} from '@angular/core';
import { ErrorService } from '@app/services/error.service';
import { Survey } from '@app/models/survey';
import { Question } from '@app/models/question';
import { User } from '@app/models/user';
import { SurveyResultService, SurveyResult } from '@app/models/survey-result';
import { environment } from '@env/environment';
import {
	SurveyOpenResult,
	SurveyOpenResultService,
} from '@app/models/survey-open-result';

@Component({
	selector: 'app-atom-survey-fill',
	templateUrl: './atom-survey-fill.component.html',
	styleUrls: ['./atom-survey-fill.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AtomSurveyFillComponent implements OnInit {
	@Input() survey: Survey;
	@Input() user: User;
	@Output() surveySuccess = new EventEmitter();
	@Output() surveyFail = new EventEmitter();
	questions: Question[] = [];
	questionCursor: number = 0;
	progressSurvey: number = 0;
	navVisible: boolean = true;
	loading = false;
	result: Object = {};

	constructor(
		private surveyResultService: SurveyResultService,
		private surveyOpenResultService: SurveyOpenResultService,
		private errorService: ErrorService,
		private cdRef: ChangeDetectorRef
	) {}

	ngOnInit() {
		// Process questions and adapt them to current context
		this.questions = <Question[]>this.survey.props.questions;
		this.filterOutOfContextQuestions();
		if (this.survey.props.randomized) this.randomizeQuestions();
	}

	async submit() {
		this.navVisible = false;
		this.loading = true;

		try {
			await Promise.all([
				this.saveSurveyResult(),
				this.saveOpenResults(),
			]);
			this.surveySuccess.emit();
		} catch (e) {
			this.errorService.handle(e);
			this.surveyFail.emit();
		}
		this.loading = false;
	}

	/**
	 * Send survey result to the API
	 */
	async saveSurveyResult(): Promise<void> {
		const surveyResult = this.generateSurveyResult();
		await this.surveyResultService.create(surveyResult.toPayload());
	}

	/**
	 * Send survey result to the API
	 */
	async saveOpenResults(): Promise<void> {
		const openResults = this.generateOpenResults();
		await Promise.all(
			openResults.map((openResult) =>
				this.surveyOpenResultService.create(openResult.toPayload())
			)
		);
	}

	/**
	 * Generate a SurveyResult instance from form results
	 */
	generateSurveyResult(): SurveyResult {
		if (this.isSurveyCompleted()) {
			return new SurveyResult({
				survey: this.survey.getId(),
				answers: Object.values(this.result).filter(
					(_, index) =>
						this.questions[index] &&
						this.questions[index].props.type !== 'open'
				),
			});
		}
	}

	/**
	 * Generate a list of SurveyOpenResult instance from form results
	 */
	generateOpenResults(): SurveyOpenResult[] {
		return this.isSurveyCompleted()
			? Object.entries(this.result)
					.filter(
						(_, index) =>
							this.questions[index] &&
							this.questions[index].props.type === 'open'
					)
					.map(
						([question, answer]) =>
							new SurveyOpenResult({
								survey: this.survey.getId(),
								question,
								answer: <string>answer,
							})
					)
			: [];
	}

	/**
	 * Randomize the question list with fisher-yates algo
	 */
	randomizeQuestions(): void {
		for (let i = this.questions.length - 1; i > 0; i--) {
			let j = Math.floor(Math.random() * (i + 1));
			[this.questions[i], this.questions[j]] = [
				this.questions[j],
				this.questions[i],
			];
		}
	}

	/**
	 * Remove the questions that must not be answered in current context
	 */
	filterOutOfContextQuestions(): void {
		this.questions = this.questions.filter(
			(question) => !!question.getLabelText(this.user)
		);
	}

	/**
	 * Move the question cursor to the next question
	 */
	nextQuestion(): void {
		if (this.questionCursor < this.getSurveyLength() - 1) {
			this.questionCursor++;
		}
		if (this.progressSurvey < this.getSurveyLength()) {
			this.progressSurvey++;
		}
		this.cdRef.detectChanges();
	}

	/**
	 * Move the question cursor to the previous question
	 */
	previousQuestion(): void {
		this.isPreviousPossible()
			? this.questionCursor-- && this.progressSurvey--
			: '';
		this.cdRef.detectChanges();
	}

	/**
	 * Check if previous action is possible
	 */
	isPreviousPossible(): boolean {
		const lastAnsweredQuestionIndex = this.getResultLength() - 1;
		const previousLimitIndex =
			lastAnsweredQuestionIndex -
			environment.surveys.previousQuestionLimit;
		return (
			this.questionCursor > 0 && this.questionCursor > previousLimitIndex
		);
	}

	/**
	 * Test if current question is the first of the array
	 */
	isQuestionFirst(): boolean {
		return this.questionCursor === 0;
	}

	/**
	 * Test if current question is the last of the array
	 */
	isQuestionLast(): boolean {
		return this.questionCursor === this.getSurveyLength() - 1;
	}

	/**
	 * Return the number of answered questions
	 */
	getResultLength(): number {
		return Object.values(this.result).filter((v) => v).length;
	}

	/**
	 * Return the length of the question array
	 */
	getSurveyLength(): number {
		return this.questions.length;
	}

	/**
	 * Test if a question have been answered
	 */
	isQuestionAnswered(questionId: string): boolean {
		return !!this.result[questionId];
	}

	/**
	 * Return the question selected by the cursor
	 */
	getCurrentQuestion(): Question {
		return this.questions[this.questionCursor];
	}

	/**
	 * Test if all questions of the survey have been answered
	 */
	isSurveyCompleted(): boolean {
		return (
			this.getSurveyLength() ===
			Object.values(this.result).filter((v) => v).length
		);
	}

	onAnswerUpdate(response: string) {
		if (response) {
			this.result[this.getCurrentQuestion().getId()] = response;
			this.cdRef.detectChanges();

			const question = this.questions[this.questionCursor];
			if (question.props.type !== 'open') this.nextQuestion();
		}
	}
}
