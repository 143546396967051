import { Helpers } from '@app/shared/helpers';
import {
	BaseModelSearchParams,
	BaseModelSearchParamsInterface,
} from '@app/abstracts';
/** Used to export and import search params */
export interface ReportModeratedSearchParamsInterface
	extends BaseModelSearchParamsInterface {
	_sort?:
		| '_id'
		| 'created_at'
		| 'report'
		| 'owners'
		| 'moderated_at'
		| 'grouped'
		| 'respondant_count'
		| 'formation_thematique';
	_id?: string[];
	report?: string[];
	owners?: string[];
	moderated?: 'true' | 'false' | boolean;
	grouped?: 'true' | 'false' | boolean;
	respondant_count?: string | number;
	respondant_count__min?: string | number;
	respondant_count__max?: string | number;
	formation_thematique?: string;
}
/** Manage report moderated search params */
export class ReportModeratedSearchParams extends BaseModelSearchParams<
	ReportModeratedSearchParamsInterface
> {
	/** @inheritDoc */
	fromObject(input: Partial<ReportModeratedSearchParamsInterface>): void {
		// Clone object with allowed keys only
		const props = {} as ReportModeratedSearchParamsInterface;
		this.allowedKeys().map((key: string) => {
			if (typeof input[key] !== 'undefined') {
				props[key] = input[key];
			}
		});
		// Keep default values
		if (typeof props._page === 'undefined') {
			props._page = this.defaultPage;
		}
		if (typeof props._limit === 'undefined') {
			props._limit = this.defaultLimit;
		}
		if (typeof props._sort === 'undefined') {
			props._sort = this.defaultSort;
		}
		if (typeof props._order === 'undefined') {
			props._order = this.defaultOrder;
		}
		// Convert potentially multiple primary keys
		if (typeof props._id === 'string') {
			props._id = (<string>props._id).split(',').filter((s) => s.length);
		}
		// Convert potentially multiple ids for owners
		if (typeof props.owners === 'string') {
			props.owners = (<string>props.owners)
				.split(',')
				.filter((s) => s.length);
		}
		// Convert boolean moderated
		if (typeof props.moderated !== 'undefined') {
			props.moderated = Helpers.convertToBoolean(props.moderated);
		}
		// Convert boolean grouped
		if (typeof props.grouped !== 'undefined') {
			props.grouped = Helpers.convertToBoolean(props.grouped);
		}
		// Assign values
		this.props = props;
		this.next();
	}
	/** @inheritDoc */
	toObject(): ReportModeratedSearchParamsInterface {
		// Filter not allowed, undefined and null values
		const props = {} as ReportModeratedSearchParamsInterface;
		this.allowedKeys().map(Helpers.prepareObjectForApi(this.props, props));
		// Convert boolean moderated
		if (typeof props.moderated !== 'undefined') {
			props.moderated = props.moderated ? 'true' : 'false';
		}
		// Convert boolean grouped
		if (typeof props.grouped !== 'undefined') {
			props.grouped = props.grouped ? 'true' : 'false';
		}
		return props;
	}
	/** @inheritDoc */
	protected allowedKeys(): string[] {
		return super
			.allowedKeys()
			.concat([
				'_id',
				'report',
				'owners',
				'moderated',
				'grouped',
				'respondant_count',
				'respondant_count__min',
				'respondant_count__max',
				'formation_thematique',
				'report',
			]);
	}
}
