import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import {
	ReportModerated,
	ReportModeratedService,
} from '@app/models/report-moderated';
import { SurveyOpenResultInterface } from '@app/models/survey-open-result';

import { PageService, PageInterface } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from '@app/services/error.service';
import { Report } from '@app/models/report';
import { User } from '@app/models/user';
import { Image } from '@app/models/image';
import { SurveyResult } from '@app/models/survey-result';
import { environment } from '@env/environment';
import { FormationThematique } from '@app/models/formation-thematique';
import { Formation } from '@app/models/formation';
import { SurveyInterface } from '@app/models/survey';
import { QuestionInterface } from '@app/models/question';
import { SurveyLabelInterface } from '@app/models/survey-label';
import { HttpClient } from '@angular/common/http';

interface ReportModeratedUserInterface {
	_id: string;
	name: string;
	role: string;
	avatar: string;
}

interface ReportModeratedAdminInterface {
	_id: string;
	name: string;
	reportModerated: ReportModerated;
	owners: ReportModeratedUserInterface[];
	grouped: boolean;
	respondedBy: ReportModeratedUserInterface[];
	respondantCount: number;
}

@Component({
	selector: 'app-molecule-report-moderated-details',
	templateUrl: './molecule-report-moderated-details.component.html',
	styleUrls: ['./molecule-report-moderated-details.component.less'],
})
export class MoleculeReportModeratedDetailsComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	private reportModeratedId: string;

	reportModeratedDetails: ReportModeratedAdminInterface;
	sesameUri = environment.sesame.uri;

	surveyOpenResults: {
		surveyLabel: string;
		questions: {
			_id: string;
			questionLabel: string;
			answers: { answer: string }[];
		}[];
	}[];

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		private route: ActivatedRoute,
		private reportModeratedService: ReportModeratedService,
		private errorService: ErrorService,
		protected http: HttpClient
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();

		this.reportModeratedId = this.route.snapshot.params.id;
		await this.refresh();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	async refresh(): Promise<void> {
		this.reportModeratedDetails = await this.reportModeratedService
			.getFromAdmin(this.reportModeratedId, {
				_populate: [
					'owners.avatar',
					'report',
					'graphs.graph.axes.axe',
					'graphs.graph.axes.survey',
					'graphs.graph.axes.color',
					'graphs.axes.axe',
					'results.owner.avatar',
					'results.answers.weights',
					'formation_thematique.formation',
				],
			})
			.then(async (data) => this.format(data));
	}

	async format(
		reportModerated: ReportModerated
	): Promise<ReportModeratedAdminInterface> {
		this.surveyOpenResults = await this.getSurveyOpenResults(
			reportModerated
		);
		const report: Report = reportModerated.reportExists()
			? <Report>reportModerated.props.report
			: null;

		const owners: ReportModeratedUserInterface[] = (reportModerated.ownersExists()
			? reportModerated.props.owners
			: []
		).map((owner) => ({
			_id: (<User>owner).getId(),
			name: (<User>owner).getLabel(),
			role: (<User>owner).props.role,
			avatar: (<Image>(<User>owner).props.avatar)?.getUrl(),
		}));

		const respondedBy: Map<
			string,
			ReportModeratedUserInterface
		> = new Map();
		(reportModerated.resultsExists()
			? reportModerated.props.results
			: []
		).forEach((result) => {
			const owner = <User>(<SurveyResult>result).props.owner;
			if (owner) {
				respondedBy.set(owner.getId(), {
					_id: owner.getId(),
					name: owner.getLabel(),
					role: owner.props.role,
					avatar: (<Image>owner.props.avatar)?.getUrl(),
				});
			}
		});

		const formation = <Formation>(
			(<FormationThematique>reportModerated.props.formation_thematique)
				?.props.formation
		);

		return {
			_id: reportModerated.getId(),
			name: report ? report.getLabel() : '',
			reportModerated,
			owners,
			grouped: reportModerated.props.grouped,
			respondedBy: [...respondedBy.values()],
			respondantCount: formation?.props.respondants.length || 1,
		};
	}

	async getSurveyOpenResults(
		reportModerated: ReportModerated
	): Promise<
		{
			surveyLabel: string;
			questions: {
				_id: string;
				questionLabel: string;
				answers: { answer: string }[];
			}[];
		}[]
	> {
		const surveyIds = [
			...new Set(
				reportModerated.props.results.map(
					(result: SurveyResult) => result.props.survey
				)
			),
		];
		const surveyOpenResults = [];

		await Promise.all(
			surveyIds.map(async (id: string, indexSurvey: number) => {
				const params = {
					_page: '0',
					_limit: '100',
					survey: id,
					_populate: ['survey', 'question.labels'],
				};
				const options = {
					withCredentials: true,
					params: params,
				};
				const result = await this.http
					.get<{ items: SurveyOpenResultInterface[] }>(
						`${environment.api.uri}/admin/survey-open-result`,
						options
					)
					.toPromise();
				if (result.items?.length > 0) {
					surveyOpenResults.push({
						surveyLabel: (<SurveyInterface>result.items[0].survey)
							.label,
						questions: [],
					});
					const questions: {
						_id: string;
						questionLabel: string;
						answers: { answer: string }[];
					}[] = [];
					result.items.map((item) => {
						const currentQuestion = <QuestionInterface>(
							item.question
						);
						const indexQuestion = questions.findIndex(
							(q) => q._id === currentQuestion._id
						);
						if (indexQuestion > -1) {
							questions[indexQuestion].answers.push({
								answer: item.answer,
							});
						} else {
							const questionLabel = currentQuestion.labels.length
								? (<SurveyLabelInterface>(
										currentQuestion.labels[0]
								  )).label
								: '';
							questions.push({
								_id: currentQuestion._id,
								questionLabel,
								answers: [],
							});
							const indexQuestion = questions.findIndex(
								(q) => q._id === currentQuestion._id
							);
							if (item.answer)
								questions[indexQuestion].answers.push({
									answer: item.answer,
								});
						}
					});
					surveyOpenResults[indexSurvey].questions = questions;
				}
			})
		);
		return surveyOpenResults;
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-report_details'),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-report_list'),
				},
				{
					label: this.reportModeratedDetails?.name ?? '',
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
