import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import { TranslateService } from '@ngx-translate/core';
import { PageService, PageInterface } from '@app/services/page.service';
import { User, UserSearchParams, UserService } from '@app/models/user';
import { SessionService } from '@app/services/session.service';
import { NavigationService } from '@app/services/navigation.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'app-molecule-coach-list',
	templateUrl: './molecule-coach-list.component.html',
	styleUrls: ['./molecule-coach-list.component.less'],
})
export class MoleculeCoachListComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'list';
	currentUser: User;
	coaches: User[];
	searchParams: UserSearchParams;
	orders = ['asc', 'desc'];
	numberTotalCoach: number;
	loading = false;
	limit = 25;
	currentPageIndex = 1;

	// Search bar
	searchInput: string;
	searchedCoaches: User[];

	private unsubscribe$ = new Subject<void>();
	inputSubject: Subject<void> = new Subject();
	paginationSubject = new BehaviorSubject<number>(0);

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		private sessionService: SessionService,
		public userService: UserService,
		private navigationService: NavigationService
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();
		this.searchParams = new UserSearchParams({
			_page: this.paginationSubject.getValue(),
			_limit: this.limit,
			_order: 'asc',
			role: 'coach',
			_sort: 'first_name',
		});
		this.currentUser = await this.sessionService.getCachedUser();

		this.paginationSubject
			.asObservable()
			.pipe(
				takeUntil(this.unsubscribe),
				tap((newIndex) => {
					const realIndex = newIndex > 1 ? newIndex - 1 : 0;
					this.searchParams.props._page = realIndex;
					this.searchParams.next();
				})
			)
			.subscribe();

		this.inputSubject
			.pipe(takeUntil(this.unsubscribe), debounceTime(300))
			.subscribe(() => {
				this.searchCoach();
			});

		this.searchParams
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(async () => {
				await this.getCoachList();
			});
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	trackById(_: number, coach: User): string {
		return coach.getId();
	}

	async getCoachList() {
		this.loading = true;
		await this.userService
			.listAsAdmin(this.searchParams.toObject())
			.then((res) => {
				this.coaches = res.items;
				this.numberTotalCoach = res.total;
			});
		this.loading = false;
	}

	async goToCoachPage() {
		if (this.searchedCoaches.length > 0) {
			const playerId = this.searchedCoaches
				.find((coach) => {
					return (
						`${coach.props.first_name} ${coach.props.last_name}` ===
						this.searchInput
					);
				})
				.getId();

			await this.navigationService.go(['/coach', playerId, 'details']);
		}
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-coach_list'),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-coach_list'),
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}

	private searchCoach(): void {
		this.searchParams.props._page = 0;
		this.searchParams.props._text = this.searchInput;
		this.searchParams.next();
	}

	handleOrderChange(order) {
		this.searchParams.props._order = order;
		this.paginationSubject.next(0);
	}

	handleNextPage(index: number) {
		this.currentPageIndex = index;
		this.paginationSubject.next(index);
	}
}
