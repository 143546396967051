import { Injectable } from '@angular/core';
import { User, UserInterface, UserPayload } from './user';
import { UserSearchParamsInterface } from './user-search-params';
import { BaseWalker } from '@app/abstracts/base-walker';
import { UserService } from './user.service';

export class UserWalker extends BaseWalker<
	User,
	UserInterface,
	UserPayload,
	UserSearchParamsInterface
> {}

export class SponsorWalker extends BaseWalker<
	User,
	UserInterface,
	UserPayload,
	UserSearchParamsInterface
> {
	listFonction = 'listSponsor';
}
export class UserAsAdminWalker extends BaseWalker<
	User,
	UserInterface,
	UserPayload,
	UserSearchParamsInterface
> {
	listFonction = 'listAsAdmin';
}

@Injectable()
export class UserWalkerService {
	constructor(private userService: UserService) {}

	getWalker(): UserWalker {
		return new UserWalker(this.userService);
	}

	getSponsorWalker(): SponsorWalker {
		return new SponsorWalker(this.userService);
	}

	getUserAsAdminWalker(): UserAsAdminWalker {
		return new UserAsAdminWalker(this.userService);
	}
}
