import {
	Component,
	OnInit,
	OnChanges,
	Input,
	ViewChild,
	ElementRef,
	SimpleChanges,
	ChangeDetectionStrategy,
} from '@angular/core';
import {
	BaseGraphComponent,
	DataAverageInterface,
} from '@app/abstracts/base-graph/base-graph.component';
import { environment } from '@env/environment';
import { Column } from '@antv/g2plot';
import { Breakpoint, ResizeService } from '@app/services/resize.service';
import { Color } from '@app/models/color';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-atom-graph-bar',
	templateUrl: '../../../abstracts/base-graph/base-graph.component.html',
	styleUrls: ['../../../abstracts/base-graph/base-graph.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AtomGraphBarComponent extends BaseGraphComponent
	implements OnInit, OnChanges {
	private mobileSize = 25;
	private desktopSize = 40;

	@ViewChild('graphDiv', { static: true }) graphDiv: ElementRef;

	@Input() data: DataAverageInterface[] = [];
	@Input() colors: Color[];
	@Input() xLabel: string;
	@Input() yLabel: string;
	@Input() maxAxis: number;

	typeGraph: string = 'bar';

	protected defaultOptions = environment.graphs.bar.options;

	constructor(
		protected resizeService: ResizeService,
		private translate: TranslateService
	) {
		super(resizeService);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.isPrinting) {
			this.graph.updateConfig({
				label: environment.graphsPrint.bar.options.label,
				xAxis: environment.graphsPrint.bar.options.xAxis,
				renderer: environment.graphsPrint.bar.options.renderer,
			});
			this.graph.render();
		}

		if (!this.isPrinting && changes.isPrinting.previousValue) {
			this.graph.updateConfig({
				label: environment.graphs.bar.options.label,
				xAxis: environment.graphs.bar.options.xAxis,
				renderer: environment.graphs.bar.options.renderer,
				// forceFit: environment.graphs.bar.options.forceFit,
			});
			this.graph.render();
		}
	}

	initializeGraph() {
		this.data.map((d) => {
			d.x = d.x.charAt(0).toUpperCase() + d.x.slice(1).toLowerCase();
		});

		const options: any = Object.assign(this.options, {
			data: this.data,
			color: this.colors.map((c) => c.toAnt()),
			meta: {
				x: {
					alias: this.xLabel,
				},
				y: {
					alias: this.yLabel,
				},
			},
			columnSize:
				this.breakpoint.label === 'xs'
					? this.mobileSize
					: this.desktopSize,
		});

		if (this.maxAxis) {
			options.yAxis.max = this.maxAxis;
		}

		this.graph = new Column(this.graphDiv.nativeElement, options);
	}

	onResize(breakpoint: Breakpoint) {
		this.graph.updateConfig({
			columnSize:
				this.breakpoint.label === 'xs'
					? this.mobileSize
					: this.desktopSize,
		});
		this.graph.render();
	}

	async loadAverages(): Promise<void> {
		this.averages = [
			{
				name: await this.translate.instant('graph-average-title'),
				average: Number.parseFloat(
					this.calculateAverage(this.data).toFixed(2)
				),
				maxAxis: this.maxAxis ?? 5,
			},
		];
	}
}
