import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import { DocumentAdministrativeService } from '@app/models/document-administrative';
import {
	DocumentPedagogique,
	DocumentPedagogiqueService,
} from '@app/models/document-pedagogique';
import {
	FormationIntervention,
	FormationInterventionService,
} from '@app/models/formation-intervention';

import { PageInterface, PageService } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-molecule-stagiaire-document-list',
	templateUrl: './molecule-stagiaire-document-list.component.html',
	styleUrls: ['./molecule-stagiaire-document-list.component.less'],
})
export class MoleculeStagiaireDocumentListComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	private unsubscribe$ = new Subject<void>();

	formationIntervention: FormationIntervention;
	documents: DocumentPedagogique[] = [];

	loading = false;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		protected formationInterventionService: FormationInterventionService,
		protected documentPedagogiqueService: DocumentPedagogiqueService,
		protected documentAdministrativeService: DocumentAdministrativeService,
		private route: ActivatedRoute,
		private http: HttpClient,
		private router: Router
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		await this.getFormationInterventionInfos();
		this.getWhiteListDocuments();
		this.loading = false;
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async getFormationInterventionInfos() {
		this.formationIntervention = await this.formationInterventionService.get(
			this.route.snapshot.params.interventionId,
			{
				_populate: ['documents', 'thematiques'],
			}
		);
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant(
				'page_title-formation_detail_intervention',
				{
					formationIntervention: this.formationIntervention
						? this.formationIntervention.getLabel()
						: '',
				}
			),
			hideTopBar: true,
			hideMenu: true,
		};
	}

	getWhiteListDocuments() {
		const whiteListDocuments: string[] = this.route.snapshot.queryParams[
			'document'
		]
			? (this.route.snapshot.queryParams['document'] as string[])
			: [];

		this.documents = (this.formationIntervention.props
			.documents as DocumentPedagogique[]).filter(
			(doc) =>
				!whiteListDocuments.length ||
				whiteListDocuments.includes(doc.getId())
		);
	}

	async handleClick(idDoc: string) {
		const {
			url,
		} = await this.formationInterventionService.getPresignedDocPedagogique(
			this.formationIntervention.getId(),
			idDoc
		);
		window.open(url, '_blank');
	}

	downloadPdf(url: string, filename: string) {
		this.http
			.get(url, { responseType: 'blob' })
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((response) => {
				const file = new Blob([response], { type: response.type });

				const fileURL = URL.createObjectURL(file);
				const fakeDownload = document.createElement('a');
				fakeDownload.href = fileURL;
				fakeDownload.target = '_blank';
				fakeDownload.download = filename;
				document.body.appendChild(fakeDownload);
				fakeDownload.click();
				URL.revokeObjectURL(fileURL);
				fakeDownload.remove();
			});
	}

	async handleDownload(event: { idDoc: string; filename: string }) {
		const {
			url,
		} = await this.formationInterventionService.getPresignedDocPedagogique(
			this.formationIntervention.getId(),
			event.idDoc
		);
		this.downloadPdf(url, event.filename);
	}

	goToIntervention() {
		// Disable because respondant doesn't have any page for intervention details
		// this.router.navigate([
		// 	'formation',
		// 	this.formationIntervention.getId(),
		// 	'details',
		// ]);
	}
}
