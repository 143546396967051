import { SelfResponse } from './session.service';

export class Self {
	/** Properties */
	private props: SelfResponse;

	/** Constructor */
	constructor(self: SelfResponse) {
		this.props = self;
	}

	/** Returns primary key */
	getId() {
		return this.props._id;
	}

	/** Returns role */
	getRole() {
		return this.props.role;
	}

	/** Returns display name */
	getName() {
		// @context user:unified-names
		// return `${this.props.name}`;
		// @context user:separate-names
		return `${this.props.first_name} ${this.props.last_name}`;
	}

	/** Returns role */
	isAdmin() {
		return this.props.role === 'admin';
	}
}
