<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5 bg-grey-light">
	<!-- Col left -->
	<div class="container-xl ml-0">
		<!-- Counter -->
		<div class="row">
			<div class="col-12 col-md-4" *ngIf="sponsorSearchParams">
				<app-atom-sponsor-counter
					[searchParams]="sponsorSearchParams"
				></app-atom-sponsor-counter>
			</div>

			<div
				class="col-12"
				[class.col-md-6]="!sponsorSearchParams"
				[class.col-md-4]="sponsorSearchParams"
			>
				<app-atom-thematique-formation-counter
					*ngIf="formationThematiqueSearchParams"
					[searchParams]="formationThematiqueSearchParams"
				></app-atom-thematique-formation-counter>
			</div>

			<div
				class="col-12"
				[class.col-md-6]="!sponsorSearchParams"
				[class.col-md-4]="sponsorSearchParams"
			>
				<app-atom-report-moderated-counter
					*ngIf="reportModeratedSearchParams"
					[searchParams]="reportModeratedSearchParams"
				></app-atom-report-moderated-counter>
			</div>
		</div>
		<!-- /Counter -->

		<!-- Calendar -->
		<div class="container-xl px-xl-0 mb-5 ml-0 calendar-container">
			<div class="row">
				<div class="col-12 px-0 px-xl-3">
					<h2 class="mb-4">
						{{ 'dashboard_next_formations' | translate }}
					</h2>
					<app-atom-thematique-calendar
						class="d-none d-lg-block"
						[formationThematiqueSearchParams]="
							formationThematiqueSearchParams
						"
						[user]="user"
					></app-atom-thematique-calendar>
					<app-atom-mobile-calendar
						class="d-lg-none"
						[formationThematiqueSearchParams]="
							formationThematiqueSearchParams
						"
						[user]="user"
					></app-atom-mobile-calendar>
				</div>
			</div>
		</div>
		<!-- Calendar -->

		<!-- Report Moderated List -->
		<div class="row" [ngClass]="numberReports === 0 ? 'd-none' : null">
			<div class="col-12 px-0 px-xl-3">
				<app-atom-report-moderated-inline-list
					*ngIf="reportModeratedSearchParams"
					[searchParams]="reportModeratedSearchParams"
					(reportsNumber)="getNumberReports($event)"
				></app-atom-report-moderated-inline-list>
			</div>
		</div>
		<!-- /Report Moderated List -->
	</div>
	<!-- /Col left -->
</div>
