<div class="svgContainer">
	<svg
		width="400px"
		height="407px"
		viewBox="0 0 400 407"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<title>logo_nove-concept</title>
		<defs>
			<linearGradient
				x1="45.703163%"
				y1="24.7460798%"
				x2="60.9340613%"
				y2="69.1147734%"
				id="linearGradient-1"
			>
				<stop stop-color="#8BB24A" offset="0%"></stop>
				<stop stop-color="#AACF74" offset="100%"></stop>
			</linearGradient>
			<linearGradient
				x1="69.885842%"
				y1="33.1964978%"
				x2="36.0302572%"
				y2="62.2480458%"
				id="linearGradient-2"
			>
				<stop stop-color="#D16A2A" offset="0%"></stop>
				<stop stop-color="#CC6325" offset="100%"></stop>
			</linearGradient>
			<linearGradient
				x1="43.6534635%"
				y1="14.8518309%"
				x2="70.8812051%"
				y2="35.5133729%"
				id="linearGradient-3"
			>
				<stop stop-color="#7766A6" offset="0%"></stop>
				<stop stop-color="#5B4895" offset="100%"></stop>
			</linearGradient>
			<linearGradient
				x1="47.9582103%"
				y1="44.8961816%"
				x2="50%"
				y2="57.1267636%"
				id="linearGradient-4"
			>
				<stop stop-color="#C82B7C" offset="0%"></stop>
				<stop stop-color="#C50071" offset="100%"></stop>
			</linearGradient>
			<linearGradient
				x1="78.3311799%"
				y1="61.8959221%"
				x2="7.42360451%"
				y2="61.8959221%"
				id="linearGradient-5"
			>
				<stop stop-color="#F9E952" offset="0%"></stop>
				<stop stop-color="#F4E21E" offset="100%"></stop>
			</linearGradient>
			<linearGradient
				x1="78.0886053%"
				y1="5.51604898%"
				x2="19.4735324%"
				y2="82.0965924%"
				id="linearGradient-6"
			>
				<stop
					stop-color="#FFFFFF"
					stop-opacity="0.08"
					offset="0%"
				></stop>
				<stop
					stop-color="#FFFFFF"
					stop-opacity="0"
					offset="100%"
				></stop>
			</linearGradient>
		</defs>
		<g
			id="Page-1"
			stroke="none"
			stroke-width="1"
			fill="none"
			fill-rule="evenodd"
		>
			<g id="logo_nove-concept" fill-rule="nonzero">
				<g id="circle-color" transform="translate(0.338641, 45.674523)">
					<path
						d="M156.190549,84.2780621 C162.32763,84.2780621 168.349374,84.7633537 174.227302,85.6825535 L188.704264,0.229799952 C185.688408,0.078503089 182.652618,0 179.598316,0 C119.487671,0 66.2801736,29.6085105 33.681024,75.0646541 L74.8735115,117.644729 C95.8122074,97.0169723 124.512673,84.2780621 156.190549,84.2780621"
						id="Fill-1"
						fill="url(#linearGradient-1)"
					></path>
					<path
						d="M42.1701308,222.140762 L6.68897346,228.839217 C15.7550513,261.172498 33.6066956,289.811853 57.4928989,312.025372 L79.1990856,287.592356 C60.3492761,270.818387 47.0029042,247.989689 42.1701308,222.140762"
						id="Fill-3"
						fill="url(#linearGradient-2)"
					></path>
					<path
						d="M359.194656,180.026139 C359.194656,162.405763 356.658661,145.383439 351.949768,129.290306 L265.250325,160.784318 C269.764142,173.204934 272.231789,186.610407 272.231789,200.595375 C272.231789,241.549723 251.099441,277.532685 219.188043,298.258928 L249.419219,345.938849 C313.924072,318.629765 359.194656,254.628338 359.194656,180.026139"
						id="Fill-5"
						fill="url(#linearGradient-3)"
					></path>
					<path
						d="M156.190549,316.914545 C126.640005,316.914545 99.6852609,305.822772 79.1993704,287.592927 L57.4931837,312.024516 C89.5370058,341.824288 132.441041,360.052706 179.598316,360.052706 C204.363076,360.052706 227.954528,355.02708 249.420073,345.939278 L219.187473,298.259356 C201.041078,310.044811 179.423175,316.914545 156.190549,316.914545"
						id="Fill-8"
						fill="url(#linearGradient-4)"
					></path>
					<path
						d="M40.1503049,200.595661 C40.1503049,168.109655 53.4496873,138.748073 74.872515,117.645015 L33.6814512,75.0649396 C12.4907222,104.613502 0.00297237092,140.856237 0.00297237092,180.026424 C0.00297237092,196.944553 2.33677191,213.316016 6.68826151,228.839645 L42.1694188,222.141191 C40.8636872,215.155843 40.1503049,207.960678 40.1503049,200.595661"
						id="Fill-10"
						fill="url(#linearGradient-5)"
					></path>
					<path
						d="M7.75805012,202.987579 C7.75805012,205.242758 7.81785464,207.483665 7.9161049,209.716007 C11.3021789,137.102077 71.0995626,79.269565 144.377166,79.269565 C219.830514,79.269565 280.996282,140.583332 280.996282,216.216063 C280.996282,289.669262 223.304871,349.609939 150.863107,353.005555 C153.090112,353.10404 155.325661,353.163988 157.57545,353.163988 C240.317828,353.163988 307.39285,285.926806 307.39285,202.987579 C307.39285,120.046924 240.317828,52.8111691 157.57545,52.8111691 C74.8344963,52.8111691 7.75805012,120.046924 7.75805012,202.987579 Z"
						id="Fill-14"
						fill="url(#linearGradient-6)"
					></path>
					<path
						d="M184.339389,350.681863 C87.732334,350.681863 9.41691316,272.178774 9.41691316,175.340218 C9.41691316,79.2852655 86.4735914,1.28602334 181.999894,0.0299739068 C181.222436,0.0157006178 180.446401,0 179.66467,0 C80.4774779,0 0.0688997178,80.5998353 0.0688997178,180.026139 C0.0688997178,279.452442 80.4774779,360.05085 179.66467,360.05085 C278.853287,360.05085 359.260441,279.452442 359.260441,180.026139 C359.260441,179.243962 359.244778,178.464641 359.230539,177.68532 C357.976069,273.440533 280.163291,350.681863 184.339389,350.681863"
						id="Fill-1"
						fill-opacity="0.08"
						fill="#000000"
					></path>
				</g>
				<g id="squares" transform="translate(167.229141, 0.000000)">
					<g
						id="square-1"
						transform="translate(0.000000, 99.199358)"
						fill="#07A1E2"
					>
						<path
							d="M0.673449732,30.66755 L4.26295637,9.19230093 C8.45071409,9.72255385 12.6384719,10.5179336 16.8262296,11.5784401 C21.0139873,12.6389459 25.5008707,13.9645786 30.2868796,15.555338 L22.2104896,36.2352066 C17.5480476,34.764767 13.958541,33.7042611 11.4419696,33.0536884 C8.92539833,32.4031158 5.33589169,31.607736 0.673449732,30.66755 Z"
							id="path-1-compact"
						></path>
						<path
							d="M28.8245614,19.2689404 L31.6723949,1.45519152e-11 C34.9948673,0.475776179 38.3173397,1.18944075 41.6398121,2.14099372 C44.9622845,3.09254608 48.5220763,4.28198683 52.3191877,5.70931597 L45.9115623,24.2645912 C42.2124862,22.9452205 39.3646527,21.9936681 37.3680618,21.4099335 C35.371471,20.8261989 32.5236375,20.1125343 28.8245614,19.2689404 Z"
							id="path-1-explosed"
							style="visibility: hidden;"
						></path>
					</g>
					<g
						id="square-2"
						transform="translate(19.989214, 105.295281)"
						fill="#10A1C5"
					>
						<path
							d="M0.555769437,30.9816003 L14.3073143,0.511187046 C23.475011,3.76136466 29.8923986,6.1989971 33.5594772,7.82408643 C37.2265559,9.44917472 42.7271739,12.2930797 50.0613311,16.3558015 L30.8091682,44.3885823 C24.1278668,40.627487 18.9666731,38.0134984 15.3255867,36.5466163 C11.6845004,35.0797342 6.76122793,33.2247285 0.555769437,30.9816003 Z"
							id="path-2-compact"
						></path>
						<path
							d="M30.1940984,19.5959972 L37.3136821,1.75438596 C42.0600712,3.65749129 45.3825436,5.08481983 47.2810993,6.03637279 C49.1796549,6.98792515 52.0274884,8.65314208 55.8245997,11.0320236 L45.8571825,27.4463063 C42.3980741,25.2440389 39.7259706,23.7134469 37.8408719,22.8545304 C35.9557733,21.995614 33.4068487,20.909436 30.1940984,19.5959972 Z"
							id="path-2-explosed"
							style="visibility: hidden;"
						></path>
					</g>
					<g
						id="square-3"
						transform="translate(39.869669, 119.181962)"
						fill="#045C76"
					>
						<path
							d="M0.498701662,25.0854261 L13.951334,7.59826324 C17.6232194,9.55258022 21.2528465,11.8545238 24.8402151,14.5040939 C28.4275836,17.153664 31.7623506,19.8859033 34.8445154,22.7008133 L18.108863,37.8033628 C15.337177,35.1537927 12.456597,32.7691792 9.46712317,30.6495229 C6.47764939,28.5298674 3.48817552,26.6751678 0.498701662,25.0854261 Z"
							id="path-3-compact"
						></path>
						<path
							d="M29.5365198,15.7006182 L40.2158953,1.45519152e-11 C43.1308225,1.75465768 46.0122029,3.82142728 48.8600364,6.20030878 C51.7078698,8.57919028 54.3551754,11.0322953 56.8019528,13.5596251 L43.5163508,27.1192495 C41.3160472,24.740368 39.0292981,22.5993743 36.6561035,20.696269 C34.2829089,18.7931642 31.9097144,17.1279467 29.5365198,15.7006182 Z"
							id="path-3-explosed"
							style="visibility: hidden;"
						></path>
					</g>
					<g
						id="square-4"
						transform="translate(57.711056, 137.023574)"
						fill="#10A1C5"
					>
						<path
							d="M0.424689154,19.215686 L18.3615322,4.91078368 C21.351006,7.5598396 24.0415322,10.2088955 26.4331114,12.8579514 C28.8246907,15.5070073 31.5152169,18.9507802 34.5046907,23.1892699 L14.0656231,34.5877122 C11.7125807,31.2661734 9.63921681,28.5457823 7.84553219,26.4265374 C6.05184833,24.3072926 3.57823378,21.9036754 0.424689154,19.215686 Z"
							id="path-4-compact"
						></path>
						<path
							d="M28.7821338,12.8459605 L43.0213012,0 C45.3944958,2.3788815 47.5303707,4.75776301 49.4289265,7.13664451 C51.3274822,9.51552601 53.4633571,12.6080721 55.8365517,16.4142827 L39.6109927,26.65021 C37.7430294,23.6674314 36.0970889,21.2244901 34.6731719,19.3213848 C33.2492556,17.4182794 31.285576,15.2598047 28.7821338,12.8459605 Z"
							id="path-4-explosed"
							style="visibility: hidden;"
						></path>
					</g>
					<g
						id="square-5"
						transform="translate(73.569752, 157.719843)"
						fill="#07A1E2"
					>
						<path
							d="M0.596536159,12.1817149 L22.1207478,1.0535206 C24.5123263,4.23300458 26.6049586,7.67744584 28.3986425,11.3868444 C30.1923271,15.0962422 31.687064,18.5406835 32.8828533,21.7201675 L10.4617993,29.6688778 C8.66811543,25.4295654 7.1733785,22.2500814 5.9775885,20.1304252 C4.78179926,18.0107696 2.98811464,15.3611995 0.596536159,12.1817149 Z"
							id="path-5-compact"
						></path>
						<path
							d="M25.7386886,9.99130219 L42.8256895,-1.45519152e-11 C44.7242447,2.85465768 46.3854814,5.94720376 47.8093978,9.27763823 C49.2333148,12.6080721 50.4199121,15.7006182 51.3691896,18.5552758 L33.5702303,25.6919204 C32.1463139,21.8857097 30.9597166,19.031052 30.0104384,17.1279467 C29.0611608,15.224842 27.6372438,12.8459605 25.7386886,9.99130219 Z"
							id="path-5-explosed"
							style="visibility: hidden;"
						></path>
					</g>
					<g
						id="square-6"
						transform="translate(88.190300, 144.873883)"
						fill="#10A1C5"
					>
						<path
							d="M0.98027108,40.1701547 L21.6076404,29.0419605 C24.239487,32.4597504 26.4522522,35.7717134 28.2459368,38.977848 C30.0396207,42.1839833 32.3110663,47.3506452 35.0602728,54.477833 L11.7423773,62.4265433 C10.3366817,57.6573177 8.84194479,53.6829625 7.2581665,50.5034778 C5.67438744,47.3239939 3.58175589,43.8795526 0.98027108,40.1701547 Z"
							id="path-6-compact"
						></path>
						<path
							d="M33.1888495,9.99130219 L49.5638918,0 C51.6531839,3.06861754 53.4097881,6.04221972 54.8337051,8.92080533 C56.2576215,11.7993916 58.0608092,16.4382107 60.2432675,22.8372621 L41.7323502,29.9739066 C40.6164384,25.6919204 39.4298411,22.1235981 38.1725583,19.2689398 C36.9152749,16.4142821 35.2540388,13.3217361 33.1888495,9.99130219 Z"
							id="path-6-explosed"
							style="visibility: hidden;"
						></path>
					</g>
					<g
						id="square-7"
						transform="translate(103.440358, 124.177614)"
						fill="#045C76"
					>
						<path
							d="M0.726041954,41.5281031 L18.9361418,28.015296 C22.5235103,31.7246938 25.2140365,34.6392212 27.0077211,36.7588774 C28.801405,38.8785336 30.8940373,41.7930603 33.2856158,45.5024582 L13.5550887,56.6306532 C11.7614041,53.9810831 9.66877257,51.331513 7.27719333,48.6819429 C4.88561485,46.0323728 2.70189722,43.6477593 0.726041954,41.5281031 Z"
							id="path-7-compact"
						></path>
						<path
							d="M4.90661627,12.1322953 L19.3627087,0 C22.2105421,3.33043386 24.346417,5.94720376 25.770334,7.85030908 C27.1942503,9.75341441 28.855487,12.3701837 30.7540422,15.7006176 L15.0909583,25.6919204 C13.6670413,23.3130389 12.0058052,20.9341574 10.1072495,18.5552758 C8.20869428,16.1763943 6.47514948,14.0354006 4.90661627,12.1322953 Z"
							id="path-7-explosed"
							style="visibility: hidden;"
						></path>
					</g>
					<g
						id="square-8"
						transform="translate(76.907261, 94.917371)"
						fill="#07A1E2"
					>
						<path
							d="M1.75438596,16.4142827 L12.4337615,1.27648379e-11 C19.0787062,4.28198683 24.2997342,8.08819748 28.0968458,11.4186313 C31.8939568,14.7490658 36.4030268,19.031052 41.6240548,24.2645912 L28.0968458,37.8242163 C24.2997342,34.0180056 20.2653035,30.211795 15.9935532,26.4055849 C11.7218032,22.5993743 6.97541405,19.2689404 1.75438596,16.4142827 Z"
							id="path-8-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.610146937,45.087158 L14.0627792,26.8051239 C22.4333059,31.5743502 29.0101482,35.8136626 33.7933067,39.5230605 C38.5764644,43.232459 44.2564652,48.0016847 50.8333076,53.8307387 L33.7933067,68.9332888 C29.0101482,64.6939764 23.9280428,60.454664 18.5469897,56.2153522 C13.1659371,51.9760398 7.18698943,48.2666419 0.610146937,45.087158 Z"
							id="path-8-compact"
						></path>
					</g>
					<g
						id="square-9"
						transform="translate(58.367515, 83.498740)"
						fill="#045C76"
					>
						<path
							d="M1.07125612,17.8416113 L8.19083981,-1.45519152e-11 C11.9879511,1.42732854 14.8357846,2.61676929 16.7343403,3.56832226 C18.6328959,4.51987461 21.4807294,5.94720376 25.2778408,7.85030908 L16.0223819,24.9782558 C13.1745484,23.5509266 10.8013539,22.3614859 8.90279819,21.4099335 C7.00424252,20.4583806 4.39372854,19.2689398 1.07125612,17.8416113 Z"
							id="path-9-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M-0.00404496824,43.1298369 L11.6295026,17.3535944 C17.8340614,19.4156932 22.4874805,21.1341094 25.5897599,22.5088429 C28.6920392,23.8835755 33.3454583,25.9456752 39.5500171,28.6951413 L24.4264051,53.4403339 C19.772986,51.3782341 15.8951368,49.659818 12.7928574,48.2850854 C9.69057803,46.9103519 5.42494396,45.1919357 -0.00404496824,43.1298369 Z"
							id="path-9-compact"
						></path>
					</g>
					<g
						id="square-10"
						transform="translate(2.559792, 74.934767)"
						fill="#10A1C5"
					>
						<path
							d="M29.8245614,18.5552758 L32.6723949,1.27648379e-11 C36.9441451,0.475776179 41.2158954,1.18944075 45.4876456,2.14099372 C49.7593958,3.09254608 53.7938265,4.51987522 57.5909379,6.42297994 L51.1833126,24.2645912 C47.8608401,22.8372627 44.3010483,21.6478219 40.503937,20.696269 C36.7068257,19.7447166 33.1470338,19.031052 29.8245614,18.5552758 Z"
							id="path-10-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.552508142,46.6216779 L7.69632714,5.45750594 C18.4120558,6.51299725 29.1277845,8.09623489 39.843513,10.2072189 C50.5592416,12.3182015 60.6796519,15.4846768 70.204744,19.706642 L54.1311512,59.2875764 C45.7966956,56.1211025 36.8669217,53.4823735 27.3418297,51.3713895 C17.8167375,49.2604069 8.88696366,47.6771693 0.552508142,46.6216779 Z"
							id="path-10-compact"
						></path>
					</g>
					<g
						id="square-11"
						transform="translate(8.255459, 39.251545)"
						fill="#07A1E2"
					>
						<path
							d="M29.8245614,19.2689401 L32.6723949,0 C36.4695062,0.475776301 39.5546591,0.951552601 41.9278537,1.4273289 C44.3010483,1.9031052 47.6235207,2.8546578 51.8952709,4.28198671 L46.9115623,22.8372623 C43.5890899,21.88571 40.7412564,21.1720453 38.3680618,20.696269 C35.9948673,20.2204927 33.1470338,19.7447164 29.8245614,19.2689401 Z"
							id="path-11-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.819963708,49.6763978 L4.41214911,28.196853 C9.20172956,28.7272121 13.0932637,29.2575713 16.0867516,29.7879304 C19.0802394,30.3182895 23.2711223,31.3790078 28.6594004,32.9700852 L22.373076,53.6540913 C18.1821931,52.5933733 14.5900077,51.7978343 11.5965198,51.2674752 C8.60303207,50.7371161 5.01084667,50.2067569 0.819963708,49.6763978 Z"
							id="path-11-compact"
						></path>
					</g>
					<g
						id="square-12"
						transform="translate(17.291081, 49.956511)"
						fill="#2284A5"
					>
						<path
							d="M42.8596491,17.841611 L48.5553161,0 C53.7763441,1.4273289 59.94665,3.80621041 67.0662338,7.13664445 C74.1858176,10.4670786 80.118804,13.5596248 84.8651932,16.4142824 L74.8977761,32.8285646 C69.6767477,29.9739069 64.6930392,27.357137 59.94665,24.9782555 C55.2002609,22.599374 49.5045939,20.2204925 42.8596491,17.841611 Z"
							id="path-12-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.0513182415,44.7683175 L8.99877929,19.9852547 C17.2006187,21.9678998 26.8937015,25.2723082 38.0780279,29.8984799 C49.2623545,34.5246517 58.5826265,38.820383 66.0388441,42.7856729 L50.3807873,65.5860907 C42.1789475,61.6208008 34.3499191,57.9859512 26.8937015,54.6815428 C19.437484,51.3771343 10.4900228,48.0727259 0.0513182415,44.7683175 Z"
							id="path-12-compact"
						></path>
					</g>
					<g
						id="square-13"
						transform="translate(67.420941, 74.934767)"
						fill="#045C76"
					>
						<path
							d="M26.9037902,15.7006182 L36.8712073,0 C43.5161523,3.80621065 49.211819,7.85030908 53.9582082,12.1322959 C58.7045974,16.4142827 63.2136668,20.696269 67.4854172,24.9782558 L53.9582082,38.5378802 C49.211819,33.304341 44.7027496,29.0223542 40.4309992,25.6919204 C36.1592488,22.3614859 31.6501793,19.031052 26.9037902,15.7006182 Z"
							id="path-13-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.0432941099,36.591046 L14.7721696,16.0760647 C24.5914202,21.0493937 33.0079203,26.3335553 40.0216706,31.9285503 C47.0354209,37.5235453 53.6984833,43.1185395 60.0108588,48.7135345 L40.0216706,66.4310179 C33.0079203,59.5926911 26.3448578,53.9976961 20.0324823,49.6460338 C13.7201069,45.2943707 7.05704443,40.9427083 0.0432941099,36.591046 Z"
							id="path-13-compact"
						></path>
					</g>
					<g
						id="square-14"
						transform="translate(102.978584, 94.203707)"
						fill="#07A1E2"
					>
						<path
							d="M31.9277744,13.5596244 L46.8789004,0 C50.6760114,3.80621004 54.7104427,8.80186144 58.9821924,14.986953 C63.2539428,21.1720451 66.8137347,27.1192489 69.6615681,32.8285643 L52.5745672,42.1062019 C48.7774562,35.4453342 45.6923031,30.4496828 43.3191085,27.1192489 C40.9459139,23.7888144 37.1488024,19.2689398 31.9277744,13.5596244 Z"
							id="path-14-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.360945691,42.4701266 L21.0849288,25.8539159 C26.348162,30.518115 31.9403485,36.6398772 37.8614858,44.219201 C43.782624,51.7985255 48.7169056,59.0863373 52.6643308,66.0826363 L28.979779,77.4516224 C23.7165458,69.2892738 19.4401682,63.1675116 16.1506471,59.0863373 C12.861126,55.0051623 7.59789193,49.4664256 0.360945691,42.4701266 Z"
							id="path-14-compact"
						></path>
					</g>
					<g
						id="square-15"
						transform="translate(112.617096, 141.305560)"
						fill="#2284A5"
					>
						<path
							d="M33.6805967,8.56397366 L50.7675976,-1.45519152e-11 C53.1407921,3.80621065 54.8020282,6.89875612 55.7513058,9.27763762 C56.700584,11.6565191 57.6498616,14.5111768 58.5991398,17.8416113 L40.8001804,24.9782558 C39.8509022,22.1235981 38.9016246,19.5068282 37.9523471,17.1279467 C37.0030689,14.7490652 35.5791525,11.8944075 33.6805967,8.56397366 Z"
							id="path-15-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.477914011,39.5878179 L22.8044306,29.6933582 C25.9053357,34.090896 28.0759691,37.6638947 29.3163309,40.4123556 C30.5566934,43.1608166 31.7970551,46.4589696 33.0374176,50.3068153 L9.78062933,58.5521982 C8.54026681,55.2540452 7.29990509,52.2307379 6.05954336,49.4822769 C4.81918084,46.733816 2.95863826,43.435663 0.477914011,39.5878179 Z"
							id="path-15-compact"
						></path>
					</g>
					<g
						id="square-16"
						transform="translate(127.950748, 125.604943)"
						fill="#045C76"
					>
						<path
							d="M66.0481556,8.56397305 L83.847115,1.45519152e-11 C85.2710314,2.3788815 86.6949483,4.99565079 88.1188653,7.85030847 C89.5427817,10.7049662 90.729379,13.5596244 91.6786572,16.4142821 L72.4557808,23.5509266 C71.5065032,21.1720451 70.5572251,18.7931636 69.6079475,16.4142821 C68.6586699,14.0354006 67.4720726,11.4186307 66.0481556,8.56397305 Z"
							id="path-16-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.410714787,40.0294688 L37.7177886,24.1571873 C40.7023537,28.5661546 43.6869201,33.4160179 46.6714865,38.7067784 C49.6560517,43.9975389 52.1431899,49.2883005 54.1329013,54.579061 L13.8412611,67.8059628 C11.851551,63.3969956 9.86183963,58.9880283 7.87212954,54.579061 C5.88241945,50.1700937 3.3952812,45.3202293 0.410714787,40.0294688 Z"
							id="path-16-compact"
						></path>
					</g>
					<g
						id="square-17"
						transform="translate(128.374664, 74.934767)"
						fill="#2284A5"
					>
						<path
							d="M67.7601141,11.4186313 L82.7112401,1.27648379e-11 C86.9829905,5.23353919 90.5427823,10.2291906 93.3906157,14.9869536 C96.2384491,19.7447166 99.3236022,24.9782558 102.646074,30.6875712 L85.5590735,39.2515448 C82.7112401,33.5422294 80.1007258,28.7844664 77.7275312,24.9782558 C75.3543372,21.1720451 72.0318645,16.6521705 67.7601141,11.4186313 Z"
							id="path-17-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.333040392,59.4898081 L19.8813024,46.2771302 C25.4665203,52.3329407 30.1208683,58.1134875 33.8443466,63.6187699 C37.5678249,69.1240523 41.6015934,75.1798628 45.9456513,81.7862014 L23.6047807,91.69571 C19.8813024,85.0893714 16.4681135,79.584089 13.3652148,75.1798628 C10.2623169,70.7756366 5.91825823,65.5456186 0.333040392,59.4898081 Z"
							id="path-17-compact"
						></path>
					</g>
					<g
						id="square-18"
						transform="translate(102.717493, 50.670176)"
						fill="#07A1E2"
					>
						<path
							d="M40.7323667,14.2732888 L52.8356587,0 C58.0566867,4.28198671 62.5657568,8.3260852 66.3628677,12.1322957 C70.1599793,15.9385058 74.19441,20.6962688 78.4661604,26.4055847 L63.5150344,38.5378801 C59.243284,32.8285647 55.6834921,28.5465779 52.8356587,25.6919202 C49.9878254,22.8372625 45.9533947,19.0310518 40.7323667,14.2732888 Z"
							id="path-18-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M-0.000270816789,56.7731972 L15.2396622,40.8745924 C21.8137509,45.6441739 27.4913737,50.1487786 32.2725288,54.3884067 C37.0536847,58.6280343 42.1336624,63.9275693 47.5124625,70.2870117 L28.6866623,83.8008255 C23.3078621,77.4413838 18.8255287,72.6718021 15.2396622,69.4920813 C11.6537956,66.3123604 6.57381797,62.0727322 -0.000270816789,56.7731972 Z"
							id="path-18-compact"
						></path>
					</g>
					<g
						id="square-19"
						transform="translate(68.635515, 40.678873)"
						fill="#10A1C5"
					>
						<path
							d="M29.2490089,17.1279467 L38.5044676,0 C45.6240513,3.80621041 51.082399,7.13664445 54.87951,9.99130225 C58.6766215,12.8459601 63.4230107,16.4142823 69.1186775,20.6962688 L57.0153854,35.6832224 C51.3197187,31.4012356 47.0479683,28.3086895 44.2001349,26.4055848 C41.3523009,24.5024795 36.3685927,21.4099334 29.2490089,17.1279467 Z"
							id="path-19-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.921341779,46.0523294 L12.5834712,26.9873162 C21.5543401,31.2239858 28.4320065,34.9310717 33.2164695,38.1085739 C38.0009333,41.2860762 43.9815126,45.2579539 51.1582075,50.0242071 L35.9077307,66.706094 C28.7310358,61.9398405 23.3485142,58.4975463 19.7601668,56.379212 C16.1718186,54.260877 9.89221077,50.8185828 0.921341779,46.0523294 Z"
							id="path-19-compact"
						></path>
					</g>
					<g
						id="square-20"
						transform="translate(32.513063, 16.414282)"
						fill="#07A1E2"
					>
						<path
							d="M33.3333333,18.5552757 L38.317042,0 C43.53807,1.4273289 49.233737,3.3304341 55.4040428,5.70931561 C61.5743485,8.08819711 67.2700159,10.7049667 72.4910438,13.5596245 L63.9475431,30.6875712 C58.2518763,27.8329135 53.0308483,25.454032 48.2844592,23.5509268 C43.53807,21.6478216 38.5543614,19.9826045 33.3333333,18.5552757 Z"
							id="path-20-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.0346641873,55.7542015 L6.3083146,35.0841731 C12.8807102,36.6741753 20.0505964,38.7941782 27.817973,41.4441818 C35.5853494,44.0941855 42.755236,47.0091895 49.3276315,50.1891939 L38.5728019,69.26922 C31.4029161,66.0892157 24.8305205,63.4392121 18.8556153,61.3192091 C12.8807102,59.1992062 6.60705985,57.3442037 0.0346641873,55.7542015 Z"
							id="path-20-compact"
						></path>
					</g>
					<g
						id="square-21"
						transform="translate(12.908698, 0.000000)"
						fill="#045C76"
					>
						<path
							d="M31.5789474,19.2689401 L34.4267809,0 C38.6985311,0.475776298 41.783684,0.951552595 43.6822397,1.4273289 C45.5807954,1.90310519 48.4286289,2.61676964 52.2257401,3.56832224 L47.2420316,22.8372623 C43.9195591,21.8857097 41.3090452,21.1720453 39.4104895,20.696269 C37.5119338,20.2204927 34.9014198,19.7447164 31.5789474,19.2689401 Z"
							id="path-21-explosed"
							style="visibility: hidden;"
						></path>
						<path
							d="M0.605880739,67.6305488 L4.18587891,46.1527427 C9.55587617,46.6830589 13.4342074,47.2133751 15.8208729,47.7436913 C18.2075383,48.2740075 21.7875365,49.0694818 26.5608673,50.1301142 L20.2958706,71.6079204 C16.1192061,70.547288 12.8375412,69.7518137 10.4508757,69.2214974 C8.06421026,68.6911812 4.78254527,68.160865 0.605880739,67.6305488 Z"
							id="path-21-compact"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
</div>
