<nav
	*ngIf="!loading; else skeleton"
	class="h-100 d-flex flex-column justify-content-start"
	ngClass="{{ device }}"
>
	<div
		class="d-none d-xl-flex justify-content-center align-items-center logo-container"
	>
		<a href="/">
			<img src="./assets/svg/logo/logo_NC.svg" alt="Nove Perform" />
		</a>
	</div>
	<div class="flex-grow-1">
		<div class="profil-container" *ngIf="user">
			<!-- Avatar -->
			<a
				class="d-flex flex-column justify-content-center align-items-center"
				(click)="emitCloseDrawer(true)"
			>
				<div class="container-avatar">
					<img
						id="svg-profil"
						src="./assets/svg/general/tourbillon_profil.svg"
						alt="decoration avatar"
					/>
					<nz-avatar
						id="avatar"
						class="respondant-avatar"
						[ngClass]="
							user.avatarExists() ? 'avatarPhoto' : 'avatarIcone'
						"
						[nzSize]="132"
						nzIcon="user"
						[nzSrc]="
							user.avatarExists()
								? user.props?.avatar.getUrl()
								: undefined
						"
					></nz-avatar>
				</div>

				<!-- /Avatar -->
				<p class="mt-3 mb-0">
					{{ 'common_hello' | translate }}<br />{{ user.getLabel() }}
				</p>
			</a>
		</div>

		<ul class="vertical-menu" nz-menu [nzMode]="'vertical'">
			<ng-container *ngFor="let item of navigationSideMenu">
				<li
					nz-menu-item
					class="mt-0 mb-0 px-4 py-3"
					[routerLink]="item.link"
					routerLinkActive="ant-menu-item-selected"
					*appRestrictedToRoles="item.roles"
					[ngClass]="item.class"
					(click)="emitCloseDrawer()"
				>
					<i
						nz-icon
						class="mr-3"
						nzType="{{ item.icon }}"
						nzTheme="outline"
					></i>
					<span>{{ item.label }}</span>
				</li>
			</ng-container>
		</ul>
	</div>

	<ul
		class="vertical-menu"
		nz-menu
		[nzMode]="'vertical'"
		[nzSelectable]="false"
	>
		<li class="mt-0 mb-0 px-4 py-3 logout" nz-menu-item (click)="logout()">
			<i nz-icon nzType="logout" nzTheme="outline" class="mr-3"></i
			>{{ 'header_action-logout' | translate }}
		</li>
	</ul>
</nav>

<!-- TEMPLATE SKELETON -->
<ng-template #skeleton>
	<nav
		class="h-100 d-flex flex-column justify-content-start"
		ngClass="{{ device }}"
	>
		<div
			class="d-none d-xl-flex justify-content-center align-items-center logo-container"
		>
			<app-atom-skeleton
				[height]="67"
				[animated]="true"
			></app-atom-skeleton>
		</div>
		<div class="flex-grow-1">
			<div class="profil-container justify-content-center">
				<div
					class="d-flex flex-column justify-content-center align-items-center"
				>
					<div class="container-avatar">
						<app-atom-skeleton
							[height]="132"
							[circle]="true"
							[animated]="true"
						></app-atom-skeleton>
					</div>
					<p class="mt-3 mb-0 d-block w-100">
						<app-atom-skeleton
							[height]="18"
							[animated]="true"
						></app-atom-skeleton>
					</p>
				</div>
			</div>

			<ul class="vertical-menu" nz-menu [nzMode]="'vertical'">
				<ng-container *ngFor="let item of navigationSideMenu">
					<li
						nz-menu-item
						class="mt-0 mb-0 px-4 py-3"
						[routerLink]="item.link"
						routerLinkActive="ant-menu-item-selected"
						*appRestrictedToRoles="item.roles"
						[ngClass]="item.class"
						(click)="emitCloseDrawer()"
					>
						<i
							nz-icon
							class="mr-3"
							nzType="{{ item.icon }}"
							nzTheme="outline"
						></i>
						<span>{{ item.label }}</span>
					</li>
				</ng-container>
			</ul>
		</div>

		<ul
			class="vertical-menu"
			nz-menu
			[nzMode]="'vertical'"
			[nzSelectable]="false"
		>
			<li
				class="mt-0 mb-0 px-4 py-3 logout"
				nz-menu-item
				(click)="logout()"
			>
				<i nz-icon nzType="logout" nzTheme="outline" class="mr-3"></i
				>{{ 'header_action-logout' | translate }}
			</li>
		</ul>
	</nav>
</ng-template>
<!-- /TEMPLATE SKELETON -->
