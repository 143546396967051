import { Injectable } from '@angular/core';
import { User, UserInterface, UserPayload } from './user';
import { UserSearchParamsInterface } from './user-search-params';
import {
	BaseModelService,
	BaseModelSearchResultInterface,
	BaseModelCountResultInterface,
} from '@app/abstracts';
import { environment } from '@env/environment';

@Injectable()
export class UserService extends BaseModelService<
	User,
	UserInterface,
	UserPayload,
	UserSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'user';
	}
	/** @inheritDoc */
	protected new(object: UserInterface): User {
		return new User(object);
	}

	listSponsor(
		searchParams: UserSearchParamsInterface,
		bypassTransform = false
	): Promise<BaseModelSearchResultInterface<User>> {
		// Start request
		const options = {
			withCredentials: !this.publicList,
			params: bypassTransform
				? searchParams
				: (this.transformSearchParams(searchParams) as {}),
		};
		return this.http
			.get<BaseModelSearchResultInterface<UserInterface>>(
				`${environment.api.uri}/sponsor`,
				options
			)
			.toPromise()
			.then((result) => {
				// Create list from results
				return {
					page: result.page,
					limit: result.limit,
					count: result.count,
					total: result.total,
					items: result.items.map((item): User => this.new(item)),
				};
			});
	}
	/** Count for model */
	countSponsor(
		searchParams: UserSearchParamsInterface,
		bypassTransform = false
	): Promise<number> {
		// Remove unwanted properties
		const params = Object.assign(
			{},
			bypassTransform
				? searchParams
				: this.transformSearchParams(searchParams)
		);
		delete params._page;
		delete params._limit;
		delete params._order;
		delete params._sort;
		// Start request
		const options = {
			withCredentials: !this.publicCount,
			params: params as {},
		};
		return this.http
			.get<BaseModelCountResultInterface>(
				`${environment.api.uri}/sponsor/count`,
				options
			)
			.toPromise()
			.then((result) => result.total);
	}
}
