import { Injectable } from '@angular/core';
import { Formation, FormationInterface, FormationPayload } from './formation';
import { FormationSearchParamsInterface } from './formation-search-params';
import { BaseModelService } from '@app/abstracts';

import { Helpers } from '@app/shared/helpers';
@Injectable()
export class FormationService extends BaseModelService<
	Formation,
	FormationInterface,
	FormationPayload,
	FormationSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'formation';
	}
	/** @inheritDoc */
	protected new(object: FormationInterface): Formation {
		return new Formation(object);
	}

	/** @inheritDoc */
	protected transformSearchParams(
		searchParams: FormationSearchParamsInterface
	): FormationSearchParamsInterface {
		// Search by edge for Start Date
		if (typeof searchParams.start_date__min !== 'undefined') {
			searchParams.start_date__min = Helpers.toMidnightUTC(
				searchParams.start_date__min
			);
		}
		if (typeof searchParams.start_date__max !== 'undefined') {
			searchParams.start_date__max = Helpers.toLastSecondUTC(
				searchParams.start_date__max
			);
		}
		if (typeof searchParams.start_date !== 'undefined') {
			searchParams.start_date__min = Helpers.toMidnightUTC(
				searchParams.start_date
			);
			searchParams.start_date__max = Helpers.toLastSecondUTC(
				searchParams.start_date
			);
			delete searchParams.start_date;
		}
		// Search by edge for End Date
		if (typeof searchParams.end_date__min !== 'undefined') {
			searchParams.end_date__min = Helpers.toMidnightUTC(
				searchParams.end_date__min
			);
		}
		if (typeof searchParams.end_date__max !== 'undefined') {
			searchParams.end_date__max = Helpers.toLastSecondUTC(
				searchParams.end_date__max
			);
		}
		if (typeof searchParams.end_date !== 'undefined') {
			searchParams.end_date__min = Helpers.toMidnightUTC(
				searchParams.end_date
			);
			searchParams.end_date__max = Helpers.toLastSecondUTC(
				searchParams.end_date
			);
			delete searchParams.end_date;
		}
		return searchParams;
	}
}
