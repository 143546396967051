import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
import { Axe, AxeInterface } from '../axe/axe';
export interface AxeWeightInterface extends BaseModelInterface {
	created_at: number | Date;
	axe: string | Axe | AxeInterface;
	weight: number;
}
export interface AxeWeightPayload {
	axe: string | Axe | AxeInterface;
	weight: number;
}
type AxeWeightPayloadKey = keyof AxeWeightPayload;
export class AxeWeight extends BaseModel<AxeWeightInterface, AxeWeightPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of axe has been populated */
	axeExists(): boolean {
		return (
			!!this.props &&
			this.props.axe instanceof Axe &&
			this.props.axe.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: AxeWeightInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.axe === 'object') {
			this.props.axe = new Axe(<AxeInterface>this.props.axe);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): AxeWeightInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.axe instanceof Axe) {
			props.axe = props.axe.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): AxeWeightPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as AxeWeightInterface);
		payload.axe = payload.axe ? this.extractId(payload.axe) : null;
		return payload as AxeWeightPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): AxeWeightPayloadKey[] {
		return ['axe', 'weight'];
	}
}
