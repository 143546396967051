<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
	<!-- Respondant Details -->
	<ng-container *ngIf="respondantDetails; else loadingTpl">
		<div class="container-xl px-xl-3 mb-5 ml-0 mx-auto">
			<div class="row mx-0">
				<div
					class="col-12 px-3 py-3 d-flex flex-row respondant-container"
				>
					<!-- Avatar -->
					<div class="card-wrapper">
						<div class="avatars-container">
							<nz-avatar
								[nzSize]="150"
								nzIcon="user"
								[nzSrc]="respondantDetails.avatar"
								class="respondant-avatar"
							></nz-avatar>
						</div>
					</div>
					<!-- Avatar -->
					<!-- Infos respondant -->
					<div class="infos-respondant-container">
						<p class="respondant-name mb-1">
							{{ respondantDetails.name }}
						</p>
						<p
							class="teams-meta"
							*ngFor="let team of respondantDetails.teams"
						>
							<span class="team-info">
								<span class="separateur">|</span>
								{{ team.name }}
								<span class="team-members">
									<ng-container
										*ngIf="team.members > 1; else player"
									>
										{{
											'team_detail-players'
												| translate
													: { members: team.members }
										}}
									</ng-container>
									<ng-template #player>{{
										'team_detail-player'
											| translate
												: { members: team.members }
									}}</ng-template>
								</span>
							</span>
						</p>
					</div>
					<!-- Infos respondant -->
					<!-- Certifications respondant -->
					<div class="certifications-container">
						<div
							*ngFor="
								let report of respondantDetails.reports;
								let i = index
							"
							class="logo-certification"
						>
							<img
								class="pointer"
								[src]="report.logo"
								alt="logo certifications"
								(click)="scrollToReportModerated(report._id)"
							/>
						</div>
					</div>
					<!-- Certifications respondant -->
				</div>
			</div>
		</div>
	</ng-container>
	<!-- Respondant Details -->
	<!-- Report Moderated Detailled -->
	<ng-container *ngIf="reportsModerated; else loadingTpl">
		<div class="container-xl px-0 mb-5 ml-0 mx-auto">
			<div
				*ngFor="let reportModerated of reportsModerated"
				#reportModerated
			>
				<app-atom-report-moderated-detailled
					[reportModerated]="reportModerated"
				></app-atom-report-moderated-detailled>
			</div>
		</div>
	</ng-container>
	<!-- /Report Moderated Detailled -->
</div>

<!-- Loading -->
<ng-template #loadingTpl>
	<div class="text-center">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
<!-- /Loading -->
