<div class="container">
	<div class="row">
		<div class="col-12 my-3 my-lg-5">
			<form
				nz-form
				class="user-form w-100"
				[formGroup]="form"
				(ngSubmit)="onSubmit()"
				[nzAutoTips]="explainErrorsService.autoTips"
			>
				<!-- First Name -->
				<nz-form-item>
					<nz-form-label>{{
						'user_first-name' | translate
					}}</nz-form-label>
					<nz-form-control>
						<input
							nz-input
							class="w-100"
							type="text"
							[placeholder]="'common_value-string' | translate"
							formControlName="first_name"
						/>
					</nz-form-control>
				</nz-form-item>
				<!-- /First Name -->
				<!-- Last Name -->
				<nz-form-item>
					<nz-form-label>{{
						'user_last-name' | translate
					}}</nz-form-label>
					<nz-form-control>
						<input
							nz-input
							class="w-100"
							type="text"
							[placeholder]="'common_value-string' | translate"
							formControlName="last_name"
						/>
					</nz-form-control>
				</nz-form-item>
				<!-- /Last Name -->
				<!-- Email -->
				<nz-form-item>
					<nz-form-label>{{
						'user_email' | translate
					}}</nz-form-label>
					<nz-form-control>
						<input
							nz-input
							class="w-100"
							type="email"
							[placeholder]="'common_value-string' | translate"
							formControlName="email"
						/>
					</nz-form-control>
				</nz-form-item>
				<!-- /Email -->
				<!-- Password -->
				<nz-form-item>
					<nz-form-label>{{
						'user_password' | translate
					}}</nz-form-label>
					<nz-form-control>
						<input
							nz-input
							class="w-100"
							type="password"
							autocomplete="new-password"
							[placeholder]="'common_value-string' | translate"
							formControlName="password"
						/>
					</nz-form-control>
				</nz-form-item>
				<!-- /Password -->
				<!-- Role -->
				<nz-form-item>
					<nz-form-label>{{ 'user_role' | translate }}</nz-form-label>
					<nz-form-control>
						<input
							nz-input
							class="w-100"
							type="text"
							[placeholder]="'common_value-string' | translate"
							formControlName="role"
						/>
					</nz-form-control>
				</nz-form-item>
				<!-- /Role -->
				<!-- Coachs -->
				<nz-form-item>
					<nz-form-label>{{
						'user_coachs' | translate
					}}</nz-form-label>
					<nz-form-control [nzValidateStatus]="form.get('coachs')">
						<hpf-user-select
							[controlName]="'coachs'"
							[formGroup]="form"
							[(model)]="user.props.coachs"
							[multiple]="true"
							[nullable]="true"
							[filterEnabled]="true"
							[placeholder]="
								'user_common_search-placeholder' | translate
							"
							[emptyLabel]="'common_empty' | translate"
						>
						</hpf-user-select>
					</nz-form-control>
				</nz-form-item>
				<!-- /Coachs -->
				<!-- Phone Number -->
				<nz-form-item>
					<nz-form-label>{{
						'user_phone-number' | translate
					}}</nz-form-label>
					<nz-form-control>
						<input
							nz-input
							class="w-100"
							type="text"
							[placeholder]="'common_value-string' | translate"
							formControlName="phone_number"
						/>
					</nz-form-control>
				</nz-form-item>
				<!-- /Phone Number -->
				<!-- Birthdate -->
				<nz-form-item>
					<nz-form-label>{{
						'user_birthdate' | translate
					}}</nz-form-label>
					<nz-form-control>
						<nz-date-picker
							class="w-100"
							[nzPlaceHolder]="'common_value-date' | translate"
							formControlName="birthdate"
						></nz-date-picker>
					</nz-form-control>
				</nz-form-item>
				<!-- /Birthdate -->
				<!-- Reports -->
				<nz-form-item>
					<nz-form-label>{{
						'user_reports' | translate
					}}</nz-form-label>
					<nz-form-control [nzValidateStatus]="form.get('reports')">
						<hpf-report-select
							[controlName]="'reports'"
							[formGroup]="form"
							[(model)]="user.props.reports"
							[multiple]="true"
							[nullable]="true"
							[filterEnabled]="true"
							[placeholder]="
								'report_common_search-placeholder' | translate
							"
							[emptyLabel]="'common_empty' | translate"
						>
						</hpf-report-select>
					</nz-form-control>
				</nz-form-item>
				<!-- /Reports -->
				<!-- Avatar -->
				<nz-form-item>
					<nz-form-label>{{
						'user_avatar' | translate
					}}</nz-form-label>
					<nz-form-control [nzValidateStatus]="form.get('avatar')">
						<hpf-image-select
							[controlName]="'avatar'"
							[formGroup]="form"
							[(model)]="user.props.avatar"
							[nullable]="true"
							[filterEnabled]="true"
							[placeholder]="
								'image_common_search-placeholder' | translate
							"
							[emptyLabel]="'common_empty' | translate"
						>
						</hpf-image-select>
					</nz-form-control>
				</nz-form-item>
				<!-- /Avatar -->
				<button
					nz-button
					nzType="primary"
					[disabled]="form.invalid"
					[nzLoading]="saving"
				>
					{{ 'common_save' | translate }}
				</button>
			</form>
		</div>
	</div>
</div>
