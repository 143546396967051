import { Component, OnInit, Input } from '@angular/core';
import { ReportModerated } from '@app/models/report-moderated';
import { AppModalService } from '@app/services/app-modal.service';
import { User } from '@app/models/user';
import { TranslateService } from '@ngx-translate/core';
import { FormationThematique } from '@app/models/formation-thematique';
import { Image } from '@app/models/image';
import { Formation } from '@app/models/formation';
import { Color } from '@app/models/color';

interface ReportCardsInterface {
	id: string;
	nameFormation: string;
	avatarSponsor: string;
	nameSponsor: string;
	color: string;
	date: Date;
}

@Component({
	selector: 'app-atom-report-moderated-card',
	templateUrl: './atom-report-moderated-card.component.html',
	styleUrls: ['./atom-report-moderated-card.component.less'],
})
export class AtomReportModeratedCardComponent implements OnInit {
	@Input() reportModerated: ReportModerated;
	@Input() inline = false;

	respondant: User;
	reportCard: ReportCardsInterface;

	constructor(
		public appModalService: AppModalService,
		public translateService: TranslateService
	) {}

	ngOnInit() {
		this.respondant = this.getRespondant();

		if (!this.reportModerated.props.formation_thematique) return;

		this.reportCard = {
			id: (<FormationThematique>(
				this.reportModerated.props.formation_thematique
			)).getId(),
			nameFormation: (<FormationThematique>(
				this.reportModerated.props.formation_thematique
			)).getLabel(),
			avatarSponsor: (<FormationThematique>(
				this.reportModerated.props.formation_thematique
			)).formationExists()
				? (<User>(
						(<Formation>(
							(<FormationThematique>(
								this.reportModerated.props.formation_thematique
							)).props.formation
						)).props.sponsor
				  )).avatarExists()
					? (<Image>(
							(<User>(
								(<Formation>(
									(<FormationThematique>(
										this.reportModerated.props
											.formation_thematique
									)).props.formation
								)).props.sponsor
							)).props.avatar
					  )).getUrl()
					: null
				: null,
			nameSponsor: (<FormationThematique>(
				this.reportModerated.props.formation_thematique
			)).formationExists()
				? (<User>(
						(<Formation>(
							(<FormationThematique>(
								this.reportModerated.props.formation_thematique
							)).props.formation
						)).props.sponsor
				  )).getLabel()
				: null,
			color: (<Color>(
				(<FormationThematique>(
					this.reportModerated.props.formation_thematique
				)).props.color
			)).props.main,
			date: <Date>(
				(<FormationThematique>(
					this.reportModerated.props.formation_thematique
				)).props.start_date
			),
		};
	}

	getRespondant(): User {
		return this.reportModerated.getFirstOwner();
	}
}
