import {
	Directive,
	Input,
	OnDestroy,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SessionService } from '@app/services/session.service';

@Directive({
	selector: '[appRestrictedToRoles]',
})
export class RestrictedToRolesDirective implements OnDestroy {
	private subscription: Subscription;

	@Input()
	set appRestrictedToRoles(roles: string[]) {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.sessionService.getSelf().subscribe((self) => {
			if (self && roles.includes(self.getRole())) {
				this.viewContainer.createEmbeddedView(this.templateRef);
			} else {
				this.viewContainer.clear();
			}
		});
	}

	constructor(
		private viewContainer: ViewContainerRef,
		private templateRef: TemplateRef<any>,
		private sessionService: SessionService
	) {}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
