import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
import { Survey, SurveyInterface } from '../survey/survey';
import { Axe, AxeInterface } from '../axe/axe';
import { Color, ColorInterface } from '../color/color';
export interface AxeSurveyInterface extends BaseModelInterface {
	created_at: number | Date;
	survey?: string | Survey | SurveyInterface;
	axe: string | Axe | AxeInterface;
	color: string | Color | ColorInterface;
}
export interface AxeSurveyPayload {
	survey?: string | Survey | SurveyInterface;
	axe: string | Axe | AxeInterface;
	color: string | Color | ColorInterface;
}
type AxeSurveyPayloadKey = keyof AxeSurveyPayload;
export class AxeSurvey extends BaseModel<AxeSurveyInterface, AxeSurveyPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of survey has been populated */
	surveyExists(): boolean {
		return (
			!!this.props &&
			this.props.survey instanceof Survey &&
			this.props.survey.exists()
		);
	}
	/** Denotes if the instance of axe has been populated */
	axeExists(): boolean {
		return (
			!!this.props &&
			this.props.axe instanceof Axe &&
			this.props.axe.exists()
		);
	}
	/** Denotes if the instance of color has been populated */
	colorExists(): boolean {
		return (
			!!this.props &&
			this.props.color instanceof Color &&
			this.props.color.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: AxeSurveyInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (
			this.props.survey !== null &&
			typeof this.props.survey === 'object'
		) {
			this.props.survey = new Survey(<SurveyInterface>this.props.survey);
		}
		if (typeof this.props.axe === 'object') {
			this.props.axe = new Axe(<AxeInterface>this.props.axe);
		}
		if (typeof this.props.color === 'object') {
			this.props.color = new Color(<ColorInterface>this.props.color);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): AxeSurveyInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.survey === 'undefined') {
			props.survey = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.survey !== null && props.survey instanceof Survey) {
			props.survey = props.survey.toObject();
		}
		if (props.axe instanceof Axe) {
			props.axe = props.axe.toObject();
		}
		if (props.color instanceof Color) {
			props.color = props.color.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): AxeSurveyPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as AxeSurveyInterface);
		payload.survey = payload.survey ? this.extractId(payload.survey) : null;
		payload.axe = payload.axe ? this.extractId(payload.axe) : null;
		payload.color = payload.color ? this.extractId(payload.color) : null;
		return payload as AxeSurveyPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): AxeSurveyPayloadKey[] {
		return ['survey', 'axe', 'color'];
	}
}
