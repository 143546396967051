import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import { PageInterface, PageService } from '@app/services/page.service';
import { NavigationService } from '@app/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-molecule-password-reset',
	templateUrl: './molecule-password-reset.component.html',
	styleUrls: ['./molecule-password-reset.component.less'],
})
export class MoleculePasswordResetComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	routeSubscription: Subscription;
	params: {
		id?: string;
		code?: string;
	} = {};

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		private _route: ActivatedRoute
	) {
		super(pageService, translate);
	}

	ngOnInit() {
		super.ngOnInit();
		this.refreshPageData();

		this.routeSubscription = this._route.params.subscribe((params) => {
			// Check params
			const { id, code } = params;
			if (typeof id === 'undefined' || typeof code === 'undefined') {
				throw new Error('Cannot find params from route');
			}
			// Bind params
			this.params.id = id;
			this.params.code = code;
		});
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.routeSubscription.unsubscribe();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-reset_password'),
			hideMenu: true,
			hideTopBar: true,
		};
	}
}
