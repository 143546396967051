import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import {
	FilterFormationType,
	FilterSelectType,
} from '@app/atoms/formation/atom-formation-filter-panel/atom-formation-filter-panel.component';
import {
	Formation,
	FormationAdministrativeStatusValues,
	FormationSearchParams,
	FormationService,
	FormationSessionStatusValues,
} from '@app/models/formation';

import { PageInterface, PageService } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'app-molecule-formation-list-admin',
	templateUrl: './molecule-formation-list-admin.component.html',
	styleUrls: ['./molecule-formation-list-admin.component.less'],
})
export class MoleculeFormationListAdminComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'list';
	private unsubscribe$ = new Subject<void>();
	searchParams: FormationSearchParams;
	formations: Formation[] = [];
	numberTotalFormation: number;
	loading = false;
	limit = 25;
	currentPageIndex = 1;

	orders = ['asc', 'desc'];

	// Search bar
	searchInput?: string;
	inputSubject: Subject<void> = new Subject();
	paginationSubject = new BehaviorSubject<number>(0);

	isPanelOpen = false;

	states?: FilterSelectType[] = FormationSessionStatusValues.map((value) => ({
		value,
		label: `formation-state-${value}`,
	}));

	statesAdmin?: FilterSelectType[] = FormationAdministrativeStatusValues.map(
		(value) => ({
			value,
			label: `formation-state-admin-${value}`,
		})
	);

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		public formationService: FormationService
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();

		this.searchParams = new FormationSearchParams({
			_page: this.paginationSubject.getValue(),
			_limit: this.limit,
			_order: 'asc',
			_sort: 'name',
			_populate: ['color', 'themes', 'sponsor'],
		});

		this.inputSubject
			.pipe(takeUntil(this.unsubscribe$), debounceTime(500))
			.subscribe(() => {
				this.searchFormations();
			});

		this.paginationSubject
			.asObservable()
			.pipe(
				takeUntil(this.unsubscribe),
				tap((newIndex) => {
					const realIndex = newIndex > 1 ? newIndex - 1 : 0;
					this.searchParams.props._page = realIndex;
					this.searchParams.next();
				})
			)
			.subscribe();

		this.searchParams
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(async () => {
				await this.getFormationList();
			});
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	trackById(_: number, formation: Formation): string {
		return formation.getId();
	}

	async getFormationList() {
		this.loading = true;
		await this.formationService
			.listAsAdmin(this.searchParams.toObject())
			.then((res) => {
				this.formations = res.items;
				this.numberTotalFormation = res.total;
			});
		this.loading = false;
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-formation_list'),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-formation_list'),
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}

	private searchFormations(): void {
		this.searchParams.props._page = 0;
		this.searchParams.props.name = this.searchInput;
		this.searchParams.next();
	}

	handleFilterToggle() {
		this.isPanelOpen = !this.isPanelOpen;
	}

	async handleFilter(filters: FilterFormationType) {
		this.createParamsFromFilters(filters);
		setTimeout(() => {
			this.closePanel();
		}, 350);
	}

	closePanel() {
		this.isPanelOpen = false;
	}

	createParamsFromFilters(filters: FilterFormationType) {
		if (filters.state) {
			this.searchParams.props.session_status = filters.state;
		} else {
			delete this.searchParams.props.session_status;
		}
		if (filters.stateAdmin) {
			this.searchParams.props.administrative_status = filters.stateAdmin;
		} else {
			delete this.searchParams.props.administrative_status;
		}
		if (filters.sponsor) {
			this.searchParams.props.sponsor = filters.sponsor.props._id;
		} else {
			delete this.searchParams.props.sponsor;
		}
		if (filters.dateRange) {
			this.searchParams.props.date_min = filters.dateRange[0] as Date;
			this.searchParams.props.date_max = filters.dateRange[1] as Date;
		} else {
			delete this.searchParams.props.date_min;
			delete this.searchParams.props.date_max;
		}
		this.searchParams.props._page = 0;
		this.searchParams.next();
	}

	handleNextPage(index: number) {
		this.currentPageIndex = index;
		this.paginationSubject.next(index);
	}
}
