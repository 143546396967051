import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
export interface DocumentPedagogiqueInterface extends BaseModelInterface {
	created_at: number | Date;
	label: string;
	uri: string;
	status: any;
}
export interface DocumentPedagogiquePayload {
	label: string;
	uri: string;
	status: any;
}
type DocumentPedagogiquePayloadKey = keyof DocumentPedagogiquePayload;
export class DocumentPedagogique extends BaseModel<
	DocumentPedagogiqueInterface,
	DocumentPedagogiquePayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.label}`;
	}
	/** Populate the current instance from an object */
	fromObject(object: DocumentPedagogiqueInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): DocumentPedagogiqueInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): DocumentPedagogiquePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as DocumentPedagogiqueInterface);
		return payload as DocumentPedagogiquePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): DocumentPedagogiquePayloadKey[] {
		return ['label', 'uri', 'status'];
	}
}
