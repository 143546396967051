import { OnInit, Input, Component } from '@angular/core';
import {
	ReportModerated,
	ReportModeratedWeightsInterface,
} from '@app/models/report-moderated';
import { Color, ColorService, ColorSearchParams } from '@app/models/color';
import { GraphModerated } from '@app/models/graph-moderated';
import { Graph } from '@app/models/graph';
import { AxeSurvey } from '@app/models/axe-survey';
import { Axe } from '@app/models/axe';
import { User } from '@app/models/user';
import { Report } from '@app/models/report';

interface GraphModeratedInterface {
	type: string;
	title: string;
	data?: { x: string; y: number }[];
	dataGrouped?: { x: string; y: number; groupBy: string }[];
	colors: Color[];
	theme: string;
	labelMin?: string;
	labelMax?: string;
	max?: number;
	nbAxe?: string;
	showAverage?: boolean;
	source: GraphModerated;
}

interface AxeDetailInterface {
	_id: string;
	title: string;
	description: string;
	color: string;
}

@Component({ template: '' })
export abstract class BaseReportModerated implements OnInit {
	@Input() title: string;
	@Input() reportModerated: ReportModerated;
	@Input() allowNotModerated = false;
	@Input() user: User | null;

	private colors: Color[];
	public theme: string;
	private weights: ReportModeratedWeightsInterface;
	public graphDetails: AxeDetailInterface[][];
	graphsModerated: GraphModeratedInterface[];
	respondantId: string;

	constructor(protected colorService: ColorService) {}

	async ngOnInit(): Promise<void> {
		if (this.reportModerated) {
			this.respondantId = this.reportModerated.firstOwnerExists()
				? this.reportModerated.getFirstOwner().getId()
				: <string>this.reportModerated.props.owners[0];

			this.colors = await this.colorService
				.list(new ColorSearchParams({ _limit: 100 }).toObject())
				.then((res) => res.items)
				.catch(() => []);

			this.theme = 'yellow';

			this.weights = this.reportModerated.getWeights(
				this.user ? this.user.props._id : null
			);

			this.graphsModerated = this.reportModerated.props.graphs
				.map((graphModerated) =>
					this.formatGraphModerated(<GraphModerated>graphModerated)
				)
				.filter((gm) => !!gm);

			this.graphDetails = this.reportModerated.props.graphs.map(
				(graphModerated) =>
					this.getGraphDetails(<GraphModerated>graphModerated)
			);
		}
	}

	formatGraphModerated(
		graphModerated: GraphModerated
	): GraphModeratedInterface {
		if (
			!graphModerated ||
			!(graphModerated instanceof GraphModerated) ||
			!graphModerated.graphExists()
		)
			return;

		// If allow not moderated and not moderated, we show all axes
		const showAllAxes =
			this.allowNotModerated && !this.reportModerated.props.moderated;

		const graph = <Graph>graphModerated.props.graph;

		const colors = graph.props.axes.map((axeSurvey) => {
			const axeSurveyId =
				typeof axeSurvey === 'string'
					? axeSurvey
					: (<AxeSurvey>axeSurvey).getId();

			return graphModerated.getAxeColor(
				axeSurveyId,
				this.colors,
				showAllAxes
			);
		});

		const data = graphModerated.getData(this.weights, showAllAxes);

		return {
			source: graphModerated,
			type: graph.props.type,
			title: graph.props.name,
			data: data.map(([axe, weight]) => ({
				x: axe.getLabel(),
				y: weight,
			})),
			dataGrouped: data.map(([axe, weight, survey]) => ({
				x: axe.getLabel(),
				y: weight,
				groupBy: survey ? survey.getLabel() : 'all',
			})),
			colors,
			theme: this.theme,
			max: graph.props.axis_max,
			...(graph.props.type === 'gauge' && {
				labelMin: data[0][0].getLabel(),
				labelMax: data[1][0].getLabel(),
			}),
			nbAxe: this.translateSize(data.length),
			showAverage: graph.props.show_average,
		};
	}

	getGraphDetails(graphModerated: GraphModerated): AxeDetailInterface[] {
		return (<AxeSurvey[]>graphModerated.props.axes).map((axeSurvey) => {
			const axe = <Axe>axeSurvey.props.axe;
			const axeSurveyId =
				typeof axeSurvey === 'string'
					? axeSurvey
					: (<AxeSurvey>axeSurvey).getId();

			return {
				_id: axe.props._id,
				title: axe.props.name,
				description: axe.formatDescription(),
				color: graphModerated.getAxeColor(axeSurveyId, this.colors)
					.props.main,
			};
		});
	}

	translateSize(nbAxe: number): string {
		if (nbAxe < 3) {
			return 'small';
		} else if (nbAxe < 5) {
			return 'medium';
		} else {
			return 'large';
		}
	}
}
