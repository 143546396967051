import { Component } from '@angular/core';
import { EntitySelectComponent } from '@app/abstracts';
import { ErrorService } from '@app/services/error.service';
import { ImageSearchParams } from '../image-search-params';
import { ImageService } from '../image.service';
import { Image } from '../image';
@Component({
	selector: 'hpf-image-select',
	templateUrl:
		'../../../abstracts/entity-select/entity-select.component.html',
})
export class ImageSelectComponent extends EntitySelectComponent<Image> {
	/** Constructor */
	constructor(
		private imageService: ImageService,
		protected errorService: ErrorService
	) {
		super(errorService);
	}
	/** @inheritDoc */
	protected async getList(): Promise<Image[]> {
		const params = new ImageSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		if (this.filterEnabled && this.filterValue) {
			params.props._text = this.filterValue;
		}
		return (await this.imageService.list(params.toObject())).items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<Image> {
		return await this.imageService.get(id);
	}
}
