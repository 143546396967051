<div *ngIf="!user">
	<div
		class="container-xl px-xl-0 mb-5 ml-0 details-formation-thematique-container"
	>
		<div class="row mx-0">
			<div
				class="col-12 px-4 px-md-5 py-3 d-flex flex-row align-items-center"
			>
				<!-- Avatar Sponsor -->
				<div class="mr-3 mr-md-5 container-avatar">
					<nz-avatar
						id="avatar"
						class="respondant-avatar d-md-none"
						[ngClass]="
							thematiqueDetails.avatar_sponsor
								? 'avatarPhoto'
								: 'avatarIcone'
						"
						[nzSize]="120"
						nzIcon="user"
						[nzSrc]="thematiqueDetails.avatar_sponsor"
					></nz-avatar>
					<nz-avatar
						id="avatar"
						class="respondant-avatar d-none d-md-inline-block"
						[ngClass]="
							thematiqueDetails.avatar_sponsor
								? 'avatarPhoto'
								: 'avatarIcone'
						"
						[nzSize]="150"
						nzIcon="user"
						[nzSrc]="thematiqueDetails.avatar_sponsor"
					></nz-avatar>
				</div>
				<!-- Avatar Sponsor -->
				<!-- Infos Thematique Formation -->
				<div class="infos-thematique-container">
					<h2 class="thematique-title mb-1">
						{{ thematiqueDetails.name }}
					</h2>
					<h3 class="thematique-title mb-1">
						Formation : {{ formationName }}
					</h3>
					<p class="thematique-meta mb-1">
						<span class="name-sponsor-formation">{{
							thematiqueDetails.sponsor
						}}</span>
						|
						<span class="number-respondants-formation">
							<ng-container
								*ngIf="
									thematiqueDetails.respondants_max > 1;
									else oneRespondantFormation
								"
							>
								{{
									'thematique_formation_detail-number-respondants'
										| translate
											: {
													number:
														thematiqueDetails.respondants_max
											  }
								}}
							</ng-container>
							<ng-template #oneRespondantFormation>
								{{
									'thematique_formation_detail-number-respondant'
										| translate
											: {
													number:
														thematiqueDetails.respondants_max
											  }
								}}
							</ng-template>
						</span>
						<ng-container
							*ngIf="
								userLogged.isRespondant() ||
								userLogged.isSponsor()
							"
						>
							|
							<span class="coachs-thematique-formation">
								<span class="separateur">/</span>
								{{ thematiqueDetails.coach }}
							</span>
							<span class="label-formateur">
								({{
									'thematique_formation_detail-label-coach'
										| translate
								}})
							</span>
						</ng-container>
					</p>
				</div>
				<!-- Infos Thematique Formation -->
				<!-- Bandeau couleur -->
				<div
					class="bandeau"
					[ngStyle]="
						colorThematique.props.gradient_min &&
						colorThematique.props.gradient_max
							? {
									background:
										'linear-gradient(180deg, ' +
										colorThematique.props.gradient_min +
										', ' +
										colorThematique.props.gradient_max +
										')'
							  }
							: {
									background: colorThematique.props.main
							  }
					"
				></div>
				<!-- / Bandeau couleur -->
			</div>
		</div>
	</div>

	<div class="container-xl px-xl-0 mb-5 ml-0">
		<div class="row">
			<div class="col-12">
				<div class="table-container">
					<table>
						<thead>
							<tr>
								<th>{{ 'table_head-survey' | translate }}</th>
								<th>{{ 'table_head-state' | translate }}</th>
								<th>{{ 'table_head-date' | translate }}</th>
								<th *ngIf="!userLogged.isRespondant()">
									{{ 'table_head-respondants' | translate }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let survey of surveys; let i = index">
								<td class="title">
									{{ survey.label }}
									<div
										class="bandeau"
										[ngStyle]="
											colorThematique.props
												.gradient_min &&
											colorThematique.props.gradient_max
												? {
														background:
															'linear-gradient(180deg, ' +
															colorThematique
																.props
																.gradient_min +
															', ' +
															colorThematique
																.props
																.gradient_max +
															')'
												  }
												: {
														background:
															colorThematique
																.props.main
												  }
										"
									></div>
								</td>
								<td
									class="state"
									[ngClass]="survey.status"
									[ngSwitch]="survey.status"
								>
									<ng-container *ngSwitchCase="'soon'">
										<span>{{
											'survey-state-soon' | translate
										}}</span>
									</ng-container>
									<ng-container *ngSwitchCase="'todo'">
										<div
											class="d-inline-flex align-items-center justify-content-center survey-todo"
											(click)="
												openSurvey(
													survey.idSurvey,
													thematiqueDetails.idThematique,
													i,
													colorThematique
												)
											"
										>
											{{
												'survey-state-todo' | translate
											}}
										</div>
									</ng-container>
									<ng-container *ngSwitchCase="'done'">
										<span
											class="d-flex align-items-center justify-content-center"
										>
											<i
												nz-icon
												nzType="check-circle"
												nzTheme="fill"
												class="mr-2"
												[style.color]="
													colorThematique.props.main
												"
											></i>
											<span>{{
												'survey-state-done' | translate
											}}</span>
										</span>
									</ng-container>
								</td>
								<td class="date">
									{{
										survey.date_acces
											| date
												: 'dd MMMM yyyy'
												: ''
												: translateService.currentLang
									}}<span>{{
										survey.date_acces | date: "HH 'h' mm "
									}}</span>
								</td>
								<td
									class="respondants"
									*ngIf="!userLogged.isRespondant()"
								>
									{{ survey.survey_completed }} /
									{{ survey.respondants_max }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Report Moderated List -->
<div class="container-xl px-xl-0 mb-5 ml-0">
	<app-report-moderated-list
		*ngIf="userLogged"
		[owner]="userLogged"
		[user]="user"
		[colorThematique]="colorThematique"
		[formationThematique]="formationThematique"
	></app-report-moderated-list>
</div>
<!-- Report Moderated List -->
