import { Injectable } from '@angular/core';
import {
	ReportModerated,
	ReportModeratedInterface,
	ReportModeratedPayload,
} from './report-moderated';
import { ReportModeratedSearchParamsInterface } from './report-moderated-search-params';
import { BaseWalker } from '@app/abstracts/base-walker';
import { ReportModeratedService } from './report-moderated.service';

export class ReportModeratedWalker extends BaseWalker<
	ReportModerated,
	ReportModeratedInterface,
	ReportModeratedPayload,
	ReportModeratedSearchParamsInterface
> {}

@Injectable()
export class ReportModeratedWalkerService {
	constructor(private reportModeratedService: ReportModeratedService) {}

	getWalker(): ReportModeratedWalker {
		return new ReportModeratedWalker(this.reportModeratedService);
	}
}
