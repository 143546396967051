import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import {
	FormationThematique,
	FormationThematiqueService,
} from '@app/models/formation-thematique';
import { PageService, PageInterface } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@app/services/navigation.service';
import { ErrorService } from '@app/services/error.service';
import { SessionService } from '@app/services/session.service';
import { User, UserService } from '@app/models/user';

@Component({
	selector: 'app-molecule-formation-thematique-details',
	templateUrl: './molecule-formation-thematique-details.component.html',
	styleUrls: ['./molecule-formation-thematique-details.component.less'],
})
export class MoleculeFormationThematiqueDetailsComponent
	extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'detail';
	userLogged: User;
	private thematiqueId: string;
	userId: string | null = null;
	thematique: FormationThematique;
	user: User;
	loading = false;

	constructor(
		private sessionService: SessionService,
		protected pageService: PageService,
		protected translate: TranslateService,
		private formationThematiqueService: FormationThematiqueService,
		private route: ActivatedRoute,
		private navigate: NavigationService,
		private errorService: ErrorService,
		private readonly userService: UserService
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();
		this.thematiqueId = this.route.snapshot.params.id;
		if (this.route.snapshot.params.userId) {
			this.userId = this.route.snapshot.params.userId;
		}

		await this.refresh();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	async refresh() {
		this.loading = true;
		this.userLogged = await this.sessionService.getCachedUser();
		this.thematique = await this.getThematique();
		if (this.userId) this.user = await this.getUser();

		this.refreshPageData();
		this.loading = false;
	}

	getThematique(): Promise<FormationThematique> {
		return this.formationThematiqueService
			.get(this.thematiqueId, {
				_populate: [
					'access.survey',
					'linked_surveys.questions.answers',
					'linked_surveys.questions.labels',
					'color',
					'formation.sponsor.avatar',
					'formateur',
					'formation.respondants',
					'access.reports',
				],
			})
			.catch((err) => {
				this.errorService.handle(err);

				this.navigate.go(['/session']);
				return null;
			});
	}

	getUser(): Promise<User> {
		return this.userService.get(this.userId).catch((err) => {
			this.errorService.handle(err);
			this.navigate.go(['/session']);
			return null;
		});
	}

	getPageData(): PageInterface {
		const breadcrumbs: {
			label: string;
			link?: string[];
		}[] = [
			{
				label: this.translate.instant(
					'top_bar-formation_thematique_list'
				),
				link: ['/session/list'],
			},
			{
				label: this.thematique ? this.thematique.getLabel() : null,
				link: [
					`/session/${
						this.thematique ? this.thematique.getId() : null
					}/details`,
				],
			},
		];
		if (this.user)
			breadcrumbs.push({
				label:
					this.user.props.first_name +
					' ' +
					this.user.props.last_name,
			});

		return {
			title: this.translate.instant(
				'page_title-formation_thematique_details',
				{
					thematique: this.thematique
						? this.thematique.getLabel()
						: '',
				}
			),
			breadcrumbs: breadcrumbs,
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
