import { Injectable } from '@angular/core';
import {
	ReportModerated,
	ReportModeratedInterface,
	ReportModeratedPayload,
} from './report-moderated';
import { ReportModeratedSearchParamsInterface } from './report-moderated-search-params';
import { BaseModelService, BaseReadQueryInterface } from '@app/abstracts';
import { environment } from '@env/environment';
@Injectable()
export class ReportModeratedService extends BaseModelService<
	ReportModerated,
	ReportModeratedInterface,
	ReportModeratedPayload,
	ReportModeratedSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'report-moderated';
	}
	/** @inheritDoc */
	protected new(object: ReportModeratedInterface): ReportModerated {
		return new ReportModerated(object);
	}

	/** Get an model from it's id */
	getFromAdmin(
		id: string,
		query: BaseReadQueryInterface = {}
	): Promise<ReportModerated> {
		// Start request
		const options = {
			withCredentials: !this.publicRead,
			params: query as {},
		};
		return this.http
			.get<ReportModeratedInterface>(
				`${environment.api.uri}/admin/report-moderated/${id}`,
				options
			)
			.toPromise()
			.then((result) => this.new(result));
	}
}
