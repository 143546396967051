<div class="container-xl px-xl-0 ml-0 formation-intervention-card">
	<div class="row mx-0">
		<div class="col-12">
			<div class="mr-0 mr-md-5 container-avatar">
				<nz-avatar
					id="avatar"
					class="sponsor-avatar d-inline-block"
					[ngClass]="
						formationTransformed.sponsor.avatarExists()
							? 'avatarPhoto'
							: 'avatarIcone'
					"
					[nzSize]="148"
					nzIcon="user"
					[nzSrc]="
						formationTransformed.sponsor.avatarExists()
							? formationTransformed.avatar
							: ''
					"
				></nz-avatar>
			</div>
			<div class="formation-intervention-card__content">
				<h1 class="formation-intervention-card__title">
					{{ formationTransformed.name }}
				</h1>
				<p class="formation-intervention-card__content__infos">
					<span class="card__sponsor">
						{{ 'common-role-sponsor' | translate }}:
						<strong>
							{{ formationTransformed.sponsor.getLabel() }}
						</strong>
					</span>
					<span class="card__status">
						{{ 'table_head-state' | translate }}:
						<strong>
							{{
								'formation-state-admin-' +
									formationTransformed.status | translate
							}}
						</strong>
					</span>
					<span class="card__date">
						{{ 'common_value-date-start' | translate }}:
						<strong>
							{{
								formationTransformed.start_date
									| date
										: 'dd MMMM yyyy'
										: ''
										: translateService.currentLang
							}}
						</strong>
					</span>
					<span class="card__date">
						{{ 'common_value-date-end' | translate }}:
						<strong>
							{{
								formationTransformed.end_date
									| date
										: 'dd MMMM yyyy'
										: ''
										: translateService.currentLang
							}}
						</strong>
					</span>
					<span class="card__number">
						{{ 'common_number-intervention' | translate }}:
						<strong>
							{{
								'formation-intervention-number'
									| translate
										: {
												numberIntervention: numberIntervention
										  }
							}}
						</strong>
					</span>
				</p>
			</div>
		</div>
	</div>
</div>
