<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
	<!-- Col left -->
	<div class="container-xl ml-0">
		<!-- Counter -->
		<div class="row">
			<div class="col-12">
				<app-atom-skeleton [height]="177" [animated]="true">
				</app-atom-skeleton>
			</div>
		</div>
		<!-- /Counter -->

		<!-- Calendar -->
		<div class="container-xl px-xl-0 mb-5 ml-0 calendar-container">
			<div class="row">
				<div class="col-12 px-0 px-xl-3">
					<h2 class="mb-4">
						{{ 'dashboard_next_formations' | translate }}
					</h2>
					<app-atom-skeleton
						class="d-none d-lg-block"
						[height]="800"
						[animated]="true"
					>
					</app-atom-skeleton>
					<app-atom-skeleton
						class="d-lg-none"
						[height]="500"
						[animated]="true"
					>
					</app-atom-skeleton>
				</div>
			</div>
		</div>
		<!-- Calendar -->
	</div>
	<!-- /Col left -->
</div>
