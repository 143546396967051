import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';

import { ErrorService } from '@app/services/error.service';
import { ExplainErrorsService } from '@app/services/explain-errors.service';
import { UserService, User } from '@app/models/user';
import { SessionService } from '@app/services/session.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import {
	FormateurProfile,
	FormateurProfileService,
} from '@app/models/formateur-profile';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import { PageInterface, PageService } from '@app/services/page.service';

@Component({
	selector: 'app-molecule-coach-details',
	templateUrl: './molecule-coach-details.component.html',
	styleUrls: ['./molecule-coach-details.component.less'],
})
export class MoleculeCoachDetailsComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'detail';
	private USER_KEYS = ['first_name', 'last_name'];

	loading = true;

	currentUser: User;
	coach: User;
	profile: FormateurProfile;
	form: FormGroup;

	genders = ['male', 'female'];

	// Spinners
	saving = false;

	constructor(
		protected pageService: PageService,
		protected translateService: TranslateService,
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private userService: UserService,
		private formateurProfileService: FormateurProfileService,
		private sessionService: SessionService,
		private messageConfirmation: NzMessageService,
		private route: ActivatedRoute
	) {
		super(pageService, translateService);
	}

	async ngOnInit() {
		super.ngOnInit();

		this.loading = true;

		this.currentUser = await this.sessionService.getFreshUser();
		this.coach = await this.userService.getAsAdmin(
			this.route.snapshot.params.id
		);
		this.profile = await this.formateurProfileService
			.listAsAdmin({
				_limit: 1,
				_page: 0,
				formateur: this.coach.getId(),
			})
			.then(({ items }) => items[0] || new FormateurProfile());

		this.profile.props.formateur = this.coach;

		if (!this.profile.props.thematiques)
			this.profile.props.thematiques = [];

		this.initForm();

		this.loading = false;
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	initForm(): void {
		this.form = this.formBuilder.group({
			gender: new FormControl(this.profile.props.gender, [
				Validators.required,
			]),
			first_name: new FormControl(this.coach.props.first_name, [
				Validators.required,
			]),
			last_name: new FormControl(this.coach.props.last_name, [
				Validators.required,
			]),
			address: new FormControl(this.profile.props.address, [
				Validators.required,
			]),
			postal_code: new FormControl(this.profile.props.postal_code, [
				Validators.required,
			]),
			city: new FormControl(this.profile.props.city, [
				Validators.required,
			]),
		});
	}

	async save(): Promise<void> {
		if (this.form.valid) {
			this.saving = true;
			try {
				// Update model
				this.updateModel();

				await Promise.all([this.saveUser(), this.saveProfile()]);

				this.refreshPageData();
				this.createMessageConfirmation();
			} catch (error) {
				this.errorService.handle(error);
			}
			this.saving = false;
		} else {
			for (const i in this.form.controls) {
				if (this.form.controls.hasOwnProperty(i)) {
					this.form.controls[i].markAsDirty();
					this.form.controls[i].updateValueAndValidity();
				}
			}
		}
	}

	saveUser() {
		return this.userService.updateAsAdmin(
			this.coach.getId(),
			this.coach.toPayload()
		);
	}

	async saveProfile() {
		if (this.profile.isNew()) {
			this.profile = await this.formateurProfileService.createAsAdmin(
				this.profile.toPayload()
			);
		} else {
			await this.formateurProfileService.updateAsAdmin(
				this.profile.getId(),
				this.profile.toPayload()
			);
		}
	}

	/** Update form values from models properties */
	async resetForm(): Promise<void> {
		this.updateForm();
	}

	/** Update form values with model properties*/
	private updateForm(): void {
		for (const key of Object.keys(this.form.controls)) {
			if (this.USER_KEYS.indexOf(key) >= 0) {
				this.form.get(key).setValue(this.coach.props[key]);
			} else {
				this.form.get(key).setValue(this.profile.props[key]);
			}
		}
	}

	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			if (this.USER_KEYS.indexOf(key) >= 0) {
				this.coach.props[key] = this.form.get(key).value;
			} else {
				this.profile.props[key] = this.form.get(key).value;
			}
		}
	}

	//Confirmation message after user being saved
	createMessageConfirmation(): void {
		this.messageConfirmation.success(
			this.translateService.instant('common_profil_save')
		);
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-coach_detail', {
				coach: this.coach ? this.coach.getLabel() : '',
			}),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-coach_list'),
					link: ['/coach/list'],
				},
				{
					label: this.coach ? this.coach.getLabel() : '',
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
