<!-- Display Inline -->
<a class="coach-item__line" [routerLink]="['/coach', idCoach, 'details']">
	<span class="coach-item__avatar">
		<nz-avatar
			class="coach-avatar"
			[ngClass]="avatar ? 'avatarPhoto' : 'avatarIcone'"
			[nzSize]="48"
			nzIcon="user"
			[nzSrc]="avatar"
		></nz-avatar>
	</span>
	<span class="coach-item__name">
		{{ coach.getLabel() }}
	</span>
</a>
<!-- / Display Inline -->
