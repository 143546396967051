import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'kebab',
})
export class KebabCasePipe implements PipeTransform {
	transform(value: string): string {
		return value
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.replace(/([a-z])([A-Z])/g, '$1-$2')
			.replace(/[\s_]+/g, '-')
			.toLowerCase();
	}
}
