import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from '@env/environment';
import { PageService, PageInterface } from './services/page.service';
import { MetaService } from '@ngx-meta/core';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { en_US, fr_FR, NzI18nService } from 'ng-zorro-antd/i18n';
import { enUS, fr } from 'date-fns/locale';
import { AppModalService } from './services/app-modal.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { SurveyStep } from './atoms/survey/atom-survey/atom-survey.component';

declare let ga: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy {
	private unsubscribe: Subject<void> = new Subject<void>();

	public page: PageInterface;
	public surveyStep: SurveyStep = 'start';

	constructor(
		private translateService: TranslateService,
		private router: Router,
		private pageService: PageService,
		private readonly meta: MetaService,
		@Inject(PLATFORM_ID) private platformId: any,
		@Inject('LOCALSTORAGE') private localStorage: any,
		private changeDetector: ChangeDetectorRef,
		private nzI18n: NzI18nService,
		private hotkeysService: HotkeysService,
		public appModalService: AppModalService
	) {
		this.initLang();
	}

	ngOnInit() {
		this.router.events
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((event) => {
				if (!(event instanceof NavigationEnd)) {
					return;
				}
				// Scroll to top
				window.scrollTo(0, 0);
				// Update GA
				if (typeof ga === 'function') {
					ga('set', 'page', event.urlAfterRedirects);
					ga('send', 'pageview');
				}
			});

		this.pageService
			.getPage()
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((page) => {
				this.page = page;
				if (this.page.title) {
					this.meta.setTitle(this.page.title);
				} else {
					this.meta.setTitle(
						this.translateService.instant('page_title-default')
					);
				}
				this.changeDetector.detectChanges();
			});
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
	}

	initLang(): any {
		if (!environment.production) {
			this.hotkeysService.add(
				new Hotkey('ctrl+alt+l', (event: KeyboardEvent): boolean => {
					if (localStorage.getItem('lang') !== 'keys') {
						this.setLang('keys');
					} else {
						this.setLang(this.translateService.defaultLang);
					}
					return false; // Prevent bubbling
				})
			);
		}
		// Setup translate service
		this.translateService.addLangs(environment.languages);
		const availableLangs = this.translateService.getLangs();
		const defaultLang = this.translateService.getLangs()[0];
		this.translateService.setDefaultLang(defaultLang);
		const browserLang = this.translateService.getBrowserLang();
		const userLang = availableLangs.includes(browserLang)
			? browserLang
			: defaultLang;

		if (isPlatformBrowser(this.platformId)) {
			// Sync lang to storage
			if (!this.localStorage.getItem('lang')) {
				// Force en as default
				this.localStorage.setItem('lang', userLang);
			} else {
				if (
					!availableLangs.includes(this.localStorage.getItem('lang'))
				) {
					this.localStorage.setItem('lang', userLang);
				}
			}

			this.translateService.use(this.localStorage.getItem('lang'));

			window.addEventListener('storage', (event: StorageEvent) => {
				if (event.key === 'lang') {
					this.translateService.use(
						this.localStorage.getItem('lang')
					);
				}
			});
		}
	}

	toggleLang(): void {
		const availableLangs = this.translateService.getLangs();
		// Get the index of the next lang
		const index =
			(Math.max(
				0,
				availableLangs.indexOf(this.translateService.currentLang)
			) +
				1) %
			availableLangs.length;
		this.setLang(availableLangs[index]);
	}

	setLang(lang: string) {
		this.translateService.use(lang);
		let locale;
		let dateLocale;
		switch (lang) {
			case 'fr':
				locale = fr_FR;
				dateLocale = fr;
				break;
			case 'en':
				locale = en_US;
				dateLocale = enUS;
				break;
			default:
				locale = en_US;
				dateLocale = enUS;
		}
		this.nzI18n.setLocale(locale);
		this.nzI18n.setDateLocale(dateLocale);
		if (isPlatformBrowser(this.platformId))
			this.localStorage.setItem('lang', lang);
		this.changeDetector.detectChanges();
	}

	cancelSurvey(): void {
		if (this.surveyStep === 'fill') {
			this.appModalService.alertDisplay = true;
		} else if (this.surveyStep === 'start') {
			this.appModalService.clear();
		}
	}
}
