<div
	class="container-fluid px-0 py-5 d-flex flex-column justify-content-center align-items-center"
>
	<div class="row py-5 mt-lg-5 logo-container">
		<img src="./assets/svg/logo/logo_NC.svg" alt="Nove Perform" />
	</div>
	<div
		class="row py-5 px-4 w-100 flex-column align-items-center form-container"
	>
		<h2 class="text-center mb-2">{{ 'login_page-title' | translate }}</h2>
		<p class="text-center mb-5">
			{{ 'login_page-indication' | translate }}
		</p>
		<app-atom-sign-in
			class="d-block w-100"
			(signIn)="onSignIn()"
			(cancel)="onCancel()"
		></app-atom-sign-in>
	</div>
	<div class="animation-container">
		<app-atom-wheel></app-atom-wheel>
	</div>
</div>
