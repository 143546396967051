import { Injectable } from '@angular/core';
import { Color } from '@app/models/color';

export type ModalDisplayType = 'survey' | 'report-moderated-detailled' | false;

@Injectable()
export class AppModalService {
	modalDisplay: ModalDisplayType = false;
	alertDisplay = false;
	meta: {
		surveyId?: string;
		surveyNumber?: number;
		thematiqueId?: string;
		colorThematique?: Color;
		respondantId?: string;
		reportModeratedId?: string;
	} = {};

	openSurvey(
		surveyId: string,
		thematiqueId: string,
		surveyNumber: number,
		colorThematique: Color
	): void {
		this.clear();

		this.meta = { surveyId, thematiqueId, surveyNumber, colorThematique };
		this.modalDisplay = 'survey';
	}

	openReportModeratedDetailled(
		respondantId: string,
		reportModeratedId: string
	): void {
		this.clear();

		this.meta = {
			respondantId,
			reportModeratedId,
		};
		this.modalDisplay = 'report-moderated-detailled';
	}

	clear(): void {
		this.meta = {};
		this.alertDisplay = false;
		this.modalDisplay = false;
	}
}
