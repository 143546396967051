import { Component, OnInit, OnDestroy } from '@angular/core';

import KUTE from 'kute.js';

@Component({
	selector: 'app-atom-loader',
	templateUrl: './atom-loader.component.html',
	styleUrls: ['./atom-loader.component.less'],
})
export class AtomLoaderComponent implements OnInit, OnDestroy {
	loop: any;
	circleRotate: any;
	square1: any;
	square2: any;
	square3: any;
	square4: any;
	square5: any;
	square6: any;
	square7: any;
	square8: any;
	square9: any;
	square10: any;
	square11: any;
	square12: any;
	square13: any;
	square14: any;
	square15: any;
	square16: any;
	square17: any;
	square18: any;
	square19: any;
	square20: any;
	square21: any;

	constructor() {}

	ngOnInit(): void {
		this.animationMorph();
		this.loop = setInterval(this.animationMorph, 3000);
	}

	ngOnDestroy(): void {
		clearInterval(this.loop);
	}

	animationMorph() {
		this.circleRotate = KUTE.fromTo(
			'#logo_nove-concept',
			{ rotateZ: 0 },
			{ rotateZ: 360 },
			{ duration: 600 }
		).start();

		this.square1 = KUTE.to(
			'#path-1-compact',
			{
				path: '#path-1-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square2 = KUTE.to(
			'#path-2-compact',
			{
				path: '#path-2-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square3 = KUTE.to(
			'#path-3-compact',
			{
				path: '#path-3-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square4 = KUTE.to(
			'#path-4-compact',
			{
				path: '#path-4-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square5 = KUTE.to(
			'#path-5-compact',
			{
				path: '#path-5-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square6 = KUTE.to(
			'#path-6-compact',
			{
				path: '#path-6-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square7 = KUTE.to(
			'#path-7-compact',
			{
				path: '#path-7-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square8 = KUTE.to(
			'#path-8-compact',
			{
				path: '#path-8-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square9 = KUTE.to(
			'#path-9-compact',
			{
				path: '#path-9-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square10 = KUTE.to(
			'#path-10-compact',
			{
				path: '#path-10-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square11 = KUTE.to(
			'#path-11-compact',
			{
				path: '#path-11-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square12 = KUTE.to(
			'#path-12-compact',
			{
				path: '#path-12-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square13 = KUTE.to(
			'#path-13-compact',
			{
				path: '#path-13-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square14 = KUTE.to(
			'#path-14-compact',
			{
				path: '#path-14-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square15 = KUTE.to(
			'#path-15-compact',
			{
				path: '#path-15-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square16 = KUTE.to(
			'#path-16-compact',
			{
				path: '#path-16-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square17 = KUTE.to(
			'#path-17-compact',
			{
				path: '#path-17-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square18 = KUTE.to(
			'#path-18-compact',
			{
				path: '#path-18-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square19 = KUTE.to(
			'#path-19-compact',
			{
				path: '#path-19-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square20 = KUTE.to(
			'#path-20-compact',
			{
				path: '#path-20-explosed',
			},
			{ delay: 600, duration: 500, repeat: 1, yoyo: true }
		).start();
		this.square21 = KUTE.to(
			'#path-21-compact',
			{
				path: '#path-21-explosed',
			},
			{
				delay: 600,
				duration: 500,
				repeat: 1,
				yoyo: true,
			}
		).start();
	}
}
