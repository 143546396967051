<div
	class="container-fluid px-0 px-xl-5 py-4 py-xl-5"
	*ngIf="reportModeratedDetails"
>
	<!-- GENERAL -->
	<section class="container-xl px-xl-0 ml-0">
		<div class="report-row row mx-0">
			<div
				class="col-12 px-4 px-md-5 py-3 d-flex flex-row align-items-center"
			>
				<!-- Avatar Sponsor -->
				<div class="mr-3 mr-md-5 container-avatar">
					<nz-avatar
						class="avatarIcone d-none d-md-inline-block"
						[nzSize]="120"
						nzIcon="audit"
					></nz-avatar>
				</div>
				<!-- Avatar Sponsor -->
				<!-- Infos Formation -->
				<div class="detail-report-container">
					<div class="info-report">
						<h2 class="report-title mb-1">
							{{ reportModeratedDetails.name }}
						</h2>
						<p
							class="report-type mb-1"
							*ngIf="
								reportModeratedDetails.grouped;
								else reportIndividuel
							"
						>
							{{ 'report_moderated-type-grouped' | translate }}
						</p>
						<ng-template #reportIndividuel>{{
							'report_moderated-type-individuel' | translate
						}}</ng-template>
					</div>
					<button nz-button nzType="primary" class="print-remove">
						<a
							[href]="
								sesameUri +
								'/report-moderated/' +
								reportModeratedDetails._id
							"
							>{{ 'report_moderated-back-admin' | translate }}</a
						>
					</button>
				</div>
				<!-- Infos Formation -->
			</div>
		</div>
	</section>
	<!-- /GENERAL -->

	<!-- OWNERS -->
	<section class="container-xl px-xl-0 ml-0">
		<h2 class="mb-3">{{ 'report_moderated-visible-by' | translate }}</h2>

		<div class="row">
			<div
				*ngFor="let owner of reportModeratedDetails.owners"
				class="col-12 col-md-6 d-flex flex-row align-items-center"
			>
				<div class="card-owner my-3 my-md-0">
					<!-- Avatar Sponsor -->
					<div class="mr-3 container-avatar">
						<nz-avatar
							id="avatar"
							class="respondant-avatar"
							[ngClass]="
								owner.avatar ? 'avatarPhoto' : 'avatarIcone'
							"
							[nzSize]="150"
							nzIcon="user"
							[nzSrc]="owner.avatar"
						></nz-avatar>
					</div>
					<!-- Avatar Sponsor -->
					<!-- Infos User -->
					<div class="infos-user-container">
						<div class="name-user">
							<p class="user-role mb-1" [ngSwitch]="owner.role">
								<ng-container *ngSwitchCase="'sponsor'">{{
									'common-role-sponsor' | translate
								}}</ng-container>
								<ng-container *ngSwitchCase="'coach'">{{
									'common-role-coach' | translate
								}}</ng-container>
							</p>
							<p class="user-name mb-1">
								{{ owner.name }}
							</p>
						</div>
						<a
							[href]="sesameUri + '/user/' + owner._id"
							target="_blank"
							class="linkOwner"
							>{{ 'report_moderated-link-user-owner' | translate
							}}<i
								class="ml-2"
								nz-icon
								nzType="right"
								nzTheme="outline"
							></i
						></a>
					</div>
					<!-- Infos User -->
				</div>
			</div>
		</div>
	</section>
	<!-- /OWNERS -->

	<!-- RESPONDED BY -->
	<section
		*ngIf="reportModeratedDetails.grouped"
		class="container-xl px-xl-0 ml-0"
	>
		<h2 class="mb-3">
			{{ 'report_moderated-responded-by' | translate }} ({{
				reportModeratedDetails.respondedBy.length
			}}/{{ reportModeratedDetails.respondantCount }})
		</h2>
		<div class="col-12 py-3 px-0 list-inline-container">
			<div
				class="row d-flex flex-nowrap justify-content-start align-items-center px-0 pb-3 m-0 mx-md-4 head-list-inline"
			>
				<span class="col-6 respondant">{{
					'respondant-list-inline-title-name' | translate
				}}</span>
				<span class="col-3 role">{{
					'respondant-list-inline-title-role' | translate
				}}</span>
				<span class="col-3 linkAccess">{{
					'respondant-list-inline-title-link-access' | translate
				}}</span>
			</div>
			<ng-container
				*ngFor="let user of reportModeratedDetails.respondedBy"
				class="item-list-container"
			>
				<div
					class="row d-flex flex-nowrap justify-content-start align-items-center px-0 py-3 m-0 mx-md-4 inline-wrapper"
				>
					<span class="col-6 respondant d-md-flex align-items-center">
						<nz-avatar
							class="respondant-avatar mr-2"
							[ngClass]="
								user.avatar ? 'avatarPhoto' : 'avatarIcone'
							"
							[nzSize]="50"
							nzIcon="user"
							[nzSrc]="user.avatar"
						></nz-avatar>
						{{ user.name }}
					</span>
					<span
						class="col-3 role d-md-flex align-items-center"
						[ngSwitch]="user.role"
					>
						<ng-container *ngSwitchCase="'respondant'">{{
							'common-role-respondant' | translate
						}}</ng-container>
						<ng-container *ngSwitchCase="'sponsor'">{{
							'common-role-sponsor' | translate
						}}</ng-container>
						<ng-container *ngSwitchCase="'coach'">{{
							'common-role-coach' | translate
						}}</ng-container>
					</span>
					<span class="col-3 linkAccess">
						<a
							[href]="sesameUri + '/user/' + user._id"
							target="_blank"
							>{{
								'report_moderated-link-user-responded'
									| translate
							}}<i
								class="ml-2"
								nz-icon
								nzType="right"
								nzTheme="outline"
							></i
						></a>
					</span>
				</div>
			</ng-container>
		</div>
	</section>
	<!-- /RESPONDED BY -->

	<!-- REPORT MODERATED -->
	<section class="container-xl px-xl-0 ml-0 formation-details__container">
		<app-atom-report-moderated-simplified
			[title]="reportModeratedDetails.name"
			[surveyOpenResults]="surveyOpenResults"
			[reportModerated]="reportModeratedDetails.reportModerated"
			[allowNotModerated]="true"
		></app-atom-report-moderated-simplified>
	</section>
	<!-- /REPORT MODERATED -->
</div>
