import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import { TranslateService } from '@ngx-translate/core';
import { PageService, PageInterface } from '@app/services/page.service';
import { ReportModeratedSearchParams } from '@app/models/report-moderated';
import { User } from '@app/models/user';
import { SessionService } from '@app/services/session.service';
import {
	ReportModeratedWalker,
	ReportModeratedWalkerService,
} from '@app/models/report-moderated/report-moderated-walker.service';

@Component({
	selector: 'app-molecule-report-moderated-list',
	templateUrl: './molecule-report-moderated-list.component.html',
	styleUrls: ['./molecule-report-moderated-list.component.less'],
})
export class MoleculeReportModeratedListComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'list';
	currentUser: User;

	searchParams: ReportModeratedSearchParams;
	sorts = ['created_at', 'report'];

	mode: 'inline' | 'card' = 'card';

	walker: ReportModeratedWalker;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		private sessionService: SessionService,
		public reportModeratedWalkerService: ReportModeratedWalkerService
	) {
		super(pageService, translate);
		this.walker = this.reportModeratedWalkerService.getWalker();
	}

	async ngOnInit() {
		super.ngOnInit();
		this.currentUser = await this.sessionService.getCachedUser();
		this.searchParams = new ReportModeratedSearchParams({
			_page: 0,
			_limit: 100,
			moderated: true,
			grouped: true,
			owners: [this.currentUser.getId()],
			_populate: [
				'owners.avatar',
				'report',
				'formation_thematique.formation.sponsor.avatar',
				'formation_thematique.color',
			],
			_sort: 'created_at',
		});
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	trackById(_: number, respondant: User): string {
		return respondant.getId();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-report_list'),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-report_list'),
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
