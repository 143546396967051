import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Survey, SurveyService } from '@app/models/survey';
import { ErrorService } from '@app/services/error.service';
import { SessionService } from '@app/services/session.service';
import { User } from '@app/models/user';
import { Report } from '@app/models/report';
import {
	FormationThematique,
	FormationThematiqueService,
} from '@app/models/formation-thematique';
import { Color } from '@app/models/color';

export type SurveyStep = 'start' | 'fill' | 'end';
type EndMode = 'fail' | 'success';

@Component({
	selector: 'app-atom-survey',
	templateUrl: './atom-survey.component.html',
	styleUrls: ['./atom-survey.component.less'],
})
export class AtomSurveyComponent implements OnInit {
	@Input() surveyId: string;
	@Input() surveyNumber: number;
	@Input() thematiqueId: string;
	@Input() colorThematique: Color;

	private currentStepValue: SurveyStep = 'start';
	@Output() currentStepChange = new EventEmitter<SurveyStep>();
	@Input()
	get currentStep(): SurveyStep {
		return this.currentStepValue;
	}
	set currentStep(currentStep: SurveyStep) {
		this.currentStepValue = currentStep;
		this.currentStepChange.emit(this.currentStepValue);
	}

	survey: Survey;
	thematique: FormationThematique;
	report: Report;
	loading = false;
	user: User;
	endMode: EndMode = 'fail';

	constructor(
		private surveyService: SurveyService,
		private formationThematiqueService: FormationThematiqueService,
		private errorService: ErrorService,
		private sessionService: SessionService
	) {}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		this.currentStep = 'start';

		// Fetch survey and current user
		[this.user] = await Promise.all([
			this.sessionService.getCachedUser(),
			this.readSurvey(),
			this.readThematique(),
		]);

		this.loading = false;
	}

	/**
	 * Fetch the populated survey on the API
	 */
	private async readSurvey(): Promise<void> {
		try {
			this.survey = await this.surveyService.get(this.surveyId, {
				_populate: [
					'questions.labels',
					'questions.answers.labels',
					'questions.labels_min',
					'questions.labels_max',
				],
			});
		} catch (e) {
			this.errorService.handle(e);
		}
	}

	/**
	 * Fetch the thematique associted to the survey
	 */
	private async readThematique(): Promise<void> {
		try {
			this.thematique = await this.formationThematiqueService.get(
				this.thematiqueId
			);
		} catch (e) {
			this.errorService.handle(e);
		}
	}

	onSurveySuccess(): void {
		this.currentStep = 'end';
		this.endMode = 'success';
	}

	onSurveyFail(): void {
		this.currentStep = 'end';
		this.endMode = 'fail';
	}
}
