import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import { Formation, FormationService } from '@app/models/formation';
import { PageInterface, PageService } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-molecule-formation-details',
	templateUrl: './molecule-formation-details.component.html',
	styleUrls: ['./molecule-formation-details.component.less'],
})
export class MoleculeFormationDetailsComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'detail';
	formation: Formation;

	loading = false;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		private route: ActivatedRoute,
		private formationService: FormationService
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.formation = await this.formationService.get(
			this.route.snapshot.params.id,
			{
				_populate: [
					'color',
					'sessions.interventions.thematiques.formateur',
					'sessions.interventions.thematiques.color',
				],
			}
		);
		this.loading = false;
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-formation_details', {
				formation: this.formation ? this.formation.getLabel() : '',
			}),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-formation_list'),
					link: ['/formation/list'],
				},
				{ label: this.formation ? this.formation.getLabel() : null },
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
