import { Component, OnInit, Input } from '@angular/core';
import { FormationThematique } from '@app/models/formation-thematique';
import { Color } from '@app/models/color';
import { Survey } from '@app/models/survey';
import { Image } from '@app/models/image';
import { FormationAccess } from '@app/models/formation-access';
import { Formation } from '@app/models/formation';
import { User } from '@app/models/user';
import {
	SurveyResultSearchParams,
	SurveyResultService,
} from '@app/models/survey-result';
import { ErrorService } from '@app/services/error.service';
import { AppModalService } from '@app/services/app-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Answer } from '@app/models/answer';

interface SurveyDetailsInterface {
	idSurvey: string;
	shortcode: string;
	label: string;
	date_acces: Date;
	survey_completed: number;
	respondants_max: number;
	status: 'soon' | 'done' | 'todo';
}

interface ThematiqueDetailsInterface {
	idThematique: string;
	name: string;
	sponsor: string;
	avatar_sponsor: string;
	respondants_max: number;
	coach: string;
}

interface OpenSurveyResultAnswersInterface {
	idSurvey: string;
	labelSurvey: string;
	answersByQuestion: {
		questionId: string;
		questionLabel: string;
		answers: Partial<Answer>[];
	}[];
}

@Component({
	selector: 'app-atom-formation-thematique-details',
	templateUrl: './atom-formation-thematique-details.component.html',
	styleUrls: ['./atom-formation-thematique-details.component.less'],
})
export class AtomFormationThematiqueDetailsComponent implements OnInit {
	@Input() formationThematique: FormationThematique;
	@Input() userLogged: User;
	@Input() user: User | null;
	gradientMin: string;
	gradientMax: string;
	colorThematique: Color;
	thematiqueDetails: ThematiqueDetailsInterface;
	formationName: string;
	access: FormationAccess[];
	surveys: SurveyDetailsInterface[] = [];
	listIdsRespondants: string[];

	constructor(
		private surveyResultService: SurveyResultService,
		private errorService: ErrorService,
		public appModalService: AppModalService,
		public translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.colorThematique = <Color>this.formationThematique.props.color;
		this.access = <FormationAccess[]>this.formationThematique.props.access;
		this.thematiqueDetails = this.getThematiqueDetails();
		this.getSurveyDetails();
	}

	getThematiqueDetails(): ThematiqueDetailsInterface {
		this.formationName = (<Formation>(
			this.formationThematique.props.formation
		)).props.name;
		const sponsor = <User>(
			(<Formation>this.formationThematique.props.formation).props.sponsor
		);
		const coach: string = (this.formationThematique.props
			.formateur as User).getLabel();
		return {
			idThematique: this.formationThematique.getId(),
			name: this.formationThematique.getLabel(),
			sponsor: sponsor ? sponsor.getLabel() : '',
			avatar_sponsor: sponsor
				? sponsor.avatarExists()
					? (<Image>sponsor.props.avatar).getUrl()
					: null
				: null,
			respondants_max: (<Formation>(
				this.formationThematique.props.formation
			)).props?.respondants?.length,
			coach,
		};
	}

	getSurveyDetails(): SurveyDetailsInterface[] {
		const access: FormationAccess[] = <FormationAccess[]>(
			this.formationThematique.props.access
		);

		access
			.sort(function (a, b) {
				return <number>a.props.date - <number>b.props.date;
			})
			.map(async (details: FormationAccess) => {
				//Get access of surveys
				const date_acces = <Date>details.props.date;
				//Get shortcode of surveys
				const shortcode = (<Survey>details.props.survey).props
					.shortcode;
				//Get label of surveys
				const label = (<Survey>details.props.survey).props.label;
				const idSurvey = (<Survey>details.props.survey).props._id;
				//Get number max of respondants
				const respondants_max = (<Formation>(
					this.formationThematique.props.formation
				)).props?.respondants?.length;
				//Get number survey completed
				this.listIdsRespondants = <string[]>(
					(<Formation>(
						this.formationThematique.props.formation
					)).props.respondants?.map((r: User) => r.getId())
				);
				const params = this.userLogged.isRespondant()
					? new SurveyResultSearchParams({
							_page: 0,
							_limit: 100,
							owner: [this.userLogged.getId()],
							survey: (<Survey>details.props.survey).getId(),
					  }).toObjectSanitizedForCount()
					: new SurveyResultSearchParams({
							_page: 0,
							_limit: 100,
							owner: this.listIdsRespondants,
							survey: (<Survey>details.props.survey).getId(),
					  }).toObjectSanitizedForCount();

				const result = await this.surveyResultService
					.count(params)
					.catch((err) => {
						this.errorService.handle(err);
						return null;
					});
				//Get state survey
				const today = new Date();
				//Test if survey is available
				const status =
					date_acces > today || false
						? 'soon'
						: this.userLogged.isRespondant()
						? result === 0
							? 'todo'
							: 'done'
						: 'done';
				const surveyDetails: SurveyDetailsInterface = {
					idSurvey: idSurvey,
					shortcode: shortcode,
					label: label,
					date_acces: date_acces,
					survey_completed: result,
					respondants_max: respondants_max,
					status: status,
				};
				this.surveys.push(surveyDetails);
			});
		return null;
	}

	openSurvey(
		surveyId: string,
		thematiqueId: string,
		surveyNumber: number,
		colorThematique: Color
	) {
		if (this.userLogged.props.role === 'respondant') {
			this.appModalService.openSurvey(
				surveyId,
				thematiqueId,
				surveyNumber,
				colorThematique
			);
		}
	}
}
