<form
	nz-form
	[formGroup]="form"
	(submit)="onSubmit()"
	[class.d-none]="success"
	[nzAutoTips]="explainErrorService.autoTips"
>
	<nz-form-item class="w-100">
		<nz-form-control>
			<nz-input-group [nzSuffix]="suffixTemplate">
				<input
					nz-input
					formControlName="password"
					[type]="passwordVisible ? 'text' : 'password'"
					autocomplete="current-password"
					placeholder="{{
						'reset_password-new_password-placeholder' | translate
					}}"
				/>
			</nz-input-group>
			<ng-template #suffixTemplate>
				<i
					nz-icon
					[nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
					(click)="passwordVisible = !passwordVisible"
				></i>
			</ng-template>
		</nz-form-control>
	</nz-form-item>

	<div
		class="mt-4 w-100 d-flex justify-content-center justify-content-sm-between align-items-center flex-wrap"
	>
		<button
			nz-button
			nzType="primary"
			type="submit"
			[disabled]="form.invalid"
			[nzLoading]="processing"
		>
			{{ 'reset_password-submit-btn' | translate }}
		</button>
	</div>
</form>
<div *ngIf="success" class="d-flex flex-column w-100 align-items-center py-5">
	<p class="mt-4">{{ 'reset_password-success' | translate }}</p>
	<i nz-icon nzType="loading" theme="outline"></i>
</div>
