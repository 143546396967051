<nz-calendar
	#calendar
	[(ngModel)]="date"
	(nzSelectChange)="changeFiltre($event)"
	(nzPanelChange)="panelChange($event)"
	[(nzMode)]="mode"
>
	<ul *nzDateCell="let date" class="events">
		<ng-container [ngSwitch]="date.getDate()">
			<ng-container *ngFor="let day of listDateMap; let i = index">
				<ng-container *ngSwitchCase="i">
					<ng-container *ngIf="day.length > 0 && date">
						<li *ngFor="let thematique of day">
							<ng-container
								*ngIf="date.getMonth() === thematique.month"
							>
								<nz-badge
									[routerLink]="[
										'/session',
										thematique.id,
										'details'
									]"
									[nzColor]="thematique.color.props.main"
									[nzText]="thematique.title"
								></nz-badge>
							</ng-container>
						</li>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</ul>
	<ul *nzMonthCell="let date" class="events">
		<ng-container [ngSwitch]="date.getMonth()">
			<ng-container *ngFor="let day of listDateMap; let i = index">
				<ng-container *ngSwitchCase="i">
					<ng-container *ngIf="day.length > 0 && date">
						<li *ngFor="let thematique of day">
							<nz-badge
								[routerLink]="[
									'/session',
									thematique.id,
									'details'
								]"
								[nzColor]="thematique.color.props.main"
								[nzText]="thematique.title"
							></nz-badge>
						</li>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</ul>
</nz-calendar>

<hpf-formation-select
	#selectFormation
	[(id)]="searchParams.props._id"
	[nullable]="true"
	[placeholder]="'formation_common_search-placeholder' | translate"
	[emptyLabel]="'common_empty' | translate"
	[multiple]="false"
	(change)="refresh()"
	style="width: 180px;"
></hpf-formation-select>
