<div class="list-wrapper list-{{ title | kebab }}">
	<div
		class="list-meta d-flex justify-content-between align-items-baseline mb-4 px-4 px-xl-0"
	>
		<h2 class="mb-0">{{ title }}</h2>
		<a *ngIf="seeFullList" [routerLink]="linkFullList"
			>{{ 'model-inline_list-access-all' | translate }}
			<i class="ml-2" nz-icon nzType="right" nzTheme="outline"></i
		></a>
	</div>
	<div class="list-caroussel d-flex flex-row flex-nowrap px-2 px-lg-3 py-4">
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</div>
</div>
