import { Injectable } from '@angular/core';
import {
	GraphModerated,
	GraphModeratedInterface,
	GraphModeratedPayload,
} from './graph-moderated';
import { GraphModeratedSearchParamsInterface } from './graph-moderated-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class GraphModeratedService extends BaseModelService<
	GraphModerated,
	GraphModeratedInterface,
	GraphModeratedPayload,
	GraphModeratedSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'graph-moderated';
	}
	/** @inheritDoc */
	protected new(object: GraphModeratedInterface): GraphModerated {
		return new GraphModerated(object);
	}
}
