import { Component, Input } from '@angular/core';
import { EntitySelectComponent } from '@app/abstracts';
import { ErrorService } from '@app/services/error.service';
import { ReportSearchParams } from '../report-search-params';
import { ReportService } from '../report.service';
import { Report } from '../report';
@Component({
	selector: 'hpf-report-select',
	templateUrl:
		'../../../abstracts/entity-select/entity-select.component.html',
})
export class ReportSelectComponent extends EntitySelectComponent<Report> {
	@Input() allowedReports: (Report | string)[];
	/** Constructor */
	constructor(
		private reportService: ReportService,
		protected errorService: ErrorService
	) {
		super(errorService);
	}
	/** @inheritDoc */
	protected async getList(): Promise<Report[]> {
		if (this.allowedReports.length === 0) return [];
		const allowReportIds = this.allowedReports.map((allow) =>
			typeof allow === 'string' ? allow : (<Report>allow).getId()
		);

		const params = new ReportSearchParams({
			_id: allowReportIds,
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		if (this.filterEnabled && this.filterValue) {
			params.props._text = this.filterValue;
		}
		return (await this.reportService.list(params.toObject())).items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<Report> {
		return await this.reportService.get(id);
	}
}
