import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import {
	Formation,
	FormationAdministrativeStatus,
	FormationService,
	FormationAdministrativeStatusValues,
} from '@app/models/formation';
import {
	FormationIntervention,
	FormationInterventionSearchParams,
	FormationInterventionService,
} from '@app/models/formation-intervention';
import { FormationInterventionWalkerService } from '@app/models/formation-intervention/formation-intervention-walker.service';

import { PageInterface, PageService } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-molecule-formation-details-admin',
	templateUrl: './molecule-formation-details-admin.component.html',
	styleUrls: ['./molecule-formation-details-admin.component.less'],
})
export class MoleculeFormationDetailsAdminComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'list';
	private unsubscribe$ = new Subject<void>();
	searchParams: FormationInterventionSearchParams;
	formation: Formation;
	formationInterventions: FormationIntervention[];
	numberIntervention: number;
	formationEnd: number;

	sorts = ['created_at', 'label'];
	orders = ['asc', 'desc'];
	loading = false;

	statesAdmin = FormationAdministrativeStatusValues;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		public formationInterventionWalkerService: FormationInterventionWalkerService,
		public formationService: FormationService,
		public formationInterventionService: FormationInterventionService,
		private route: ActivatedRoute
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.formation = await this.formationService.getAsAdmin(
			this.route.snapshot.params.id,
			{
				_populate: ['sponsor.avatar'],
			}
		);

		this.refreshPageData();

		this.searchParams = new FormationInterventionSearchParams({
			_page: 0,
			_limit: 100,
			_order: 'desc',
			_sort: 'label',
			_populate: ['formateur', 'thematiques.color'],
			formation: this.formation.getId(),
		});
		this.searchParams
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(async () => {
				await this.getFormationInterventions();
			});
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async getFormationInterventions() {
		this.loading = true;
		await this.formationInterventionService
			.listAsAdmin(this.searchParams.toObject())
			.then((res) => {
				this.formationInterventions = res.items;
				this.numberIntervention = res.total;
				this.formationEnd = this.getLastDateFromFormationInterventions(
					res.items
				);
			});
		this.loading = false;
	}

	trackById(_: number, formation: Formation): string {
		return formation.getId();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant(
				'page_title-formation_list_intervention',
				{
					formation: this.formation ? this.formation.getLabel() : '',
				}
			),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-formation_list'),
					link: ['/formations/list'],
				},
				{ label: this.formation ? this.formation.getLabel() : null },
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}

	getLastDateFromFormationInterventions(
		formationInterventions: FormationIntervention[]
	) {
		if (!formationInterventions.length) {
			return 0;
		}
		return Math.max(
			...formationInterventions.map((formationIntervention) =>
				formationIntervention.getEndDate()
			)
		);
	}

	handleFilterState(value: FormationAdministrativeStatus) {
		if (value) {
			this.searchParams.props.administrative_status = value;
		} else {
			delete this.searchParams.props.administrative_status;
		}
		this.searchParams.next();
	}
}
