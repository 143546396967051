import { Injectable } from '@angular/core';
import {
	SurveyLabel,
	SurveyLabelInterface,
	SurveyLabelPayload,
} from './survey-label';
import { SurveyLabelSearchParamsInterface } from './survey-label-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class SurveyLabelService extends BaseModelService<
	SurveyLabel,
	SurveyLabelInterface,
	SurveyLabelPayload,
	SurveyLabelSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'survey-label';
	}
	/** @inheritDoc */
	protected new(object: SurveyLabelInterface): SurveyLabel {
		return new SurveyLabel(object);
	}
}
