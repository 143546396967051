<div class="container-end-survey">
	<div *ngIf="mode === 'success'">
		<p class="icon-wrapper">
			<i nz-icon nzType="trophy" nzTheme="outline"></i>
		</p>
		<h3>{{ 'survey_end-congratulation' | translate }}</h3>
		<p class="message">{{ 'survey_end-message-success' | translate }}</p>
		<button nz-button nz-type="primary" (click)="navigateToSession()">
			{{ 'survey_end-dashboard_link' | translate }}
		</button>
	</div>
	<div *ngIf="mode === 'fail'">
		<p class="icon-wrapper">
			<i nz-icon nzType="warning" nzTheme="outline"></i>
		</p>
		<h3>{{ 'survey_end-congratulation' | translate }}</h3>
		<p class="message">{{ 'survey_end-message-fail' | translate }}</p>
		<button nz-button nz-type="primary" (click)="navigateToSession()">
			{{ 'survey_end-dashboard_link' | translate }}
		</button>
	</div>
</div>
