import { Injectable } from '@angular/core';
import {
	SurveyResult,
	SurveyResultInterface,
	SurveyResultPayload,
} from './survey-result';
import { SurveyLabelInterface } from '../survey-label';
import { SurveyResultSearchParamsInterface } from './survey-result-search-params';
import { BaseModelService } from '@app/abstracts';

export interface AnswerResultFormatted {
	label: SurveyLabelInterface;
	isCorrectAnswer: boolean;
	selected: boolean;
	score: number;
}

export interface QuestionResultFormatted {
	label: string;
	type: string;
	answers?: AnswerResultFormatted[];
	open_answer?: string;
}

export interface SurveyResultFormatted {
	label: string;
	totalAnswers: number;
	correctAnswers: number;
	questions: QuestionResultFormatted[];
}

@Injectable()
export class SurveyResultService extends BaseModelService<
	SurveyResult,
	SurveyResultInterface,
	SurveyResultPayload,
	SurveyResultSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'survey-result';
	}
	/** @inheritDoc */
	protected new(object: SurveyResultInterface): SurveyResult {
		return new SurveyResult(object);
	}

	getFormatted(id: string, scoreAxe: string): Promise<SurveyResultFormatted> {
		// Start request
		const options = {
			withCredentials: !this.publicRead,
			params: {
				scoreAxe,
			},
		};
		return this.http
			.get<SurveyResultFormatted>(
				`${this.uri()}/${id}/formatted`,
				options
			)
			.toPromise();
	}
}
