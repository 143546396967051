<div class="container-xl px-xl-0 mb-5 ml-0 details-formation-container">
	<div class="row mx-0">
		<div
			class="col-12 px-4 px-md-5 py-3 d-flex flex-row align-items-center"
		>
			<!-- Avatar Sponsor -->
			<div class="mr-3 mr-md-5 container-avatar">
				<nz-avatar
					class="avatarIcone d-md-none avatarFormation"
					[nzSize]="120"
					nzIcon="audit"
					[style.color]="colorFormation"
				></nz-avatar>
				<nz-avatar
					class="avatarIcone d-none d-md-inline-block avatarFormation"
					[nzSize]="150"
					nzIcon="audit"
					[style.color]="colorFormation"
				></nz-avatar>
			</div>
			<!-- Avatar Sponsor -->
			<!-- Infos Formation -->
			<div class="infos-formation-container">
				<h2 class="formation-title mb-1">
					{{ formation.getLabel() }}
				</h2>
				<p
					class="formation-description mb-1"
					[innerHTML]="formation.props.description"
				></p>
			</div>
			<!-- Infos Formation -->
		</div>
	</div>
</div>

<div class="container-xl px-xl-0 mb-5 ml-0">
	<div class="row">
		<div class="col-12">
			<div class="table-container">
				<table>
					<thead>
						<tr>
							<th>{{ 'table_head-thematique' | translate }}</th>
							<th>{{ 'table_head-state' | translate }}</th>
							<th>{{ 'table_head-date' | translate }}</th>
							<th>
								{{ 'table_head-count-respondants' | translate }}
							</th>
							<th>
								{{ 'table_head-coach' | translate }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							*ngFor="
								let thematique of thematiques;
								let i = index
							"
							[routerLink]="[
								'/session',
								thematique.idThematique,
								'details'
							]"
							class="clicable"
						>
							<td class="title">
								{{ thematique.name }}
								<div
									class="bandeau"
									[ngStyle]="
										thematique.colorThematique.props
											.gradient_min &&
										thematique.colorThematique.props
											.gradient_max
											? {
													background:
														'linear-gradient(180deg, ' +
														thematique
															.colorThematique
															.props
															.gradient_min +
														', ' +
														thematique
															.colorThematique
															.props
															.gradient_max +
														')'
											  }
											: {
													background:
														thematique
															.colorThematique
															.props.main
											  }
									"
								></div>
							</td>
							<td class="state" [ngSwitch]="thematique.status">
								<ng-container *ngSwitchCase="'soon'">
									<span>{{
										'survey-state-soon' | translate
									}}</span>
								</ng-container>
								<ng-container *ngSwitchCase="'done'">
									<span
										class="d-flex align-items-center justify-content-center"
									>
										<i
											nz-icon
											nzType="check-circle"
											nzTheme="fill"
											class="mr-2"
											[style.color]="
												thematique.colorThematique.props
													.main
											"
										></i>
										<span>{{
											'survey-state-done' | translate
										}}</span>
									</span>
								</ng-container>
							</td>
							<td class="date">
								{{
									thematique.date_acces
										| date
											: 'dd MMMM yyyy'
											: ''
											: translateService.currentLang
								}}<span>{{
									thematique.date_acces | date: "HH 'h' mm "
								}}</span>
							</td>
							<td class="respondants">
								{{ thematique.respondants }}
							</td>
							<td class="coach">
								<span *ngFor="let coach of thematique.coach">
									<span class="delimitateur"> / </span
									>{{ coach }}
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
