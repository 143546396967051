<div class="row mx-0">
	<div class="col-12 px-5 py-4 d-flex flex-row align-items-center">
		<!-- Avatar Sponsor -->
		<div class="mr-5 container-avatar">
			<nz-avatar
				id="avatar"
				class="respondant-avatar"
				[ngClass]="avatar ? 'avatarPhoto' : 'avatarIcone'"
				[nzSize]="150"
				nzIcon="user"
				[nzSrc]="avatar"
			></nz-avatar>
		</div>
		<!-- Avatar Sponsor -->
		<!-- Infos Sponsor -->
		<div class="infos-sponsor-container">
			<h2 class="sponsor-title mb-1">
				{{ name }}
			</h2>
			<p class="sponsor-meta mb-1">
				<span class="number-respondants-formation">
					<ng-container
						*ngIf="totalRespondant > 1; else oneRespondantFormation"
					>
						{{
							'sponsor_detail-number-respondants'
								| translate
									: {
											number: totalRespondant
									  }
						}}
					</ng-container>
					<ng-template #oneRespondantFormation>
						{{
							'sponsor_detail-number-respondant'
								| translate
									: {
											number: totalRespondant
									  }
						}}
					</ng-template>
				</span>
			</p>
		</div>
		<!-- Infos Sponsor -->
	</div>
</div>
