
import { GraphConfig, GraphConfigPrint } from './commons/graph';

export const environment = {
	production: false,
	appCode: 'novp-pwa',
	appVersion: '1',
	languages: ['fr'],
	api: {
		uri: 'https://api.staging.nove-concept.com/v1',
	},
	sesame: {
		uri: 'https://sesame.staging.nove-concept.com',
	},
	images: {
    uri: 'https://images.staging.nove-concept.com/resize?nocrop=true&url=https://novc-files-staging-4kwkkq.s3.amazonaws.com',
	},
	graphs: GraphConfig,
	graphsPrint: GraphConfigPrint,
  	surveys: {
    	previousQuestionLimit: 5
  	},
 	reports: {
		evalEnd: '999999999999999999999999',
	},
	emailContact: 'technique@nove-concept.com',
};
