import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import { User, UserService } from '@app/models/user';
import { PageService, PageInterface } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-molecule-respondant-edit',
	templateUrl: './molecule-respondant-edit.component.html',
	styleUrls: ['./molecule-respondant-edit.component.less'],
})
export class MoleculeRespondantEditComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	loading = true;
	respondantId: string;
	respondant: User;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		private route: ActivatedRoute,
		private userService: UserService
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();

		this.respondantId = this.route.snapshot.params.id;
		this.respondant = await this.userService.get(this.respondantId);
		this.refreshPageData();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-respondant_details', {
				respondant: this.respondant ? this.respondant.getLabel() : '',
			}),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-respondant_list'),
					link: ['/respondant/list'],
				},
				{
					label: this.respondant ? this.respondant.getLabel() : null,
					link: ['/respondant', this.respondantId, 'details'],
				},
				{ label: this.translate.instant('top_bar-respondant_edit') },
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
