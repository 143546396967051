<section class="container">
	<div class="row py-5">
		<div class="col-12 text-center">
			<p class="mb-0">{{ 'logout-message' | translate }}</p>
			<div class="py-5 mb-0">
				<i nz-icon nzType="loading" theme="outline"></i>
			</div>
		</div>
	</div>
</section>
