import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { ExplainErrorsService } from '@app/services/explain-errors.service';
import { PasswordService } from '@app/services/password.service';
import { NavigationService } from '@app/services/navigation.service';

@Component({
	selector: 'app-atom-reset-password',
	templateUrl: './atom-reset-password.component.html',
	styleUrls: ['./atom-reset-password.component.less'],
})
export class AtomResetPasswordComponent implements OnInit {
	form: FormGroup;
	success = false;
	processing: boolean;
	passwordVisible = false;
	passwordMinLength = 6;
	@Input()
	id: string;
	@Input()
	code: string;

	constructor(
		private formBuilder: FormBuilder,
		private passwordService: PasswordService,
		private message: NzMessageService,
		private navigation: NavigationService,
		public explainErrorService: ExplainErrorsService
	) {}

	ngOnInit() {
		this._initForm();
	}

	private _initForm(): void {
		this.form = this.formBuilder.group({
			password: [
				'',
				[
					Validators.minLength(this.passwordMinLength),
					Validators.required,
				],
			],
		});
	}

	onSubmit(): void {
		this.processing = true;
		this.passwordService
			.reset(this.id, this.code, this.form.get('password').value)
			.then(() => {
				this.success = true;
				this.navigation.delayed(['sign-in']);
			})
			.catch((err) => {
				if (err.error.statusCode === 400) {
					this.form.get('password').setErrors({ badRequest: true });
				} else if (err.error.statusCode === 401) {
					this.form.get('password').setErrors({ unauthorized: true });
				} else {
					this.message.create('error', err.message);
				}
			})
			.then(() => (this.processing = false));
	}
}
