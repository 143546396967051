import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
import { User, UserInterface } from '../user/user';
import { Graph, GraphInterface } from '../graph/graph';
import { AxeSurvey, AxeSurveyInterface } from '../axe-survey/axe-survey';
import { Axe } from '../axe';
import { Survey } from '../survey';
import { ReportModeratedWeightsInterface } from '../report-moderated';
import { Color } from '../color';
import { environment } from '@env/environment';
export interface GraphModeratedInterface extends BaseModelInterface {
	created_at: number | Date;
	owners: (string | User | UserInterface)[];
	graph: string | Graph | GraphInterface;
	axes: (string | AxeSurvey | AxeSurveyInterface)[];
	override?: {
		[key: string]: {
			color?: string;
		};
	};
}
export interface GraphModeratedPayload {
	owners: (string | User | UserInterface)[];
	graph: string | Graph | GraphInterface;
	axes: (string | AxeSurvey | AxeSurveyInterface)[];
	override?: any;
}
type GraphModeratedPayloadKey = keyof GraphModeratedPayload;
export class GraphModerated extends BaseModel<
	GraphModeratedInterface,
	GraphModeratedPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of owners has been populated */
	ownersExists(): boolean {
		return (
			!!this.props &&
			this.props.owners instanceof Array &&
			(<User[]>this.props.owners).every((item) => {
				return item instanceof User && item.exists();
			})
		);
	}
	/** Denotes if the instance of graph has been populated */
	graphExists(): boolean {
		return (
			!!this.props &&
			this.props.graph instanceof Graph &&
			this.props.graph.exists()
		);
	}
	/** Denotes if the instance of axes has been populated */
	axesExists(): boolean {
		return (
			!!this.props &&
			this.props.axes instanceof Array &&
			(<AxeSurvey[]>this.props.axes).every((item) => {
				return item instanceof AxeSurvey && item.exists();
			})
		);
	}
	/** Get the good color of the axe in the graph */
	getAxeColor(axeSurveyId: string, colors: Color[], showAll = false): Color {
		const isDisabled = !showAll && !this.axeIsEnable(axeSurveyId);

		//Search override color
		const override = this.props.override
			? this.props.override[axeSurveyId]
			: null;

		if (override?.color) {
			const color = colors.find((c) => c.getId() === override.color);
			if (color) {
				color.props.disabled = isDisabled;

				return new Color(color.props);
			}
		}

		return !isDisabled
			? new Color({
					name: 'Default Color',
					main: environment.graphs.defaultColor,
					disabled: false,
			  })
			: new Color({
					name: 'Default Disabled Color',
					main: environment.graphs.disabledColor,
					disabled: true,
			  });
	}

	/** Check if an axe is enable in this graph */
	axeIsEnable(axeSurveyId: string): boolean {
		if (!this.axesExists()) return false;

		return !!(<AxeSurvey[]>this.props.axes).find(
			(as) => as.getId() === axeSurveyId
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: GraphModeratedInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.owners instanceof Array) {
			this.props.owners = (<UserInterface[]>this.props.owners).map(
				(item) => {
					return typeof item === 'object' ? new User(item) : item;
				}
			);
		}
		if (typeof this.props.graph === 'object') {
			this.props.graph = new Graph(<GraphInterface>this.props.graph);
		}
		if (this.props.axes instanceof Array) {
			this.props.axes = (<AxeSurveyInterface[]>this.props.axes).map(
				(item) => {
					return typeof item === 'object'
						? new AxeSurvey(item)
						: item;
				}
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): GraphModeratedInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.override === 'undefined') {
			props.override = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.owners instanceof Array) {
			props.owners = (<User[]>props.owners).map((item) => {
				return item instanceof User ? item.toObject() : item;
			});
		}
		if (props.graph instanceof Graph) {
			props.graph = props.graph.toObject();
		}
		if (this.props.axes instanceof Array) {
			props.axes = (<AxeSurvey[]>props.axes).map((item) => {
				return item instanceof AxeSurvey ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): GraphModeratedPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as GraphModeratedInterface);
		payload.owners = payload.owners
			? payload.owners.map((i) => this.extractId(i))
			: null;
		payload.graph = payload.graph ? this.extractId(payload.graph) : null;
		payload.axes = payload.axes
			? payload.axes.map((i) => this.extractId(i))
			: null;
		return payload as GraphModeratedPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): GraphModeratedPayloadKey[] {
		return ['owners', 'graph', 'axes', 'override'];
	}

	/**
	 * Get the data of the graph
	 */
	getData(
		weights: ReportModeratedWeightsInterface,
		showAll = false
	): [Axe, number, Survey][] {
		const graph = this.graphExists() ? <Graph>this.props.graph : null;

		if (!graph || !graph.axesExists()) return;

		const filterData = (axeSurvey) =>
			showAll ||
			graph.props.type !== 'radar' ||
			this.axeIsEnable(axeSurvey.getId());

		return (<AxeSurvey[]>graph.props.axes)
			.filter(filterData)
			.map((axeSurvey) => {
				const weight = this.getAxeWeight(
					weights,
					<Survey>axeSurvey.props.survey,
					<Axe>axeSurvey.props.axe
				);

				return [
					<Axe>axeSurvey.props.axe,
					weight ?? 0,
					typeof axeSurvey.props.survey === 'string'
						? null
						: <Survey>axeSurvey.props.survey,
				];
			});
	}

	/**
	 * Get the weight of an axe of survey
	 * If no survey, get the sum of that axe in all surveys
	 */
	getAxeWeight(
		weights: ReportModeratedWeightsInterface,
		survey: Survey | string | null,
		axe: Axe | string
	): number {
		const axeId = typeof axe === 'string' ? axe : (<Axe>axe).getId();

		if (survey) {
			const surveyId =
				typeof survey === 'string' ? survey : (<Survey>survey).getId();

			return weights[surveyId] ? weights[surveyId][axeId] : 0;
		} else {
			return Object.values(weights).reduce((acc, surveyResult) => {
				return surveyResult[axeId] ? (acc += surveyResult[axeId]) : acc;
			}, 0);
		}
	}
}
