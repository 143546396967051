import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	AfterViewInit,
	AfterViewChecked,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@app/models/user';
import { NavigationService } from '@app/services/navigation.service';
import { SessionService } from '@app/services/session.service';

@Component({
	selector: 'app-atom-main-menu',
	templateUrl: './atom-main-menu.component.html',
	styleUrls: ['./atom-main-menu.component.less'],
})
export class AtomMainMenuComponent
	implements OnInit, AfterViewInit, AfterViewChecked {
	user: User;
	navigationSideMenu: {
		label: string;
		link: string[] | string;
		roles: string[];
		class: string;
		icon: string;
	}[];
	rotateSvg: any;
	scaleAvatar: any;
	loading: boolean = false;
	@Input() device: string;

	@Output() closeDrawerEvent = new EventEmitter<boolean>();

	constructor(
		private translate: TranslateService,
		private navigationService: NavigationService,
		private sessionService: SessionService
	) {}

	ngOnInit() {
		this.refreshUser();
		this.navigationSideMenu = [
			{
				//Dashboard - role : tout le monde
				label: this.translate.instant('main_menu-dashboard'),
				link: ['/dashboard'],
				roles: ['coach', 'respondant', 'sponsor', 'admin'],
				class: 'dashboard',
				icon: 'appstore',
			},
			{
				//Commanditaires - role : Formateur
				label: this.translate.instant('main_menu-sponsors'),
				link: ['/sponsor'],
				roles: ['coach'],
				class: 'sponsors',
				icon: 'user',
			},
			{
				//Formations - role : Commanditaire
				label: this.translate.instant('main_menu-formations'),
				link: ['/formation'],
				roles: ['sponsor'],
				class: 'formations',
				icon: 'audit',
			},
			{
				//Session - role : Formateur
				label: this.translate.instant('main_menu-sessions'),
				link: ['/session'],
				roles: ['coach'],
				class: 'sessions',
				icon: 'audit',
			},
			{
				//Évaluations - role : Formateur / Commanditaire
				label: this.translate.instant('main_menu-reports'),
				link: ['/report'],
				roles: ['coach', 'sponsor'],
				class: 'rapports',
				icon: 'line-chart',
			},
			{
				//Stagiaires - role : Commanditaire
				label: this.translate.instant('main_menu-respondants'),
				link: ['/respondant'],
				roles: ['sponsor'],
				class: 'stagiaires',
				icon: 'team',
			},
			{
				//Formateurs - role : admin
				label: this.translate.instant('main_menu-coaches'),
				link: ['/coach'],
				roles: ['admin'],
				class: 'coaches',
				icon: 'user',
			},
			{
				//Formations - role : admin
				label: this.translate.instant('main_menu-formations'),
				link: ['/formations'],
				roles: ['admin'],
				class: 'formations',
				icon: 'audit',
			},
		];
	}

	//use to finish to load image
	ngAfterViewInit() {
		setTimeout(() => {
			this.loading = false;
		}, 500);
	}

	ngAfterViewChecked() {}

	logout() {
		this.navigationService.go('/logout');
	}

	emitCloseDrawer(toProfile = false) {
		this.closeDrawerEvent.emit();
		if (toProfile) this.navigationService.go('/my-profile');
	}

	async refreshUser() {
		this.user = await this.sessionService.getCachedUser();
	}
}
