import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { ExplainErrorsService } from '@app/services/explain-errors.service';
import { Self } from '@app/services/self';
import { ErrorService } from '@app/services/error.service';
import { SessionService } from '@app/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
	selector: 'app-atom-sign-in',
	templateUrl: './atom-sign-in.component.html',
	styleUrls: ['./atom-sign-in.component.less'],
})
export class AtomSignInComponent implements OnInit {
	/** The form group to use */
	form: FormGroup;
	processing = false;
	success = false;
	passwordVisible = false;

	@Output() signIn = new EventEmitter<Self>();
	@Output() cancel = new EventEmitter<void>();

	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		private sessionService: SessionService,
		private translateService: TranslateService,
		private message: NzMessageService,
		public explainErrorService: ExplainErrorsService
	) {}

	ngOnInit() {
		this.initForm();
	}

	// Init the sign in form
	private initForm() {
		this.form = this.formBuilder.group({
			email: new FormControl('', [Validators.email, Validators.required]),
			password: new FormControl('', [Validators.required]),
		});
	}

	// Submit form
	submit(): void {
		this.processing = true;
		this.sessionService
			.login(
				this.form.get('email').value,
				this.form.get('password').value
			)
			.then((user: Self) => {
				this.success = true;
				this.signIn.emit(user);
			})
			.catch((err) => {
				if (err.error.statusCode === 401) {
					return this.message.create(
						'error',
						this.translateService.instant('login-bad_login-message')
					);
				}

				this.errorService.handle(err);
			})
			.then(() => (this.processing = false));
	}
}
