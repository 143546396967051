<nz-calendar
	#calendar
	([ngModel])="(date)"
	[nzMode]="'month'"
	[nzFullscreen]="false"
	(nzSelectChange)="selectChange($event)"
>
	<div *nzDateCell="let date" class="cellDate">
		<ng-container [ngSwitch]="date.getDate()">
			<ng-container *ngFor="let day of listDateMap; let i = index">
				<ng-container *ngSwitchCase="i">
					<ng-container *ngIf="day.length > 0 && date">
						<ng-container *ngIf="day[0].month === date.getMonth()">
							<div
								class="actionDate"
								nz-popover
								nzPopoverTrigger="click"
								[nzPopoverContent]="contentTemplate"
								nzPopoverPlacement="topCenter"
								nzTooltipOrigin="'bottomCenter'"
							></div>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>

		<ng-template #contentTemplate>
			<ul class="events-calendar-mobile">
				<ng-container [ngSwitch]="date.getDate()">
					<ng-container
						*ngFor="let day of listDateMap; let i = index"
					>
						<ng-container *ngSwitchCase="i">
							<ng-container *ngIf="day.length > 0 && date">
								<li *ngFor="let thematique of day">
									<ng-container
										*ngIf="
											date.getMonth() === thematique.month
										"
									>
										<nz-badge
											[routerLink]="[
												'/session',
												thematique.id,
												'details'
											]"
											[nzColor]="
												thematique.color.props.main
											"
											[nzText]="thematique.title"
										></nz-badge>
									</ng-container>
								</li>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
			</ul>
		</ng-template>
	</div>
</nz-calendar>
