import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
export interface DocumentAdministrativeInterface extends BaseModelInterface {
	created_at: number | Date;
	label: string;
	uri: string;
	status: any;
	template?: string;
	data?: any;
}
export interface DocumentAdministrativePayload {
	label: string;
	uri: string;
	status: any;
	template?: string;
	data?: any;
}
type DocumentAdministrativePayloadKey = keyof DocumentAdministrativePayload;
export class DocumentAdministrative extends BaseModel<
	DocumentAdministrativeInterface,
	DocumentAdministrativePayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.label}`;
	}
	/** Populate the current instance from an object */
	fromObject(object: DocumentAdministrativeInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): DocumentAdministrativeInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.template !== 'string' || props.template.length === 0) {
			props.template = null;
		}
		if (typeof props.data === 'undefined') {
			props.data = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): DocumentAdministrativePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as DocumentAdministrativeInterface);
		return payload as DocumentAdministrativePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): DocumentAdministrativePayloadKey[] {
		return ['label', 'uri', 'status', 'template', 'data'];
	}
}
