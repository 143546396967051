<!-- Display Cards -->
<ng-container *ngIf="!inline">
	<div
		[routerLink]="['/formation', idFormation, 'details']"
		class="clicable p-relative"
	>
		<div class="cards-container formation-cards-container">
			<!-- Cards -->
			<div class="circle-cards">
				<div
					class="content-circle"
					[style.border-color]="color.props.main"
					[style.border-width.px]="2"
				>
					<span class="start-date">{{
						'formation_start_date' | translate
					}}</span>
					<span class="day-card" [style.color]="color.props.main">{{
						date_start | date: 'dd':'':translateService.currentLang
					}}</span>
					<span class="month-card">{{
						date_start
							| date: 'MMMM':'':translateService.currentLang
					}}</span>
					<span class="year-card">{{
						date_start
							| date: 'yyyy':'':translateService.currentLang
					}}</span>
				</div>
			</div>

			<!-- /Cards -->
		</div>
		<div class="name-wrapper">
			<span class="name-card">
				{{ title }}
			</span>
		</div>
	</div>
</ng-container>
<!-- / Display Cards -->

<!-- Display Inline -->
<ng-container *ngIf="inline">
	<a
		class="row d-flex flex-nowrap justify-content-start align-items-center px-0 py-3 m-0 mx-md-4 inline-wrapper"
		[routerLink]="['/formation', idFormation, 'details']"
	>
		<span class="col-5 col-xl-5 sponsor d-md-flex align-items-center">
			{{ title }}
		</span>
		<span
			class="col-2 col-sm-3 col-xl-5 module d-md-flex align-items-center"
		>
			{{ date_start | date: 'dd/MM/yy' }}
		</span>
		<span class="col-3 col-sm-2 date">
			{{ total_respondants }}
		</span>
	</a>
</ng-container>
<!-- / Display Inline -->
