import { Component, OnInit, Input } from '@angular/core';
import { BaseGraphComponent } from '@app/abstracts/base-graph/base-graph.component';
import { environment } from '@env/environment';
import { Breakpoint } from '@app/services/resize.service';
import { Color } from '@app/models/color';

@Component({
	selector: 'app-atom-graph-gauge-svg',
	templateUrl: './atom-graph-gauge-svg.component.html',
	styleUrls: [
		'../../../abstracts/base-graph/base-graph.component.less',
		'./atom-graph-gauge-svg.component.less',
	],
})
export class AtomGraphGaugeSvgComponent extends BaseGraphComponent
	implements OnInit {
	@Input() valueAxe1: number;
	@Input() valueAxe2: number;
	@Input() colors: Color[];
	@Input() labelMin: string;
	@Input() labelMax: string;

	// Used to fix bug with id of gradient when more than one gauge svg
	randomId: string;

	title: string;
	value: number;
	valueMax: number;
	gradient1: { min: string; max: string; opacity: number };
	gradient2: { min: string; max: string; opacity: number };

	protected defaultOptions = environment.graphs.gauge.options;

	initializeGraph(): void {
		this.labelMin =
			this.labelMin.charAt(0).toUpperCase() +
			this.labelMin.slice(1).toLowerCase();
		this.labelMax =
			this.labelMax.charAt(0).toUpperCase() +
			this.labelMax.slice(1).toLowerCase();
		this.randomId = this.getRandomId();
		this.loadColor();
		this.calculateValue();

		this.graph = {
			render: () => null,
		};
	}

	onResize(breakpoint: Breakpoint) {}

	private calculateValue(): void {
		this.valueMax = this.valueAxe1 + this.valueAxe2;
		this.value = this.valueAxe2;

		const label =
			this.value / this.valueMax > 0.5 ? this.labelMin : this.labelMax;
		this.title = `${label}: ${this.value}/${this.valueMax}`;
	}

	private loadColor(): void {
		this.gradient1 = this.colors?.[0]
			? this.getGradient(this.colors[0])
			: { min: '#CACE00', max: '#8CB28B', opacity: 1 };
		this.gradient2 = this.colors?.[1]
			? this.getGradient(this.colors[1])
			: { min: '#00C9E7', max: '#0098CB', opacity: 1 };
	}

	private getGradient(
		color: Color
	): { min: string; max: string; opacity: number } {
		const opacity = color.isDisabled() ? 0.6 : 1;
		return color.isGradient()
			? {
					min: color.props.gradient_min,
					max: color.props.gradient_max,
					opacity,
			  }
			: { min: color.props.main, max: color.props.main, opacity };
	}

	getRandomId(): string {
		return Math.floor(Math.random() * 10000).toString(16);
	}

	async loadAverages(): Promise<void> {
		this.averages = [];
	}
}
