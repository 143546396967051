import { Component, Input } from '@angular/core';
import { Formation } from '@app/models/formation';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-atom-formation-table',
	templateUrl: './atom-formation-table.component.html',
	styleUrls: ['./atom-formation-table.component.less'],
})
export class AtomFormationTableComponent {
	@Input() formations: Formation[];
	colorFormation: string;
	constructor(public translateService: TranslateService) {}
}
