import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
import { Survey, SurveyInterface } from '../survey/survey';
import { User, UserInterface } from '../user/user';
import { Answer, AnswerInterface } from '../answer/answer';
export interface SurveyResultInterface extends BaseModelInterface {
	created_at?: number | Date;
	survey: string | Survey | SurveyInterface;
	owner?: string | User | UserInterface;
	answers: (string | Answer | AnswerInterface)[];
}
export interface SurveyResultPayload {
	survey: string | Survey | SurveyInterface;
	owner: string | User | UserInterface;
	answers: (string | Answer | AnswerInterface)[];
}
type SurveyResultPayloadKey = keyof SurveyResultPayload;
export class SurveyResult extends BaseModel<
	SurveyResultInterface,
	SurveyResultPayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of survey has been populated */
	surveyExists(): boolean {
		return (
			!!this.props &&
			this.props.survey instanceof Survey &&
			this.props.survey.exists()
		);
	}
	/** Denotes if the instance of owner has been populated */
	ownerExists(): boolean {
		return (
			!!this.props &&
			this.props.owner instanceof User &&
			this.props.owner.exists()
		);
	}
	/** Denotes if the instance of answers has been populated */
	answersExists(): boolean {
		return (
			!!this.props &&
			this.props.answers instanceof Array &&
			(<Answer[]>this.props.answers).every((item) => {
				return item instanceof Answer && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: SurveyResultInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.survey === 'object') {
			this.props.survey = new Survey(<SurveyInterface>this.props.survey);
		}
		if (typeof this.props.owner === 'object') {
			this.props.owner = new User(<UserInterface>this.props.owner);
		}
		if (this.props.answers instanceof Array) {
			this.props.answers = (<AnswerInterface[]>this.props.answers).map(
				(item) => {
					return typeof item === 'object' ? new Answer(item) : item;
				}
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): SurveyResultInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.survey instanceof Survey) {
			props.survey = props.survey.toObject();
		}
		if (props.owner instanceof User) {
			props.owner = props.owner.toObject();
		}
		if (this.props.answers instanceof Array) {
			props.answers = (<Answer[]>props.answers).map((item) => {
				return item instanceof Answer ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): SurveyResultPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as SurveyResultInterface);
		payload.survey = payload.survey ? this.extractId(payload.survey) : null;
		payload.answers = payload.answers
			? payload.answers.map((i) => this.extractId(i))
			: null;
		return payload as SurveyResultPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): SurveyResultPayloadKey[] {
		return ['survey', 'answers'];
	}
}
