<div class="w-100 min-h-100 d-flex flex-column flex-nowrap bg-grey-light z1">
	<div class="w-100 min-h-100 d-flex flex-column flex-nowrap flex-grow-1">
		<section
			class="w-100 min-h-100 d-flex flex-row flex-shrink-1 flex-grow-1"
			[class.d-none]="page"
		>
			<app-atom-main-menu
				*ngIf="page && !page.hideMenu"
				class="d-none d-xl-block"
				device="desktop-menu"
			></app-atom-main-menu>
			<main
				class="w-100 flex-shrink-1 flex-grow-1"
				[class.no-header]="!page || page.hideMenu"
			>
				<app-atom-top-bar
					*ngIf="page && !page.hideTopBar"
					[breadcrumbs]="page.breadcrumbs"
				></app-atom-top-bar>
				<div [class.d-none]="!page || page.skeleton">
					<router-outlet #o="outlet"></router-outlet>
				</div>
				<ng-container *ngIf="page && page.skeleton">
					<ng-container [ngSwitch]="page.skeleton">
						<ng-container *ngSwitchCase="'dashboard'">
							<app-atom-skeleton-dashboard></app-atom-skeleton-dashboard>
						</ng-container>
						<ng-container *ngSwitchCase="'detail'">
							<app-atom-skeleton-detail></app-atom-skeleton-detail>
						</ng-container>
						<ng-container *ngSwitchCase="'list'">
							<app-atom-skeleton-list></app-atom-skeleton-list>
						</ng-container>
					</ng-container>
				</ng-container>

				<nz-back-top [nzTemplate]="tpl" [nzVisibilityHeight]="100">
					<ng-template #tpl>
						<i nz-icon nzType="up" nzTheme="outline"></i>
					</ng-template>
				</nz-back-top>
			</main>
		</section>

		<div *ngIf="!page" class="loader-container text-center my-5 py-5">
			<app-atom-concept-loader></app-atom-concept-loader>
		</div>
	</div>
	<!--{{ 'common_error-min' | translate }}-->
	<!--{{ 'common_error-max' | translate }}-->
	<!--{{ 'common_error-required' | translate }}-->
	<!--{{ 'common_error-required_true' | translate }}-->
	<!--{{ 'common_error-email' | translate }}-->
	<!--{{ 'common_error-min_length' | translate }}-->
	<!--{{ 'common_error-max_length' | translate }}-->
	<!--{{ 'common_error-pattern' | translate }}-->
	<!--{{ 'common_error-password_wrong' | translate }}-->
</div>

<!-- Modal Survey -->
<nz-modal
	[nzVisible]="appModalService.modalDisplay === 'survey'"
	[nzFooter]="null"
	[nzTitle]="titleModalSurvey"
	[nzClosable]="surveyStep !== 'end'"
	(nzOnCancel)="cancelSurvey()"
	(nzAfterClose)="surveyStep = 'start'"
	nzWrapClassName="modal-full-screen"
>
	<app-atom-survey
		*ngIf="appModalService.meta.surveyId"
		[surveyId]="appModalService.meta.surveyId"
		[thematiqueId]="appModalService.meta.thematiqueId"
		[surveyNumber]="appModalService.meta.surveyNumber"
		[colorThematique]="appModalService.meta.colorThematique"
		(currentStepChange)="surveyStep = $event"
	>
		<nz-modal
			[nzVisible]="appModalService.alertDisplay"
			[nzTitle]="null"
			[nzFooter]="null"
			(nzOnOk)="appModalService.clear()"
			(nzOnCancel)="appModalService.alertDisplay = false"
			[nzWrapClassName]="'popup-modal'"
		>
			<div class="custom-modal">
				<p>{{ 'survey_modal-close-confirm_message' | translate }}</p>
				<div>
					<button
						nz-button
						nzType="primary"
						(click)="appModalService.clear()"
					>
						{{ 'survey_modal-close-accept_btn' | translate }}
					</button>
					<button
						nz-button
						nzType="default"
						(click)="appModalService.alertDisplay = false"
						class="default-btn"
					>
						{{ 'common_cancel' | translate }}
					</button>
				</div>
			</div>
		</nz-modal>
	</app-atom-survey>
</nz-modal>
<!-- /Modal Survey -->
<ng-template #titleModalSurvey>
	<img src="./assets/svg/logo/logo_NC.svg" alt="Nove Perform" />
</ng-template>

<!-- Modal Report -->
<nz-modal
	[nzVisible]="appModalService.modalDisplay === 'report-moderated-detailled'"
	[nzFooter]="null"
	[nzTitle]="titleModalSurvey"
	(nzOnCancel)="appModalService.clear()"
	nzWrapClassName="modal-full-screen"
>
	<app-atom-respondant-report-moderated-detailled
		*ngIf="
			appModalService.meta.reportModeratedId &&
			appModalService.meta.respondantId
		"
		[respondantId]="appModalService.meta?.respondantId"
		[reportModeratedId]="appModalService.meta?.reportModeratedId"
	></app-atom-respondant-report-moderated-detailled>
</nz-modal>
<!-- /Modal Report -->
