import { Component, Input, OnInit } from '@angular/core';
import { Formation } from '@app/models/formation';
import {
	FormationThematique,
	FormationThematiqueSearchParams,
	FormationThematiqueService,
} from '@app/models/formation-thematique';
import { Image } from '@app/models/image';
import { User } from '@app/models/user';
import { ErrorService } from '@app/services/error.service';
import { NavigationService } from '@app/services/navigation.service';

@Component({
	selector: 'app-atom-sponsor-card',
	templateUrl: './atom-sponsor-card.component.html',
	styleUrls: ['./atom-sponsor-card.component.less'],
})
export class AtomSponsorCardComponent implements OnInit {
	@Input() sponsor: User;
	@Input() inline = false;
	@Input() user: User;
	name: string;
	avatar: string;
	numberRespondant: number;
	numberFormation: number;

	loading = false;

	constructor(
		private formationThematiqueService: FormationThematiqueService,
		private errorService: ErrorService,
		private navigate: NavigationService
	) {}

	async ngOnInit() {
		this.name = this.sponsor.getLabel();
		this.avatar = this.sponsor.avatarExists()
			? (<Image>this.sponsor.props.avatar).getUrl()
			: null;
		await this.refresh();
	}

	async refresh() {
		this.loading = true;
		await this.getFormations();
		this.loading = false;
	}

	async getFormations() {
		//First get all thematique formation of coach
		await this.formationThematiqueService
			.list(
				new FormationThematiqueSearchParams({
					_limit: 100,
					formateur: this.user.getId(),
					_populate: ['formation', 'color'],
				}).toObject()
			)
			.then((result) => {
				//Filter thematique from formation of sponsor's page
				const thematiqueFormations = result.items.filter(
					(m) =>
						(<Formation>m.props.formation).props.sponsor ===
						this.sponsor.getId()
				);
				//Regroup thematiques by formation
				this.groupByFormation(thematiqueFormations);
			})
			.catch((err) => {
				this.errorService.handle(err);
				this.navigate.go(['/sponsor']);
				return null;
			});
	}

	groupByFormation(mod: FormationThematique[]) {
		const listFormation = new Set();
		const listRespondant = new Set();

		//Determine number of formations of sponsor
		for (let i = 0; i < mod.length; i++) {
			listFormation.add((<Formation>mod[i].props.formation).getId());
			(<Formation>mod[i].props.formation).props.respondants.forEach(
				(r) => {
					listRespondant.add(r);
				}
			);
		}
		//Result unique
		this.numberRespondant = listRespondant.size;
		this.numberFormation = listFormation.size;
	}
}
