import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PageBreadcrumbsInterface } from '@app/services/page.service';
import { User, UserService } from '@app/models/user';
import { ErrorService } from '@app/services/error.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NavigationService } from '@app/services/navigation.service';

@Component({
	selector: 'app-atom-top-bar',
	templateUrl: './atom-top-bar.component.html',
	styleUrls: ['./atom-top-bar.component.less'],
})
export class AtomTopBarComponent implements OnInit, OnDestroy {
	@Input() breadcrumbs: PageBreadcrumbsInterface[] = [];
	visible = false;
	private subscriptions: Subscription[] = [];

	// Search bar
	searchInput: string;
	searchedPlayers: User[];
	inputSubject: Subject<void> = new Subject();

	constructor(
		private userService: UserService,
		private errorService: ErrorService,
		private navigationService: NavigationService
	) {}

	ngOnInit() {
		this.subscriptions.push(
			this.inputSubject.pipe(debounceTime(300)).subscribe(() => {
				this.searchPlayers();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.map((s) => s.unsubscribe());
	}

	openMenu(): void {
		this.visible = true;
	}

	closeMenu(): void {
		this.visible = false;
	}

	private async searchPlayers(): Promise<void> {
		if (this.searchInput.length === 0) return;
		try {
			const res = await this.userService.list({
				_page: 0,
				_limit: 30,
				name: this.searchInput,
				_populate: ['avatar'],
			});
			this.searchedPlayers = res.items;
		} catch (e) {
			this.errorService.handle(e);
		}
	}

	async navigateToPlayerPage() {
		const playerId = this.searchedPlayers.find((player) => {
			return (
				`${player.props.first_name} ${player.props.last_name}` ===
				this.searchInput
			);
		});
		await this.navigationService.go([
			'/respondant',
			playerId.getId(),
			'details',
		]);
	}
}
