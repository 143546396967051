import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-atom-skeleton',
	templateUrl: './atom-skeleton.component.html',
	styleUrls: ['./atom-skeleton.component.less'],
})
export class AtomSkeletonComponent implements OnInit {
	@Input() width: number = 100;
	@Input() height: number = 100;
	@Input() animated: boolean = false;
	@Input() circle: boolean = false;
	@Input() square: boolean = false;

	customStyle: {};
	constructor() {}

	ngOnInit(): void {
		this.customStyle = {
			width: this.width + '%',
			height: this.height + 'px',
		};

		if (this.square || this.circle) {
			this.customStyle = {
				...this.customStyle,
				width: this.height + 'px',
			};
		}

		if (this.circle) {
			this.customStyle = { ...this.customStyle, borderRadius: '50%' };
		}
	}
}
