import { NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule } from '@angular/router';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { EditorModule } from '@tinymce/tinymce-angular';
import {
	NZ_I18N,
	fr_FR,
	NzMessageModule,
	NzButtonModule,
	NzFormModule,
	NzInputModule,
	NzSpinModule,
	NzIconModule,
	NzMenuModule,
	NzSelectModule,
	NzEmptyModule,
	NzDatePickerModule,
	NzSliderModule,
	NzRadioModule,
	NzAffixModule,
	NzBreadCrumbModule,
	NzAvatarModule,
	NzDrawerModule,
	NzModalModule,
	NzBackTopModule,
	NzUploadModule,
	NzTransferModule,
	NzAutocompleteModule,
	NzProgressModule,
	NzCalendarModule,
	NzBadgeModule,
	NzPopoverModule,
	NzSkeletonModule,
	NzPaginationModule,
	NzDropDownModule,
} from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import en from '@angular/common/locales/en';
import { RestrictedToRolesDirective } from '@app/directives/restricted-to-roles.directive';
import { SafeHtmlPipe } from '@app/pipes/safe-html.pipe';
import { TranslateEntryPipe } from '@app/pipes/translate-entry.pipe';
import { TranslateCutPipe } from '@app/pipes/translate-cut.pipe';
import { MinPipe } from '@app/pipes/min.pipe';
import { MaxPipe } from '@app/pipes/max.pipe';
import { StripHtmlPipe } from '@app/pipes/strip-html.pipe';
import { I18nImagePipe } from '@app/pipes/i18n-image.pipe';
import { IsNotLoggedGuard } from '@app/guards/is-not-logged.guard';
import { IsLoggedGuard } from '@app/guards/is-logged.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ExplainErrorsService } from '@app/services/explain-errors.service';
import { FacebookFill } from '@ant-design/icons-angular/icons';
import { GlobalErrorService } from '@app/services/global-error.service';
import { ErrorService } from '@app/services/error.service';
import { SessionService } from '@app/services/session.service';
import { EmailConfirmationService } from '@app/services/email-confirmation.service';
import { PasswordService } from '@app/services/password.service';
import { NavigationService } from '@app/services/navigation.service';
import { ResizeService } from '@app/services/resize.service';
import { EncodeHttpParamsInterceptor } from '@app/services/http-interceptor';
import { KebabCasePipe } from '@app/pipes/kebab-case.pipe';
import { RoundPipe } from '@app/pipes/round.pipe';

registerLocaleData(fr);
registerLocaleData(en);
const icons = [FacebookFill];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ScrollingModule,
		RouterModule,
		Nl2BrPipeModule,
		EditorModule,
		NzMessageModule,
		NzButtonModule,
		NzFormModule,
		NzInputModule,
		NzIconModule.forRoot(icons),
		NzSpinModule,
		NzMenuModule,
		NzSelectModule,
		NzEmptyModule,
		NzDatePickerModule,
		NzSliderModule,
		NzRadioModule,
		NzAffixModule,
		NzBreadCrumbModule,
		NzAvatarModule,
		NzDrawerModule,
		NzModalModule,
		NzUploadModule,
		NzBackTopModule,
		NzTransferModule,
		NzAutocompleteModule,
		NzProgressModule,
		NzCalendarModule,
		NzBadgeModule,
		NzPopoverModule,
		NzSkeletonModule,
		NzDropDownModule,
	],
	declarations: [
		SafeHtmlPipe,
		TranslateEntryPipe,
		TranslateCutPipe,
		MinPipe,
		MaxPipe,
		StripHtmlPipe,
		RestrictedToRolesDirective,
		I18nImagePipe,
		KebabCasePipe,
		RoundPipe,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		Nl2BrPipeModule,
		EditorModule,
		SafeHtmlPipe,
		TranslateEntryPipe,
		TranslateCutPipe,
		MinPipe,
		MaxPipe,
		StripHtmlPipe,
		RestrictedToRolesDirective,
		I18nImagePipe,
		NzMessageModule,
		NzButtonModule,
		NzFormModule,
		NzInputModule,
		NzIconModule,
		NzSpinModule,
		NzMenuModule,
		NzSelectModule,
		NzEmptyModule,
		NzDatePickerModule,
		NzSliderModule,
		NzRadioModule,
		NzAffixModule,
		NzBreadCrumbModule,
		NzAvatarModule,
		NzDrawerModule,
		NzUploadModule,
		NzBackTopModule,
		KebabCasePipe,
		RoundPipe,
		NzModalModule,
		NzTransferModule,
		NzAutocompleteModule,
		NzProgressModule,
		NzCalendarModule,
		NzBadgeModule,
		NzPopoverModule,
		NzSkeletonModule,
		NzPaginationModule,
		NzDropDownModule,
	],
})
export class SharedModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: SharedModule,
			providers: [
				{ provide: NZ_I18N, useValue: fr_FR },
				{ provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
				{ provide: 'NAVIGATOR', useFactory: getNavigator },
				{ provide: 'LOCATION', useFactory: getLocation },
				IsNotLoggedGuard,
				IsLoggedGuard,
				{
					provide: ErrorHandler,
					useClass: GlobalErrorService,
				},
				ErrorService,
				SessionService,
				EmailConfirmationService,
				PasswordService,
				NavigationService,
				ResizeService,
				ExplainErrorsService,
				{
					provide: HTTP_INTERCEPTORS,
					useClass: EncodeHttpParamsInterceptor,
					multi: true,
				},
			],
		};
	}
}

export function getLocalStorage() {
	return typeof window !== 'undefined' && window.localStorage
		? window.localStorage
		: null;
}

export function getNavigator() {
	return typeof window !== 'undefined' && window.navigator
		? window.navigator
		: null;
}

export function getLocation() {
	return typeof window !== 'undefined' && window.location
		? window.location
		: null;
}
