import { Component, OnInit, HostListener } from '@angular/core';

@Component({
	selector: 'app-atom-concept-loader',
	templateUrl: './atom-concept-loader.component.html',
	styleUrls: ['./atom-concept-loader.component.less'],
})
export class AtomConceptLoaderComponent implements OnInit {
	@HostListener('window:blur', ['$event'])
	onFocusOut(event) {
		this._focus = false;
	}
	@HostListener('window:focus', ['$event'])
	onFocusIn(event) {
		this._focus = true;
	}
	_focus: boolean = true;

	constructor() {}

	ngOnInit(): void {}
}
