import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
import { User, UserInterface } from '../user/user';
import { environment } from '@env/environment';

export interface ImageInterface extends BaseModelInterface {
	created_at: number | Date;
	owner: string | User | UserInterface;
	uri: string;
	name: string;
}

/** & or ? depending on uri */
const ParamsGlue = environment.images.uri.indexOf('?') > -1 ? '&' : '?';

export interface ImagePayload {
	uri: string;
	name: string;
}
type ImagePayloadKey = keyof ImagePayload;
export class Image extends BaseModel<ImageInterface, ImagePayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}

	/* Return the type of the media */
	getMediaType(): string {
		return 'image';
	}

	/** Denotes if the instance of owner has been populated */
	ownerExists(): boolean {
		return (
			!!this.props &&
			this.props.owner instanceof User &&
			this.props.owner.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: ImageInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.owner === 'object') {
			this.props.owner = new User(<UserInterface>this.props.owner);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ImageInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.owner instanceof User) {
			props.owner = props.owner.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ImagePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ImageInterface);
		return payload as ImagePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): ImagePayloadKey[] {
		return ['uri', 'name'];
	}

	/**
	 * Short function to get full resource url
	 *
	 * @return {number} width
	 * @return {number} height
	 * @return {string}
	 */
	getUrl(width = 1200, height: number = null): string {
		return `${environment.images.uri}/${this.props.uri}&width=${width}&heigth=${height}`;
	}
}
