import { AfterViewInit, Component, OnInit } from '@angular/core';

import KUTE from 'kute.js';

@Component({
	selector: 'app-atom-survey-animation',
	templateUrl: './atom-survey-animation.component.html',
	styleUrls: ['./atom-survey-animation.component.less'],
})
export class AtomSurveyAnimationComponent implements OnInit, AfterViewInit {
	loop: any;
	square1: any;
	square2: any;
	square3: any;
	square4: any;
	square5: any;
	square6: any;
	square7: any;
	square8: any;
	square9: any;
	square10: any;
	square11: any;
	square12: any;
	square13: any;
	square14: any;
	square15: any;
	square16: any;
	square17: any;
	square18: any;
	square19: any;
	square20: any;
	square21: any;

	constructor() {}

	ngOnInit(): void {}

	ngAfterViewInit() {
		this.animationMorph();
	}

	animationMorph() {
		this.square1 = KUTE.to(
			'#square-1-compact',
			{
				path: '#square-1-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square2 = KUTE.to(
			'#square-2-compact',
			{
				path: '#square-2-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square3 = KUTE.to(
			'#square-3-compact',
			{
				path: '#square-3-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square4 = KUTE.to(
			'#square-4-compact',
			{
				path: '#square-4-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square5 = KUTE.to(
			'#square-5-compact',
			{
				path: '#square-5-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square6 = KUTE.to(
			'#square-6-compact',
			{
				path: '#square-6-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square7 = KUTE.to(
			'#square-7-compact',
			{
				path: '#square-7-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square8 = KUTE.to(
			'#square-8-compact',
			{
				path: '#square-8-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square9 = KUTE.to(
			'#square-9-compact',
			{
				path: '#square-9-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square10 = KUTE.to(
			'#square-10-compact',
			{
				path: '#square-10-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square11 = KUTE.to(
			'#square-11-compact',
			{
				path: '#square-11-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square12 = KUTE.to(
			'#square-12-compact',
			{
				path: '#square-12-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square13 = KUTE.to(
			'#square-13-compact',
			{
				path: '#square-13-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square14 = KUTE.to(
			'#square-14-compact',
			{
				path: '#square-14-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square15 = KUTE.to(
			'#square-15-compact',
			{
				path: '#square-15-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square16 = KUTE.to(
			'#square-16-compact',
			{
				path: '#square-16-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square17 = KUTE.to(
			'#square-17-compact',
			{
				path: '#square-17-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square18 = KUTE.to(
			'#square-18-compact',
			{
				path: '#square-18-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square19 = KUTE.to(
			'#square-19-compact',
			{
				path: '#square-19-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square20 = KUTE.to(
			'#square-20-compact',
			{
				path: '#square-20-explosed',
			},
			{ duration: 5000, easing: 'easingExponentialOut' }
		).start();
		this.square21 = KUTE.to(
			'#square-21-compact',
			{
				path: '#square-21-explosed',
			},
			{ delay: 0, duration: 5000, easing: 'easingExponentialOut' }
		).start();
	}
}
