import { Component, OnInit, Input } from '@angular/core';
import { Survey } from '@app/models/survey';
import { Report } from '@app/models/report';
import { FormationThematique } from '@app/models/formation-thematique';

@Component({
	selector: 'app-atom-survey-details',
	templateUrl: './atom-survey-details.component.html',
	styleUrls: ['./atom-survey-details.component.less'],
})
export class AtomSurveyDetailsComponent implements OnInit {
	@Input() survey: Survey;
	@Input() surveyNumber: number;
	@Input() report: Report;
	@Input() thematique: FormationThematique;

	constructor() {}

	ngOnInit(): void {}
}
