import { Component, OnInit, Input } from '@angular/core';
import { FormationThematique } from '@app/models/formation-thematique';
import { Color } from '@app/models/color';
import { TranslateService } from '@ngx-translate/core';
import { Formation } from '@app/models/formation';
import { User } from '@app/models/user';
import { Image } from '@app/models/image';

@Component({
	selector: 'app-atom-formation-thematique-card',
	templateUrl: './atom-formation-thematique-card.component.html',
	styleUrls: ['./atom-formation-thematique-card.component.less'],
})
export class AtomFormationThematiqueCardComponent implements OnInit {
	@Input() formationThematique: FormationThematique;
	@Input() inline = false;
	color: Color;
	avatar: string;
	sponsor: string;

	constructor(public translateService: TranslateService) {}

	ngOnInit(): void {
		if (
			(<Formation>this.formationThematique.props.formation).props.sponsor
		) {
			this.avatar = (<User>(
				(<Formation>this.formationThematique.props.formation).props
					.sponsor
			)).avatarExists()
				? (<Image>(
						(<User>(
							(<Formation>(
								this.formationThematique.props.formation
							)).props.sponsor
						)).props.avatar
				  )).getUrl()
				: null;
			this.sponsor = (<User>(
				(<Formation>this.formationThematique.props.formation).props
					.sponsor
			)).getLabel();
		}

		this.color = <Color>this.formationThematique.props.color;
	}
}
