import {
	Component,
	OnInit,
	Input,
	ElementRef,
	ViewChildren,
	QueryList,
} from '@angular/core';
import {
	ReportModerated,
	ReportModeratedService,
	ReportModeratedSearchParams,
} from '@app/models/report-moderated';
import { User, UserService } from '@app/models/user';
import { Image } from '@app/models/image';
import { Report } from '@app/models/report';

interface RespondantDetailsInterface {
	name: string;
	avatar: string;
	reports: { _id: string; name: string; logo: string }[];
	teams?: {
		name: string;
		avatar: string;
		members: number;
	}[];
}

@Component({
	selector: 'app-atom-respondant-report-moderated-detailled',
	templateUrl: './atom-respondant-report-moderated-detailled.component.html',
	styleUrls: ['./atom-respondant-report-moderated-detailled.component.less'],
})
export class AtomRespondantReportModeratedDetailledComponent implements OnInit {
	@Input() respondantId: string;
	@Input() reportModeratedId: string;

	@ViewChildren('reportModerated') reportModeratedRefs: QueryList<ElementRef>;

	respondant: User;
	reportsModerated: ReportModerated[];
	respondantDetails: RespondantDetailsInterface;

	constructor(
		private reportModeratedService: ReportModeratedService,
		private userService: UserService
	) {}

	async ngOnInit(): Promise<void> {
		await Promise.all([this.loadReportsModerated(), this.loadRespondant()]);

		this.respondantDetails = this.formatRespondantDetails();

		setTimeout(() => {
			if (this.reportModeratedId)
				this.scrollToReportModerated(this.reportModeratedId);
		}, 1000);
	}

	async loadReportsModerated(): Promise<void> {
		const searchParams = new ReportModeratedSearchParams({
			owners: [this.respondantId],
			moderated: true,
			_limit: 100,
			_populate: [
				'report',
				'graphs.graph.axes.axe',
				'graphs.graph.axes.survey',
				'graphs.graph.axes.color',
				'graphs.axes.axe',
				'results.answers.weights',
				'owners.teams.avatar',
			],
		});

		this.reportsModerated = await this.reportModeratedService
			.list(searchParams.toObject())
			.then((res) => res.items);
	}

	async loadRespondant(): Promise<void> {
		this.respondant = await this.userService.get(this.respondantId, {
			_populate: ['avatar', 'reports'],
		});
	}

	formatRespondantDetails(): RespondantDetailsInterface {
		const details: RespondantDetailsInterface = {
			name: this.respondant.getLabel(),
			avatar: this.respondant.avatarExists()
				? (<Image>this.respondant.props.avatar).getUrl()
				: null,
			reports: this.reportsModerated.map((rpM) => ({
				_id: rpM.getId(),
				name: (<Report>rpM.props.report).props._id,
				logo: null,
			})),
		};

		return details;
	}

	scrollToReportModerated(reportModeratedId: string) {
		if (!reportModeratedId || !this.reportsModerated) return;

		const index = this.reportsModerated.findIndex(
			(r) => r.getId() === reportModeratedId
		);

		const refs = [...this.reportModeratedRefs];
		if (index >= 0 && refs[index]) {
			refs[index].nativeElement.scrollIntoView({ behavior: 'smooth' });
		}
	}
}
