import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PageService, PageInterface } from '@app/services/page.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

export type PageSkeletonType = 'dashboard' | 'list' | 'detail' | null;

export abstract class BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	/** @type {Subject<void>} Observables unsubscriber */
	protected unsubscribe: Subject<void> = new Subject<void>();

	protected skeleton: PageSkeletonType = null;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService
	) {}

	ngOnInit() {
		this.refreshPageData(true);
		this.translate.onLangChange
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(() => {
				this.refreshPageData();
			});
	}

	ngOnDestroy() {
		this.pageService.clearPage();
		this.unsubscribe.next();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.refreshPageData();
		}, 500);
	}

	refreshPageData(loading = false) {
		this.pageService.setPage(
			Object.assign(this.getPageData(), {
				skeleton: loading ? this.skeleton : null,
			})
		);
	}

	abstract getPageData(): PageInterface;
}
