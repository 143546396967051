import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { DocumentAdministrative } from '@app/models/document-administrative';
import { DocumentPedagogique } from '@app/models/document-pedagogique';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-atom-formation-table-documents',
	templateUrl: './atom-formation-table-documents.component.html',
	styleUrls: ['./atom-formation-table-documents.component.less'],
})
export class AtomFormationTableDocumentsComponent {
	@Input() title: string;
	@Input() documents: DocumentAdministrative[] | DocumentPedagogique[];
	@Input() disableUpload: boolean;
	@Input() disableGenerate: boolean;
	@Input() disableSend: boolean;
	@Input() disableDelete = true;
	@Input() generateLoading = false;
	@Input() docsToGenerate: string[] = [];

	@Input() showButtonSendGrouped = false;

	@Output() emitGenerate = new EventEmitter<string>(); // generate for a doc type
	@Output() emitUpload = new EventEmitter<File>(); // upload for a doc type
	@Output() emitSendGrouped = new EventEmitter<void>(); // send grouped for a doc type

	@Output() emitReadDocument = new EventEmitter<string>();
	@Output() emitSendDocument = new EventEmitter<string>();
	@Output() emitDeleteDocument = new EventEmitter<string>();
	@Output() emitDownloadDocument = new EventEmitter<{
		idDoc: string;
		filename: string;
	}>();

	@ViewChild('btnsContainer') btnsContainer: ElementRef;

	private selectedFile: File;

	constructor(public translateService: TranslateService) {}

	onFileSelect(event) {
		this.selectedFile = event.target.files[0];
		this.emitUpload.emit(this.selectedFile);
	}

	handleDropdownVisible(isVisible: boolean) {
		if (isVisible) {
			(this.btnsContainer.nativeElement as HTMLElement)
				.querySelector('.btn--generate')
				.classList.add('open');
		} else {
			(this.btnsContainer.nativeElement as HTMLElement)
				.querySelector('.btn--generate')
				.classList.remove('open');
		}
	}
}
