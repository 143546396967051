import { Component, Input, OnInit } from '@angular/core';
import { Color } from '@app/models/color';
import { Formation } from '@app/models/formation';
import { FormationThematique } from '@app/models/formation-thematique';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-atom-formation-card',
	templateUrl: './atom-formation-card.component.html',
	styleUrls: ['./atom-formation-card.component.less'],
})
export class AtomFormationCardComponent implements OnInit {
	@Input() formation: Formation;
	@Input() inline = false;

	idFormation: string;
	title: string;
	date_start: Date;
	color: Color;
	total_respondants: number;
	constructor(public translateService: TranslateService) {}

	ngOnInit(): void {
		this.idFormation = this.formation.getId();
		this.title = this.formation.getLabel();
		this.date_start = this.formation.props.start_date as Date;
		this.color = <Color>this.formation.props.color;
		this.total_respondants = this.formation.countRespondant();
	}
}
