<div
	class="graph-container"
	[style.height]="height"
	[ngClass]="'theme--' + theme + ' graph--average'"
>
	<div
		class="bandeau"
		*ngIf="colorThematique"
		[ngStyle]="
			colorThematique.props.gradient_min &&
			colorThematique.props.gradient_max
				? {
						background:
							'linear-gradient(180deg, ' +
							colorThematique.props.gradient_min +
							', ' +
							colorThematique.props.gradient_max +
							')'
				  }
				: {
						background: colorThematique.props.main
				  }
		"
	></div>
	<div
		class="bandeau"
		*ngIf="!colorThematique"
		[ngStyle]="{
			background: 'linear-gradient(180deg, #79c5f1, #07a1e2)'
		}"
	></div>
	<h2 class="pt-4 mb-3" *ngIf="titleGraph">
		{{ titleGraph }}
	</h2>
	<div class="averages pb-4">
		<div *ngIf="averages && averages.length" class="averages-wrapper">
			<span class="current-average">{{ averages[0].average }}</span
			><span class="max-value">/{{ averages[0].maxAxis }}</span>
			<img
				class="svg-average"
				src="./assets/svg/general/tourbillon_profil.svg"
				alt="decoration average"
			/>
		</div>
	</div>
</div>
