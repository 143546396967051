import { Component, OnInit } from '@angular/core';
import { BaseReportModerated } from '@app/abstracts/base-report-moderated/base-report-moderated.component';
import { Report } from '@app/models/report';

@Component({
	selector: 'app-atom-report-moderated-detailled',
	templateUrl: './atom-report-moderated-detailled.component.html',
	styleUrls: ['./atom-report-moderated-detailled.component.less'],
})
export class AtomReportModeratedDetailledComponent extends BaseReportModerated
	implements OnInit {
	panelActive: boolean = false;
	async ngOnInit(): Promise<void> {
		await super.ngOnInit();
	}

	getReportName(): string {
		return (<Report>this.reportModerated.props.report).props.name;
	}
	getReportId(): string {
		return (<Report>this.reportModerated.props.report).props._id;
	}

	togglePanel(): void {
		this.panelActive = !this.panelActive;
	}
}
