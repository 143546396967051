import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-atom-wheel',
	templateUrl: './atom-wheel.component.html',
	styleUrls: ['./atom-wheel.component.less'],
})
export class AtomWheelComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
