import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MoleculeSignInComponent } from '@app/molecules/connection/molecule-sign-in/molecule-sign-in.component';
import { MoleculeLogoutComponent } from '@app/molecules/connection/molecule-logout/molecule-logout.component';
import { MoleculeMyAccountComponent } from '@app/molecules/connection/molecule-my-account/molecule-my-account.component';
import { IsNotLoggedGuard } from '@app/guards/is-not-logged.guard';
import { IsLoggedGuard } from '@app/guards/is-logged.guard';
import { MoleculeNotFoundComponent } from '@app/molecules/general/molecule-not-found/molecule-not-found.component';
import { MoleculePasswordResetComponent } from '@app/molecules/connection/molecule-password-reset/molecule-password-reset.component';
import { MoleculeLostPasswordComponent } from '@app/molecules/connection/molecule-lost-password/molecule-lost-password.component';
import { MoleculePasswordChangeComponent } from '@app/molecules/connection/molecule-password-change/molecule-password-change.component';
import { MoleculeDashboardComponent } from './molecules/dashboard/molecule-dashboard/molecule-dashboard.component';
import { MoleculeReportModeratedListComponent } from './molecules/report-moderated/molecule-report-list/molecule-report-moderated-list.component';
import { MoleculeRespondantListComponent } from './molecules/respondant/molecule-respondant-list/molecule-respondant-list.component';
import { MoleculeCoachListComponent } from './molecules/coach/molecule-coach-list/molecule-coach-list.component';
import { MoleculeRespondantDetailsComponent } from './molecules/respondant/molecule-respondant-details/molecule-respondant-details.component';
import { MoleculeCoachDetailsComponent } from './molecules/coach/molecule-coach-details/molecule-coach-details.component';
import { MoleculeRespondantEditComponent } from './molecules/respondant/molecule-respondant-edit/molecule-respondant-edit.component';
import { MoleculeSponsorListComponent } from './molecules/sponsor/molecule-sponsor-list/molecule-sponsor-list.component';
import { MoleculeFormationListComponent } from './molecules/formation/molecule-formation-list/molecule-formation-list.component';
import { IsRoleGuard } from './guards/is-role.guard';
import { MoleculeFormationThematiqueListComponent } from './molecules/formation-thematique/molecule-formation-thematique-list/molecule-formation-thematique-list.component';
import { MoleculeFormationThematiqueDetailsComponent } from './molecules/formation-thematique/molecule-formation-thematique-details/molecule-formation-thematique-details.component';
import { MoleculeFormationDetailsComponent } from './molecules/formation/molecule-formation-details/molecule-formation-details.component';
import { MoleculeSponsorDetailsComponent } from './molecules/sponsor/molecule-sponsor-details/molecule-sponsor-details.component';
import { MoleculeReportModeratedDetailsComponent } from './molecules/report-moderated/molecule-report-moderated-details/molecule-report-moderated-details.component';
import { MoleculeFormationListAdminComponent } from './molecules/formation/molecule-formation-list-admin/molecule-formation-list-admin.component';
import { MoleculeFormationInterventionDocument } from './molecules/formation-intervention/molecule-formation-intervention-document.component';
import { MoleculeFormationDetailsAdminComponent } from './molecules/formation/molecule-formation-details-admin/molecule-formation-details-admin.component';
import { MoleculeFormationInterventionDetailsAdminComponent } from './molecules/formation/molecule-formation-intervention-details-admin/molecule-formation-intervention-details-admin.component';
import { MoleculeStagiaireDocumentListComponent } from './molecules/stagiaire/molecule-stagiaire-document-list/molecule-stagiaire-document-list.component';

const routes: Routes = [
	/** Private route */
	{
		path: 'dashboard',
		canActivate: [IsLoggedGuard],
		children: [
			{
				path: '',
				component: MoleculeDashboardComponent,
			},
			{
				path: '**',
				redirectTo: '',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'report',
		canActivate: [IsLoggedGuard],
		children: [
			{
				path: 'list',
				canActivate: [IsRoleGuard],
				data: { roles: ['coach', 'sponsor'] },
				component: MoleculeReportModeratedListComponent,
			},
			{
				path: ':id/details',
				canActivate: [IsRoleGuard],
				data: { roles: ['admin'] },
				component: MoleculeReportModeratedDetailsComponent,
			},
			{
				path: '**',
				redirectTo: 'list',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'respondant',
		canActivate: [IsLoggedGuard, IsRoleGuard],
		data: { roles: ['sponsor'] },
		children: [
			{
				path: 'list',
				component: MoleculeRespondantListComponent,
			},
			{
				path: ':id/details',
				component: MoleculeRespondantDetailsComponent,
				runGuardsAndResolvers: 'always',
			},
			{
				path: ':id/edit',
				component: MoleculeRespondantEditComponent,
			},
			{
				path: '**',
				redirectTo: 'list',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'coach',
		canActivate: [IsLoggedGuard, IsRoleGuard],
		data: { roles: ['admin'] },
		children: [
			{
				path: 'list',
				component: MoleculeCoachListComponent,
			},
			{
				path: ':id/details',
				component: MoleculeCoachDetailsComponent,
			},
			{
				path: '**',
				redirectTo: 'list',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'formations',
		canActivate: [IsLoggedGuard, IsRoleGuard],
		data: { roles: ['admin'] },
		children: [
			{
				path: 'list',
				component: MoleculeFormationListAdminComponent,
			},
			{
				path: ':id/details',
				component: MoleculeFormationDetailsAdminComponent,
			},
			{
				path: ':id/details/:interventionId',
				component: MoleculeFormationInterventionDetailsAdminComponent,
			},
			{
				path: '**',
				redirectTo: 'list',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'sponsor',
		canActivate: [IsLoggedGuard, IsRoleGuard],
		data: { roles: ['coach'] },
		children: [
			{
				path: 'list',
				component: MoleculeSponsorListComponent,
			},
			{
				path: ':id/details',
				component: MoleculeSponsorDetailsComponent,
			},
			{
				path: '**',
				redirectTo: 'list',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'formation',
		canActivate: [IsLoggedGuard, IsRoleGuard],
		data: { roles: ['sponsor'] },
		children: [
			{
				path: 'list',
				pathMatch: 'full',
				component: MoleculeFormationListComponent,
			},
			{
				path: ':id/details',
				pathMatch: 'full',
				component: MoleculeFormationDetailsComponent,
			},
			{
				path: '**',
				redirectTo: 'list',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'formation-intervention/:id/:docType',
		canActivate: [IsLoggedGuard, IsRoleGuard],
		data: { roles: ['coach'] },
		component: MoleculeFormationInterventionDocument,
	},
	{
		path: 'stagiaire',
		canActivate: [IsLoggedGuard, IsRoleGuard],
		data: { roles: ['respondant'] },
		children: [
			{
				path: 'formation/:interventionId/documents',
				component: MoleculeStagiaireDocumentListComponent,
			},
		],
	},
	{
		path: 'session',
		children: [
			{
				path: 'list',
				canActivate: [IsLoggedGuard, IsRoleGuard],
				data: { roles: ['coach'] },
				component: MoleculeFormationThematiqueListComponent,
			},
			{
				path: ':id/details',
				canActivate: [IsLoggedGuard, IsRoleGuard],
				data: { roles: ['coach', 'respondant', 'sponsor'] },
				component: MoleculeFormationThematiqueDetailsComponent,
			},
			{
				path: ':id/user/:userId',
				canActivate: [IsLoggedGuard, IsRoleGuard],
				data: { roles: ['coach', 'respondant', 'sponsor'] },
				component: MoleculeFormationThematiqueDetailsComponent,
			},
			{
				path: '**',
				redirectTo: 'list',
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'my-profile',
		component: MoleculeMyAccountComponent,
		canActivate: [IsLoggedGuard],
	},
	{
		path: 'my-account/change-password',
		component: MoleculePasswordChangeComponent,
		canActivate: [IsLoggedGuard],
	},
	/** /Private route */

	/** Public route */
	{
		path: 'sign-in',
		component: MoleculeSignInComponent,
		canActivate: [IsNotLoggedGuard],
	},
	{
		path: 'logout',
		component: MoleculeLogoutComponent,
		canActivate: [IsLoggedGuard],
	},
	{
		path: 'lost-password',
		component: MoleculeLostPasswordComponent,
		canActivate: [IsNotLoggedGuard],
	},
	{
		path: 'password/reset/:id/:code',
		component: MoleculePasswordResetComponent,
	},
	{
		path: '',
		redirectTo: 'sign-in',
		pathMatch: 'full',
	},
	{
		path: '**',
		component: MoleculeNotFoundComponent,
	},
	/** /Public route */
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			onSameUrlNavigation: 'reload',
		}),
	],
	exports: [RouterModule],
	declarations: [],
})
export class AppRoutingModule {}
