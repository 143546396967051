import { Injectable } from '@angular/core';
import { Answer, AnswerInterface, AnswerPayload } from './answer';
import { AnswerSearchParamsInterface } from './answer-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class AnswerService extends BaseModelService<
	Answer,
	AnswerInterface,
	AnswerPayload,
	AnswerSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'answer';
	}
	/** @inheritDoc */
	protected new(object: AnswerInterface): Answer {
		return new Answer(object);
	}
}
