import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavigationService } from '@app/services/navigation.service';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import { PageInterface, PageService } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

@Component({
	selector: 'app-molecule-lost-password',
	templateUrl: './molecule-lost-password.component.html',
	styleUrls: ['./molecule-lost-password.component.less'],
})
export class MoleculeLostPasswordComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	contactEmail: string;
	constructor(
		private navigation: NavigationService,
		protected pageService: PageService,
		protected translate: TranslateService
	) {
		super(pageService, translate);
	}

	ngOnInit() {
		super.ngOnInit();
		this.refreshPageData();

		this.contactEmail =
			'<a href="mailto:' +
			environment.emailContact +
			'" target="_blank">' +
			environment.emailContact +
			'</a>';
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-lost_password'),
			hideMenu: true,
			hideTopBar: true,
		};
	}

	submit(): void {
		this.navigation.delayed(['/sign-in']);
	}

	onCancel(): void {
		this.navigation.go(['/sign-in']);
	}

	onSuccess(): void {
		this.navigation.delayed(['/sign-in']);
	}
}
