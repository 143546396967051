import { Component, Input, OnInit } from '@angular/core';
import { SurveyResult } from '@app/models/survey-result';
import { User, UserSearchParams } from '@app/models/user';

@Component({
	selector: 'app-atom-report-moderated-user-list',
	templateUrl: './atom-report-moderated-user-list.component.html',
	styleUrls: ['./atom-report-moderated-user-list.component.less'],
})
export class AtomReportModeratedUserListComponent implements OnInit {
	@Input() surveyResults: SurveyResult[];

	@Input() customLink: string;

	respondantSearchParams: UserSearchParams;

	ngOnInit(): void {
		this.respondantSearchParams = new UserSearchParams({
			_populate: ['avatar'],
			_id: this.surveyResults.map(
				(s: SurveyResult) => (<User>s.props.owner).props._id
			),
		});
	}
}
