import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as translationFr from '@assets/i18n/fr.json';
import { Observable, of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModuleWithProviders, PLATFORM_ID } from '@angular/core';

const TRANSLATIONS = {
	fr: translationFr,
};
export class JSONModuleLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return of(TRANSLATIONS[lang]);
	}
}
export function JSONModuleLoaderFactory(http: HttpClient, platform) {
	if (isPlatformBrowser(platform)) {
		return new TranslateHttpLoader(http);
	} else {
		return new JSONModuleLoader();
	}
}

export function TranslateModuleLoad(): ModuleWithProviders<TranslateModule> {
	return TranslateModule.forRoot({
		loader: {
			provide: TranslateLoader,
			useFactory: JSONModuleLoaderFactory,
			deps: [HttpClient, PLATFORM_ID],
		},
	});
}
