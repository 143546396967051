import { Component } from '@angular/core';
import { EntitySelectComponent } from '@app/abstracts';
import { FormationThemeSearchParams } from '../formation-theme-search-params';
import { FormationThemeService } from '../formation-theme.service';
import { FormationTheme } from '../formation-theme';
import { ErrorService } from '@app/services/error.service';
@Component({
	selector: 'hpf-formation-theme-select',
	templateUrl:
		'../../../abstracts/entity-select/entity-select.component.html',
})
export class FormationThemeSelectComponent extends EntitySelectComponent<
	FormationTheme
> {
	protected modelName = 'formation_theme';
	/**
	 * Constructor
	 * @param {FormationThemeService} formationThemeService
	 * @param {ErrorService} errorService
	 */
	constructor(
		private formationThemeService: FormationThemeService,
		protected errorService: ErrorService
	) {
		super(errorService);
	}
	ngOnInit() {
		super.ngOnInit();
	}
	/** @inheritDoc */
	protected async getList(): Promise<FormationTheme[]> {
		const params = new FormationThemeSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		return (await this.formationThemeService.list(params.toObject())).items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<FormationTheme> {
		return await this.formationThemeService.get(id);
	}
}
