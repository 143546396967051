import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentAdministrative } from '@app/models/document-administrative';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-atom-stagiaire-document-table',
	templateUrl: './atom-stagiaire-document-table.component.html',
	styleUrls: ['./atom-stagiaire-document-table.component.less'],
})
export class AtomStagiaireDocumentTableComponent implements OnInit {
	@Input() title: string;
	@Input() documents: DocumentAdministrative[];
	@Input() expiredDate: Date;
	@Output() emitReadDocument = new EventEmitter<string>();
	@Output() emitDownloadDocument = new EventEmitter<{
		idDoc: string;
		filename: string;
	}>();
	@Output() emitGoToIntervention = new EventEmitter<string>();

	isExpired = false;

	constructor(public translateService: TranslateService) {}

	ngOnInit() {
		this.isExpired = this.expiredDate?.getTime() < Date.now();
	}
}
