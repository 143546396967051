import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Survey } from '@app/models/survey';

@Component({
	selector: 'app-atom-survey-start',
	templateUrl: './atom-survey-start.component.html',
	styleUrls: ['./atom-survey-start.component.less'],
})
export class AtomSurveyStartComponent implements OnInit {
	@Input() survey: Survey;
	@Output() surveyStart = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}
}
