<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
	<div class="container-xl px-xl-0 ml-0">
		<div
			class="d-flex flex-wrap justify-content-between align-items-center"
		>
			<h2 class="mb-4">
				{{ 'sponsor-list-title' | translate }}
			</h2>
			<!-- Mode display list-->
			<div class="d-none d-lg-block mb-4 mode-display-container">
				<nz-radio-group>
					<label nz-radio-button nzValue="card"
						><i nz-icon nzType="appstore" nzTheme="outline"></i
					></label>
					<label nz-radio-button nzValue="inline"
						><i nz-icon nzType="bars" nzTheme="outline"></i
					></label>
				</nz-radio-group>
			</div>
			<!-- /Mode display list-->
			<!-- Sort and Filter -->
			<div
				class="d-flex justify-content-start align-items-center sort-container mb-4"
			>
				<nz-select
					[nzPlaceHolder]="'sort_select-placeholder' | translate"
					style="width: 180px; margin-right: 15px;"
				></nz-select>
				<hpf-user-select
					[nullable]="false"
					[emptyLabel]="'common_filter-empty' | translate"
					[filterEnabled]="false"
					[placeholder]="
						'common_filter_search-placeholder' | translate
					"
					[multiple]="false"
					style="width: 180px;"
				></hpf-user-select>
			</div>
			<!-- /Sort and Filter -->
		</div>
	</div>
	<div class="container-xl ml-0">
		<div class="row">
			<div class="col-12 py-4 list-card-container">
				<div *ngFor="let item of items" class="item-card-container">
					<div class="p-relative">
						<div class="cards-container">
							<!-- Cards -->
							<div>
								<div class="content-circle">
									<app-atom-skeleton
										[height]="140"
										[animated]="true"
										[circle]="true"
									></app-atom-skeleton>
								</div>
							</div>
							<!-- /Cards -->
						</div>
						<div class="name-wrapper">
							<span class="name-card">
								<app-atom-skeleton
									[height]="20"
									[animated]="true"
								></app-atom-skeleton>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--/ Infinite List -->
</div>
