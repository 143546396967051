import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-atom-skeleton-detail',
	templateUrl: './atom-skeleton-detail.component.html',
	styleUrls: ['./atom-skeleton-detail.component.less'],
})
export class AtomSkeletonDetailComponent implements OnInit {
	items: number[] = new Array(5);
	constructor() {}

	ngOnInit(): void {}
}
