import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {
	FormationThematiqueSearchParams,
	FormationThematiqueService,
} from '@app/models/formation-thematique';
import { ErrorService } from '@app/services/error.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-atom-thematique-formation-counter',
	templateUrl: './atom-thematique-formation-counter.component.html',
	styleUrls: ['./atom-thematique-formation-counter.component.less'],
})
export class AtomThematiqueFormationCounterComponent implements OnInit {
	/** @type {Subject<void>} Observables unsubscriber */
	protected unsubscribe: Subject<void> = new Subject<void>();

	@Input() searchParams: FormationThematiqueSearchParams;
	loading = true;
	count: number;

	constructor(
		private formationThematiqueService: FormationThematiqueService,
		private errorService: ErrorService
	) {}

	ngOnInit() {
		this.refresh();

		this.searchParams.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
			this.refresh();
		});
	}

	ngOnDestroy() {
		this.unsubscribe.next();
	}

	async refresh() {
		this.loading = true;
		if (this.searchParams) {
			const params = this.searchParams.toObjectSanitizedForCount();
			this.count = await this.formationThematiqueService
				.count(params)
				.catch((err) => {
					this.errorService.handle(err);
					return null;
				});
		}
		this.loading = false;
	}
}
