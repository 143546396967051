import { Component } from '@angular/core';
import { Color } from '@app/models/color';
import { BaseCalendarComponent } from '../base/base-calendar.component';

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

registerLocaleData(en);

@Component({
	selector: 'app-atom-mobile-calendar',
	templateUrl: './atom-mobile-calendar.component.html',
	styleUrls: ['./atom-mobile-calendar.component.less'],
})
export class AtomMobileCalendarComponent extends BaseCalendarComponent {
	visible: boolean = false;

	//Event call after each filter change of calendar
	changeFiltre(date: Date): void {
		this.date_min = new Date(
			date.getFullYear(),
			date.getMonth(),
			1,
			0,
			0,
			0
		);
		this.date_max = new Date(
			date.getFullYear(),
			date.getMonth(),
			new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(),
			23,
			59,
			59
		);
		if (
			date.getMonth() !== this.date.getMonth() ||
			date.getFullYear() !== this.date.getFullYear()
		) {
			this.refresh();
			this.date = date;
		} else {
			this.date = date;
		}
	}

	calendarFormat(items, mode): void {
		const size =
			mode === 'month'
				? new Date(
						this.date_max.getFullYear(),
						this.date_max.getMonth() + 1,
						0
				  ).getDate()
				: 12;

		for (let i = 0; i < size; i++) {
			this.listDateMap = [...new Array(size + 1)].map((_) => []);
		}
		for (let item of items) {
			const day = <Date>item.props.start_date;
			const index = Number(
				mode === 'month' ? day.getUTCDate() : day.getMonth()
			);
			this.listDateMap[index].push({
				id: item.props._id,
				title: item.props.name,
				color: <Color>item.props.color,
				month: day.getMonth(),
			});
		}
	}

	selectChange(event) {
		const date = new Date(event);
		this.changeFiltre(date);
	}
}
