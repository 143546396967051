import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	ViewChild,
	ElementRef,
	Input,
	OnDestroy,
	AfterViewInit,
} from '@angular/core';

@Component({
	selector: 'app-atom-intersection-observer',
	templateUrl: './atom-intersection-observer.component.html',
	styleUrls: ['./atom-intersection-observer.component.less'],
})
export class AtomIntersectionObserverComponent
	implements OnDestroy, AfterViewInit {
	@Input() options = {};
	@Output() onIntersection = new EventEmitter();
	@ViewChild('anchor') anchor: ElementRef<HTMLElement>;

	private observer: IntersectionObserver;

	constructor() {}

	ngAfterViewInit() {
		// When intersection happen, emit onIntersection event
		this.observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) this.onIntersection.emit();
			});
		}, this.options);

		// Init anchor element observation
		this.observer.observe(this.anchor.nativeElement);
	}

	ngOnDestroy() {
		this.observer.disconnect();
	}
}
