import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
import { Report, ReportInterface } from '../report/report';
import { User, UserInterface } from '../user/user';
import {
	GraphModerated,
	GraphModeratedInterface,
} from '../graph-moderated/graph-moderated';
import {
	SurveyResult,
	SurveyResultInterface,
} from '../survey-result/survey-result';
import {
	FormationThematique,
	FormationThematiqueInterface,
} from '../formation-thematique/formation-thematique';
import { Answer } from '../answer';
import { AxeWeight } from '../axe-weight';
import { Survey } from '../survey';
import { Axe } from '../axe';
export interface ReportModeratedInterface extends BaseModelInterface {
	created_at: number | Date;
	report: string | Report | ReportInterface;
	owners: (string | User | UserInterface)[];
	graphs: (string | GraphModerated | GraphModeratedInterface)[];
	results: (string | SurveyResult | SurveyResultInterface)[];
	moderated: boolean;
	moderated_at: number | Date;
	grouped?: boolean;
	respondant_count?: number;
	formation_thematique?:
		| string
		| FormationThematique
		| FormationThematiqueInterface;
}
export interface ReportModeratedPayload {
	report: string | Report | ReportInterface;
	owners: (string | User | UserInterface)[];
	graphs: (string | GraphModerated | GraphModeratedInterface)[];
	results: (string | SurveyResult | SurveyResultInterface)[];
	moderated: boolean;
	grouped?: boolean;
	respondant_count?: number;
	formation_thematique?:
		| string
		| FormationThematique
		| FormationThematiqueInterface;
}
type ReportModeratedPayloadKey = keyof ReportModeratedPayload;

export interface ReportModeratedWeightsInterface {
	[key: string]: {
		[key: string]: number;
	};
}

export class ReportModerated extends BaseModel<
	ReportModeratedInterface,
	ReportModeratedPayload
> {
	private weights: ReportModeratedWeightsInterface;

	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of report has been populated */
	reportExists(): boolean {
		return (
			!!this.props &&
			this.props.report instanceof Report &&
			this.props.report.exists()
		);
	}
	/** Denotes if the instance of owners has been populated */
	ownersExists(): boolean {
		return (
			!!this.props &&
			this.props.owners instanceof Array &&
			(<User[]>this.props.owners).every((item) => {
				return item instanceof User && item.exists();
			})
		);
	}
	firstOwnerExists(): boolean {
		return this.ownersExists() && this.props.owners[0] instanceof User;
	}
	getFirstOwner(): User {
		return this.firstOwnerExists() ? <User>this.props.owners[0] : null;
	}
	/** Denotes if the instance of graphs has been populated */
	graphsExists(): boolean {
		return (
			!!this.props &&
			this.props.graphs instanceof Array &&
			(<GraphModerated[]>this.props.graphs).every((item) => {
				return item instanceof GraphModerated && item.exists();
			})
		);
	}
	/** Denotes if the instance of results has been populated */
	resultsExists(): boolean {
		return (
			!!this.props &&
			this.props.results instanceof Array &&
			(<SurveyResult[]>this.props.results).every((item) => {
				return item instanceof SurveyResult && item.exists();
			})
		);
	}
	/** Denotes if the instance of formation thematique has been populated */
	formationThematiqueExists(): boolean {
		return (
			!!this.props &&
			this.props.formation_thematique instanceof FormationThematique &&
			this.props.formation_thematique.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: ReportModeratedInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.props.moderated_at = Helpers.convertToDate(
			this.props.moderated_at
		);
		if (typeof this.props.report === 'object') {
			this.props.report = new Report(<ReportInterface>this.props.report);
		}
		if (this.props.owners instanceof Array) {
			this.props.owners = (<UserInterface[]>this.props.owners).map(
				(item) => {
					return typeof item === 'object' ? new User(item) : item;
				}
			);
		}
		if (this.props.graphs instanceof Array) {
			this.props.graphs = (<GraphModeratedInterface[]>(
				this.props.graphs
			)).map((item) => {
				return typeof item === 'object'
					? new GraphModerated(item)
					: item;
			});
		}
		if (this.props.results instanceof Array) {
			this.props.results = (<SurveyResultInterface[]>(
				this.props.results
			)).map((item) => {
				return typeof item === 'object' ? new SurveyResult(item) : item;
			});
		}
		if (
			this.props.formation_thematique !== null &&
			typeof this.props.formation_thematique === 'object'
		) {
			this.props.formation_thematique = new FormationThematique(
				<FormationThematiqueInterface>this.props.formation_thematique
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): ReportModeratedInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.grouped === 'undefined') {
			props.grouped = null;
		}
		if (typeof props.respondant_count === 'undefined') {
			props.respondant_count = null;
		}
		if (typeof props.formation_thematique === 'undefined') {
			props.formation_thematique = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		props.moderated_at = Helpers.convertToTimestamp(props.moderated_at);
		if (props.report instanceof Report) {
			props.report = props.report.toObject();
		}
		if (this.props.owners instanceof Array) {
			props.owners = (<User[]>props.owners).map((item) => {
				return item instanceof User ? item.toObject() : item;
			});
		}
		if (this.props.graphs instanceof Array) {
			props.graphs = (<GraphModerated[]>props.graphs).map((item) => {
				return item instanceof GraphModerated ? item.toObject() : item;
			});
		}
		if (this.props.results instanceof Array) {
			props.results = (<SurveyResult[]>props.results).map((item) => {
				return item instanceof SurveyResult ? item.toObject() : item;
			});
		}
		if (
			props.formation_thematique !== null &&
			props.formation_thematique instanceof FormationThematique
		) {
			props.formation_thematique = props.formation_thematique.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): ReportModeratedPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as ReportModeratedInterface);
		payload.report = payload.report ? this.extractId(payload.report) : null;
		payload.owners = payload.owners
			? payload.owners.map((i) => this.extractId(i))
			: null;
		payload.graphs = payload.graphs
			? payload.graphs.map((i) => this.extractId(i))
			: null;
		payload.results = payload.results
			? payload.results.map((i) => this.extractId(i))
			: null;
		payload.formation_thematique = payload.formation_thematique
			? this.extractId(payload.formation_thematique)
			: null;
		return payload as ReportModeratedPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): ReportModeratedPayloadKey[] {
		return [
			'report',
			'owners',
			'graphs',
			'results',
			'moderated',
			'grouped',
			'respondant_count',
			'formation_thematique',
		];
	}

	/** Calculate the weight of report regroupe by survey/axe */
	getWeights(userId: string = null): ReportModeratedWeightsInterface {
		if (this.isNew()) return;

		const weights = {};
		const respondants = new Set();
		for (let i = 0; i < this.props.results.length; i++) {
			if (userId) {
				const index = this.props.results.findIndex(
					(survey: SurveyResult) =>
						(<User>survey.props.owner).props._id == userId
				);
				i = index;
				if (!(this.props.results[i] instanceof SurveyResult)) break;
			}
			if (!(this.props.results[i] instanceof SurveyResult)) continue;
			const surveyResult = <SurveyResult>this.props.results[i];
			const surveyId = surveyResult.surveyExists()
				? (<Survey>surveyResult.props.survey).getId()
				: <string>surveyResult.props.survey;

			// Count respondants
			respondants.add(
				surveyResult.props.owner instanceof User
					? (<User>surveyResult.props.owner).getId()
					: surveyResult.props.owner
			);

			// Init survey's weights
			if (!weights[surveyId]) weights[surveyId] = {};

			// Index results with survey key
			(<Answer[]>surveyResult.props.answers).forEach((answer) => {
				// Loop throw answers and sum weights in the result object
				for (const weight of <AxeWeight[]>answer.props.weights) {
					const axeId = weight.axeExists()
						? (<Axe>weight.props.axe).getId()
						: <string>weight.props.axe;
					if (!weights[surveyId][axeId]) weights[surveyId][axeId] = 0;
					weights[surveyId][axeId] += weight.props.weight;
				}
			});
			if (userId) break;
		}

		// Apply average
		if (this.props.grouped) {
			const respondantCount = respondants.size;

			if (respondantCount > 0) {
				Object.entries(weights).forEach(([survey, axes]) => {
					Object.entries(axes).forEach(([axe, weight]) => {
						weights[survey][axe] = +(
							weight / respondantCount
						).toFixed(2); // Round to 2 decimals, convert into number with the "+"
					});
				});
			}
		}
		return weights;
	}
}
