<!-- Display Cards -->
<ng-container *ngIf="!inline">
	<div
		[routerLink]="['/sponsor', sponsor.getId(), 'details']"
		class="clicable p-relative"
	>
		<div class="cards-container sponsor-cards-container">
			<!-- Cards -->
			<div class="circle-cards">
				<div
					class="content-circle"
					[style.border-color]="!avatar ? '#bfc200' : 'inherit'"
					[style.border-width.px]="!avatar ? 2 : 1"
				>
					<nz-avatar
						class="respondant-avatar"
						[ngClass]="avatar ? 'avatarPhoto' : 'avatarIcone'"
						[nzSize]="140"
						nzIcon="user"
						[nzSrc]="avatar"
					></nz-avatar>
				</div>
			</div>
			<!-- /Cards -->
		</div>
		<div class="name-wrapper">
			<span class="name-card">
				{{ name }}
			</span>
		</div>
	</div>
</ng-container>
<!-- / Display Cards -->

<!-- Display Inline -->
<ng-container *ngIf="inline">
	<a
		class="row d-flex flex-nowrap justify-content-start align-items-center px-0 py-3 m-0 mx-md-4 inline-wrapper"
		[routerLink]="['/session', sponsor.getId(), 'details']"
	>
		<span class="col-5 col-xl-5 sponsor d-md-flex align-items-center">
			<nz-avatar
				class="respondant-avatar"
				[ngClass]="!avatar ? 'avatarPhoto' : 'avatarIcone'"
				[nzSize]="60"
				nzIcon="user"
				[nzSrc]="avatar"
			></nz-avatar>

			{{ sponsor.getLabel() }}
		</span>
		<span
			class="col-2 col-sm-3 col-xl-5 formation d-md-flex align-items-center"
		>
			{{ numberFormation }}
		</span>
		<span class="col-3 col-sm-2 respondants">
			{{ numberRespondant }}
		</span>
	</a>
</ng-container>
<!-- / Display Inline -->
