<aside class="formation-filter">
	<div class="formation-filter__wrapper">
		<button
			(click)="closePanel.emit(false)"
			class="formation-filter__btn--close"
		>
			<i nz-icon>
				<svg
					width="54"
					height="54"
					viewBox="0 0 54 54"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M39.0373 12.5217L26.5156 25.0434L13.9939 12.5217L12.5208 13.9948L25.0425 26.5165L12.5208 39.0382L13.9939 40.5113L26.5156 27.9896L39.0373 40.5113L40.5104 39.0382L27.9888 26.5165L40.5104 13.9948L39.0373 12.5217Z"
						fill="black"
					/>
				</svg>
			</i>
		</button>
		<form
			nz-form
			class="formation-filter__form d-flex flex-column w-100"
			[formGroup]="form"
		>
			<h3 class="form__title">
				{{ 'common_filter' | translate }}
			</h3>

			<div class="d-flex flex-column inputs-container w-100">
				<div class="form__group">
					<h4 class="form__group__subtitle">
						{{ 'filter-sponsor-subtitle' | translate }}
					</h4>
					<nz-form-item>
						<nz-form-control>
							<hpf-user-select
								[isAdmin]="true"
								[formGroup]="form"
								[roleFilter]="'sponsor'"
								[controlName]="'sponsor'"
								[nullable]="true"
								[filterEnabled]="false"
								[placeholder]="'filter-placeholder' | translate"
								[multiple]="false"
							></hpf-user-select>
						</nz-form-control>
					</nz-form-item>
				</div>

				<div class="form__group">
					<h4 class="form__group__subtitle">
						{{ 'filter-state-subtitle' | translate }}
					</h4>
					<div class="form__group--splitted">
						<nz-form-item>
							<label class="form__group__label">
								{{ 'filter-state-label-admin' | translate }}
							</label>
							<nz-form-control>
								<nz-select
									formControlName="stateAdmin"
									[nzAllowClear]="true"
									[nzPlaceHolder]="
										'filter-placeholder' | translate
									"
								>
									<nz-option
										*ngFor="let state of statesAdmin"
										[nzValue]="state.value"
										[nzLabel]="state.label | translate"
									>
									</nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
						<span> {{ 'common_or' | translate }}</span>
						<nz-form-item>
							<label class="form__group__label">
								{{ 'filter-state-label-session' | translate }}
							</label>
							<nz-form-control>
								<nz-select
									formControlName="state"
									[nzAllowClear]="true"
									[nzPlaceHolder]="
										'filter-placeholder' | translate
									"
								>
									<nz-option
										*ngFor="let state of states"
										[nzValue]="state.value"
										[nzLabel]="state.label | translate"
									>
									</nz-option>
								</nz-select>
							</nz-form-control>
						</nz-form-item>
					</div>
				</div>
				<div class="form__group">
					<h4 class="form__group__subtitle">
						{{ 'filter-date-subtitle' | translate }}
					</h4>
					<nz-form-item>
						<nz-range-picker
							formControlName="dateRange"
							[nzSize]="'large'"
							[nzFormat]="'dd/MM/yyyy'"
						></nz-range-picker>
					</nz-form-item>
				</div>
			</div>
			<div class="d-flex btns-container">
				<button
					nz-button
					nzType="default"
					class="cancel-btn"
					(click)="handleFilter()"
				>
					{{ 'common_filter' | translate }}
				</button>
			</div>
		</form>
	</div>
</aside>
