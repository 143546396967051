import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-atom-model-counter',
	templateUrl: './atom-model-counter.component.html',
	styleUrls: ['./atom-model-counter.component.less'],
})
export class AtomModelCounterComponent implements OnInit {
	/** Label of the model */
	@Input() label: string;

	@Input() customClass: string;
	/**
	 * Count of the model.
	 * Can be undefined, in case of async count function. Count is ready when loading = false
	 */
	@Input() count: number;
	/** Icon template of the model */
	@Input() iconTpl: TemplateRef<void>;
	/** Allow to know if count is already loaded */
	@Input() loading: boolean;
	/** Link to access to thee full list */
	@Input() linkFullList: string | string[];

	constructor() {}

	ngOnInit() {}
}
