import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { BaseGraphComponent } from '@app/abstracts/base-graph/base-graph.component';
import { environment } from '@env/environment';
import { GroupedColumn } from '@antv/g2plot';
import { Breakpoint } from '@app/services/resize.service';
import { Color } from '@app/models/color';

@Component({
	selector: 'app-atom-graph-grouped-bar',
	templateUrl: '../../../abstracts/base-graph/base-graph.component.html',
	styleUrls: ['../../../abstracts/base-graph/base-graph.component.less'],
})
export class AtomGraphGroupedBarComponent extends BaseGraphComponent
	implements OnInit, OnChanges {
	private mobileSize = 25;
	private desktopSize = 40;

	@ViewChild('graphDiv', { static: true }) graphDiv: ElementRef;

	@Input() data: { x: string; y: number; groupBy: string }[] = [];
	@Input() colors: Color[];
	@Input() xLabel: string;
	@Input() yLabel: string;
	@Input() maxAxis: number;

	typeGraph: string = 'grouped_bar';

	protected defaultOptions = environment.graphs.grouped_bar.options;

	ngOnChanges(changes: SimpleChanges): void {
		if (this.isPrinting) {
			this.graph.updateConfig({
				label: environment.graphsPrint.grouped_bar.options.label,
				xAxis: environment.graphsPrint.grouped_bar.options.xAxis,
				renderer: environment.graphsPrint.bar.options.renderer,
			});
			this.graph.render();
		}

		if (!this.isPrinting && changes.isPrinting.previousValue) {
			this.graph.updateConfig({
				label: environment.graphs.grouped_bar.options.label,
				xAxis: environment.graphs.grouped_bar.options.xAxis,
				renderer: environment.graphs.bar.options.renderer,
			});
			this.graph.render();
		}
	}

	initializeGraph() {
		this.data.map((d) => {
			d.x = d.x.charAt(0).toUpperCase() + d.x.slice(1).toLowerCase();
		});

		const options: any = Object.assign(this.options, {
			data: this.data,
			color: this.colors.map((c) => c.toAnt()),
			meta: {
				x: {
					alias: this.xLabel,
				},
				y: {
					alias: this.yLabel,
				},
			},
			columnSize:
				this.breakpoint.label === 'xs'
					? this.mobileSize
					: this.desktopSize,
		});

		if (this.maxAxis) {
			options.yAxis.max = this.maxAxis;
		}

		this.graph = new GroupedColumn(this.graphDiv.nativeElement, options);
	}

	onResize(breakpoint: Breakpoint) {
		this.graph.updateConfig({
			columnSize:
				this.breakpoint.label === 'xs'
					? this.mobileSize
					: this.desktopSize,
		});
		this.graph.render();
	}

	async loadAverages(): Promise<void> {
		const averageData: {
			[key: string]: {
				name: string;
				sum: number;
				count: number;
			};
		} = {};

		this.data.forEach((d) => {
			if (!averageData[d.groupBy]) {
				averageData[d.groupBy] = { name: d.groupBy, sum: 0, count: 0 };
			}

			averageData[d.groupBy].sum += d.y;
			averageData[d.groupBy].count += 1;
		});

		this.averages = Object.entries(averageData).map(([survey, data]) => ({
			name: survey,
			average: Number.parseFloat((data.sum / data.count).toFixed(2)),
			maxAxis: this.maxAxis ?? 5,
		}));
	}
}
