<div *ngIf="!loading" class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
	<div
		class="container-xl px-0 px-xl-0 ml-0 d-flex flex-wrap justify-content-between"
	>
		<div
			class="order-1 px-3 px-xl-0 d-flex justify-content-between align-items-center mb-0 mb-md-4 mt-4 mt-md-0"
		>
			<h2 class="mb-0">{{ 'my_profile-title' | translate }}</h2>
		</div>
		<div
			class="order-3 order-md-2 px-3 px-xl-0 d-flex justify-content-md-center justify-content-md-start align-items-center btns-container mb-4 mt-5 mt-md-0"
		>
			<button
				nz-button
				nzType="primary"
				[nzLoading]="saving"
				(click)="save()"
				type="submit"
			>
				{{ 'common_save' | translate }}
			</button>
			<button
				nz-button
				nzType="default"
				(click)="resetForm()"
				[routerLink]="['/dashboard']"
				class="cancel-btn ml-2"
			>
				{{ 'common_cancel' | translate }}
			</button>
		</div>
		<form
			nz-form
			class="user-form w-100 order-2 order-md-3"
			[formGroup]="form"
			[nzAutoTips]="explainErrorsService.autoTips"
		>
			<div class="col-12 p-4 mt-4 form-container">
				<h3>
					{{ 'my_profile-personel-informations_form' | translate }}
				</h3>
				<div
					class="d-flex flex-column flex-md-row align-items-start align-items-md-center"
				>
					<div class="d-flex flex-column inputs-container w-100">
						<!-- Last Name -->
						<nz-form-item>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="'user_last-name' | translate"
									formControlName="last_name"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Last Name -->
						<!-- First Name -->
						<nz-form-item *ngIf="user.props.role !== 'sponsor'">
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="
										'user_first-name' | translate
									"
									formControlName="first_name"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /First Name -->
						<!-- Poste -->
						<nz-form-item *ngIf="user.props.role === 'respondant'">
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="'user_poste' | translate"
									formControlName="position"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Poste -->
						<!-- Address -->
						<nz-form-item *ngIf="user.props.role === 'sponsor'">
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="'user_address' | translate"
									formControlName="address"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Address -->
						<!-- Phone number -->
						<nz-form-item *ngIf="user.props.role === 'sponsor'">
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="
										'user_phone-number' | translate
									"
									formControlName="phone_number"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Phone number -->
						<!-- Department -->
						<nz-form-item
							*ngIf="
								user.props.role === 'respondant' ||
								user.props.role === 'sponsor'
							"
						>
							<nz-form-control>
								<input
									nz-input
									class="w-100"
									type="text"
									[placeholder]="
										'user_department' | translate
									"
									formControlName="department"
								/>
							</nz-form-control>
						</nz-form-item>
						<!-- /Department -->
					</div>
					<div
						*ngIf="user.props.role !== 'respondant'"
						class="d-flex flex-row align-items-center avatars-container"
					>
						<!-- Avatar -->
						<app-atom-upload
							(temporaryUrl)="updateUri($event)"
							acceptedFileTypes="image/*"
							(remove)="updateUri(undefined)"
							[placeholder]="'common-upload-image' | translate"
						></app-atom-upload>
						<app-atom-media-player
							[media]="user.props.avatar"
							class="ml-4"
						></app-atom-media-player>
						<!-- /Avatar -->
					</div>
				</div>
			</div>
			<div class="col-12 p-4 mt-4 form-container">
				<h3>
					{{ 'my_profile-connexion-informations_form' | translate }}
				</h3>
				<!-- Email -->
				<nz-form-item class="mt-4">
					<nz-form-control>
						<input
							nz-input
							class="w-100"
							type="email"
							[placeholder]="'user_email' | translate"
							formControlName="email"
						/>
					</nz-form-control>
				</nz-form-item>
				<!-- /Email -->
				<!-- Password -->
				<nz-form-item class="w-100 mt-4">
					<nz-form-control>
						<nz-input-group [nzSuffix]="suffixTemplate">
							<input
								nz-input
								[type]="passwordVisible ? 'text' : 'password'"
								placeholder="{{
									'atom-sign-in-input-password' | translate
								}}"
								formControlName="password"
								autocomplete="off"
							/>
						</nz-input-group>
						<ng-template #suffixTemplate>
							<i
								nz-icon
								[nzType]="
									passwordVisible ? 'eye-invisible' : 'eye'
								"
								(click)="passwordVisible = !passwordVisible"
							></i>
						</ng-template>
					</nz-form-control>
				</nz-form-item>
			</div>
		</form>
	</div>
</div>

<ng-template #successTemplate>
	{{ 'common_save' | translate }}
</ng-template>
