<div class="table__container">
	<div class="table__header">
		<h2 class="table__title">
			{{ title }}
		</h2>
		<!--
		<div class="d-flex btns-container">
			<button
				nz-button
				nzType="primary"
				(click)="emitGoToIntervention.emit()"
			>
				{{ 'stagiaire-download-docs-btn' | translate }}
			</button>
		</div>
		-->
	</div>
	<div class="table__wrapper">
		<table>
			<thead>
				<tr>
					<th>
						{{ 'table_head-stagiaire-doc-name' | translate }}
					</th>
					<th>
						{{ 'table_head-stagiaire-doc-date' | translate }}
					</th>
					<th>
						{{ 'table_head-stagiaire-doc-action' | translate }}
					</th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngIf="!isExpired; else expiredDocTpl">
					<ng-container *ngIf="documents.length; else noDocTpl">
						<tr
							*ngFor="let document of documents; let i = index"
							class="clicable"
						>
							<td class="label">
								<a
									(click)="
										emitReadDocument.emit(document.getId())
									"
									>{{ document.getLabel() }}</a
								>
							</td>

							<td class="date">
								<span>
									{{
										expiredDate
											? (expiredDate
											  | date
													: 'dd MMMM yyyy'
													: ''
													: translateService.currentLang)
											: ' - '
									}}
								</span>
							</td>
							<td>
								<div class="actions">
									<button
										nz-button
										nzType="default"
										class="icon-btn"
										[title]="'common_read' | translate"
										(click)="
											emitDownloadDocument.emit({
												idDoc: document.getId(),
												filename: document.getLabel()
											})
										"
									>
										<i
											nz-icon
											nzType="download"
											nzTheme="outline"
										></i>
									</button>
								</div>
							</td>
						</tr>
					</ng-container>
				</ng-container>
				<ng-template #expiredDocTpl>
					<tr>
						<td colspan="4">
							{{
								'expired-docs-for-respondant'
									| translate
										: {
												dateExpiration: expiredDate
													? (expiredDate
													  | date
															: 'dd MMMM yyyy'
															: ''
															: translateService.currentLang)
													: ' - '
										  }
							}}
						</td>
					</tr>
				</ng-template>
				<ng-template #noDocTpl>
					<tr>
						<td colspan="4">
							{{ 'empty-docs-for-respondant' | translate }}
						</td>
					</tr>
				</ng-template>
			</tbody>
		</table>
	</div>
</div>
