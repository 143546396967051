import { Component, OnInit, Input } from '@angular/core';
import { User, UserSearchParams } from '@app/models/user';
import { ReportModeratedSearchParams } from '@app/models/report-moderated';
import { FormationSearchParams, FormationService } from '@app/models/formation';
import { FormationThematiqueSearchParams } from '@app/models/formation-thematique';
import { ErrorService } from '@app/services/error.service';

@Component({
	selector: 'app-atom-dashboard-sponsor',
	templateUrl: './atom-dashboard-sponsor.component.html',
	styleUrls: ['./atom-dashboard-sponsor.component.less'],
})
export class AtomDashboardSponsorComponent implements OnInit {
	@Input() user: User;

	reportModeratedSearchParams: ReportModeratedSearchParams;
	formationSearchParams = new FormationSearchParams({ _populate: ['color'] });
	respondantSearchParams = new UserSearchParams({
		_populate: ['avatar'],
	});
	formationThematiqueSearchParams: FormationThematiqueSearchParams;

	loading = false;
	listIdFormation: string[] = [];
	numberReports: number;
	numberRespondants: number;

	constructor(
		private formationService: FormationService,
		private errorService: ErrorService
	) {}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		await this.getFormationId();
		this.loading = false;

		this.formationThematiqueSearchParams = new FormationThematiqueSearchParams(
			{
				_page: 0,
				_limit: 100,
				_sort: 'created_at',
				_populate: ['color'],
				formation: this.listIdFormation,
			}
		);

		this.respondantSearchParams = new UserSearchParams({
			_populate: ['avatar'],
			coachs: [this.user.getId()],
		});

		this.reportModeratedSearchParams = new ReportModeratedSearchParams({
			_page: 0,
			_limit: 100,
			moderated: true,
			grouped: true,
			owners: [this.user.getId()],
			_populate: [
				'owners.avatar',
				'report',
				'formation_thematique.color',
				'formation_thematique.formation.sponsor.avatar',
			],
		});
	}

	async getFormationId() {
		const formationSearchParams = new FormationSearchParams({
			_page: 0,
			_limit: 100,
		});
		await this.formationService
			.list(formationSearchParams.toObject())
			.then((result) =>
				result.items.map((f) => this.listIdFormation.push(f.getId()))
			)
			.catch((err) => {
				this.errorService.handle(err);
				return null;
			});
	}

	getNumberReports(number: number) {
		this.numberReports = number;
	}
	getNumberRespondants(number: number) {
		this.numberRespondants = number;
	}
}
