import { Component, Input, OnInit } from '@angular/core';
import {
	Formation,
	FormationAdministrativeStatus,
} from '@app/models/formation';
import { FormationIntervention } from '@app/models/formation-intervention';
import { Image } from '@app/models/image';
import { User } from '@app/models/user';
import { TranslateService } from '@ngx-translate/core';

export type FormationInterventionDetailCardType = {
	formationTitle: string;
	name: string;
	coach: User;
	place: string;
	status: FormationAdministrativeStatus;
	start_date: number | Date;
	end_date: number | Date;
	avatar?: string;
};

@Component({
	selector: 'app-atom-formation-intervention-card-detail',
	templateUrl: './atom-formation-intervention-card.component.html',
	styleUrls: ['./atom-formation-intervention-card.component.less'],
})
export class AtomFormationInterventionCardDetailComponent implements OnInit {
	@Input() formationIntervention: FormationIntervention;
	@Input() formationTitle: string;
	formationInterventionTransformed: FormationInterventionDetailCardType;

	constructor(public translateService: TranslateService) {}

	ngOnInit(): void {
		this.transformFormationIntervention();
	}

	transformFormationIntervention() {
		const sponsor = (this.formationIntervention.props
			.formation as Formation).props.sponsor as User;
		this.formationInterventionTransformed = {
			formationTitle: this.formationTitle,
			name: this.formationIntervention.props.label,
			coach: this.formationIntervention.props.formateur as User,
			place: this.formationIntervention.props.lieu,
			status: this.formationIntervention.props.administrative_status,
			start_date: this.formationIntervention.getStartDate(),
			end_date: this.formationIntervention.getEndDate(),

			avatar: sponsor.avatarExists()
				? (sponsor.props.avatar as Image).getUrl()
				: '',
		};
	}
}
