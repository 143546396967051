<div
	*ngIf="!loading"
	class="container-fluid px-0 px-xl-5 py-4 py-xl-5 bg-grey-light"
>
	<!-- Sponsor -->
	<div
		*ngFor="let sponsor of sponsors"
		class="container-xl px-xl-0 mb-5 ml-0 sponsor-container"
	>
		<div class="row mx-0">
			<div
				class="col-12 px-3 px-md-5 py-3 d-flex flex-row align-items-center"
			>
				<div class="mr-3 mr-md-5 container-avatar">
					<!-- Sponsor Avatar -->
					<nz-avatar
						class="d-md-none respondant-avatar"
						[ngClass]="
							sponsor.avatar ? 'avatarPhoto' : 'avatarIcone'
						"
						[nzSize]="120"
						nzIcon="user"
						[nzSrc]="sponsor.avatar"
					></nz-avatar>
					<nz-avatar
						class="d-none d-md-block respondant-avatar"
						[ngClass]="
							sponsor.avatar ? 'avatarPhoto' : 'avatarIcone'
						"
						[nzSize]="150"
						nzIcon="user"
						[nzSrc]="sponsor.avatar"
					></nz-avatar>
					<!-- /Sponsor Avatar -->
				</div>
				<div class="d-flex flex-column flex-grow-1 info-sponsor">
					<p class="sponsor-name mb-1">{{ sponsor.name }}</p>
					<p class="sponsor-meta">
						{{
							'dashboard_respondant-formation-progress'
								| translate
						}}:
						<span
							*ngFor="let formation of sponsor.formations"
							class="formation"
							><span class="separateur"> / </span
							>{{ formation }}</span
						>
					</p>
				</div>
			</div>
		</div>
	</div>
	<!-- /Sponsor -->

	<!-- Calendar -->
	<div class="container-xl px-xl-0 mb-5 ml-0 calendar-container">
		<div class="row">
			<div class="col-12 px-0 px-xl-3">
				<h2 class="mb-4">
					{{ 'dashboard_next_formations' | translate }}
				</h2>
				<app-atom-thematique-calendar
					class="d-none d-lg-block"
					[formationThematiqueSearchParams]="
						formationThematiqueSearchParams
					"
					[user]="user"
				></app-atom-thematique-calendar>
				<app-atom-mobile-calendar
					class="d-lg-none"
					[formationThematiqueSearchParams]="
						formationThematiqueSearchParams
					"
					[user]="user"
				></app-atom-mobile-calendar>
			</div>
		</div>
	</div>
	<!-- Calendar -->
</div>
