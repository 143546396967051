import { Component } from '@angular/core';
import { EntitySelectComponent } from '@app/abstracts';
import { ErrorService } from '@app/services/error.service';
import { FormationSearchParams } from '../formation-search-params';
import { FormationService } from '../formation.service';
import { Formation } from '../formation';
@Component({
	selector: 'hpf-formation-select',
	templateUrl:
		'../../../abstracts/entity-select/entity-select.component.html',
})
export class FormationSelectComponent extends EntitySelectComponent<Formation> {
	/** Constructor */
	constructor(
		private formationService: FormationService,
		protected errorService: ErrorService
	) {
		super(errorService);
	}
	/** @inheritDoc */
	protected async getList(): Promise<Formation[]> {
		const params = new FormationSearchParams({
			_limit: this.resultsLimit,
			_page: this.currentPage,
		});
		if (this.filterEnabled && this.filterValue) {
			params.props._text = this.filterValue;
		}
		return (await this.formationService.list(params.toObject())).items;
	}
	/** @inheritDoc */
	protected async getOne(id: string): Promise<Formation> {
		return await this.formationService.get(id);
	}
}
