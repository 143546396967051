import { Injectable } from '@angular/core';
import {
	FormationThematique,
	FormationThematiqueInterface,
	FormationThematiquePayload,
} from './formation-thematique';
import { FormationThematiqueSearchParamsInterface } from './formation-thematique-search-params';
import { BaseWalker } from '@app/abstracts/base-walker';
import { FormationThematiqueService } from './formation-thematique.service';

export class FormationThematiqueWalker extends BaseWalker<
	FormationThematique,
	FormationThematiqueInterface,
	FormationThematiquePayload,
	FormationThematiqueSearchParamsInterface
> {}

@Injectable()
export class FormationThematiqueWalkerService {
	constructor(
		private formationThematiqueService: FormationThematiqueService
	) {}

	getWalker(): FormationThematiqueWalker {
		return new FormationThematiqueWalker(this.formationThematiqueService);
	}
}
