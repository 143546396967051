import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
	selector: 'app-atom-model-inline-list',
	templateUrl: './atom-model-inline-list.component.html',
	styleUrls: ['./atom-model-inline-list.component.less'],
})
export class AtomModelInlineListComponent implements OnInit {
	/** Title of the model */
	@Input() title: string;
	/** Link to access to thee full list */
	@Input() linkFullList: string | string[];
	/** Content template */
	@Input() contentTpl: TemplateRef<void>;

	@Input() seeFullList: boolean = true;

	constructor() {}

	ngOnInit() {}
}
