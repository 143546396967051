<section class="container">
	<div class="row py-5">
		<app-atom-lost-password
			(success)="onSuccess()"
			class="d-block col-12"
		></app-atom-lost-password>
		<div class="col-12 text-center mt-4">
			<p
				[innerHTML]="
					'lost-password-support-technique'
						| translate: { url: contactEmail }
				"
			></p>
		</div>
	</div>
</section>
