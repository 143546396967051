import { BaseModel, BaseModelInterface } from './base-model';
import { BaseModelSearchParamsInterface } from './base-model-search-params';
import { Subject } from 'rxjs';
import { BaseModelService } from './base-model-service';

export class BaseWalker<
	T extends BaseModel<BaseModelInterface, P>,
	I extends BaseModelInterface,
	P extends {},
	S extends BaseModelSearchParamsInterface
> extends Subject<T[]> {
	listFonction = 'list';
	isComplete = false;
	processing = false;

	private currentPage = 0;
	private items: T[] = [];
	private searchParams: S;
	private autoWalk: boolean;
	private modelService: BaseModelService<T, I, P, S>;

	constructor(modelService: BaseModelService<T, I, P, S>) {
		super();

		this.modelService = modelService;
	}

	/** Start the walker */
	reset(searchParams: S, autoWalk = false): void {
		this.searchParams = searchParams;
		this.items = [];
		this.currentPage = 0;
		this.isComplete = false;
		this.processing = false;
		this.autoWalk = autoWalk;

		if (this.autoWalk) {
			this.walk();
		}
	}

	/** Get one more page */
	async walk() {
		if (!this.searchParams || this.processing || this.isComplete) return;

		this.processing = true;
		this.searchParams._page = this.currentPage++;

		// Get new page
		const res = await this.modelService[this.listFonction](
			this.searchParams
		);

		// Return in the pipe only items that are not already send
		const previousItemsIds = this.items.map((item) => item.getId());
		const newItems = res.items.filter(
			(newItem) => !previousItemsIds.includes(newItem.getId())
		);
		this.next(newItems);

		// Save new items to remove doublon
		this.items.push(...newItems);

		// Check if it's the last page
		const isLastPage =
			this.searchParams._page + 1 >=
			Math.ceil(res.total / (this.searchParams._limit as number));

		this.isComplete = isLastPage;
		this.processing = false;

		// Autowalk if not complete
		if (!this.isComplete && this.autoWalk) {
			this.walk();
		}
	}
}
