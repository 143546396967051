<div class="svgContainer">
	<svg
		id="Calque_1"
		data-name="Calque 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 287.1 288.19"
	>
		<g
			id="background_tourbillon-full"
			data-name="background tourbillon-full"
		>
			<g id="cercle-1">
				<g>
					<path
						id="Fill-16"
						d="M212.74,72.82s2.73,2.56,6.1,6.1a67,67,0,0,1,5.55,6.61L214,93.86a56.55,56.55,0,0,0-4.81-5.73c-2.74-2.83-5.27-5.29-5.27-5.29Z"
						fill="#1a9ec4"
					/>
					<path
						id="Fill-16-Copy"
						d="M132.36,46.89s3.71-.46,8.57-.77a65.72,65.72,0,0,1,8.61,0l-.3,13.31a56.13,56.13,0,0,0-7.46,0c-3.92.28-7.42.66-7.42.66Z"
						fill="#1a9ec4"
					/>
					<path
						id="Fill-16-Copy-2"
						d="M61.12,91.83s2-3.15,4.92-7.09a66.45,66.45,0,0,1,5.52-6.63l10,8.78a56.9,56.9,0,0,0-4.78,5.75c-2.3,3.2-4.27,6.13-4.27,6.13Z"
						fill="#1a9ec4"
					/>
					<path
						id="Fill-16-Copy-3"
						d="M48,175.72s-1-3.61-2.1-8.38a68.42,68.42,0,0,1-1.37-8.53L57.63,157a56.81,56.81,0,0,0,1.19,7.4c.89,3.85,1.81,7.26,1.81,7.26Z"
						fill="#1a9ec4"
					/>
					<path
						id="Fill-16-Copy-4"
						d="M104.46,238.42S101,237,96.65,234.79a66.16,66.16,0,0,1-7.46-4.31l6.88-11.38a56.56,56.56,0,0,0,6.47,3.73c3.54,1.73,6.75,3.16,6.75,3.16Z"
						fill="#1a9ec4"
					/>
					<path
						id="Fill-16-Copy-5"
						d="M189.07,234.05s-3.3,1.76-7.73,3.81a67.41,67.41,0,0,1-8,3.11l-4.48-12.53a57.42,57.42,0,0,0,7-2.71c3.56-1.67,6.69-3.28,6.69-3.28Z"
						fill="#1a9ec4"
					/>
					<path
						id="Fill-16-Copy-6"
						d="M241.45,171.3s-.92,3.72-2.23,7.26a53.65,53.65,0,0,1-2.62,5.75l-12.2-5.52s.67-1.36,2.18-5.11a34.61,34.61,0,0,0,1.84-5.37Z"
						fill="#1a9ec4"
					/>
					<path
						id="Stroke-18"
						d="M226.61,89.23l-10.67,7.85s1.83,2.63,3.29,5.14,2.49,4.53,2.49,4.53l11.57-6.38a64.46,64.46,0,0,0-3.12-5.65C228.34,91.78,226.61,89.23,226.61,89.23Z"
						fill="none"
						stroke="#005d77"
						stroke-width="0.84"
					/>
					<path
						id="Stroke-18-Copy"
						d="M153.79,46.76,152.92,60s3.19.27,6,.77,5.06,1,5.06,1l2.57-13a63.07,63.07,0,0,0-6.31-1.23C156.85,47.08,153.79,46.76,153.79,46.76Z"
						fill="none"
						stroke="#005d77"
						stroke-width="0.84"
					/>
					<path
						id="Stroke-18-Copy-2"
						d="M74.8,75.26l9.55,9.2s2.26-2.28,4.47-4.15,4-3.25,4-3.25L84.57,66.73a62,62,0,0,0-5,4.06C77,73.11,74.8,75.26,74.8,75.26Z"
						fill="none"
						stroke="#005d77"
						stroke-width="0.84"
					/>
					<path
						id="Stroke-18-Copy-3"
						d="M44.48,154.48l13.18-1.21s-.22-3.2-.18-6.1.18-5.18.18-5.18l-13.18-.51a63.4,63.4,0,0,0-.23,6.45C44.31,151.4,44.48,154.48,44.48,154.48Z"
						fill="none"
						stroke="#005d77"
						stroke-width="0.84"
					/>
					<path
						id="Stroke-18-Copy-4"
						d="M85.84,227.77l7.35-11.05s-2.62-1.84-4.84-3.69-3.89-3.4-3.89-3.4l-8.69,10a60,60,0,0,0,4.85,4.24C83.34,226,85.84,227.77,85.84,227.77Z"
						fill="none"
						stroke="#005d77"
						stroke-width="0.84"
					/>
					<path
						id="Stroke-18-Copy-5"
						d="M169.11,241.88l-3.92-12.69s-3.08.89-5.91,1.45-5.08.89-5.08.89l2.24,13.06a60,60,0,0,0,6.33-1.12C166.14,242.69,169.11,241.88,169.11,241.88Z"
						fill="none"
						stroke="#005d77"
						stroke-width="0.84"
					/>
					<path
						id="Stroke-18-Copy-6"
						d="M235.17,187.4l-12.11-5.35s-1.35,2.91-2.79,5.43-2.66,4.43-2.66,4.43l11.29,6.87a64,64,0,0,0,3.31-5.53C233.83,190.19,235.17,187.4,235.17,187.4Z"
						fill="none"
						stroke="#005d77"
						stroke-width="0.84"
					/>
					<path
						id="Fill-20"
						d="M235.55,104.15,223.73,110a115.92,115.92,0,0,1,4.2,11.31,60.17,60.17,0,0,1,2.38,10.42l12.8-2.56A114.58,114.58,0,0,0,240,116a87.76,87.76,0,0,0-4.49-11.88"
						fill="#0b9edf"
					/>
					<path
						id="Fill-20-Copy"
						d="M170.92,49.48,167.8,62.35a113.56,113.56,0,0,1,11.33,4,59,59,0,0,1,9.48,4.87l6.27-11.51a113.68,113.68,0,0,0-12-6.08,86.26,86.26,0,0,0-12-4.19"
						fill="#0b9edf"
					/>
					<path
						id="Fill-20-Copy-2"
						d="M87.88,63.83,95.69,74.5a111.81,111.81,0,0,1,10.36-6.11,59,59,0,0,1,9.81-4.17L111.12,52a112.67,112.67,0,0,0-12.37,5.33,87.2,87.2,0,0,0-10.87,6.5"
						fill="#0b9edf"
					/>
					<path
						id="Fill-20-Copy-3"
						d="M57.62,138.15a113.81,113.81,0,0,1,2.2-11.87,60,60,0,0,1,3.31-10.16l-12.3-4.42a113.56,113.56,0,0,0-4.11,12.86,86.4,86.4,0,0,0-2.24,12.5Z"
						fill="#0b9edf"
					/>
					<path
						id="Fill-20-Copy-4"
						d="M81.46,207.24a115.44,115.44,0,0,1-7.8-9.18,60.47,60.47,0,0,1-5.78-9l-11.16,6.81a112.32,112.32,0,0,0,7.37,11.3,86.18,86.18,0,0,0,8.26,9.62Z"
						fill="#0b9edf"
					/>
					<path
						id="Fill-20-Copy-5"
						d="M150.47,232.37a112.78,112.78,0,0,1-12,.31,59.21,59.21,0,0,1-10.59-1.14l-1.75,13a111.73,111.73,0,0,0,13.39,1.36,85.66,85.66,0,0,0,12.64-.39Z"
						fill="#0b9edf"
					/>
					<path
						id="Fill-20-Copy-6"
						d="M215.31,195.29a114,114,0,0,1-7.66,9.31,58.92,58.92,0,0,1-7.79,7.28l8.61,9.86a112.06,112.06,0,0,0,9.81-9.26,86,86,0,0,0,8-9.84Z"
						fill="#0b9edf"
					/>
					<path
						id="Fill-22"
						d="M243.51,133.74s.52,3.52.66,7.06,0,6.13,0,6.13l-13,.26s.09-2.47-.12-5.62-.5-5.83-.5-5.83Z"
						fill="#005d77"
					/>
					<path
						id="Fill-22-Copy"
						d="M198.61,62.37s3,1.87,5.81,4,4.7,3.91,4.7,3.91L201,80.47s-1.82-1.66-4.36-3.52-4.77-3.36-4.77-3.36Z"
						fill="#005d77"
					/>
					<path
						id="Fill-22-Copy-2"
						d="M115.51,50.82s3.36-1.12,6.81-1.88,6-1.1,6-1.1l2.51,12.79s-2.44.34-5.49,1.09-5.64,1.51-5.64,1.51Z"
						fill="#005d77"
					/>
					<path
						id="Fill-22-Copy-3"
						d="M52.83,107.59s1.36-3.29,3.05-6.4S59,95.92,59,95.92l11.26,6.49s-1.35,2.07-2.78,4.88-2.56,5.26-2.56,5.26Z"
						fill="#005d77"
					/>
					<path
						id="Fill-22-Copy-4"
						d="M54.79,191.75s-1.68-3.13-3-6.41-2.13-5.75-2.13-5.75l12.12-4.7s.75,2.36,2,5.24,2.46,5.31,2.46,5.31Z"
						fill="#005d77"
					/>
					<path
						id="Fill-22-Copy-5"
						d="M121.7,243.44s-3.48-.66-6.87-1.68-5.78-1.95-5.78-1.95l4-12.42s2.3.9,5.33,1.72,5.65,1.42,5.65,1.42Z"
						fill="#005d77"
					/>
					<path
						id="Fill-22-Copy-6"
						d="M204.75,224.36s-2.78,2.21-5.76,4.1-5.27,3.1-5.27,3.1L187,220.4s2.18-1.15,4.78-2.91,4.78-3.35,4.78-3.35Z"
						fill="#005d77"
					/>
					<path
						id="Fill-24"
						d="M244,150.85a67.65,67.65,0,0,1-.84,8.54c-.75,4.65-1.61,9-1.61,9l-12.64-2.65a68.63,68.63,0,0,0,1.29-7.06c.55-4.09.83-8.16.83-8.16Z"
						fill="#1a9ec4"
						opacity="0.14"
						style="isolation: isolate;"
					/>
				</g>
			</g>
			<g id="cercle-2">
				<path
					id="Fill-1"
					d="M253.67,51.5l-9.85,9a102.4,102.4,0,0,1,6.92,8.83c4.67,6.53,6,9.53,6,9.53l11.26-7a65.37,65.37,0,0,0-6.39-10.38,100.31,100.31,0,0,0-8-10"
					fill="#0b9edf"
				/>
				<path
					id="Fill-1-Copy"
					d="M142.63,1.18l.47,13.38a100.45,100.45,0,0,1,11.16.33c8,.58,11.08,1.48,11.08,1.48l2-13.19a65.65,65.65,0,0,0-12-1.73,99.24,99.24,0,0,0-12.69-.27"
					fill="#0b9edf"
				/>
				<path
					id="Fill-1-Copy-2"
					d="M33.52,54.65,44,62.89a103.05,103.05,0,0,1,7.42-8.42c5.55-5.78,8.25-7.62,8.25-7.62l-8.74-10a65.08,65.08,0,0,0-9,8.16,100.44,100.44,0,0,0-8.37,9.64"
					fill="#0b9edf"
				/>
				<path
					id="Fill-1-Copy-3"
					d="M2.21,172.61l13-2.57a105.52,105.52,0,0,1-1.42-11.17c-.68-8-.29-11.29-.29-11.29l-13.2.11a65.64,65.64,0,0,0,.18,12.22,100.71,100.71,0,0,0,1.72,12.7"
					fill="#0b9edf"
				/>
				<path
					id="Fill-1-Copy-4"
					d="M73.62,273l6.22-11.83a102.2,102.2,0,0,1-9.49-5.92c-6.6-4.51-8.87-6.88-8.87-6.88L53.25,258.8a64.53,64.53,0,0,0,9.52,7.55A100.24,100.24,0,0,0,73.62,273"
					fill="#0b9edf"
				/>
				<path
					id="Fill-1-Copy-5"
					d="M195.8,280l-5.2-12.33a98.52,98.52,0,0,1-10.53,3.73c-7.62,2.34-10.87,2.63-10.87,2.63l2.85,13a64.79,64.79,0,0,0,11.8-2.72,100.13,100.13,0,0,0,12-4.33"
					fill="#0b9edf"
				/>
				<path
					id="Fill-1-Copy-6"
					d="M281.08,193l-12.55-4.35a103,103,0,0,1-4.3,10.4c-3.38,7.29-5.34,9.92-5.34,9.92l11.49,6.58a66.14,66.14,0,0,0,5.9-10.68,102.66,102.66,0,0,0,4.8-11.87"
					fill="#0b9edf"
				/>
				<path
					id="Stroke-8"
					d="M285,128.5s1.08,9.8,1.13,19.64-.48,17-.48,17l-35.82-.54s.5-6.84.22-15.58-.83-16.23-.83-16.23Z"
					fill="none"
					stroke="#7aaade"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-8-Copy"
					d="M221.19,26.45s8.13,5.46,15.63,11.75,12.59,11.3,12.59,11.3L226,76.86s-4.86-4.79-11.68-10.2-12.84-9.78-12.84-9.78Z"
					fill="none"
					stroke="#7aaade"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-8-Copy-2"
					d="M103.2,10.13s9.37-2.78,19-4.53,16.66-2.48,16.66-2.48l5.69,35.71s-6.76.69-15.24,2.48S113.59,45,113.59,45Z"
					fill="none"
					stroke="#7aaade"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-8-Copy-3"
					d="M14.64,90.31s4.07-9,9.05-17.42,9.08-14.32,9.08-14.32L63.2,77.66s-3.92,5.6-8.15,13.24-7.56,14.34-7.56,14.34Z"
					fill="none"
					stroke="#7aaade"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-8-Copy-4"
					d="M18.1,211.45s-4.34-8.83-7.71-18.07-5.31-16.13-5.31-16.13l33.85-11.86S40.77,172,44,180.11s6.28,14.95,6.28,14.95Z"
					fill="none"
					stroke="#7aaade"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-8-Copy-5"
					d="M113.48,284.87s-9.53-2.16-18.76-5.32-15.76-6-15.76-6l12.17-34s6.24,2.71,14.52,5.29,15.46,4.49,15.46,4.49Z"
					fill="none"
					stroke="#7aaade"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-8-Copy-6"
					d="M229.56,258s-8,5.71-16.47,10.52-15,7.82-15,7.82l-16.9-31.9s6.17-2.87,13.61-7.35,13.65-8.6,13.65-8.6Z"
					fill="none"
					stroke="#7aaade"
					stroke-width="1.68"
				/>
				<path
					id="Fill-10"
					d="M259.36,123.82l-12.78,2.77a77.11,77.11,0,0,0-2.8-11.9c-2-6.16-4.21-11.41-4.21-11.41l11.89-5.72s2.67,6.28,4.73,12.86a101.4,101.4,0,0,1,3.17,13.4"
					fill="#005d77"
				/>
				<path
					id="Fill-10-Copy"
					d="M201.15,43.27,195,54.93a76.29,76.29,0,0,0-10.82-5.48c-6-2.4-11.36-4.08-11.36-4.08l3.3-12.87s6.48,2,12.8,4.6a99.49,99.49,0,0,1,12.19,6.17"
					fill="#005d77"
				/>
				<path
					id="Fill-10-Copy-2"
					d="M103.08,36.44,108,48.66a76.42,76.42,0,0,0-11.12,4.85c-5.66,3.07-10.4,6.16-10.4,6.16L78.84,48.84s5.66-3.73,11.72-6.93a99.12,99.12,0,0,1,12.52-5.47"
					fill="#005d77"
				/>
				<path
					id="Fill-10-Copy-3"
					d="M34.22,107.66l12.37,4.27a77.12,77.12,0,0,0-3.67,11.65c-1.42,6.32-2.22,12-2.22,12l-13.1-1.28s.92-6.77,2.5-13.48a102.11,102.11,0,0,1,4.12-13.13"
					fill="#005d77"
				/>
				<path
					id="Fill-10-Copy-4"
					d="M43.78,207l11.07-7a77.35,77.35,0,0,0,6.66,10.22c4,5.09,7.82,9.27,7.82,9.27L60.09,229s-4.63-5-8.8-10.45A102.82,102.82,0,0,1,43.78,207"
					fill="#005d77"
				/>
				<path
					id="Fill-10-Copy-5"
					d="M126.22,261.92l1.56-13.1a75.74,75.74,0,0,0,12.06,1.2c6.41.09,12-.3,12-.3l1.49,13.21s-6.75.5-13.59.33a99.61,99.61,0,0,1-13.57-1.34"
					fill="#005d77"
				/>
				<path
					id="Fill-10-Copy-6"
					d="M221.19,233.11l-8.6-9.94a77,77,0,0,0,8.95-8.24c4.36-4.76,7.85-9.25,7.85-9.25l10.71,7.73s-4.15,5.4-8.84,10.41a102.11,102.11,0,0,1-10.07,9.29"
					fill="#005d77"
				/>
				<path
					id="Stroke-12"
					d="M253.16,92.31l-11.73,6.06s-2.6-5.51-5.75-10.63a100.86,100.86,0,0,0-7-9.78l10.1-8.67A116.43,116.43,0,0,1,246.47,80,100.45,100.45,0,0,1,253.16,92.31Z"
					fill="none"
					stroke="#2681a3"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-12-Copy"
					d="M173.27,27.81l-2.94,13s-5.86-1.53-11.77-2.38a98.31,98.31,0,0,0-11.93-.87l-.09-13.38a112.71,112.71,0,0,1,13.11.94A98.09,98.09,0,0,1,173.27,27.81Z"
					fill="none"
					stroke="#2681a3"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-12-Copy-2"
					d="M73.42,48.07l8,10.61S76.45,62.23,72,66.25a99.57,99.57,0,0,0-8.33,8.67L53.47,66.39a116.23,116.23,0,0,1,9.13-9.53A98.71,98.71,0,0,1,73.42,48.07Z"
					fill="none"
					stroke="#2681a3"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-12-Copy-3"
					d="M23.46,137.89l13.15.9s-.58,6.08-.49,12.11a102.37,102.37,0,0,0,1,12l-13.08,2.18a117.78,117.78,0,0,1-1.12-13.21A99.72,99.72,0,0,1,23.46,137.89Z"
					fill="none"
					stroke="#2681a3"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-12-Copy-4"
					d="M60.28,234.47l9-9.75s4.31,4.28,9,8a97.59,97.59,0,0,0,9.9,6.77l-6.55,11.64a114.87,114.87,0,0,1-10.88-7.43A100.11,100.11,0,0,1,60.28,234.47Z"
					fill="none"
					stroke="#2681a3"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-12-Copy-5"
					d="M157.74,266.26l-1.86-13.17s6-.69,11.83-2a99.24,99.24,0,0,0,11.45-3.51L184,260a113.24,113.24,0,0,1-12.57,3.86A98.27,98.27,0,0,1,157.74,266.26Z"
					fill="none"
					stroke="#2681a3"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-12-Copy-6"
					d="M245.48,212.36l-10.94-7.42s3.51-5,6.41-10.23A99.52,99.52,0,0,0,246,183.77l12.41,4.71a115.59,115.59,0,0,1-5.57,12A100.27,100.27,0,0,1,245.48,212.36Z"
					fill="none"
					stroke="#2681a3"
					stroke-width="1.68"
				/>
				<path
					id="Fill-36"
					d="M257.87,171.58l1.35-6.85c.42-2.28.63-4.6,1-6.9l-12.9-1.12c-.29,2-.48,4.1-.85,6.12l-1.19,6.08Z"
					fill="#2681a3"
				/>
				<path
					id="Fill-36-Copy"
					d="M236.44,75.12l-4.33-5.44c-1.47-1.8-3.08-3.45-4.62-5.18l-9.14,9.25c1.36,1.54,2.8,3,4.1,4.6l3.84,4.82Z"
					fill="#2681a3"
				/>
				<path
					id="Fill-36-Copy-2"
					d="M149.93,29.62,143,29.47c-2.3,0-4.6.17-6.9.25l1.15,13c2-.06,4.08-.23,6.12-.21l6.13.12Z"
					fill="#2681a3"
				/>
				<path
					id="Fill-36-Copy-3"
					d="M59.86,67.49l-4.65,5.17c-1.52,1.75-2.89,3.62-4.34,5.42l10.48,7.67c1.3-1.6,2.5-3.27,3.86-4.81l4.12-4.59Z"
					fill="#2681a3"
				/>
				<path
					id="Fill-36-Copy-4"
					d="M29,161.6l1.05,6.9c.39,2.3,1,4.55,1.44,6.82l12.5-3.4c-.42-2-.94-4-1.28-6l-.94-6.12Z"
					fill="#2681a3"
				/>
				<path
					id="Fill-36-Copy-5"
					d="M82,245l6,3.52c2,1.14,4.1,2.1,6.15,3.16l5.25-11.95c-1.82-.94-3.68-1.79-5.46-2.8l-5.3-3.12Z"
					fill="#2681a3"
				/>
				<path
					id="Fill-36-Copy-6"
					d="M179.09,255l6.59-2.13c2.18-.74,4.29-1.67,6.44-2.5l-5.28-11.93c-1.91.73-3.78,1.56-5.72,2.22l-5.84,1.89Z"
					fill="#2681a3"
				/>
			</g>
			<g id="cercle-3">
				<path
					id="Fill-4"
					d="M247.45,89.35,259,82.6A92.13,92.13,0,0,1,264.42,94,113.42,113.42,0,0,1,268.8,106l-12.5,4.46s-1-3.44-3.79-10.33a101.41,101.41,0,0,0-5.06-10.74"
					fill="#1a9ec4"
					opacity="0.14"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-4-Copy-2"
					d="M167.92,30.29l2.29-13.24a90,90,0,0,1,12.18,3.16,112.28,112.28,0,0,1,11.88,4.27L189.62,37s-3.24-1.45-10.28-3.71a101.24,101.24,0,0,0-11.42-3"
					fill="#1a9ec4"
					opacity="0.14"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-4-Copy-3"
					d="M71.48,54.25,62.88,44a91.71,91.71,0,0,1,10.23-7.38A111.51,111.51,0,0,1,84,30.17L90.53,41.8s-3.19,1.57-9.43,5.56a99.83,99.83,0,0,0-9.62,6.89"
					fill="#1a9ec4"
					opacity="0.14"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-4-Copy-4"
					d="M26.35,143.14,13,142.7a93.32,93.32,0,0,1,1.41-12.6A113.72,113.72,0,0,1,17,117.64l13,2.9S29,124,27.7,131.32a104.57,104.57,0,0,0-1.35,11.82"
					fill="#1a9ec4"
					opacity="0.14"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-4-Copy-5"
					d="M66.26,235.09,57.7,245.41a91.34,91.34,0,0,1-9-8.88,114.26,114.26,0,0,1-8.17-9.69l10.24-8.5s2.09,2.89,7.06,8.4a102.12,102.12,0,0,0,8.41,8.35"
					fill="#1a9ec4"
					opacity="0.14"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-4-Copy-6"
					d="M162.09,261.7l2.57,13.19a89.4,89.4,0,0,1-12.5,1.45,110,110,0,0,1-12.61.3l-.11-13.36s3.54.18,10.92-.25a99.6,99.6,0,0,0,11.73-1.33"
					fill="#1a9ec4"
					opacity="0.14"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-4-Copy-7"
					d="M245,205.23l11.57,6.7a91.62,91.62,0,0,1-7.12,10.45,113.11,113.11,0,0,1-8.05,9.8l-10.08-8.69s2.46-2.58,7-8.47a103.2,103.2,0,0,0,6.7-9.79"
					fill="#1a9ec4"
					opacity="0.14"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-4-Copy-8"
					d="M260.4,166.69,273,167.91s-.49,3.11-1.86,8.23-2.78,8.92-2.78,8.92l-11.65-3.89s.7-2.44,2-7.11"
					fill="#1a9ec4"
					opacity="0.14"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-6"
					d="M271.68,153.54a137.32,137.32,0,0,0,.4-14,90.13,90.13,0,0,0-1.28-13.9l-13,2.41s1.06,8.26,1.14,13.57c.1,5.83-.35,11.21-.35,11.21Z"
					fill="#0b9edf"
					opacity="0.45"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-6-Copy"
					d="M232.28,52.84a138.49,138.49,0,0,0-10.4-9.32,89.88,89.88,0,0,0-11.39-7.94L204,47.15s6.95,4.49,11,7.84c4.48,3.67,8.28,7.48,8.28,7.48Z"
					fill="#0b9edf"
					opacity="0.45"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-6-Copy-2"
					d="M130,19.05a135.06,135.06,0,0,0-13.77,2,88.1,88.1,0,0,0-13.35,3.69l4.6,12.46s7.89-2.48,13.07-3.49c5.67-1.1,11-1.59,11-1.59Z"
					fill="#0b9edf"
					opacity="0.45"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-6-Copy-3"
					d="M39.55,75.76a139,139,0,0,0-7.71,11.67,90,90,0,0,0-6.22,12.48l12.27,4.88s3.44-7.57,6.17-12.12c3-5,6.19-9.31,6.19-9.31Z"
					fill="#0b9edf"
					opacity="0.45"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-6-Copy-4"
					d="M21.7,183.13s1.51,5.8,4.38,13.3A90,90,0,0,0,32,209.05l11.37-6.73s-3.8-7.4-5.68-12.36c-2.06-5.44-3.46-10.66-3.46-10.66Z"
					fill="#0b9edf"
					opacity="0.45"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-6-Copy-5"
					d="M94,263.9a136.39,136.39,0,0,0,13,4.94,88.6,88.6,0,0,0,13.46,3.3l2-13.14s-8.09-1.68-13.1-3.34c-5.49-1.8-10.4-4-10.4-4Z"
					fill="#0b9edf"
					opacity="0.45"
					style="isolation: isolate;"
				/>
				<path
					id="Fill-6-Copy-6"
					d="M202,258.48s5.4-2.51,12.24-6.66a89.78,89.78,0,0,0,11.3-8.07l-8.56-10.12s-6.57,5.05-11.09,7.78c-5,3-9.81,5.29-9.81,5.29Z"
					fill="#0b9edf"
					opacity="0.45"
					style="isolation: isolate;"
				/>
				<path
					id="Stroke-14"
					d="M253.68,76.77a43.37,43.37,0,0,0-3.38-5c-2.1-2.67-3.78-4.68-3.78-4.68l-7.81,7.54s1.68,1.89,3.17,3.94,2.84,4.2,2.84,4.2Z"
					fill="none"
					stroke="#1a9ec4"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-14-Copy"
					d="M162.36,17.4a41.44,41.44,0,0,0-6-.6c-3.38-.1-6-.1-6-.1l.71,10.88s2.51-.08,5,.08,5,.51,5,.51Z"
					fill="none"
					stroke="#1a9ec4"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-14-Copy-2"
					d="M58.1,50.25a42.66,42.66,0,0,0-4.29,4.22C51.56,57,49.89,59,49.89,59l8.73,6.44s1.55-2,3.29-3.83,3.61-3.55,3.61-3.55Z"
					fill="none"
					stroke="#1a9ec4"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-14-Copy-3"
					d="M14.45,150.48a41.86,41.86,0,0,0,.25,6c.37,3.39.73,6,.73,6L26,160.28s-.43-2.5-.62-5-.2-5.08-.2-5.08Z"
					fill="none"
					stroke="#1a9ec4"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-14-Copy-4"
					d="M64.67,249.06a41.92,41.92,0,0,0,4.88,3.53c2.88,1.79,5.13,3.09,5.13,3.09l4.78-9.77s-2.22-1.2-4.32-2.61-4.09-3-4.09-3Z"
					fill="none"
					stroke="#1a9ec4"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-14-Copy-5"
					d="M171.86,271.74a41.36,41.36,0,0,0,5.79-1.6c3.19-1.13,5.62-2.08,5.62-2.08l-4.53-9.89s-2.32,1-4.73,1.74-4.86,1.34-4.86,1.34Z"
					fill="none"
					stroke="#1a9ec4"
					stroke-width="1.68"
				/>
				<path
					id="Stroke-14-Copy-6"
					d="M258.91,204.38a42.31,42.31,0,0,0,2.59-5.45c1.25-3.17,2.14-5.64,2.14-5.64l-10.38-3s-.79,2.41-1.8,4.73-2.19,4.58-2.19,4.58Z"
					fill="none"
					stroke="#1a9ec4"
					stroke-width="1.68"
				/>
			</g>
		</g>
	</svg>
</div>
