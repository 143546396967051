<div *ngIf="media?.props?.uri">
	<div>
		<div *ngIf="media.getMediaType() === 'video'">
			<video controls>
				<source [src]="bypassUrl(getSourceUrl())" />
			</video>
		</div>
		<div *ngIf="media.getMediaType() === 'image'">
			<img [src]="bypassUrl(getSourceUrl())" />
		</div>
		<div *ngIf="media.getMediaType() === 'audio'">
			<audio controls>
				<source [src]="bypassUrl(getSourceUrl())" />
			</audio>
		</div>
	</div>
</div>
