<div *ngIf="question && user">
	<div class="question-label">
		{{ question.getLabelText(user) }}
	</div>
	<div [ngClass]="'reponse-' + question.props.type">
		<nz-radio-group [(ngModel)]="selectedAnswer">
			<label
				*ngFor="let answer of question.props.answers"
				nz-radio
				[nzValue]="answer.getId()"
				(click)="emitAnswer()"
			>
				{{ answer.getText(user) }}
			</label>
		</nz-radio-group>
	</div>
</div>
