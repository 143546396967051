<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
	<div class="container-xl px-xl-0 ml-0">
		<app-atom-formation-filter-panel
			[class.open]="isPanelOpen"
			[isOpen]="isPanelOpen"
			[states]="states"
			[statesAdmin]="statesAdmin"
			(filterFormation)="handleFilter($event)"
			(closePanel)="closePanel()"
		></app-atom-formation-filter-panel>
		<div
			class="d-flex flex-wrap justify-content-between align-items-center header__formations"
		>
			<h2 class="mb-0">
				{{ 'formation-list-admin-title' | translate }}
			</h2>
			<!-- Sort and Filter -->
			<div
				class="d-flex align-items-center sort-container"
				*ngIf="searchParams; else loadingTpl"
			>
				<nz-input-group nzSize="large" [nzSuffix]="suffixIcon">
					<input
						type="text"
						nz-input
						[(ngModel)]="searchInput"
						(input)="inputSubject.next()"
						placeholder="{{ 'search-placeholder' | translate }}"
					/>
				</nz-input-group>
				<ng-template #suffixIcon>
					<i
						nz-icon
						nzType="search"
						style="font-size: 14px; color: rgba(0, 0, 0, 0.25);"
					></i>
				</ng-template>
				<nz-select
					class="formation-select--order"
					[(ngModel)]="searchParams.props._order"
					[nzPlaceHolder]="'sort_select-placeholder' | translate"
					(ngModelChange)="searchParams.next()"
				>
					<nz-option
						*ngFor="let order of orders"
						[nzValue]="order"
						[nzLabel]="'formation_list-sort-' + order | translate"
					>
					</nz-option>
				</nz-select>
				<a class="filter-toggle" (click)="handleFilterToggle()">
					{{ 'toggle-filter' | translate }}
				</a>
			</div>
			<!-- /Sort and Filter -->
		</div>
	</div>

	<div *ngIf="searchParams && !loading" class="container-xl px-xl-0 ml-0">
		<div class="row">
			<ng-container *ngIf="formations">
				<div class="col-12 item-list-container">
					<app-atom-formation-table
						[formations]="formations"
					></app-atom-formation-table>
				</div>
			</ng-container>

			<div
				class="col-12 justify-content-end"
				*ngIf="numberTotalFormation > limit"
			>
				<nz-pagination
					[nzPageIndex]="currentPageIndex"
					[nzPageSize]="limit"
					[nzTotal]="numberTotalFormation"
					(nzPageIndexChange)="handleNextPage($event)"
				></nz-pagination>
			</div>
		</div>
	</div>
</div>
<!-- Loading -->
<ng-template #loadingTpl>
	<div class="text-center">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
<!-- /Loading -->
