import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import {
	ThematiqueFormateur,
	ThematiqueFormateurInterface,
} from '../thematique-formateur/thematique-formateur';
import {
	FormationAccess,
	FormationAccessInterface,
} from '../formation-access/formation-access';
import { Color, ColorInterface } from '../color/color';
import { Formation, FormationInterface } from '../formation/formation';
import { Report, ReportInterface } from '../report/report';
import { Survey, SurveyInterface } from '../survey/survey';

export interface FormationThematiqueInterface extends BaseModelInterface {
	created_at: number | Date;
	name: string;
	formateur: string | User | UserInterface;
	thematique: string | ThematiqueFormateur | ThematiqueFormateurInterface;
	access?: (string | FormationAccess | FormationAccessInterface)[];
	start_date: number | Date;
	end_date?: number | Date;
	color: string | Color | ColorInterface;
	email_date?: number | Date;
	email_sended: boolean;
	email_sponsor?: number | Date;
	email_sponsor_sended: boolean;
	linked_reports?: (string | Report | ReportInterface)[];
	linked_surveys?: (string | Survey | SurveyInterface)[];
	formation: string | Formation | FormationInterface;
}
export interface FormationThematiquePayload {
	name: string;
	formateur: string | User | UserInterface;
	thematique: string | ThematiqueFormateur | ThematiqueFormateurInterface;
	access?: (string | FormationAccess | FormationAccessInterface)[];
	start_date: number | Date;
	end_date?: number | Date;
	color: string | Color | ColorInterface;
	email_date?: number | Date;
	email_sponsor?: number | Date;
	linked_reports?: (string | Report | ReportInterface)[];
	linked_surveys?: (string | Survey | SurveyInterface)[];
}
type FormationThematiquePayloadKey = keyof FormationThematiquePayload;
export class FormationThematique extends BaseModel<
	FormationThematiqueInterface,
	FormationThematiquePayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Denotes if the instance of formateur has been populated */
	formateurExists(): boolean {
		return (
			!!this.props &&
			this.props.formateur instanceof User &&
			this.props.formateur.exists()
		);
	}
	/** Denotes if the instance of thematique has been populated */
	thematiqueExists(): boolean {
		return (
			!!this.props &&
			this.props.thematique instanceof ThematiqueFormateur &&
			this.props.thematique.exists()
		);
	}
	/** Denotes if the instance of access has been populated */
	accessExists(): boolean {
		return (
			!!this.props &&
			this.props.access instanceof Array &&
			(<FormationAccess[]>this.props.access).every((item) => {
				return item instanceof FormationAccess && item.exists();
			})
		);
	}
	/** Denotes if the instance of color has been populated */
	colorExists(): boolean {
		return (
			!!this.props &&
			this.props.color instanceof Color &&
			this.props.color.exists()
		);
	}
	/** Denotes if the instance of linked reports has been populated */
	linkedReportsExists(): boolean {
		return (
			!!this.props &&
			this.props.linked_reports instanceof Array &&
			(<Report[]>this.props.linked_reports).every((item) => {
				return item instanceof Report && item.exists();
			})
		);
	}
	/** Denotes if the instance of linked surveys has been populated */
	linkedSurveysExists(): boolean {
		return (
			!!this.props &&
			this.props.linked_surveys instanceof Array &&
			(<Survey[]>this.props.linked_surveys).every((item) => {
				return item instanceof Survey && item.exists();
			})
		);
	}
	/** Denotes if the instance of formation has been populated */
	formationExists(): boolean {
		return (
			!!this.props &&
			this.props.formation instanceof Formation &&
			this.props.formation.exists()
		);
	}

	/** Get list of report visible by respondant in this thematique */
	getRespondantReports(): Report[] {
		return [
			...new Set<Report>(
				this.accessExists()
					? this.props.access.reduce((reports, access) => {
							return [
								...reports,
								...(<FormationAccess>(
									access
								)).getRespondantReports(),
							];
					  }, [])
					: []
			),
		];
	}

	/** Populate the current instance from an object */
	fromObject(object: FormationThematiqueInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.props.start_date = Helpers.convertToDate(this.props.start_date);
		if (this.props.end_date !== null) {
			this.props.end_date = Helpers.convertToDate(this.props.end_date);
		}
		if (this.props.email_date !== null) {
			this.props.email_date = Helpers.convertToDate(
				this.props.email_date
			);
		}
		if (this.props.email_sponsor !== null) {
			this.props.email_sponsor = Helpers.convertToDate(
				this.props.email_sponsor
			);
		}
		if (typeof this.props.formateur === 'object') {
			this.props.formateur = new User(
				<UserInterface>this.props.formateur
			);
		}
		if (typeof this.props.thematique === 'object') {
			this.props.thematique = new ThematiqueFormateur(
				<ThematiqueFormateurInterface>this.props.thematique
			);
		}
		if (this.props.access instanceof Array) {
			this.props.access = (<FormationAccessInterface[]>(
				this.props.access
			)).map((item) => {
				return typeof item === 'object'
					? new FormationAccess(item)
					: item;
			});
		}
		if (typeof this.props.color === 'object') {
			this.props.color = new Color(<ColorInterface>this.props.color);
		}
		if (this.props.linked_reports instanceof Array) {
			this.props.linked_reports = (<ReportInterface[]>(
				this.props.linked_reports
			)).map((item) => {
				return typeof item === 'object' ? new Report(item) : item;
			});
		}
		if (this.props.linked_surveys instanceof Array) {
			this.props.linked_surveys = (<SurveyInterface[]>(
				this.props.linked_surveys
			)).map((item) => {
				return typeof item === 'object' ? new Survey(item) : item;
			});
		}
		if (typeof this.props.formation === 'object') {
			this.props.formation = new Formation(
				<FormationInterface>this.props.formation
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): FormationThematiqueInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.access === 'undefined') {
			props.access = [];
		}
		if (typeof props.end_date === 'undefined') {
			props.end_date = null;
		}
		if (typeof props.email_date === 'undefined') {
			props.email_date = null;
		}
		if (typeof props.email_sponsor === 'undefined') {
			props.email_sponsor = null;
		}
		if (typeof props.linked_reports === 'undefined') {
			props.linked_reports = [];
		}
		if (typeof props.linked_surveys === 'undefined') {
			props.linked_surveys = [];
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		props.start_date = Helpers.convertToTimestamp(props.start_date);
		if (props.end_date !== null) {
			props.end_date = Helpers.convertToTimestamp(props.end_date);
		}
		if (props.email_date !== null) {
			props.email_date = Helpers.convertToTimestamp(props.email_date);
		}
		if (props.email_sponsor !== null) {
			props.email_sponsor = Helpers.convertToTimestamp(
				props.email_sponsor
			);
		}
		if (props.formateur instanceof User) {
			props.formateur = props.formateur.toObject();
		}
		if (props.thematique instanceof ThematiqueFormateur) {
			props.thematique = props.thematique.toObject();
		}
		if (this.props.access instanceof Array) {
			props.access = (<FormationAccess[]>props.access).map((item) => {
				return item instanceof FormationAccess ? item.toObject() : item;
			});
		}
		if (props.color instanceof Color) {
			props.color = props.color.toObject();
		}
		if (this.props.linked_reports instanceof Array) {
			props.linked_reports = (<Report[]>props.linked_reports).map(
				(item) => {
					return item instanceof Report ? item.toObject() : item;
				}
			);
		}
		if (this.props.linked_surveys instanceof Array) {
			props.linked_surveys = (<Survey[]>props.linked_surveys).map(
				(item) => {
					return item instanceof Survey ? item.toObject() : item;
				}
			);
		}
		if (props.formation instanceof Formation) {
			props.formation = props.formation.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): FormationThematiquePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as FormationThematiqueInterface);
		payload.formateur = payload.formateur
			? this.extractId(payload.formateur)
			: null;
		payload.thematique = payload.thematique
			? this.extractId(payload.thematique)
			: null;
		payload.access = payload.access
			? payload.access.map((i) => this.extractId(i))
			: null;
		payload.start_date = <number>payload.start_date;
		payload.end_date = <number>payload.end_date;
		payload.color = payload.color ? this.extractId(payload.color) : null;
		payload.email_date = <number>payload.email_date;
		payload.email_sponsor = <number>payload.email_sponsor;
		payload.linked_reports = payload.linked_reports
			? payload.linked_reports.map((i) => this.extractId(i))
			: null;
		payload.linked_surveys = payload.linked_surveys
			? payload.linked_surveys.map((i) => this.extractId(i))
			: null;
		return payload as FormationThematiquePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): FormationThematiquePayloadKey[] {
		return [
			'name',
			'formateur',
			'thematique',
			'access',
			'start_date',
			'end_date',
			'color',
			'email_date',
			'email_sponsor',
			'linked_reports',
			'linked_surveys',
		];
	}
}
