import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
import { AxeSurvey, AxeSurveyInterface } from '../axe-survey/axe-survey';
export interface GraphInterface extends BaseModelInterface {
	created_at: number | Date;
	name: string;
	type: string;
	axes: (string | AxeSurvey | AxeSurveyInterface)[];
	axis_max?: number;
	show_average: boolean;
}
export interface GraphPayload {
	name: string;
	type: string;
	axes: (string | AxeSurvey | AxeSurveyInterface)[];
	axis_max?: number;
	show_average: boolean;
}
type GraphPayloadKey = keyof GraphPayload;
export class Graph extends BaseModel<GraphInterface, GraphPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}
	/** Denotes if the instance of axes has been populated */
	axesExists(): boolean {
		return (
			!!this.props &&
			this.props.axes instanceof Array &&
			(<AxeSurvey[]>this.props.axes).every((item) => {
				return item instanceof AxeSurvey && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: GraphInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.axes instanceof Array) {
			this.props.axes = (<AxeSurveyInterface[]>this.props.axes).map(
				(item) => {
					return typeof item === 'object'
						? new AxeSurvey(item)
						: item;
				}
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): GraphInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.axis_max === 'undefined') {
			props.axis_max = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.axes instanceof Array) {
			props.axes = (<AxeSurvey[]>props.axes).map((item) => {
				return item instanceof AxeSurvey ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): GraphPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as GraphInterface);
		payload.axes = payload.axes
			? payload.axes.map((i) => this.extractId(i))
			: null;
		return payload as GraphPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): GraphPayloadKey[] {
		return ['name', 'type', 'axes', 'axis_max', 'show_average'];
	}
}
