import { Helpers } from '@app/shared/helpers';
import {
	BaseModelSearchParams,
	BaseModelSearchParamsInterface,
} from '@app/abstracts';
import { UserRole } from './user';

/** Used to export and import search params */
export interface UserSearchParamsInterface
	extends BaseModelSearchParamsInterface {
	_sort?:
		| '_id'
		| 'created_at'
		| 'first_name'
		| 'last_name'
		| 'email'
		| 'coachs'
		| 'role'
		| 'position'
		| 'department'
		| 'address';
	_id?: string[];
	_text?: string;
	name?: string;
	first_name?: string;
	last_name?: string;
	email?: string;
	role?: UserRole;
	email_confirmed?: 'true' | 'false' | boolean;
	coachs?: string[];
	reports?: string[];
	surveys?: string[];
	position?: string;
	department?: string;
	address?: string;
}

/** Manage user search params */
export class UserSearchParams extends BaseModelSearchParams<
	UserSearchParamsInterface
> {
	/** @inheritDoc */
	fromObject(input: Partial<UserSearchParamsInterface>): void {
		// Clone object with allowed keys only
		const props = {} as UserSearchParamsInterface;
		this.allowedKeys().map((key: string) => {
			if (typeof input[key] !== 'undefined') {
				props[key] = input[key];
			}
		});

		// Keep default values
		if (typeof props._page === 'undefined') {
			props._page = this.defaultPage;
		}
		if (typeof props._limit === 'undefined') {
			props._limit = this.defaultLimit;
		}
		if (typeof props._sort === 'undefined') {
			props._sort = this.defaultSort;
		}
		if (typeof props._order === 'undefined') {
			props._order = this.defaultOrder;
		}
		// Convert potentially multiple primary keys
		if (typeof props._id === 'string') {
			props._id = (<string>props._id).split(',').filter((s) => s.length);
		}

		// Convert boolean email confirmed
		if (typeof props.email_confirmed !== 'undefined') {
			props.email_confirmed = Helpers.convertToBoolean(
				props.email_confirmed
			);
		}

		// Convert potentially multiple ids for coachs
		if (typeof props.coachs === 'string') {
			props.coachs = (<string>props.coachs)
				.split(',')
				.filter((s) => s.length);
		}

		// Convert potentially multiple ids for reports
		if (typeof props.reports === 'string') {
			props.reports = (<string>props.reports)
				.split(',')
				.filter((s) => s.length);
		}

		// Convert potentially multiple ids for surveys
		if (typeof props.surveys === 'string') {
			props.surveys = (<string>props.surveys)
				.split(',')
				.filter((s) => s.length);
		}

		// Assign values
		this.props = props;
		this.next();
	}
	/** @inheritDoc */
	toObject(): UserSearchParamsInterface {
		// Filter not allowed, undefined and null values
		const props = {} as UserSearchParamsInterface;
		this.allowedKeys().map(Helpers.prepareObjectForApi(this.props, props));

		// Convert boolean email confirmed
		if (typeof props.email_confirmed !== 'undefined') {
			props.email_confirmed = props.email_confirmed ? 'true' : 'false';
		}

		return props;
	}
	/** @inheritDoc */
	protected allowedKeys(): string[] {
		return super
			.allowedKeys()
			.concat([
				'_id',
				'_text',
				'name',
				'first_name',
				'last_name',
				'email',
				'role',
				'email_confirmed',
				'coachs',
				'reports',
				'surveys',
				'position',
				'department',
				'address',
			]);
	}
}
