import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import { Formation } from '@app/models/formation';
import {
	FormationThematiqueSearchParams,
	FormationThematiqueService,
} from '@app/models/formation-thematique';
import { User, UserSearchParams } from '@app/models/user';
import {
	SponsorWalker,
	UserWalkerService,
} from '@app/models/user/user-walker.service';
import { ErrorService } from '@app/services/error.service';
import { PageService, PageInterface } from '@app/services/page.service';
import { SessionService } from '@app/services/session.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-molecule-sponsor-list',
	templateUrl: './molecule-sponsor-list.component.html',
	styleUrls: ['./molecule-sponsor-list.component.less'],
})
export class MoleculeSponsorListComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'list';
	user: User;
	sponsors: User[] = [];
	searchParams: UserSearchParams;
	sorts = ['created_at', 'last_name'];
	mode: 'inline' | 'card' = 'card';
	walker: SponsorWalker;

	sponsorsId: string[];
	loading = true;

	constructor(
		private sessionService: SessionService,
		protected pageService: PageService,
		protected translate: TranslateService,
		public userWalkerService: UserWalkerService,
		private formationThematiqueService: FormationThematiqueService,
		private errorService: ErrorService
	) {
		super(pageService, translate);

		this.walker = this.userWalkerService.getSponsorWalker();
	}

	async ngOnInit() {
		super.ngOnInit();
		this.user = await this.sessionService.getCachedUser();
		this.sponsorsId = await this.getSponsorsId();
		if (this.sponsorsId && this.sponsorsId.length) {
			this.searchParams = new UserSearchParams({
				_id: this.sponsorsId,
				_limit: 100,
				_populate: ['avatar'],
			});
		}
		this.loading = false;
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	trackById(_: number, respondant: User): string {
		return respondant.getId();
	}

	async getSponsorsId(): Promise<string[]> {
		const searchParams = new FormationThematiqueSearchParams({
			_populate: ['formation'],
			formateur: this.user.getId(),
		});
		//Get all thematique formation of coach to find formation and to make an array with ID's sponsor
		return await this.formationThematiqueService
			.list(searchParams.toObject())
			.then((res) => res.items)
			.then((formationsMod) =>
				formationsMod.map((fm) => <Formation>fm.props.formation)
			)
			.then((formations) => [
				...new Set(
					formations
						.map((f) => <string>f.props.sponsor)
						.filter((m) => m)
				),
			])
			.catch((err) => {
				this.errorService.handle(err);
				return [];
			});
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-sponsor_list'),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-sponsor_list'),
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
