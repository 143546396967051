import { Injectable } from '@angular/core';
import {
	FormationTheme,
	FormationThemeInterface,
	FormationThemePayload,
} from './formation-theme';
import { FormationThemeSearchParamsInterface } from './formation-theme-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class FormationThemeService extends BaseModelService<
	FormationTheme,
	FormationThemeInterface,
	FormationThemePayload,
	FormationThemeSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'formation-theme';
	}
	/** @inheritDoc */
	protected new(object: FormationThemeInterface): FormationTheme {
		return new FormationTheme(object);
	}
}
