export const GraphConfig = {
	defaultColor: '#0098CB',
	disabledColor: 'l(270) 0:#aba8a7aa 1:#c9c8c7aa',
	bar: {
		options: {
			renderer: 'canvas',
			forceFit: true,
			padding: 'auto',
			xField: 'x',
			yField: 'y',
			colorField: 'x',
			legend: {
				visible: false,
			},
			label: {
				visible: false,
			},
			xAxis: {
				visible: true,
				title: false,
				label: {
					autoRotate: true,
					autoHide: false,
					style: {
						fontSize: 14,
						fill: 'black',
					},
				},
			},
			yAxis: {
				visible: true,
				title: false,
				label: false,
				grid: {
					visible: true,
				},
			},
		},
	},
	grouped_bar: {
		options: {
			renderer: 'canvas',
			forceFit: true,
			padding: 'auto',
			xField: 'x',
			yField: 'y',
			groupField: 'groupBy',
			legend: {
				visible: false,
			},
			label: {
				visible: false,
			},
			xAxis: {
				visible: true,
				title: false,
				label: {
					autoRotate: true,
					autoHide: false,
					style: {
						fontSize: 14,
						fill: 'black',
					},
				},
			},
			yAxis: {
				visible: true,
				title: false,
				label: false,
				grid: {
					visible: true,
				},
			},
		},
	},
	radar: {
		options: {
			angleField: 'x',
			radiusField: 'y',
			area: {
				visible: false,
			},
			point: {
				visible: false,
			},
			radiusAxis: {
				label: false,
				grid: {
					line: {
						type: 'line',
					},
				},
			},
			angleAxis: {
				label: {
					style: {
						fontSize: 14,
						fill: 'black',
					},
				},
			},
		},
	},
	gauge: {
		options: {
			axis: {
				offset: -15,
				tickLine: {
					visible: true,
					length: 10,
				},
				label: {
					visible: false,
				},
			},
			pivot: {
				visible: true,
				thickness: 10,
				pointer: {
					visible: true,
					style: {
						fill: '#e25869',
					},
				},
				pin: {
					visible: true,
					style: {
						fill: '#e8e6ea',
					},
				},
			},
			statistic: {
				visible: true,
				position: ['50%', '100%'],
				color: '#2e3033',
				size: 40,
			},
		},
	},
};

export const GraphConfigPrint = {
	defaultColor: '#0098CB',
	disabledColor: 'l(270) 0:#aba8a7aa 1:#c9c8c7aa',
	bar: {
		options: {
			renderer: 'svg',
			forceFit: false,
			padding: 'auto',
			xField: 'x',
			yField: 'y',
			colorField: 'x',
			legend: {
				visible: false,
			},
			label: {
				visible: true,
			},
			xAxis: {
				visible: true,
				title: false,
				label: {
					autoRotate: true,
					autoHide: false,
					style: {
						fontSize: 12,
						fill: 'black',
					},
				},
			},
			yAxis: {
				visible: true,
				title: false,
				label: false,
				grid: {
					visible: true,
				},
			},
		},
	},
	grouped_bar: {
		options: {
			renderer: 'svg',
			forceFit: false,
			padding: 'auto',
			xField: 'x',
			yField: 'y',
			groupField: 'groupBy',
			legend: {
				visible: false,
			},
			label: {
				visible: true,
			},
			xAxis: {
				visible: true,
				title: false,
				label: {
					autoRotate: true,
					autoHide: false,
					style: {
						fontSize: 12,
						fill: 'black',
					},
				},
			},
			yAxis: {
				visible: true,
				title: false,
				label: false,
				grid: {
					visible: true,
				},
			},
		},
	},
	radar: {
		options: {
			angleField: 'x',
			radiusField: 'y',
			area: {
				visible: false,
			},
			point: {
				visible: false,
			},
			radiusAxis: {
				label: false,
				grid: {
					line: {
						type: 'line',
					},
				},
			},
			angleAxis: {
				label: {
					style: {
						fontSize: 14,
						fill: 'black',
					},
				},
			},
		},
	},
	gauge: {
		options: {
			axis: {
				offset: -15,
				tickLine: {
					visible: true,
					length: 10,
				},
				label: {
					visible: false,
				},
			},
			pivot: {
				visible: true,
				thickness: 10,
				pointer: {
					visible: true,
					style: {
						fill: '#e25869',
					},
				},
				pin: {
					visible: true,
					style: {
						fill: '#e8e6ea',
					},
				},
			},
			statistic: {
				visible: true,
				position: ['50%', '100%'],
				color: '#2e3033',
				size: 40,
			},
		},
	},
};
