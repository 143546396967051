import { Component, OnInit, Input } from '@angular/core';
import { User } from '@app/models/user';
import { Image } from '@app/models/image';

@Component({
	selector: 'app-atom-coach-card',
	templateUrl: './atom-coach-card.component.html',
	styleUrls: ['./atom-coach-card.component.less'],
})
export class AtomCoachCardComponent implements OnInit {
	@Input() coach: User;

	avatar: string | null = null;
	idCoach: string;

	constructor() {}

	ngOnInit(): void {
		this.idCoach = this.coach.getId();
		this.avatar = this.coach.avatarExists()
			? (<Image>this.coach.props.avatar).getUrl()
			: null;
	}
}
