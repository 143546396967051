import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import { Formation, FormationSearchParams } from '@app/models/formation';
import {
	FormationWalker,
	FormationWalkerService,
} from '@app/models/formation/formation-walker.service';
import { User } from '@app/models/user';
import { PageInterface, PageService } from '@app/services/page.service';
import { SessionService } from '@app/services/session.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-molecule-formation-list',
	templateUrl: './molecule-formation-list.component.html',
	styleUrls: ['./molecule-formation-list.component.less'],
})
export class MoleculeFormationListComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'list';
	user: User;
	searchParams: FormationSearchParams;
	walker: FormationWalker;

	sorts = ['name', 'themes'];
	mode: 'inline' | 'card' = 'card';

	constructor(
		private sessionService: SessionService,
		protected pageService: PageService,
		protected translate: TranslateService,
		public formationWalkerService: FormationWalkerService
	) {
		super(pageService, translate);
		this.walker = this.formationWalkerService.getWalker();
	}

	async ngOnInit() {
		super.ngOnInit();
		this.user = await this.sessionService.getCachedUser();
		this.searchParams = new FormationSearchParams({
			_page: 0,
			_limit: 100,
			_order: 'asc',
			_sort: 'name',
			_populate: ['color', 'sponsor.avatar'],
		});
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	trackById(_: number, formation: Formation): string {
		return formation.getId();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-formation_list'),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-formation_list'),
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
