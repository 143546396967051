<div class="container-questions-survey">
	<div *ngIf="!loading; else loadingTemplate" class="flex-grow-1">
		<div *ngIf="questions && questions.length; else emptyQuestions">
			<p class="numero-question">
				{{ 'question-question' | translate }} {{ questionCursor + 1 }}
			</p>
			<nz-progress
				[nzPercent]="(progressSurvey / getSurveyLength()) * 100 | round"
				nzStatus="active"
				[nzStrokeColor]="'#1A5E9E'"
			></nz-progress>
			<app-atom-question
				[question]="getCurrentQuestion()"
				[user]="user"
				[selectedAnswer]="result[getCurrentQuestion().getId()]"
				(answerUpdate)="onAnswerUpdate($event)"
			>
			</app-atom-question>
			<!-- <div>RESULT: {{ readResult() }}</div> -->
		</div>
	</div>
	<div
		class="nav-survey"
		[ngClass]="navVisible ? 'nav-visible' : 'nav-hidden'"
	>
		<button
			nz-button
			nz-type="default"
			nzShape="circle"
			[disabled]="!isPreviousPossible()"
			(click)="previousQuestion()"
			class="nav-btn"
			[ngClass]="isQuestionFirst() ? 'btn-hidden' : 'btn-visible'"
		>
			<i nz-icon nzType="left" nzTheme="outline"></i>
		</button>
		<button
			nz-button
			nz-type="default"
			nzShape="circle"
			[disabled]="
				isQuestionLast() ||
				(!isQuestionAnswered(getCurrentQuestion().getId()) &&
					getCurrentQuestion().props.type !== 'open')
			"
			(click)="nextQuestion()"
			class="nav-btn"
			[ngClass]="
				isQuestionLast() ||
				(!isQuestionAnswered(getCurrentQuestion().getId()) &&
					getCurrentQuestion().props.type !== 'open')
					? 'btn-hidden'
					: 'btn-visible'
			"
		>
			<i nz-icon nzType="right" nzTheme="outline"></i>
		</button>
		<button
			nz-button
			nz-type="primary"
			[disabled]="
				!isSurveyCompleted() &&
				getCurrentQuestion().props.type !== 'open'
			"
			(click)="submit()"
			class="btn-submit"
			[ngClass]="isQuestionLast() ? 'btn-visible' : 'btn-hidden'"
		>
			{{ 'survey-fill-save_button' | translate }}
		</button>
	</div>
</div>

<ng-template #loadingTemplate>
	<app-atom-concept-loader></app-atom-concept-loader>
</ng-template>

<ng-template #emptyQuestions>
	<div>{{ 'survey_fill-empty_questions' | translate }}</div>
</ng-template>
