<div *ngIf="question">
	<ng-container [ngSwitch]="question.props.type">
		<app-atom-question-radio
			*ngSwitchCase="'radio'"
			[question]="question"
			[user]="user"
			[selectedAnswer]="selectedAnswer"
			(answerUpdate)="answerUpdate.emit($event)"
		>
		</app-atom-question-radio>
		<app-atom-question-slider
			*ngSwitchCase="'slider'"
			[question]="question"
			[user]="user"
			[selectedAnswer]="selectedAnswer"
			(answerUpdate)="answerUpdate.emit($event)"
		>
		</app-atom-question-slider>
		<app-atom-question-open
			*ngSwitchCase="'open'"
			[question]="question"
			[user]="user"
			[selectedAnswer]="selectedAnswer"
			(openAnswerChange)="
				answerUpdate.emit($event !== '' ? $event : null)
			"
		>
		</app-atom-question-open>
		<div *ngSwitchDefault>
			{{ 'atom_question-invalid_question_type' | translate }}
		</div>
	</ng-container>
</div>
