import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import { Color, ColorInterface } from '../color/color';
import {
	FormationTheme,
	FormationThemeInterface,
} from '../formation-theme/formation-theme';
import {
	FormationSession,
	FormationSessionInterface,
} from '../formation-session/formation-session';
import {
	FormationThematique,
	FormationThematiqueInterface,
} from '../formation-thematique';

export enum FormationSessionStatus {
	'soon' = 'soon',
	'inprogress' = 'inprogress',
	'done' = 'done',
}
export const FormationSessionStatusValues: FormationSessionStatus[] = Object.keys(
	FormationSessionStatus
) as FormationSessionStatus[];

export enum FormationAdministrativeStatus {
	'created' = 'created',
	'todo' = 'todo',
	'finished' = 'finished',
}
export const FormationAdministrativeStatusValues: FormationAdministrativeStatus[] = Object.keys(
	FormationAdministrativeStatus
) as FormationAdministrativeStatus[];

export interface FormationInterface extends BaseModelInterface {
	created_at: number | Date;
	name: string;
	description: string;
	respondants?: (string | User | UserInterface)[];
	sponsor: string | User | UserInterface;
	color: string | Color | ColorInterface;
	themes: (string | FormationTheme | FormationThemeInterface)[];
	sessions?: (string | FormationSession | FormationSessionInterface)[];
	administrative_status: FormationAdministrativeStatus;
	session_status: FormationSessionStatus;
	start_date: number | Date;
	end_date: number | Date;
	lead?: string | User | UserInterface;
}
export interface FormationPayload {
	name: string;
	description: string;
	respondants?: (string | User | UserInterface)[];
	sponsor: string | User | UserInterface;
	color: string | Color | ColorInterface;
	themes: (string | FormationTheme | FormationThemeInterface)[];
	sessions?: (string | FormationSession | FormationSessionInterface)[];
	administrative_status: FormationAdministrativeStatus;
	session_status: FormationSessionStatus;
	start_date: number | Date;
	end_date: number | Date;
	lead?: string | User | UserInterface;
}
type FormationPayloadKey = keyof FormationPayload;
export class Formation extends BaseModel<FormationInterface, FormationPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}

	getThematiques(): FormationThematique[] {
		const thematiques = [];

		if (this.sessionsExists()) {
			(this.props.sessions as FormationSession[]).forEach((session) => {
				thematiques.push(...session.getThematiques());
			});
		}

		return thematiques;
	}

	getCoachs(): User[] {
		const coachs: Record<string, User> = {};

		const thematiques = this.getThematiques();
		thematiques.forEach((thematique) => {
			if (thematique.formateurExists()) {
				const formateur = thematique.props.formateur as User;

				coachs[formateur.getId()] = formateur;
			}
		});

		return Object.values(coachs);
	}

	/** Short function to count respondants of instance */
	countRespondant(): number {
		return this.props.respondants.length;
	}
	/** Denotes if the instance of respondants has been populated */
	respondantsExists(): boolean {
		return (
			!!this.props &&
			this.props.respondants instanceof Array &&
			(<User[]>this.props.respondants).every((item) => {
				return item instanceof User && item.exists();
			})
		);
	}
	/** Denotes if the instance of sponsor has been populated */
	sponsorExists(): boolean {
		return (
			!!this.props &&
			this.props.sponsor instanceof User &&
			this.props.sponsor.exists()
		);
	}
	/** Denotes if the instance of color has been populated */
	colorExists(): boolean {
		return (
			!!this.props &&
			this.props.color instanceof Color &&
			this.props.color.exists()
		);
	}
	/** Denotes if the instance of themes has been populated */
	themesExists(): boolean {
		return (
			!!this.props &&
			this.props.themes instanceof Array &&
			(<FormationTheme[]>this.props.themes).every((item) => {
				return item instanceof FormationTheme && item.exists();
			})
		);
	}
	/** Denotes if the instance of sessions has been populated */
	sessionsExists(): boolean {
		return (
			!!this.props &&
			this.props.sessions instanceof Array &&
			(<FormationSession[]>this.props.sessions).every((item) => {
				return item instanceof FormationSession && item.exists();
			})
		);
	}
	/** Denotes if the instance of lead has been populated */
	leadExists(): boolean {
		return (
			!!this.props &&
			this.props.lead instanceof User &&
			this.props.lead.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: FormationInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		this.props.start_date = Helpers.convertToDate(this.props.start_date);
		this.props.end_date = Helpers.convertToDate(this.props.end_date);
		if (this.props.respondants instanceof Array) {
			this.props.respondants = (<UserInterface[]>(
				this.props.respondants
			)).map((item) => {
				return typeof item === 'object' ? new User(item) : item;
			});
		}
		if (typeof this.props.sponsor === 'object') {
			this.props.sponsor = new User(<UserInterface>this.props.sponsor);
		}
		if (typeof this.props.color === 'object') {
			this.props.color = new Color(<ColorInterface>this.props.color);
		}
		if (this.props.themes instanceof Array) {
			this.props.themes = (<FormationThemeInterface[]>(
				this.props.themes
			)).map((item) => {
				return typeof item === 'object'
					? new FormationTheme(item)
					: item;
			});
		}
		if (this.props.sessions instanceof Array) {
			this.props.sessions = (<FormationSessionInterface[]>(
				this.props.sessions
			)).map((item) => {
				return typeof item === 'object'
					? new FormationSession(item)
					: item;
			});
		}
		if (this.props.lead !== null && typeof this.props.lead === 'object') {
			this.props.lead = new User(<UserInterface>this.props.lead);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): FormationInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.respondants === 'undefined') {
			props.respondants = [];
		}
		if (typeof props.sessions === 'undefined') {
			props.sessions = [];
		}
		if (typeof props.lead === 'undefined') {
			props.lead = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		props.start_date = Helpers.convertToTimestamp(props.start_date);
		props.end_date = Helpers.convertToTimestamp(props.end_date);
		if (this.props.respondants instanceof Array) {
			props.respondants = (<User[]>props.respondants).map((item) => {
				return item instanceof User ? item.toObject() : item;
			});
		}
		if (props.sponsor instanceof User) {
			props.sponsor = props.sponsor.toObject();
		}
		if (props.color instanceof Color) {
			props.color = props.color.toObject();
		}
		if (this.props.themes instanceof Array) {
			props.themes = (<FormationTheme[]>props.themes).map((item) => {
				return item instanceof FormationTheme ? item.toObject() : item;
			});
		}
		if (this.props.sessions instanceof Array) {
			props.sessions = (<FormationSession[]>props.sessions).map(
				(item) => {
					return item instanceof FormationSession
						? item.toObject()
						: item;
				}
			);
		}
		if (props.lead !== null && props.lead instanceof User) {
			props.lead = props.lead.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): FormationPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as FormationInterface);
		payload.respondants = payload.respondants
			? payload.respondants.map((i) => this.extractId(i))
			: null;
		payload.sponsor = payload.sponsor
			? this.extractId(payload.sponsor)
			: null;
		payload.color = payload.color ? this.extractId(payload.color) : null;
		payload.themes = payload.themes
			? payload.themes.map((i) => this.extractId(i))
			: null;
		payload.sessions = payload.sessions
			? payload.sessions.map((i) => this.extractId(i))
			: null;
		payload.start_date = <number>payload.start_date;
		payload.end_date = <number>payload.end_date;
		payload.lead = payload.lead ? this.extractId(payload.lead) : null;
		return payload as FormationPayload;
	}

	/** List allowed keys to be sent to the API */
	protected allowedKeys(): FormationPayloadKey[] {
		return [
			'name',
			'description',
			'respondants',
			'sponsor',
			'color',
			'themes',
			'sessions',
			'administrative_status',
			'session_status',
			'start_date',
			'end_date',
			'lead',
		];
	}
}
