import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import { PageService, PageInterface } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@app/services/session.service';
import { User } from '@app/models/user';
import { NavigationService } from '@app/services/navigation.service';

@Component({
	selector: 'app-molecule-dashboard',
	templateUrl: './molecule-dashboard.component.html',
	styleUrls: ['./molecule-dashboard.component.less'],
})
export class MoleculeDashboardComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	protected skeleton: PageSkeletonType = 'dashboard';

	user: User;
	constructor(
		private sessionService: SessionService,
		private navigationService: NavigationService,
		protected pageService: PageService,
		protected translate: TranslateService
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();

		this.user = await this.sessionService.getCachedUser();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-dashboard'),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-dashboard'),
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}

	logout() {
		this.navigationService.go('/logout');
	}
}
