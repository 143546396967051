<div class="container-fluid">
	<header class="container-xl pdf__header">
		<div class="row">
			<div class="col-12">
				<img src="./assets/svg/logo/logo_NC.svg" alt="Nove Concept" />
			</div>
		</div>
	</header>
	<div class="container-xl pdf__container">
		<div class="row">
			<div class="col-12">
				<ng-container *ngIf="document">
					<div *ngIf="document.props.status !== 'signed'">
						<h1 class="pdf__title">
							{{ 'pdf-document-' + docType | translate }}
						</h1>
						<app-atom-pdf-signature
							*ngIf="pdfUrl"
							[pdfSrc]="pdfUrl"
							(signature)="signePdf($event)"
						></app-atom-pdf-signature>
					</div>

					<div *ngIf="document.props.status === 'signed'">
						<h2 class="pdf__title">
							{{ 'pdf-document-' + docType | translate }}
						</h2>
						<div class="pdf__wrapper">
							<pdf-viewer
								class="pdf__viewer"
								[src]="pdfUrl"
								[render-text]="true"
								[original-size]="false"
							></pdf-viewer>
						</div>
						<div class="pdf__actions">
							<button
								nz-button
								nzType="primary"
								(click)="download(pdfUrl)"
								type="submit"
							>
								{{ 'common_download' | translate }}
							</button>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
