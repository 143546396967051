import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { User } from '@app/models/user';
import { SessionService } from '@app/services/session.service';
import { Router } from '@angular/router';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import { PageService, PageInterface } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-molecule-my-account',
	templateUrl: './molecule-my-account.component.html',
	styleUrls: ['./molecule-my-account.component.less'],
})
export class MoleculeMyAccountComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	user: User;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		private sessionService: SessionService,
		private router: Router
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();

		this.user = await this.sessionService.getFreshUser();
		if (!this.user) {
			this.sessionService.logout().then(() => {
				this.router.navigate(['/sign-in']);
			});
		}
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-my_account'),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-my_account'),
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
