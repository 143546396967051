<div class="row my-5">
	<div class="col-12">
		<h2 class="mb-4">
			{{ formation.name }}
		</h2>
		<div class="table-container">
			<table>
				<thead>
					<tr>
						<th>{{ 'table_head-thematique' | translate }}</th>
						<th>{{ 'table_head-state' | translate }}</th>
						<th>{{ 'table_head-date' | translate }}</th>
						<th>
							{{ 'table_head-count-respondants' | translate }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						*ngFor="
							let session of formation.thematiques;
							let i = index
						"
						[routerLink]="[
							'/session',
							session.id_thematique,
							'details'
						]"
						class="clicable"
					>
						<td class="title">
							{{ session.title }}
							<div
								class="bandeau"
								[ngStyle]="
									session.color.props.gradient_min &&
									session.color.props.gradient_max
										? {
												background:
													'linear-gradient(180deg, ' +
													session.color.props
														.gradient_min +
													', ' +
													session.color.props
														.gradient_max +
													')'
										  }
										: {
												background:
													session.color.props.main
										  }
								"
							></div>
						</td>
						<td class="state" [ngSwitch]="session.status">
							<ng-container *ngSwitchCase="'soon'">
								<span>{{
									'thematique-state-soon' | translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'done'">
								<span
									class="d-flex align-items-center justify-content-center"
								>
									<i
										nz-icon
										nzType="check-circle"
										nzTheme="fill"
										class="mr-2"
										[style.color]="session.color.props.main"
									></i>
									<span>{{
										'thematique-state-done' | translate
									}}</span>
								</span>
							</ng-container>
						</td>
						<td class="date">
							{{
								session.date
									| date
										: 'dd MMMM yyyy'
										: ''
										: translateService.currentLang
							}}<span>{{
								session.date | date: "HH 'h' mm "
							}}</span>
						</td>
						<td class="respondants">
							{{ formation.totalRespondant }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
