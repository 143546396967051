import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PasswordService {
	/** Route for reset process */
	private _resetUri = `${environment.api.uri}/password/reset`;
	/** Route for change process */
	private _changeUri = `${environment.api.uri}/password/change`;

	/** Constructor */
	constructor(private http: HttpClient) {}

	/** Send request to API for a new password token */
	async request(email: string): Promise<void> {
		await this.http.post(this._resetUri, { email }).toPromise();
	}

	/** Do the password reset */
	async reset(
		userId: string,
		resetCode: string,
		newPassword: string
	): Promise<void> {
		const body = {
			_id: userId,
			code: resetCode,
			password: newPassword,
		};
		await this.http.put(this._resetUri, body).toPromise();
	}

	/** Change the password */
	async change(oldPassword: string, newPassword: string): Promise<void> {
		const options = { withCredentials: true };
		const body = {
			old_password: oldPassword,
			new_password: newPassword,
		};
		await this.http.patch(this._changeUri, body, options).toPromise();
	}
}
