import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-molecule-not-found',
	templateUrl: './molecule-not-found.component.html',
	styleUrls: ['./molecule-not-found.component.less'],
})
export class MoleculeNotFoundComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
