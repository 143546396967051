<div *ngIf="question && user">
	<div class="question-label">
		{{ question.getLabelText(user) }}
	</div>
	<div [ngClass]="'reponse-' + question.props.type">
		<textarea
			nz-input
			[(ngModel)]="selectedAnswer"
			[placeholder]="'open_answer-input-placeholder'"
			nzAutosize
		></textarea>
	</div>
</div>
