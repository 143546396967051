import { Component, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators,
} from '@angular/forms';

import { ErrorService } from '@app/services/error.service';
import { ExplainErrorsService } from '@app/services/explain-errors.service';
import { UserService, User, UserPayload } from '@app/models/user';
import { SessionService } from '@app/services/session.service';
import { Image, ImagePayload, ImageService } from '@app/models/image';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-atom-my-account',
	templateUrl: './atom-my-account.component.html',
	styleUrls: ['./atom-my-account.component.less'],
})
export class AtomMyAccountComponent implements OnInit {
	user: User;
	form: FormGroup;
	image: Image = new Image();
	isImageUpdated = false;
	passwordVisible = false;

	// Spinners
	loading = false;
	saving = false;

	constructor(
		private formBuilder: FormBuilder,
		private errorService: ErrorService,
		public explainErrorsService: ExplainErrorsService,
		private userService: UserService,
		private sessionService: SessionService,
		private imageService: ImageService,
		private messageConfirmation: NzMessageService,
		private translateService: TranslateService,
		private router: Router
	) {}

	async ngOnInit() {
		this.loading = true;
		this.user = await this.sessionService.getFreshUser();
		if (this.user.props.avatar) {
			this.image = this.user.props.avatar as Image;
		}
		this.initForm();
		this.loading = false;
	}

	initForm(): void {
		this.form = this.formBuilder.group({
			first_name: new FormControl(
				this.user.props.first_name,
				this.user.props.role !== 'sponsor' ? [Validators.required] : []
			),
			last_name: new FormControl(this.user.props.last_name, [
				Validators.required,
			]),
			email: new FormControl(this.user.props.email, [
				Validators.email,
				Validators.required,
			]),
			password: new FormControl(this.user.props.password, []),
			phone_number: new FormControl(this.user.props.phone_number, []),
			position: new FormControl(
				this.user.props.position,
				this.user.props.role === 'respondant'
					? [Validators.required]
					: []
			),
			department: new FormControl(this.user.props.department, []),
			address: new FormControl(this.user.props.address, []),
		});
	}

	async save(): Promise<void> {
		if (this.form.valid) {
			this.saving = true;
			try {
				// Create Image if necessary
				if (this.isImageUpdated) {
					await this.createImage();
					this.user.props.avatar = this.image.getId();
				}
				// Update model
				this.updateModel();

				// Update user
				await this.updateUserBasedOnRole(
					this.user.getId(),
					this.user.toPayload(),
					this.user.props.role
				);

				// Refresh user session
				this.user = await this.sessionService.getFreshUser();
			} catch (error) {
				this.errorService.handle(error);
			}
			this.saving = false;
			this.createMessageConfirmation();
			setTimeout(() => {
				this.router.navigate(['/']);
			}, 1000);
		} else {
			for (const i in this.form.controls) {
				if (this.form.controls.hasOwnProperty(i)) {
					this.form.controls[i].markAsDirty();
					this.form.controls[i].updateValueAndValidity();
				}
			}
		}
	}

	async createImage(): Promise<void> {
		const { first_name, last_name } = this.user.props;
		this.image.props.name = `${first_name}-${last_name}-avatar`;
		try {
			this.image = await this.createImageBasedOnRole(
				this.image.toPayload(),
				this.user.props.role
			);
		} catch (e) {
			this.errorService.handle(e);
		}
	}

	/** Update models properties from inputs values */
	private updateModel(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.user.props[key] = this.form.get(key).value;
		}
	}

	/** Update form values with model properties*/
	private updateForm(): void {
		for (const key of Object.keys(this.form.controls)) {
			this.form.get(key).setValue(this.user.props[key]);
		}
	}

	/** Update form values from models properties */
	async resetForm(): Promise<void> {
		this.user = await this.sessionService.getFreshUser();
		this.updateForm();
	}

	updateUri(uri: string): void {
		this.isImageUpdated = true;
		this.image.props.uri = uri;
	}

	//Message de confirmation après sauvegarde du formulaire
	createMessageConfirmation(): void {
		this.messageConfirmation.success(
			this.translateService.instant('common_profil_save')
		);
	}

	async updateUserBasedOnRole(
		id: string,
		payload: UserPayload,
		role: string
	) {
		if (role === 'admin') {
			return this.userService.updateAsAdmin(id, payload);
		}

		return this.userService.update(id, payload);
	}
	async createImageBasedOnRole(payload: ImagePayload, role: string) {
		if (role === 'admin') {
			return this.imageService.createAsAdmin(payload);
		}

		return this.imageService.create(payload);
	}
}
