import { Helpers } from '@app/shared/helpers';
import {
	BaseModelSearchParams,
	BaseModelSearchParamsInterface,
} from '@app/abstracts';

/** Used to export and import search params */
export interface FormationSearchParamsInterface
	extends BaseModelSearchParamsInterface {
	_sort?:
		| '_id'
		| 'created_at'
		| 'name'
		| 'sponsor'
		| 'themes'
		| 'administrative_status'
		| 'session_status'
		| 'start_date'
		| 'end_date';
	_id?: string[];
	_text?: string;
	name?: string;
	respondants?: string[];
	sponsor?: string;
	themes?: string[];
	administrative_status?: any;
	session_status?: any;
	start_date?: string | number | Date;
	start_date__min?: string | number | Date;
	start_date__max?: string | number | Date;
	end_date?: string | number | Date;
	end_date__min?: string | number | Date;
	end_date__max?: string | number | Date;
	date_min?: string | number | Date;
	date_max?: string | number | Date;
}

/** Manage formation search params */
export class FormationSearchParams extends BaseModelSearchParams<
	FormationSearchParamsInterface
> {
	/** @inheritDoc */
	fromObject(input: Partial<FormationSearchParamsInterface>): void {
		// Clone object with allowed keys only
		const props = {} as FormationSearchParamsInterface;
		this.allowedKeys().map((key: string) => {
			if (typeof input[key] !== 'undefined') {
				props[key] = input[key];
			}
		});

		// Keep default values
		if (typeof props._page === 'undefined') {
			props._page = this.defaultPage;
		}
		if (typeof props._limit === 'undefined') {
			props._limit = this.defaultLimit;
		}
		if (typeof props._sort === 'undefined') {
			props._sort = this.defaultSort;
		}
		if (typeof props._order === 'undefined') {
			props._order = this.defaultOrder;
		}
		// Convert potentially multiple primary keys
		if (typeof props._id === 'string') {
			props._id = (<string>props._id).split(',').filter((s) => s.length);
		}

		// Convert potentially multiple ids for respondants
		if (typeof props.respondants === 'string') {
			props.respondants = (<string>props.respondants)
				.split(',')
				.filter((s) => s.length);
		}

		// Convert potentially multiple ids for themes
		if (typeof props.themes === 'string') {
			props.themes = (<string>props.themes)
				.split(',')
				.filter((s) => s.length);
		}

		// Convert dates for start date
		if (typeof props.start_date !== 'undefined') {
			props.start_date = Helpers.convertToDate(props.start_date);
		}
		if (typeof props.start_date__min !== 'undefined') {
			props.start_date__min = Helpers.convertToDate(
				props.start_date__min
			);
		}
		if (typeof props.start_date__max !== 'undefined') {
			props.start_date__max = Helpers.convertToDate(
				props.start_date__max
			);
		}

		// Convert dates for end date
		if (typeof props.end_date !== 'undefined') {
			props.end_date = Helpers.convertToDate(props.end_date);
		}
		if (typeof props.end_date__min !== 'undefined') {
			props.end_date__min = Helpers.convertToDate(props.end_date__min);
		}
		if (typeof props.end_date__max !== 'undefined') {
			props.end_date__max = Helpers.convertToDate(props.end_date__max);
		}

		// Convert dates date_min/date_max
		if (typeof props.date_min !== 'undefined') {
			props.date_min = Helpers.convertToDate(props.date_min);
		}
		if (typeof props.date_max !== 'undefined') {
			props.date_max = Helpers.convertToDate(props.date_max);
		}

		// Assign values
		this.props = props;
		this.next();
	}
	/** @inheritDoc */
	toObject(): FormationSearchParamsInterface {
		// Filter not allowed, undefined and null values
		const props = {} as FormationSearchParamsInterface;
		this.allowedKeys().map(Helpers.prepareObjectForApi(this.props, props));

		// Avoid conflict in value of start date
		if (typeof props.start_date !== 'undefined') {
			delete props.start_date__min;
			delete props.start_date__max;
		}

		// Avoid conflict in value of end date
		if (typeof props.end_date !== 'undefined') {
			delete props.end_date__min;
			delete props.end_date__max;
		}

		// Avoid conflict in value of date_min/date_max date
		if (typeof props.date_min !== 'undefined') {
			delete props.start_date__min;
			delete props.start_date__max;
			delete props.end_date__min;
			delete props.end_date__max;
		}
		if (typeof props.date_max !== 'undefined') {
			delete props.start_date__min;
			delete props.start_date__max;
			delete props.end_date__min;
			delete props.end_date__max;
		}

		return props;
	}
	/** @inheritDoc */
	protected allowedKeys(): string[] {
		return super
			.allowedKeys()
			.concat([
				'_id',
				'_text',
				'name',
				'respondants',
				'sponsor',
				'themes',
				'administrative_status',
				'session_status',
				'start_date',
				'start_date__min',
				'start_date__max',
				'end_date',
				'end_date__min',
				'end_date__max',
				'date_min',
				'date_max',
			]);
	}
}
