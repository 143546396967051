import { OnInit, Input, Component } from '@angular/core';
import { ReportTheme } from '@app/models/report';
import { ResizeService, Breakpoint } from '@app/services/resize.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Color } from '@app/models/color';

export interface DataAverageInterface {
	x: string;
	y: number;
}

export interface AverageInterface {
	name: string;
	average: number;
	maxAxis: number;
}

@Component({ template: '' })
export abstract class BaseGraphComponent implements OnInit {
	/** @type {Subject<void>} Observables unsubscriber */
	protected unsubscribe: Subject<void> = new Subject<void>();
	protected breakpoint: Breakpoint;

	@Input() options: {};
	@Input() titleGraph: string;
	@Input() height = '400px';
	@Input() theme: ReportTheme = 'green';
	@Input() colorThematique: Color;
	@Input() showAverage = false;
	@Input() isPrinting?: boolean = false;

	averages: AverageInterface[];

	renderValue = true;
	@Input()
	get render() {
		return this.renderValue;
	}
	set render(render) {
		this.renderValue = render;

		if (this.graph) this.graph.render();
	}

	graph: any;

	protected abstract defaultOptions: {};

	constructor(protected resizeService: ResizeService) {}

	ngOnInit() {
		this.breakpoint = this.resizeService.currentBreakpoint;

		// Use default options by default
		if (!this.options) this.options = this.defaultOptions;

		this.initializeGraph();

		if (this.render) this.graph.render();

		this.resizeService.breakpointChanges
			.pipe(takeUntil(this.unsubscribe))
			.subscribe((resizeInfos) => {
				this.breakpoint = resizeInfos.current;
				this.onResize(resizeInfos.current);
			});

		this.refreshAverages();
	}

	refreshAverages(): void {
		if (this.showAverage) {
			this.loadAverages();
		}
	}

	calculateAverage(data: DataAverageInterface[]): number {
		let sum = 0;

		data.forEach((d) => {
			sum += d.y;
		});

		return data.length ? sum / data.length : 0;
	}

	abstract initializeGraph(): void;
	abstract onResize(breakpoint: Breakpoint): void;
	abstract loadAverages(): void;
}
