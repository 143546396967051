import { Component, Input, OnInit } from '@angular/core';
import { Color } from '@app/models/color';
import { Formation } from '@app/models/formation';
import { FormationThematique } from '@app/models/formation-thematique';
import { User } from '@app/models/user';
import { TranslateService } from '@ngx-translate/core';

interface ThematiqueDetailsInterface {
	idThematique: string;
	name: string;
	date_acces: Date;
	respondants: number;
	coach: string;
	status: 'soon' | 'done';
	colorThematique: Color;
}

@Component({
	selector: 'app-atom-formation-details',
	templateUrl: './atom-formation-details.component.html',
	styleUrls: ['./atom-formation-details.component.less'],
})
export class AtomFormationDetailsComponent implements OnInit {
	@Input() formation: Formation;
	colorFormation: string;
	thematiques: ThematiqueDetailsInterface[];
	constructor(public translateService: TranslateService) {}

	ngOnInit(): void {
		this.getThematiqueDetails();
		this.colorFormation = (<Color>this.formation.props.color).props.main;
	}

	getThematiqueDetails() {
		this.thematiques = this.formation
			.getThematiques()
			.map((thematique) => ({
				idThematique: thematique.getId(),
				name: thematique.getLabel(),
				date_acces: <Date>thematique.props.start_date,
				respondants: this.formation.countRespondant(),
				coach: (thematique.props.formateur as User).getLabel(),
				status:
					<Date>thematique.props.start_date > new Date()
						? 'soon'
						: 'done',
				colorThematique: <Color>thematique.props.color,
			}));
	}
}
