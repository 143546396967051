import { Component, OnInit, Input } from '@angular/core';
import { User, UserSearchParams, UserService } from '@app/models/user';
import { ReportModeratedSearchParams } from '@app/models/report-moderated';
import {
	FormationThematique,
	FormationThematiqueSearchParams,
	FormationThematiqueService,
} from '@app/models/formation-thematique';
import { ErrorService } from '@app/services/error.service';
import { Formation } from '@app/models/formation';

@Component({
	selector: 'app-atom-dashboard-coach',
	templateUrl: './atom-dashboard-coach.component.html',
	styleUrls: ['./atom-dashboard-coach.component.less'],
})
export class AtomDashboardCoachComponent implements OnInit {
	@Input() user: User;

	reportModeratedSearchParams: ReportModeratedSearchParams;
	formationThematiqueSearchParams: FormationThematiqueSearchParams;

	sponsorSearchParams: UserSearchParams;

	numberReports: number;

	constructor(
		private formationThematiqueService: FormationThematiqueService,
		private userService: UserService,
		private errorService: ErrorService
	) {}

	async ngOnInit() {
		this.formationThematiqueSearchParams = new FormationThematiqueSearchParams(
			{
				_page: 0,
				_limit: 100,
				_sort: 'created_at',
				_populate: ['color'],
				formateur: this.user.getId(),
			}
		);

		const sponsorsId = await this.getSponsorsId();
		if (sponsorsId && sponsorsId.length) {
			this.sponsorSearchParams = new UserSearchParams({
				_id: sponsorsId,
			});
		} else if (this.user.props.role !== 'sponsor') {
			// use fake id to have counter = 0 (except for sponsor)
			this.sponsorSearchParams = new UserSearchParams({
				_id: ['999999999999999999999999'],
			});
		}

		this.reportModeratedSearchParams = new ReportModeratedSearchParams({
			_page: 0,
			_limit: 100,
			moderated: true,
			grouped: true,
			owners: [this.user.getId()],
			_populate: [
				'owners.avatar',
				'report',
				'formation_thematique.color',
				'formation_thematique.formation.sponsor.avatar',
			],
		});
	}

	trackById(_: number, thematique: FormationThematique): string {
		return thematique.getId();
	}

	getNumberReports(number: number) {
		this.numberReports = number;
	}

	async getSponsorsId(): Promise<string[]> {
		const searchParams = new FormationThematiqueSearchParams({
			_populate: ['formation'],
			formateur: this.user.getId(),
		});
		//Get all thematique formation of coach to find formation and to make an array with ID's sponsor
		return await this.formationThematiqueService
			.list(searchParams.toObject())
			.then((res) => res.items)
			.then((formationsMod) =>
				formationsMod.map((fm) => <Formation>fm.props.formation)
			)
			.then((formations) => [
				...new Set(
					formations
						.map((f) => <string>f.props.sponsor)
						.filter((m) => m)
				),
			])
			.catch((err) => {
				this.errorService.handle(err);
				return [];
			});
	}
}
