import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import { PageInterface, PageService } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import {
	FormationIntervention,
	FormationInterventionSearchParams,
	FormationInterventionService,
} from '@app/models/formation-intervention';
import { DocumentAdministrative } from '../../models/document-administrative';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-molecule-formation-intervention-document',
	templateUrl: './molecule-formation-intervention-document.component.html',
	styleUrls: ['./molecule-formation-intervention-document.component.less'],
})
export class MoleculeFormationInterventionDocument extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	pdfUrl?: string;
	presignedUrl: string;
	searchParams: FormationInterventionSearchParams;
	intervention: FormationIntervention;
	formationInterventionId: string;
	docType: string;
	document: DocumentAdministrative;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		private route: ActivatedRoute,
		protected http: HttpClient,
		private formationInterventionService: FormationInterventionService
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();
		this.formationInterventionId = this.route.snapshot.params.id;
		this.docType = this.route.snapshot.params.docType;
		this.intervention = await this.formationInterventionService.get(
			this.formationInterventionId,
			{ _populate: [this.docType] }
		);

		this.pdfUrl = await this.getUrl();
	}

	async signePdf(signature: string) {
		await this.formationInterventionService.signDocument(
			this.formationInterventionId,
			this.docType,
			signature
		);
		this.intervention = await this.formationInterventionService.get(
			this.formationInterventionId,
			{ _populate: [this.docType] }
		);

		this.pdfUrl = await this.getUrl();
	}

	async getUrl() {
		this.document = this.intervention.props[this.docType];

		if (this.document && this.document.props) {
			const {
				url,
			} = await this.formationInterventionService.getPresignedDocAdmininistrative(
				this.formationInterventionId,
				this.docType
			);
			return url;
		}
	}

	getPageData(): PageInterface {
		return {
			breadcrumbs: [],
			hideTopBar: true,
			hideMenu: true,
		};
	}
	download(url: string) {
		window.open(url, '_blank');
	}
}
