import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PageService, PageInterface } from '@app/services/page.service';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@app/services/session.service';
import { NavigationService } from '@app/services/navigation.service';
import { ErrorService } from '@app/services/error.service';

@Component({
	selector: 'app-molecule-sign-in',
	templateUrl: './molecule-sign-in.component.html',
	styleUrls: ['./molecule-sign-in.component.less'],
})
export class MoleculeSignInComponent extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit {
	signedIn = false;

	constructor(
		private sessionService: SessionService,
		private navigation: NavigationService,
		private errorService: ErrorService,
		protected pageService: PageService,
		protected translate: TranslateService
	) {
		super(pageService, translate);
	}

	ngOnInit() {
		super.ngOnInit();
		this.refreshPageData();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	onSignIn(): void {
		const redirect = this.sessionService.popUrlAfterLogin();
		const path = redirect ? redirect : ['/dashboard'];
		this.navigation
			.delayed(path)
			.catch((error) => this.errorService.handle(error));
	}

	onCancel(): void {
		this.navigation
			.go(['/'])
			.catch((error) => this.errorService.handle(error));
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-sign_in'),
			hideMenu: true,
			hideTopBar: true,
		};
	}
}
