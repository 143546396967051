import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
	UrlTree,
} from '@angular/router';
import { SessionService } from '@app/services/session.service';
import { User } from '@app/models/user/user';

@Injectable()
export class IsLoggedGuard implements CanActivate {
	constructor(
		private _sessionService: SessionService,
		private _router: Router
	) {}

	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<UrlTree | boolean> {
		if (await this._sessionService.loggedIn()) {
			const currentUser: User = await this._sessionService.getCachedUser();
			this._sessionService.setPathAfterLogin(null);
			if (
				currentUser.profilIsCompleted() ||
				(next.url[0] && next.url[0].path === 'logout')
			) {
				return true;
			} else {
				return next.url[0] && next.url[0].path === 'my-profile'
					? true
					: this._router.createUrlTree(['/my-profile']);
			}
		} else {
			this._sessionService.setPathAfterLogin(state);
			return this._router.createUrlTree(['/sign-in']);
		}
	}
}
