import {
	Component,
	Input,
	Output,
	EventEmitter,
	OnChanges,
} from '@angular/core';
import { Question } from '@app/models/question';
import { User } from '@app/models/user';

@Component({
	selector: 'app-atom-question-open',
	templateUrl: './atom-question-open.component.html',
	styleUrls: ['./atom-question-open.component.less'],
})
export class AtomQuestionOpenComponent implements OnChanges {
	@Input() question: Question;
	@Input() user: User;

	openAnswerValue: string;
	@Output() openAnswerChange = new EventEmitter<string>();
	@Input()
	get selectedAnswer() {
		return this.openAnswerValue;
	}
	set selectedAnswer(selectedAnswer: string) {
		this.openAnswerValue = selectedAnswer;
		this.openAnswerChange.emit(this.openAnswerValue);
	}

	constructor() {}

	ngOnChanges() {
		if (this.selectedAnswer == null) {
			this.openAnswerChange.emit(' ');
		}
	}
}
