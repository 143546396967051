<div *ngIf="!processing">
	<div class="row">
		<div class="col-12">
			<div class="pdf__wrapper">
				<pdf-viewer
					class="pdf__viewer"
					[src]="pdfSrc"
					[render-text]="true"
					[original-size]="false"
				></pdf-viewer>
			</div>
		</div>
		<div class="col-12">
			<div class="pdf__signature">
				<h2 class="pdf__title">
					{{ 'pdf-pad-signature' | translate }}
				</h2>
				<p>
					{{ 'pdf-pad-indication' | translate }}
				</p>
				<div class="pdf__signature__canvas">
					<signature-pad
						#signature
						[options]="options"
						(drawEnd)="drawComplete($event)"
					>
					</signature-pad>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="pdf__actions">
				<button
					nz-button
					nzType="primary"
					(click)="save()"
					type="submit"
				>
					{{ 'common_save' | translate }}
				</button>
				<button
					nz-button
					nzType="default"
					(click)="delete()"
					class="cancel-btn"
				>
					{{ 'common_cancel' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>

<div class="pdf__processing" *ngIf="processing">
	<app-atom-spinner></app-atom-spinner>
	<span>{{ 'pdf_generate' | translate }}</span>
</div>
