import { Injectable } from '@angular/core';
import { Axe, AxeInterface, AxePayload } from './axe';
import { AxeSearchParamsInterface } from './axe-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class AxeService extends BaseModelService<
	Axe,
	AxeInterface,
	AxePayload,
	AxeSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'axe';
	}
	/** @inheritDoc */
	protected new(object: AxeInterface): Axe {
		return new Axe(object);
	}
}
