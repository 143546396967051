<form
	nz-form
	[formGroup]="form"
	(submit)="onSubmit()"
	[class.d-none]="success"
	[nzAutoTips]="explainErrorService.autoTips"
>
	<nz-form-item class="w-100">
		<nz-form-control>
			<input
				nz-input
				formControlName="old_password"
				type="password"
				autocomplete="current-password"
				placeholder="{{
					'change_password-old_password-placeholder' | translate
				}}"
			/>
		</nz-form-control>
	</nz-form-item>
	<nz-form-item class="w-100">
		<nz-form-control>
			<nz-input-group [nzSuffix]="suffixTemplate">
				<input
					nz-input
					formControlName="new_password"
					[type]="passwordVisible ? 'text' : 'password'"
					autocomplete="new-password"
					placeholder="{{
						'change_password-new_password-placeholder' | translate
					}}"
				/>
			</nz-input-group>
			<ng-template #suffixTemplate>
				<i
					nz-icon
					[nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
					(click)="passwordVisible = !passwordVisible"
				></i>
			</ng-template>
		</nz-form-control>
	</nz-form-item>
	<div class="w-100 d-flex align-items-center justify-content-between">
		<button
			nz-button
			nzType="default"
			type="button"
			routerLink="/my-account"
		>
			{{ 'change_password-goto_my_account' | translate }}
		</button>
		<button
			nz-button
			nzType="primary"
			type="submit"
			[disabled]="form.invalid"
			[nzLoading]="processing"
		>
			{{ 'change_password-submit-btn' | translate }}
		</button>
	</div>
</form>
<div *ngIf="success" class="d-flex flex-column w-100 align-items-center py-5">
	<p class="mt-4">{{ 'change_password-success' | translate }}</p>
	<i nz-icon nzType="loading" theme="outline"></i>
</div>
