import {
	Component,
	OnInit,
	Input,
	TemplateRef,
	Output,
	EventEmitter,
	OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
	BaseModel,
	BaseModelInterface,
	BaseModelSearchParamsInterface,
	BaseModelSearchParams,
} from '@app/abstracts';
import { takeUntil } from 'rxjs/operators';
import { BaseWalker } from '@app/abstracts/base-walker';

@Component({
	selector: 'app-atom-infinite-list',
	templateUrl: './atom-infinite-list.component.html',
	styleUrls: ['./atom-infinite-list.component.less'],
})
export class AtomInfiniteListComponent implements OnInit, OnDestroy {
	/** @type {Subject<void>} Observables unsubscriber */
	protected unsubscribe: Subject<void> = new Subject<void>();

	/** Search params of model */
	@Input() searchParams: BaseModelSearchParams<
		BaseModelSearchParamsInterface
	>;
	/** Content template */
	@Input() contentTpl: TemplateRef<{
		items: BaseModel<BaseModelInterface, {}>;
	}>;
	/** Walker */
	@Input() walker: BaseWalker<
		BaseModel<BaseModelInterface, {}>,
		BaseModelInterface,
		{},
		BaseModelSearchParamsInterface
	>;
	/** Enable auto walk */
	@Input() autoWalk = false;
	/** Event to get items after walks */
	@Output() onWalk = new EventEmitter<BaseModel<BaseModelInterface, {}>[]>();

	items: BaseModel<BaseModelInterface, {}>[] = [];

	constructor() {}

	ngOnInit(): void {
		this.walker.pipe(takeUntil(this.unsubscribe)).subscribe((items) => {
			this.items.push(...items);
			this.onWalk.emit(items);
		});
		this.searchParams.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
			this.walk(true);
		});
	}

	ngOnDestroy() {
		this.unsubscribe.next();
	}

	async walk(reset = false) {
		if (reset) {
			this.items = [];
			this.walker.reset(this.searchParams.toObject(), this.autoWalk);
		}

		this.walker.walk();
	}
}
