<app-atom-model-inline-list
	[seeFullList]="seeFullList"
	[title]="'respondant-list-inline-title-name' | translate"
	[linkFullList]="['/respondant/list']"
	[contentTpl]="contentTpl"
></app-atom-model-inline-list>

<ng-template #contentTpl>
	<ng-container *ngIf="!loading; else loadingTpl">
		<ng-container *ngIf="items.length > 0; else emptyTpl">
			<div *ngIf="currentPag || posScroll" class="btn-prev-container">
				<div class="btn-prev" (click)="prev()">
					<i nz-icon nzType="left"></i>
				</div>
			</div>
			<div
				#cardsRespondantContainer
				class="cards-container"
				[style.transform]="'translateX(' + position + 'px)'"
				(scroll)="scroll($event)"
			>
				<app-atom-respondant-card
					*ngFor="let item of items"
					[respondant]="item"
					[routerLink]="
						customLink
							? customLink + item.props._id
							: ['/respondant', item.props._id]
					"
				></app-atom-respondant-card>
			</div>
			<div
				[ngClass]="
					btnScrollNext ? 'btnScrollVisible' : 'btnScrollHidden'
				"
				*ngIf="maxPag - currentPag"
				class="btn-next-container"
			>
				<div class="btn-next" (click)="next()">
					<i nz-icon nzType="right"></i>
				</div>
			</div>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #emptyTpl>
	<nz-empty
		[nzNotFoundContent]="'respondant-list_empty' | translate"
	></nz-empty>
</ng-template>

<ng-template #loadingTpl>
	<div class="text-center">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
