import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts/base-model';
import {
	SurveyLabel,
	SurveyLabelInterface,
} from '../survey-label/survey-label';
import { Answer, AnswerInterface } from '../answer/answer';
import { User } from '../user';
export interface QuestionInterface extends BaseModelInterface {
	created_at: number | Date;
	labels: (string | SurveyLabel | SurveyLabelInterface)[];
	answers: (string | Answer | AnswerInterface)[];
	type: string;
	labels_min?: (string | SurveyLabel | SurveyLabelInterface)[];
	labels_max?: (string | SurveyLabel | SurveyLabelInterface)[];
}
export interface QuestionPayload {
	labels: (string | SurveyLabel | SurveyLabelInterface)[];
	answers: (string | Answer | AnswerInterface)[];
	type: string;
	labels_min?: (string | SurveyLabel | SurveyLabelInterface)[];
	labels_max?: (string | SurveyLabel | SurveyLabelInterface)[];
}
type QuestionPayloadKey = keyof QuestionPayload;
export class Question extends BaseModel<QuestionInterface, QuestionPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of labels has been populated */
	labelsExists(): boolean {
		return (
			!!this.props &&
			this.props.labels instanceof Array &&
			(<SurveyLabel[]>this.props.labels).every((item) => {
				return item instanceof SurveyLabel && item.exists();
			})
		);
	}
	/** Denotes if the instance of answers has been populated */
	answersExists(): boolean {
		return (
			!!this.props &&
			this.props.answers instanceof Array &&
			(<Answer[]>this.props.answers).every((item) => {
				return item instanceof Answer && item.exists();
			})
		);
	}
	/** Denotes if the instance of labels min has been populated */
	labelsMinExists(): boolean {
		return (
			!!this.props &&
			this.props.labels_min instanceof Array &&
			(<SurveyLabel[]>this.props.labels_min).every((item) => {
				return item instanceof SurveyLabel && item.exists();
			})
		);
	}
	/** Denotes if the instance of labels max has been populated */
	labelsMaxExists(): boolean {
		return (
			!!this.props &&
			this.props.labels_max instanceof Array &&
			(<SurveyLabel[]>this.props.labels_max).every((item) => {
				return item instanceof SurveyLabel && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: QuestionInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.labels instanceof Array) {
			this.props.labels = (<SurveyLabelInterface[]>this.props.labels).map(
				(item) => {
					return typeof item === 'object'
						? new SurveyLabel(item)
						: item;
				}
			);
		}
		if (this.props.answers instanceof Array) {
			this.props.answers = (<AnswerInterface[]>this.props.answers).map(
				(item) => {
					return typeof item === 'object' ? new Answer(item) : item;
				}
			);
		}
		if (this.props.labels_min instanceof Array) {
			this.props.labels_min = (<SurveyLabelInterface[]>(
				this.props.labels_min
			)).map((item) => {
				return typeof item === 'object' ? new SurveyLabel(item) : item;
			});
		}
		if (this.props.labels_max instanceof Array) {
			this.props.labels_max = (<SurveyLabelInterface[]>(
				this.props.labels_max
			)).map((item) => {
				return typeof item === 'object' ? new SurveyLabel(item) : item;
			});
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): QuestionInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.labels_min === 'undefined') {
			props.labels_min = [];
		}
		if (typeof props.labels_max === 'undefined') {
			props.labels_max = [];
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.labels instanceof Array) {
			props.labels = (<SurveyLabel[]>props.labels).map((item) => {
				return item instanceof SurveyLabel ? item.toObject() : item;
			});
		}
		if (this.props.answers instanceof Array) {
			props.answers = (<Answer[]>props.answers).map((item) => {
				return item instanceof Answer ? item.toObject() : item;
			});
		}
		if (this.props.labels_min instanceof Array) {
			props.labels_min = (<SurveyLabel[]>props.labels_min).map((item) => {
				return item instanceof SurveyLabel ? item.toObject() : item;
			});
		}
		if (this.props.labels_max instanceof Array) {
			props.labels_max = (<SurveyLabel[]>props.labels_max).map((item) => {
				return item instanceof SurveyLabel ? item.toObject() : item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): QuestionPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as QuestionInterface);
		payload.labels = payload.labels
			? payload.labels.map((i) => this.extractId(i))
			: null;
		payload.answers = payload.answers
			? payload.answers.map((i) => this.extractId(i))
			: null;
		payload.labels_min = payload.labels_min
			? payload.labels_min.map((i) => this.extractId(i))
			: null;
		payload.labels_max = payload.labels_max
			? payload.labels_max.map((i) => this.extractId(i))
			: null;
		return payload as QuestionPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): QuestionPayloadKey[] {
		return ['labels', 'answers', 'type', 'labels_min', 'labels_max'];
	}

	getLabelText(user: User): string | null {
		// guards against unpopulated labels
		if (typeof this.props.labels[0] === 'string')
			throw new Error('Calling getText on unpopulated labels');

		return this.getText(<SurveyLabel[]>this.props.labels, user);
	}

	getLabelMinText(user: User): string | null {
		// guards against unpopulated labels
		if (typeof this.props.labels_min[0] === 'string')
			throw new Error('Calling getText on unpopulated labels');

		return this.getText(<SurveyLabel[]>this.props.labels_min, user);
	}

	getLabelMaxText(user: User): string | null {
		// guards against unpopulated labels
		if (typeof this.props.labels_max[0] === 'string')
			throw new Error('Calling getText on unpopulated labels');

		return this.getText(<SurveyLabel[]>this.props.labels_max, user);
	}

	/**
	 * Return text label that best fits user age and sport
	 */
	private getText(labels: SurveyLabel[], user: User): string | null {
		return labels[0].getLabel();
	}
}
