<div class="svgContainer">
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		width="1095.285"
		height="1177.765"
		viewBox="0 0 1095.285 1177.765"
	>
		<defs>
			<linearGradient
				id="linearGradient-2"
				x1="245.102"
				x2="298.307"
				y1="424.438"
				y2="541.213"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stop-color="rgb(139,178,74)" />
				<stop offset="1" stop-color="rgb(170,207,116)" />
			</linearGradient>
			<linearGradient
				id="linearGradient-4"
				x1="138.972"
				x2="83.39"
				y1="925.112"
				y2="983.813"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stop-color="rgb(209,106,42)" />
				<stop offset="1" stop-color="rgb(204,99,37)" />
			</linearGradient>
			<linearGradient
				id="linearGradient-6"
				x1="641.086"
				x2="727.396"
				y1="721.645"
				y2="822.267"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stop-color="rgb(119,102,166)" />
				<stop offset="1" stop-color="rgb(91,72,149)" />
			</linearGradient>
			<linearGradient
				id="linearGradient-8"
				x1="346.517"
				x2="355.348"
				y1="1078.341"
				y2="1098.167"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stop-color="rgb(200,43,124)" />
				<stop offset="1" stop-color="rgb(197,0,113)" />
			</linearGradient>
			<linearGradient
				id="linearGradient-10"
				x1="141.714"
				x2="22.089"
				y1="741.378"
				y2="741.378"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stop-color="rgb(249,233,82)" />
				<stop offset="1" stop-color="rgb(244,226,30)" />
			</linearGradient>
			<linearGradient
				id="linearGradient-12"
				x1="555.185"
				x2="157.531"
				y1="514.634"
				y2="1031.694"
				gradientUnits="userSpaceOnUse"
			>
				<stop
					offset="0"
					stop-color="rgb(255,255,255)"
					stop-opacity=".08"
				/>
				<stop
					offset="1"
					stop-color="rgb(255,255,255)"
					stop-opacity="0"
				/>
			</linearGradient>
			<rect
				id="rect-13"
				width="828.807"
				height="828.804"
				x="0"
				y="348.962"
			/>
			<mask
				id="mask-14"
				maskContentUnits="userSpaceOnUse"
				maskUnits="userSpaceOnUse"
			>
				<rect
					width="1095.285"
					height="1177.765"
					x="0"
					y="0"
					fill="black"
				/>
				<use fill="white" xlink:href="#rect-13" />
			</mask>
			<filter
				id="filter-16"
				width="200%"
				height="200%"
				x="-50%"
				y="-50%"
				color-interpolation-filters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feOffset
					dx="0"
					dy="0"
					in="SourceAlpha"
					result="shadow-1-feOffset-shadow"
				/>
				<feGaussianBlur
					in="shadow-1-feOffset-shadow"
					result="shadow-1-feGaussianBlur-shadow"
					stdDeviation="3.209"
				/>
				<feColorMatrix
					in="shadow-1-feGaussianBlur-shadow"
					result="shadow-1-feColorMatrix-shadow"
					type="matrix"
					values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 0.08 0"
				/>
			</filter>
		</defs>
		<g
			id="animationSurvey"
			stroke="none"
			stroke-width="1"
			fill="none"
			fill-rule="evenodd"
		>
			<g id="round">
				<path
					fill="url(#linearGradient-2)"
					fill-rule="evenodd"
					d="M361.50815388 548.127679c13.828947 0 27.397999 1.090914 40.642986 3.157235l32.621555-192.09401885c-6.795756-.34010867-13.636433-.51658015-20.51882-.51658015-135.449882 0-255.344606 66.558625-328.8016614 168.742029l92.8207894 95.718131c47.182057-46.370288 111.854041-75.006796 183.235151-75.006796"
				/>
				<path
					fill="url(#linearGradient-4)"
					fill-rule="evenodd"
					d="M104.58108018 858.036945l-79.951206 15.05783c20.4289802 72.683789 60.6548519 137.063794 114.4786527 186.998805l48.911478-54.924341c-42.475081-37.707143-72.54903-89.025049-83.4389247-147.132294"
				/>
				<path
					fill="url(#linearGradient-6)"
					fill-rule="evenodd"
					d="M818.94597288 763.36513c0-39.609826-5.714468-77.87526-16.325216-114.051914l-195.363554 70.79715c10.171174 27.920996 15.73163 58.055908 15.73163 89.4935 0 92.063567-47.618422 172.951676-119.525738 219.543355l68.1212 107.18236c145.351537-61.389615 247.361678-205.262 247.361678-372.964451"
				/>
				<path
					fill="url(#linearGradient-8)"
					fill-rule="evenodd"
					d="M361.50815388 1071.084224c-66.587502 0-127.325777-24.933816-173.487508-65.913702l-48.911477 54.921133c72.205713 66.988574 168.883206 107.965252 275.144706 107.965252 55.80349 0 108.963117-11.297384 157.332345-31.726364l-68.124408-107.18236c-40.890046 26.493182-89.602592 41.936041-141.953658 41.936041"
				/>
				<path
					fill="url(#linearGradient-10)"
					fill-rule="evenodd"
					d="M100.02972028 809.604508c0-73.027107 29.9680656-139.030649 78.2410366-186.469392l-92.8175808-95.71813C37.70320228 593.840851 9.56402265 675.31292 9.56402265 763.365771c0 38.031208 5.2588501 74.833533 15.06424723 109.729966l79.951206-15.05783c-2.9422608-15.702753-4.5497556-31.877166-4.5497556-48.433399"
				/>
				<path
					fill="url(#linearGradient-12)"
					fill-rule="evenodd"
					d="M27.03887028 814.981433c0 5.069544.13476 10.107003.3561515 15.12521 7.6299851-163.23291 142.3739811-293.237845 307.4935321-293.237845 170.022249 0 307.849684 137.830643 307.849684 307.849684 0 165.11955-129.998518 299.863547-293.234637 307.49674 5.018207.221392 10.055666.356152 15.12521.356152 186.446931 0 337.589941-151.146218 337.589941-337.589941 0-186.446931-151.14301-337.589941-337.589941-337.589941-186.443722 0-337.5899406 151.14301-337.5899406 337.589941z"
				/>
				<g>
					<use fill="none" xlink:href="#rect-13" />
					<g mask="url(#mask-14)">
						<path
							fill="black"
							fill-rule="evenodd"
							d="M424.93713688 1146.991667c-217.6888 0-394.1602798-176.47148-394.1602798-394.160281 0-215.927294 173.6351018-391.2661473 388.8885958-394.08969098-1.751881-.03208572-3.500553-.06738002-5.262059-.06738002-223.502733 0-404.69081433 181.184873-404.69081433 404.690815 0 223.505942 181.18808133 404.687605 404.69081433 404.687605 223.505942 0 404.690815-181.181663 404.690815-404.687605 0-1.758298-.035295-3.510179-.06738-5.262059-2.826753 215.253494-178.165606 388.888596-394.089692 388.888596"
							filter="url(#filter-16)"
						/>
					</g>
				</g>
			</g>
			<g id="squares">
				<g id="square-1">
					<path
						id="square-1-compact"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M529.98036688 561.479c16.373 10.848 31.458 23.484 44.966 37.64l32.159-31.489c-1.528-1.599-3.074-3.185-4.642-4.754-14.801-14.8-30.988-27.79-48.398-38.876l-24.085 37.479z"
					/>
					<path
						id="square-1-explosed"
						style="visibility: hidden;"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M504.91196688 591.8561c16.373 10.848 31.458 23.484 44.966 37.64l32.159-31.489c-1.528-1.599-3.074-3.185-4.642-4.754-14.801-14.8-30.988-27.79-48.398-38.876l-24.085 37.479z"
					/>
				</g>
				<g id="square-2">
					<path
						id="square-2-compact"
						fill="rgb(16,161,197)"
						fill-rule="evenodd"
						d="M582.21136688 598.1857l-32.162 31.492c12.706 13.341 24.018 28.023 33.7 43.813l37.524-23.651c-10.963-18.588-24.012-35.872-39.062-51.654"
					/>
					<path
						id="square-2-explosed"
						style="visibility: hidden;"
						fill="rgb(16,161,197)"
						fill-rule="evenodd"
						d="M650.14236688 535l-32.162 31.492c12.706 13.341 24.018 28.023 33.7 43.813l37.524-23.651c-10.963-18.588-24.012-35.872-39.062-51.654"
					/>
				</g>
				<g id="square-3">
					<path
						id="square-3-compact"
						fill="rgb(16,161,197)"
						fill-rule="evenodd"
						d="M455.68346688 566.3063c17.313 6.755 33.729 15.301 49.021 25.412l24.081-37.473c-13.195-8.385-27.088-15.681-41.623-21.829-5.396-2.282-10.846-4.379-16.342-6.311l-15.137 40.201z"
					/>
					<path
						id="square-3-explosed"
						style="visibility: hidden;"
						fill="rgb(16,161,197)"
						fill-rule="evenodd"
						d="M520.98036688 466.201c17.313 6.755 33.729 15.301 49.021 25.412l24.081-37.473c-13.195-8.385-27.088-15.681-41.623-21.829-5.396-2.282-10.846-4.379-16.342-6.311l-15.137 40.201z"
					/>
				</g>
				<g id="square-4">
					<path
						id="square-4-compact"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M470.58436688 526.0221c-20.057-7.034-40.75-11.752-61.898-14.117l-6.706 39.839c18.546 2.944 36.438 7.845 53.47 14.472l15.134-40.194z"
					/>
					<path
						id="square-4-explosed"
						style="visibility: hidden;"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M505.58436688 496.117c-20.057-7.034-40.75-11.752-61.898-14.117l-6.706 39.839c18.546 2.944 36.438 7.845 53.47 14.472l15.134-40.194z"
					/>
				</g>
				<g id="square-5">
					<path
						id="square-5-compact"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M621.40076688 650.0549l-37.52 23.648c9.024 14.748 16.627 30.459 22.62 46.945l40.164-14.535c-2.486-7.633-5.284-15.182-8.433-22.626-4.89-11.562-10.52-22.71-16.831-33.432"
					/>
					<path
						id="square-5-explosed"
						style="visibility: hidden;"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M662.50036688 606l-37.52 23.648c9.024 14.748 16.627 30.459 22.62 46.945l40.164-14.535c-2.486-7.633-5.284-15.182-8.433-22.626-4.89-11.562-10.52-22.71-16.831-33.432"
					/>
				</g>
				<g id="square-6">
					<path
						id="square-6-compact"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M519.72646688 548.4465c21.029 12.262 40.4 27.186 57.845 44.63 7.165 7.166 13.891 14.666 20.199 22.461l39.115-32.836c-7.187-8.983-14.883-17.609-23.106-25.833-20.475-20.475-43.384-37.765-68.362-51.677l-25.691 43.255z"
					/>
					<path
						id="square-6-explosed"
						style="visibility: hidden;"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M620.98036688 423.255c21.029 12.262 40.4 27.186 57.845 44.63 7.165 7.166 13.891 14.666 20.199 22.461l39.115-32.836c-7.187-8.983-14.883-17.609-23.106-25.833-20.475-20.475-43.384-37.765-68.362-51.677l-25.691 43.255z"
					/>
				</g>
				<g id="square-7">
					<path
						id="square-7-compact"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M637.04286688 582.8967l-39.115 32.835c10.224 12.658 19.303 26.119 27.187 40.313l43.092-25.777c-8.914-16.727-19.324-32.549-31.164-47.371"
					/>
					<path
						id="square-7-explosed"
						style="visibility: hidden;"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M724.09536688 498l-39.115 32.835c10.224 12.658 19.303 26.119 27.187 40.313l43.092-25.777c-8.914-16.727-19.324-32.549-31.164-47.371"
					/>
				</g>
				<g id="square-8">
					<path
						id="square-8-compact"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M473.16936688 526.6672c4.733 1.71 9.431 3.549 14.09 5.52 11.137 4.71 21.892 10.104 32.252 16.132l25.688-43.25c-9.672-5.377-19.643-10.263-29.917-14.609-8.086-3.42-16.28-6.477-24.569-9.179l-17.544 45.386z"
					/>
					<path
						id="square-8-explosed"
						style="visibility: hidden;"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M559.98036688 364.386c4.733 1.71 9.431 3.549 14.09 5.52 11.137 4.71 21.892 10.104 32.252 16.132l25.688-43.25c-9.672-5.377-19.643-10.263-29.917-14.609-8.086-3.42-16.28-6.477-24.569-9.179l-17.544 45.386z"
					/>
				</g>
				<g id="square-9">
					<path
						id="square-9-compact"
						fill="rgb(16,161,197)"
						fill-rule="evenodd"
						d="M490.47446688 481.2053c-24.019-7.81-48.841-12.609-74.207-14.344l-7.54 44.797c21.959 2.456 43.432 7.437 64.207 14.922l17.54-45.375z"
					/>
					<path
						id="square-9-explosed"
						style="visibility: hidden;"
						fill="rgb(16,161,197)"
						fill-rule="evenodd"
						d="M538.72736688 376.344c-24.019-7.81-48.841-12.609-74.207-14.344l-7.54 44.797c21.959 2.456 43.432 7.437 64.207 14.922l17.54-45.375z"
					/>
				</g>
				<g id="square-10">
					<path
						id="square-10-compact"
						fill="rgb(16,161,197)"
						fill-rule="evenodd"
						d="M668.32316688 630.4894l-43.087 25.774c4.863 8.772 9.287 17.813 13.226 27.126 3.15 7.449 5.95 15.001 8.438 22.638l45.429-16.441c-3.364-11.591-7.4-23.011-12.141-34.22-3.594-8.499-7.563-16.789-11.865-24.877"
					/>
					<path
						id="square-10-explosed"
						style="visibility: hidden;"
						fill="rgb(16,161,197)"
						fill-rule="evenodd"
						d="M775.06736688 564l-43.087 25.774c4.863 8.772 9.287 17.813 13.226 27.126 3.15 7.449 5.95 15.001 8.438 22.638l45.429-16.441c-3.364-11.591-7.4-23.011-12.141-34.22-3.594-8.499-7.563-16.789-11.865-24.877"
					/>
				</g>
				<g id="square-11">
					<path
						id="square-11-compact"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M621.74306688 564.7912c21.19 22.75 38.678 48.254 52.154 76.102l46.647-25.805c-7.583-16.397-16.499-32.249-26.622-47.234-9.762-14.45-20.713-28.185-32.616-40.994l-39.563 37.931z"
					/>
					<path
						id="square-11-explosed"
						style="visibility: hidden;"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M842.98036688 396.931c21.19 22.75 38.678 48.254 52.154 76.102l46.647-25.805c-7.583-16.397-16.499-32.249-26.622-47.234-9.762-14.45-20.713-28.185-32.616-40.994l-39.563 37.931z"
					/>
				</g>
				<g id="square-12">
					<path
						id="square-12-compact"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M589.85046688 468.4191l-29.784 45.168c19.406 12.25 37.434 26.649 53.89 43.105 2.596 2.596 5.123 5.244 7.616 7.916l39.564-37.93c-2.759-2.965-5.552-5.895-8.411-8.755-15.268-15.267-32.066-29.127-49.931-41.196-4.246-2.869-8.567-5.634-12.944-8.308"
					/>
					<path
						id="square-12-explosed"
						style="visibility: hidden;"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M744.76436688 290l-29.784 45.168c19.406 12.25 37.434 26.649 53.89 43.105 2.596 2.596 5.123 5.244 7.616 7.916l39.564-37.93c-2.759-2.965-5.552-5.895-8.411-8.755-15.268-15.267-32.066-29.127-49.931-41.196-4.246-2.869-8.567-5.634-12.944-8.308"
					/>
				</g>
				<g id="square-13">
					<path
						id="square-13-compact"
						fill="rgb(34,132,165)"
						fill-rule="evenodd"
						d="M725.02726688 625.1623c-1.398-3.305-2.871-6.583-4.377-9.847l-46.642 25.802c2.248 4.654 4.388 9.37 6.411 14.151 4.743 11.214 8.78 22.638 12.146 34.233l48.322-17.488c-4.111-15.926-9.394-31.565-15.86-46.851"
					/>
					<path
						id="square-13-explosed"
						style="visibility: hidden;"
						fill="rgb(34,132,165)"
						fill-rule="evenodd"
						d="M888.99936688 537.847c-1.398-3.305-2.871-6.583-4.377-9.847l-46.642 25.802c2.248 4.654 4.388 9.37 6.411 14.151 4.743 11.214 8.78 22.638 12.146 34.233l48.322-17.488c-4.111-15.926-9.394-31.565-15.86-46.851"
					/>
				</g>
				<g id="square-14">
					<path
						id="square-14-compact"
						fill="rgb(34,132,165)"
						fill-rule="evenodd"
						d="M464.77286688 473.8205c17.25 3.984 34.151 9.449 50.607 16.409 15.515 6.563 30.356 14.33 44.474 23.226l29.784-45.169c-14.151-8.631-28.944-16.233-44.152-22.665-21.403-9.053-43.496-15.797-66.105-20.219l-14.608 48.418z"
					/>
					<path
						id="square-14-explosed"
						style="visibility: hidden;"
						fill="rgb(34,132,165)"
						fill-rule="evenodd"
						d="M564.98036688 277.418c17.25 3.984 34.151 9.449 50.607 16.409 15.515 6.563 30.356 14.33 44.474 23.226l29.784-45.169c-14.151-8.631-28.944-16.233-44.152-22.665-21.403-9.053-43.496-15.797-66.105-20.219l-14.608 48.418z"
					/>
				</g>
				<g id="square-15">
					<path
						id="square-15-compact"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M479.13466688 425.3522c-17.986-3.503-36.3-5.54-54.857-6.081l-7.968 47.342c16.322 1.117 32.416 3.515 48.219 7.152l14.606-48.413z"
					/>
					<path
						id="square-15-explosed"
						style="visibility: hidden;"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M551.80536688 182.081c-17.986-3.503-36.3-5.54-54.857-6.081l-7.968 47.342c16.322 1.117 32.416 3.515 48.219 7.152l14.606-48.413z"
					/>
				</g>
				<g id="square-16">
					<path
						id="square-16-compact"
						fill="rgb(16,161,197)"
						fill-rule="evenodd"
						d="M570.21916688 390.9157l-26.44 53.736c.601.25 1.205.486 1.805.739 20.006 8.462 39.301 18.935 57.35 31.129 8.016 5.416 15.803 11.21 23.348 17.312l38.699-46.808c-28.679-22.771-60.524-41.722-94.762-56.108"
					/>
					<path
						id="square-16-explosed"
						style="visibility: hidden;"
						fill="rgb(16,161,197)"
						fill-rule="evenodd"
						d="M697.42036688 117l-26.44 53.736c.601.25 1.205.486 1.805.739 20.006 8.462 39.301 18.935 57.35 31.129 8.016 5.416 15.803 11.21 23.348 17.312l38.699-46.808c-28.679-22.771-60.524-41.722-94.762-56.108"
					/>
				</g>
				<g id="square-17">
					<path
						id="square-17-compact"
						fill="rgb(34,132,165)"
						fill-rule="evenodd"
						d="M728.38126688 509.8849l-47.592 39.476c4.651 5.976 9.112 12.095 13.34 18.353 12.193 18.049 22.667 37.345 31.128 57.351.396.934.773 1.874 1.159 2.811l56.678-28.859c-14.379-32.153-32.842-62.076-54.713-89.132"
					/>
					<path
						id="square-17-explosed"
						style="visibility: hidden;"
						fill="rgb(34,132,165)"
						fill-rule="evenodd"
						d="M1040.57236688 295l-47.592 39.476c4.651 5.976 9.112 12.095 13.34 18.353 12.193 18.049 22.667 37.345 31.128 57.351.396.934.773 1.874 1.159 2.811l56.678-28.859c-14.379-32.153-32.842-62.076-54.713-89.132"
					/>
				</g>
				<g id="square-18">
					<path
						id="square-18-compact"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M469.51166688 423.3781c25.37 4.173 50.125 11.248 74.036 21.179l26.441-53.738c-26.871-11.27-55.212-19.722-84.661-24.987l-15.816 57.546z"
					/>
					<path
						id="square-18-explosed"
						style="visibility: hidden;"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M601.98036688 57.546c25.37 4.173 50.125 11.248 74.036 21.179l26.441-53.738c-26.871-11.27-55.212-19.722-84.661-24.987l-15.816 57.546z"
					/>
				</g>
				<g id="square-19">
					<path
						id="square-19-compact"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M626.47696688 493.988c9.212 7.461 18.056 15.39 26.425 23.759 9.871 9.871 19.143 20.39 27.736 31.415l47.587-39.471c-18.67-23.066-39.815-44.043-63.047-62.513l-38.701 46.81z"
					/>
					<path
						id="square-19-explosed"
						style="visibility: hidden;"
						fill="rgb(7,161,226)"
						fill-rule="evenodd"
						d="M842.98036688 241.81c9.212 7.461 18.056 15.39 26.425 23.759 9.871 9.871 19.143 20.39 27.736 31.415l47.587-39.471c-18.67-23.066-39.815-44.043-63.047-62.513l-38.701 46.81z"
					/>
				</g>
				<g id="square-20">
					<path
						id="square-20-compact"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M783.19516688 599.2463l-56.684 28.861c5.894 14.323 10.771 28.945 14.612 43.82l60.737-21.981c-5.108-17.417-11.363-34.344-18.665-50.7"
					/>
					<path
						id="square-20-explosed"
						style="visibility: hidden;"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M1029.66436688 483l-56.684 28.861c5.894 14.323 10.771 28.945 14.612 43.82l60.737-21.981c-5.108-17.417-11.363-34.344-18.665-50.7"
					/>
				</g>
				<g id="square-21">
					<path
						id="square-21-compact"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M485.08096688 365.7873c-16.615-2.96-33.576-4.922-50.827-5.788l-9.935 59.024c15.16.444 30.155 1.895 44.944 4.316l15.818-57.552z"
					/>
					<path
						id="square-21-explosed"
						style="visibility: hidden;"
						fill="rgb(4,92,118)"
						fill-rule="evenodd"
						d="M565.74236688 76.788c-16.615-2.96-33.576-4.922-50.827-5.788l-9.935 59.024c15.16.444 30.155 1.895 44.944 4.316l15.818-57.552z"
					/>
				</g>
			</g>
		</g>
	</svg>
</div>
