import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import {
	FormationAdministrativeStatus,
	FormationSessionStatus,
} from '@app/models/formation';
import { User } from '@app/models/user';

export type FilterFormationType = {
	sponsor?: User;
	stateAdmin?: string;
	state?: string;
	dateRange?: string[] | Date[];
};

export type FilterSelectType = {
	value: FormationAdministrativeStatus | FormationSessionStatus;
	label: string;
};

@Component({
	selector: 'app-atom-formation-filter-panel',
	templateUrl: './atom-formation-filter-panel.component.html',
	styleUrls: ['./atom-formation-filter-panel.component.less'],
})
export class AtomFormationFilterPanelComponent {
	@Input() isOpen = false;
	@Input() states?: FilterSelectType[];
	@Input() statesAdmin?: FilterSelectType[];

	@Output() closePanel = new EventEmitter<boolean>();
	@Output() filterFormation = new EventEmitter<FilterFormationType>();

	form = this.formBuilder.group({
		sponsor: new FormControl(),
		stateAdmin: new FormControl(),
		state: new FormControl(),
		dateRange: new FormControl(),
	});

	constructor(private formBuilder: FormBuilder) {}

	handleFilter() {
		this.filterFormation.emit(this.form.value as FilterFormationType);
	}
}
