import { Injectable } from '@angular/core';
import { AxeSurvey, AxeSurveyInterface, AxeSurveyPayload } from './axe-survey';
import { AxeSurveySearchParamsInterface } from './axe-survey-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class AxeSurveyService extends BaseModelService<
	AxeSurvey,
	AxeSurveyInterface,
	AxeSurveyPayload,
	AxeSurveySearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'axe-survey';
	}
	/** @inheritDoc */
	protected new(object: AxeSurveyInterface): AxeSurvey {
		return new AxeSurvey(object);
	}
}
